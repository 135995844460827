import React from 'react';
import './author.styles.scss';


const AuthorViewMode = (props) =>{

    const updateInfo = (props.updated !== '' && props.updated !== null && props.updated !== undefined) ? props.updated : props.created;

    const searchLink = '/parceiros/' + props.author; 

    const avatarImg = (props.authorAvatar === null || props.authorAvatar === '' || props.authorAvatar === 0) ? '0.jpg' : props.authorAvatar;

    const avatar = 'url("' + window.$imagesPath + '/users/' + avatarImg + '")';

    const formatDate = (date, withHour) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' às ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }

    return(
        
            <div className='module-author'>
                <a href={searchLink}>
                    <div className='avatar' style={{backgroundImage: avatar}}></div>
                </a>
                <div className='info'>
                    <div className='author-info'>Postado por <a href={searchLink}>{props.authorName}</a></div>
                    <div className='update-info'>Última atualização em {formatDate(updateInfo, true)}</div>
                </div>
            </div>
        )

}

export default AuthorViewMode;
