import React, {Component} from 'react';
import './category-manager.styles.scss';

import Grid from '@material-ui/core/Grid';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from "@material-ui/core/CircularProgress";

import {getToken, getType} from "../../utils/user-validation/user-validation.utils";
import validate from "../../utils/form-validation/form-validation.utils";

class CategoryManager extends Component{

    constructor(props){
        super(props);
        this.state = {
            userType: getType(),
            loading: false,
            categories: [],
            formItems: {
                category: {
                    id: "category",
                    label: "Categoria",
                    placeholder: "Nome da categoria",
                    value: "",
                    hasTouched: false,
                    hasError: false,
                    errorMsg: "",
                    rules: {
                      required: true,
                      maxLength: 255
                    },
                  },
                  parent: {
                    id: "parent",
                    label: "Criar em",
                    placeholder: "",
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: "",
                    rules: [],
                  },
                  editId: {
                    id: "editId",
                    label: "Id",
                    placeholder: "",
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: "",
                    rules:[],
                  },
                  editCategory: {
                    id: "editCategory",
                    label: "Categoria",
                    placeholder: "Nome da categoria",
                    value: "",
                    hasTouched: false,
                    hasError: false,
                    errorMsg: "",
                    rules: {
                      required: true,
                      maxLength: 255
                    },
                  },
                  editParent: {
                    id: "editParent",
                    label: "Criar em",
                    placeholder: "",
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: "",
                    rules: [],
                  },
            },
            selected: 0,
            view: 'list',
            editing: 0,
            deleting: 0
        }
    }

    componentDidMount(){
        if(this.props.source){
          this.setState({
            categories: this.props.source,
            selected: this.props.selected
        });
        }
    }

    componentDidUpdate(prevProps){

      if(prevProps.source !== this.props.source){
        const formItems = this.state.formItems;
        formItems.category.value = '';
        formItems.parent.value = 0;
        formItems.editId.value = 0;
        formItems.editCategory.value = '';
        formItems.editParent.value = 0;
        this.setState({
              loading: false,
              editing: 0,
              deleting: 0,
              formItems: formItems,
              view: 'list',
              categories: this.props.source});
      }

      if(prevProps.selected !== this.props.selected){
        this.setState({selected: this.props.selected});
      }

    }

    deleteCategory = (id) => {

        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: id,
          }),
        };
    
        fetch("/api/category_delete.php", requestOptions)
          .then((data) => data.json())
          .then((data) => {
            
                if(data.success){
                    this.props.getCategories();
                }else{
                    console.log(data.feedback);
                }

          });

    }

    addCategory = () => {

        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            category: this.state.formItems.category.value,
            parent: this.state.formItems.parent.value
          }),
        };
    
        fetch("/api/category_add.php", requestOptions)
          .then((data) => data.json())
          .then((data) => {
            
                if(data.success){
                    this.props.getCategories();
                }else{
                    console.log(data.feedback);
                }

          });

    }

    updateCategory = () => {

        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: this.state.formItems.editId.value,
            category: this.state.formItems.editCategory.value,
            parent: this.state.formItems.editParent.value
          }),
        };
    
        fetch("/api/category_update.php", requestOptions)
          .then((data) => data.json())
          .then((data) => {
            
                if(data.success){
                    this.props.getCategories();
                }else{
                    console.log(data.feedback);
                }

          });

    }

    render(){

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;
  
        // Get current FormItems object from State
        const formItems = {
          ...this.state.formItems,
        };
  
        // Get State of the Input
        const formItem = {
          ...formItems[name],
        };
  
        // Validate Element if it has been Touched Before
        if (formItem.hasTouched) {
          if (formItem.rules) {
            const validation = validate(value, formItem.rules);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
          } else {
            formItem.hasError = false;
            formItem.errorMsg = "";
          }
        }
  
        // Set the new value to element
        formItem.value = value;
  
        // Update formItems collection
        formItems[name] = formItem;
  
        // Update State
        this.setState({
          ...this.state,
          formItems: formItems,
        });
  
        if (callbacks) {
          callbacks.map((item) => {
            return item(event);
          });
        }
      };
  
      // FORMS: INPUT BLUR HANDLE FUNCTION
      const handleBlur = (event) => {
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;
  
        // formItems collection
        const formItems = {
          ...this.state.formItems,
        };
  
        // state of modified element
        const formItem = {
          ...formItems[name],
        };
  
        formItem.hasTouched = true;
        if (formItem.rules) {
          const validation = validate(value, formItem.rules);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
        } else {
          formItem.hasError = false;
          formItem.errorMsg = "";
        }
  
        // Update formItems collection
        formItems[name] = formItem;
  
        // Update state
        this.setState({
          ...this.state,
          formItems: formItems,
        });
      };

      const selectCategory = (id) => {
          let value = 0
          if(this.state.selected !== id){
            value = id;
          }

          this.setState({selected: value}, () => this.props.action(value));

      }

      const toggleView = (view) => {
          this.setState({view: view});
      }

      const editCategory = (id) => {

        // Get category
        const obj = this.state.categories.filter((item) => (item.categoryId === id));
        console.log(obj);
        const formItems = this.state.formItems;
        formItems.editCategory.value = obj[0].categoryTitle;
        formItems.editParent.value = obj[0].categoryParent;
        formItems.editId.value = obj[0].categoryId;

        this.setState({view: 'edit', formItems: formItems});

      }

      const eraseCategory = (id) => {
          this.setState({deleting: id, loading: true}, () => this.deleteCategory(id));
      }

      const categories = this.state.categories.filter((item) => (item.categoryParent === 0 && (item.allowChildren || this.state.userType > 4)));
      const parentCategories = this.state.categories.filter((item) => (item.categoryParent === 0));
      

        return(
            <div className={(this.props.visible) ? 'category-manager' : 'category-manager hidden'}>
                <div className="window">
                    <div className="window-title">
                        <h2 className="title">Categorias</h2>
                        <button className="close-button" onClick={() => this.props.closeFunction()}><CloseIcon /> </button>
                    </div>
                    <div className="window-content">

                        <div className={(this.state.view === 'add') ? 'categories-form' : 'categories-form hidden'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={this.state.formItems.category.hasError}
                                    >
                                        <TextField
                                            name="category"
                                            id={this.state.formItems.category.id}
                                            value={this.state.formItems.category.value}
                                            label={this.state.formItems.category.label}
                                            placeholder={this.state.formItems.category.placeholder}
                                            error={this.state.formItems.category.hasError}
                                            helperText={this.state.formItems.category.errorMsg}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            error={this.state.formItems.parent.hasError}
                                        >
                                        <InputLabel htmlFor={this.state.formItems.parent.id} >{this.state.formItems.parent.label}</InputLabel>
                                        <Select
                                            native
                                            variant="outlined"
                                            fullWidth
                                            name={this.state.formItems.parent.id}
                                            id={this.state.formItems.parent.id}
                                            value={this.state.formItems.parent.value}
                                            label={this.state.formItems.parent.label}
                                            inputlabelprops={{shrink: false}}
                                            size="small"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            >
                                              {
                                              (this.state.userType > 4)?
                                              <option key={'parent-0'} value="0">Raiz</option>:
                                              null
                                              }

                                            {categories.map((item) => {
                                                return (<option key={"parent-" + item.categoryId} value={item.categoryId}>{item.categoryTitle}</option>);
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid className="btn-wrapper side-by-side on-right" item xs={12}>
                                    <Button  variant="outlined" color="default" onClick={() => toggleView('list')}>Cancelar</Button>
                                    <Button variant="contained" color="primary" onClick={this.addCategory}>Adicionar</Button>
                                </Grid>
                            </Grid>
                        </div>

                        <div className={(this.state.view === 'edit') ? 'categories-edit-form' : 'categories-edit-form hidden'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={this.state.formItems.editCategory.hasError}
                                    >
                                        <TextField
                                            name="editCategory"
                                            id={this.state.formItems.editCategory.id}
                                            value={this.state.formItems.editCategory.value}
                                            label={this.state.formItems.editCategory.label}
                                            placeholder={this.state.formItems.editCategory.placeholder}
                                            error={this.state.formItems.editCategory.hasError}
                                            helperText={this.state.formItems.editCategory.errorMsg}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        error={this.state.formItems.editParent.hasError}
                                    >
                                        <InputLabel htmlFor={this.state.formItems.editParent.id} >{this.state.formItems.editParent.label}</InputLabel>
                                        <Select
                                            native
                                            name="editParent"
                                            variant="outlined"
                                            fullWidth
                                            name={this.state.formItems.editParent.id}
                                            id={this.state.formItems.editParent.id}
                                            value={this.state.formItems.editParent.value}
                                            label={this.state.formItems.editParent.label}
                                            inputlabelprops={{shrink: false}}
                                            size="small"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            >
                                            <option key={'edit-parent-0'} value="0">Raiz</option>
                                            {categories.filter((item) => (item.categoryId !== this.state.formItems.editId.value)).map((item) => {
                                                return (<option key={"edit-parent-" + item.categoryId} value={item.categoryId}>{item.categoryTitle}</option>);
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid className="btn-wrapper side-by-side on-right" item xs={12}>
                                    <Button  variant="outlined" color="default" onClick={() => toggleView('list')}>Cancelar</Button>
                                    <Button variant="contained" color="primary" onClick={this.updateCategory}>Atualizar</Button>
                                </Grid>
                            </Grid>
                        </div>

                        

                        <div className={(this.state.view === 'list') ? 'categories-list' : 'categories-list hidden'}>
                            <div className="toolbar">
                                <Grid container spacing={2}>
                                    <Grid className="right" item xs={12}>
                                        <Button variant="contained" color="primary" onClick={() => toggleView('add')} endIcon={<AddIcon />}>Criar Categoria</Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="list">
                            <div className={(this.state.loading) ? 'loading' : 'loading hidden'}><CircularProgress size={36} /></div>
                            {parentCategories.map((item) => {
                                
                                let keyCat = 'category-' + item.categoryId;
                                let children = this.state.categories.filter((sub) => (sub.categoryParent === item.categoryId));
                                let hasChildren = (children.length > 0);
                                return(
                                    <React.Fragment key={keyCat}>
                                    <Grid className={(hasChildren) ? 'category with-children' : 'category'} container spacing={2}>
                                        <Grid item xs={9}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    checked={(item.categoryId === this.state.selected)}
                                                    onChange={() => selectCategory(item.categoryId)}
                                                    color="secondary"
                                                    />
                                                }
                                                label={item.categoryTitle}
                                            />
                                            
                                        </Grid>
                                    
                                        <Grid className="right" item xs={3}>
                                            <IconButton onClick={() => editCategory(item.categoryId)} disabled={(item.IsShared || item.IsSystem)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => eraseCategory(item.categoryId)} disabled={(item.IsShared || item.IsSystem)}>
                                                {(this.state.deleting === item.categoryId) ? <CircularProgress size={16} /> : <DeleteIcon />}
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    {
                                        children.map(subcategory => {
                                            let keySub = 'subcategory-' + subcategory.categoryId;
                                            return(
                                                <React.Fragment key={keySub}>
                                                <Grid className="subcategory" container spacing={2}>
                                                    <Grid className="subcategory-title" item xs={9}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                checked={(subcategory.categoryId === this.state.selected)}
                                                                onChange={() => selectCategory(subcategory.categoryId)}
                                                                color="secondary"
                                                                />
                                                            }
                                                            label={subcategory.categoryTitle}
                                                        />
                                                        
                                                    </Grid>
                                                
                                                    <Grid className="right" item xs={3}>
                                                        <IconButton onClick={() => editCategory(subcategory.categoryId)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => eraseCategory(subcategory.categoryId)} disabled={(subcategory.IsSystem)}>
                                                            {(this.state.deleting === subcategory.categoryId) ? <CircularProgress size={16} /> : <DeleteIcon />}
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                    </React.Fragment>
                                );
                            })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default CategoryManager;