//Initial State
const INITIAL_STATE = {
    
        menuActive: 0,
        userMenuIsOpen: false,
        menuItems: []
    }

//Reducer and Actions
const menusReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
        case 'SET_MENU_ACTIVE_ITEM':
            return {
                ...state,
                menuActive: action.payload
            }
        
        case 'TOGGLE_USER_MENU':
            return {
                ...state,
                userMenuIsOpen: !state.userMenuIsOpen
            }
        
        case 'SET_MENU_ITEMS':
            return{
                ...state,
                menuItems: action.payload
            }
      
        default:
            return state
    }
  }

  //Export Reducer
  export default menusReducer;

  