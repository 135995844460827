import React from 'react';
import './box-selection.styles.scss';

const BoxSelection = (props) => {

    let classes = 'box-selection';
    if(props.error){
        classes += ' error';
    }

    return(
        <div className={classes}>
            {
            (props.label)?
            <label>{props.label}</label>:
            null
            }
            <ul id={props.id} name={props.id} className="boxes">
                {
                    props.source.map((item, index) => {
                        
                        let key = 'box-' + props.id + '-' + index;
                        let elClasses = 'box-option';
                        if(item.selected){
                            elClasses += ' selected';
                        }

                        return(
                            <li key={key} className={elClasses} onClick={() => props.action(index)}>{item.label}</li>
                        )
                    })
                }
            </ul>
            <span className="error-message">{props.errorMsg}</span>
        </div>
    )
}

export default BoxSelection;