import React from 'react';
import ClampLines from 'react-clamp-lines';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import StarsIcon from '@material-ui/icons/Stars';
import Avatar from '../../components/avatar/avatar.component';

const BlogCard = (props) => {

    // Format Dates
    const formatDate = (date, withHour) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        let mNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

        sDate = d + ' de ' + mNames[m - 1] + ', ' + y;

        }

        return sDate;

    };

    // Go to a given url page
    const goto = (url) => {
        window.location = '/page/' + url;
    };


    return(
    <div className="post-card" onClick={() => goto(props.url)}>

        {/* Image */}
        <div className='image' style={{backgroundImage: props.thumbFile}}>
            {(props.premium === 1)? (<div className="label"><span><StarsIcon />Premium</span></div>) : null}
        </div>

        {/* BODY */}
        <div className='info'>

            {/* Title and Description */}
            <div className='category'><span>{props.categoryName}</span></div>
            <ClampLines
                text={props.title}
                id={'title-' + props.id}
                buttons={false}
                lines={2}
                ellipsis="..."
                className="title"
                innerElement="div"
            />
            
            <ClampLines
                text={props.description}
                id={'desc-' + props.id}
                buttons={false}
                lines={2}
                ellipsis="..."
                className="desc"
                innerElement="div"
            />
            <div className='desc'></div>

            {/* Nest Classes when training */}
            {
            (props.nextClass)?
            <div className="next-class">
                <span>Proxima turma em {formatDate(props.nextClass.StartDate, false)}. <a href={'/page/' + props.url}>Saiba mais</a></span>
            </div>:
            null
            }

            {/* Card Footer */}
            <div className='footer'>
                
                {/* Author */}
                <div className='author'>
                    <div className='avatar'><Avatar size='micro' source={props.authorAvatar} /></div>
                    <div className='info'>
                        <span className="name">{props.authorName}</span>
                        {
                        (props.category === 8 || props.category === 16)?
                        <span className="company">{props.authorCompany}</span>:
                        <span className="date">{formatDate(props.published, false)}</span>
                        }
                    </div>
                </div>
                
                {/* Tools */}
                <div className='tools'>
                    <div className="tool"><ChatBubbleIcon size='inherit' /><span>{props.comments}</span></div>
                    <div className="tool"><FavoriteIcon /><span>{props.favorites}</span></div>
                    <div className="tool"><BookmarkIcon /><span>{props.bookmarks}</span></div>
                </div>
                
            </div>

        </div>
    </div>
    );
};

export default BlogCard;