import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './testimonials.styles.scss';

// Import UI component
import TextField from "@material-ui/core/TextField";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';

// Import components
import IptImage from '../../components/ipt-image/ipt-image.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class TestimonialForm extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            formItems: {
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: '',
                    value: this.props.text,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
                author: {
                    id: 'author',
                    label: 'Autor',
                    placeholder: '',
                    value: this.props.author,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 25
                    }
                },
                job: {
                    id: 'job',
                    label: 'Função / Título',
                    placeholder: '',
                    value: this.props.job,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 25
                    }
                },
                company: {
                    id: 'company',
                    label: 'Empresa / Instituição',
                    placeholder: '',
                    value: this.props.company,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        requiredIfFilled: {id: 'companyLink', label: 'Link'},
                        maxLength: 25
                    }
                },
                companyLink: {
                    id: 'companyLink',
                    label: 'Link',
                    placeholder: '',
                    value: this.props.companyLink,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                        link: true
                    }
                },
                avatar: {
                    id: 'Imagem',
                    label: 'Imagem',
                    placeholder: '',
                    value: this.props.avatar,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                avatarId: {
                    id: 'Imagem',
                    label: 'Imagem',
                    placeholder: '',
                    value: this.props.avatarId,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                }
            },
        }
    }


    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            checkErrors();

            this.props.changeItem(this.props.itemId, name, value);

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            checkErrors();

        }

        // Set Image
        const setImage = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.avatar.value = obj[0].mediaFile;
            formItems.avatarId.value = obj[0].mediaId;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();

            this.props.changeItem(this.props.itemId, 'avatar', obj[0].mediaFile);
            this.props.changeItem(this.props.itemId, 'avatarId', obj[0].mediaId);
    
        }

        // Remove Image
        const unsetImage = () => {
    
            const formItems = this.state.formItems;
            formItems.avatar.value = '';
            formItems.avatarId.value = 0;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            });

            this.props.changeItem(this.props.itemId, 'avatar', '');
            this.props.changeItem(this.props.itemId, 'avatarId', 0);
    
        }
        
        // Set Link
        const setLink = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.companyLink.value = url;
            
    
            formItems.companyLink.hasTouched = true;
            const validation = validate(url, formItems.companyLink.rules, formItems);
            formItems.companyLink.hasError = validation[0];
            formItems.companyLink.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });

            checkErrors();

            this.props.cardChange(this.props.itemId, 'companyLink', url);
    
            this.props.closeDirectory();
    
        }

        // Check for errors
        const checkErrors = () => {

            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;
            let hasError = false;
            
            for(let i=0; i < size; ++i){
                
                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    hasError = true;
                }
            }

            this.props.validateItem(this.props.itemId, hasError);


        }

        const cardClasses = (this.props.hasError) ? 'testimonial-card-form card has-error' : 'testimonial-card-form card';

        return(
            <div className={cardClasses}>

                { /* Image */ }
                <div className="avatar">
                    <IptImage 
                    className="image" 
                    limit={1} 
                    action={setImage}
                    clearFunction = {unsetImage}
                    selectedFile={this.state.formItems.avatar.value} 
                    selectedImage={this.state.formItems.avatarId.value} 
                    />
                </div>

                { /* Text */ }
                <div className="text">
                    <TextField
                    name={this.state.formItems.text.id}
                    id={this.state.formItems.text.id + this.props.itemId}
                    value={this.state.formItems.text.value}
                    label={this.state.formItems.text.label}
                    placeholder={this.state.formItems.text.placeholder}
                    error={this.state.formItems.text.hasError}
                    helperText={this.state.formItems.text.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                </div>

                { /* Signature */ }
                <div className="signature">
                    <TextField
                    name={this.state.formItems.author.id}
                    id={this.state.formItems.author.id + this.props.itemId}
                    value={this.state.formItems.author.value}
                    label={this.state.formItems.author.label}
                    placeholder={this.state.formItems.author.placeholder}
                    error={this.state.formItems.author.hasError}
                    helperText={this.state.formItems.author.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                </div>

                { /* Function */ }
                <div className="function">
                    <TextField
                    name={this.state.formItems.job.id}
                    id={this.state.formItems.job.id + this.props.itemId}
                    value={this.state.formItems.job.value}
                    label={this.state.formItems.job.label}
                    placeholder={this.state.formItems.job.placeholder}
                    error={this.state.formItems.job.hasError}
                    helperText={this.state.formItems.job.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                </div>

                { /* Company */ }
                <div className="company">
                    <TextField
                    name={this.state.formItems.company.id}
                    id={this.state.formItems.company.id + this.props.itemId}
                    value={this.state.formItems.company.value}
                    label={this.state.formItems.company.label}
                    placeholder={this.state.formItems.company.placeholder}
                    error={this.state.formItems.company.hasError}
                    helperText={this.state.formItems.company.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                </div>

                { /* Company Link */ }
                <div className="link">
                    <TextField
                    name={this.state.formItems.companyLink.id}
                    id={this.state.formItems.companyLink.id}
                    value={this.state.formItems.companyLink.value}
                    label={this.state.formItems.companyLink.label}
                    placeholder={this.state.formItems.companyLink.placeholder}
                    error={this.state.formItems.companyLink.hasError}
                    helperText={this.state.formItems.companyLink.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                            aria-label="Procurar conteúdo"
                            edge="end"
                            onClick={() => this.props.openDirectory(1, setLink)}
                            >
                            <DescriptionIcon /> 
                            </IconButton>
                        </InputAdornment>,
                    }}
                    />
                </div>

                <div className="buttons">
                    <div className="btn-wrapper centered">
                        <button type="button" className="btn-circle attention medium" onClick={(e) => this.props.removeItem(e, this.props.itemId)} ><DeleteIcon /></button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(TestimonialForm));