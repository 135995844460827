import React, { Component } from "react";
import "./posts.styles.scss";

import Post from "./post";
import Carrousel from "../../components/carrousel/carrousel.component";

class PostsViewMode extends Component {
  //Constructor
  constructor(props) {
    super(props);
    this.current = 1;
    this.currentPos = 0;
    this.itemWidth = 0;
    this.touchstartX = 0;
    this.touchendX = 0;
    this.screenWidth = window.innerWidth;
    this.viewMode =
      window.innerWidth >= 1024
        ? "desktop"
        : window.innerWidth > 767
        ? "tablet"
        : "mobile";
    this.hasCarrousel = false;

    this.state = {
      loading: false,
      current: 1,
      items: [],
      total: 0,
      qtd: 0
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
    });

    const qtd = (this.props.wide === "full") ? 5 : (this.props.wide === 'sidebar') ? 3 : 4;

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: JSON.stringify({
        app: window.$appToken,
        category: this.props.category,
        subcategory: this.props.subcategory ? this.props.subcategory : 0,
        author: this.props.author ? this.props.author : 0,
        qtd: qtd,
      }),
    };

    fetch("/api/get_last_posts.php", requestOptions)
      .then((data) => data.json())
      .then((data) => {
        this.setState({
          items: data.items,
          total: data.total,
          qtd: qtd
        });
      });
  }

  controlViewMode = () => {
    const windowWidth = window.innerWidth;
    let updatedViewMode = "desktop";

    if (windowWidth <= 1024) {
      updatedViewMode = "tablet";
    }

    if (windowWidth < 768) {
      updatedViewMode = "mobile";
    }

    console.dir("current: " + this.viewMode);
    console.dir("new: " + updatedViewMode);

    if (updatedViewMode !== this.viewMode) {
      console.dir("Viewmode has changed to " + updatedViewMode);

      // update viewmode
      this.viewMode = updatedViewMode;

      if (updatedViewMode !== "desktop") {
        const carrousel = document.getElementById(
          "content-" + this.props.contentId
        );
        const slidesWrapper = carrousel.querySelector(".slides");
        console.dir(slidesWrapper);
        slidesWrapper.style.transform = "translateX(0px)";

        if (!this.carrousel) {
          console.dir("Carrousel is on");
          this.initCarrousel();
        }
      }

      if (updatedViewMode === "desktop" && this.hasCarrousel) {
        console.dir("Carrousel is off");
        this.destroyCarrousel();
      }
    }
  };

  // Destroy the carrousel
  destroyCarrousel = () => {
    const carrousel = document.getElementById(
      "content-" + this.props.contentId
    );
    const slidesWrapper = carrousel.querySelector(".slides");
    const swipeArea = document.getElementById(
      "swipe-area-" + this.props.contentId
    );

    // Remove clones
    const firstCloneId = this.props.contentId + "-first-clone";
    const lastCloneId = this.props.contentId + "-last-clone";
    const firstClone = document.getElementById(firstCloneId);
    const lastClone = document.getElementById(lastCloneId);
    if (firstClone) {
      firstClone.remove();
    }
    if (lastClone) {
      lastClone.remove();
    }

    // Hide controls and navigate bars
    const controls = carrousel.querySelector(".controls");
    const navigate = carrousel.querySelector(".navigate");
    controls.classList.add("hidden");
    navigate.classList.add("hidden");

    // Remove event listeners
    slidesWrapper.removeEventListener("transitionstart", this.updateNavigate);
    slidesWrapper.removeEventListener("transitionend", this.cloneSlides);
    swipeArea.removeEventListener("touchstart", this.swipeStart, false);
    swipeArea.removeEventListener("touchend", this.swipeEnd, false);

    // Reset slider position
    slidesWrapper.style.transform = "translateX(0px)";

    this.hasCarrousel = false;
  };

  // Initialize the carrousel
  initCarrousel = () => {
    //const { current } = this.state;
    const carrousel = document.getElementById(
      "content-" + this.props.contentId
    );
    const slidesWrapper = carrousel.querySelector(".slides");
    let slides = carrousel.querySelectorAll(".slide");

    if (slides.length > 1) {
      const controls = carrousel.querySelector(".controls");
      const navigate = carrousel.querySelector(".navigate");
      controls.classList.remove("hidden");
      navigate.classList.remove("hidden");

      // Check slides number per page and needed clones
      let perPage =
        this.viewMode === "desktop" ? 4 : this.viewMode === "tablet" ? 2 : 1;
      let cardWidth = 100 / perPage + "%";
      const totalItems = slides.length;

      slides.forEach((item) => {
        item.style.width = cardWidth;
      });

      this.itemWidth = slides[0].clientWidth;

      const slideWidth = slides[this.current].clientWidth;

      this.detectSwipe();

      this.hasCarrousel = true;
    }
  };

  swipeStart = (event) => {
    this.touchstartX = event.changedTouches[0].screenX;
  };

  swipeEnd = (event) => {
    this.touchendX = event.changedTouches[0].screenX;
    this.handleGesture(event);
  };

  handleGesture = (e) => {
    //Left Swipe -> go to next
    if (this.touchendX < this.touchstartX) {
      this.nextSlide();
    }

    // Right Swipe -> go to previous
    if (this.touchendX > this.touchstartX) {
      this.previousSlide();
    }
  };

  // Detect swipe gestures on mobile
  detectSwipe = () => {
    let touchstartX = 0;
    let touchendX = 0;

    const swipeArea = document.getElementById(
      "swipe-area-" + this.props.contentId
    );

    swipeArea.addEventListener("touchstart", this.swipeStart, false);

    swipeArea.addEventListener("touchend", this.swipeEnd, false);
  };

  // Go to next slide
  nextSlide = () => {
    const carrousel = document.getElementById(
      "content-" + this.props.contentId
    );

    if (carrousel) {
      const slidesWrapper = carrousel.querySelector(".slides");
      const slides = carrousel.querySelectorAll(".slide");

      // Clone the first item
      const clonedItem = slides[0].cloneNode(true);
      slides[0].classList.add("to-remove");
      slidesWrapper.append(clonedItem);
      slidesWrapper.style.transition = ".7s ease-out";
      slidesWrapper.style.transform = `translateX(${-this.itemWidth}px)`;
    }
  };

  // Go to previous slide
  previousSlide = () => {
    const carrousel = document.getElementById(
      "content-" + this.props.contentId
    );

    if (carrousel) {
      const slidesWrapper = carrousel.querySelector(".slides");
      const slides = carrousel.querySelectorAll(".slide");

      // Clone the last item
      const clonedItem = slides[slides.length - 1].cloneNode(true);
      slides[slides.length - 1].classList.add("to-remove");
      slidesWrapper.prepend(clonedItem);
      slidesWrapper.style.transition = "none";
      slidesWrapper.style.transform = `translateX(${-this.itemWidth}px)`;
      slidesWrapper.style.transition = ".7s ease-out";
      //slidesWrapper.style.transform = `translateX(0px)`;
    }
  };

  // Go to a given slide
  gotoSlide = (slide) => {
    const carrousel = document.getElementById(
      "content-" + this.props.contentId
    );

    if (carrousel) {
      const slidesWrapper = carrousel.querySelector(".slides");
      const slides = carrousel.querySelectorAll(".slide");
      const slideWidth = slides[this.current].clientWidth;

      this.current = slide;
      slidesWrapper.style.transition = ".7s ease-out";
      slidesWrapper.style.transform = `translateX(${-slideWidth *
        this.current}px)`;
    }
  };

  // Update navigate dots
  updateNavigate = () => {
    const carrousel = document.getElementById(
      "content-" + this.props.contentId
    );
    const dots = carrousel.querySelectorAll(".dot");

    let current = this.current;
    const limit = this.state.items.length + 1;

    if (current === 0) {
      current = limit - 1;
    }

    if (current === limit) {
      current = 1;
    }

    dots.forEach((item) => {
      let itemIndex = parseInt(item.getAttribute("data-index"));
      if (itemIndex === current) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  };

  render(props) {

    const {total, qtd } = this.state;
    const seemoreurl = (this.props.author) ? '/blog?author=' + this.props.author : '/blog';

    return (
      <div
        key={"content-" + this.props.contentId}
        id={"content-" + this.props.contentId}
        className="module__posts"
      >
        {
          (total > qtd && this.props.category === 3) ? 
          <div className="see-all"><a href={seemoreurl}>Ver todos<span className="material-icons">arrow_forward</span></a></div>:
          null
        }
        {this.state.items.length > 0 ? (
          <Carrousel
            id={"carrousel-" + this.props.contentId}
            itemsPerPage={qtd}
            showControls
            showDots
          >
            {this.state.items.map((item, index) => {
              let itemId = "post-" + this.props.contentId + "-" + index;

              return <Post key={itemId} {...item} />;
            })}
          </Carrousel>
        ) : (
          <div className="no-items">Nenhum item publicado.</div>
        )}
      </div>
    );
  }
}

export default PostsViewMode;
