import React, {Component} from 'react';
import './blog.styles.scss';

// UI Componentes
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Import Components
import Pagination from '../../components/pagination/pagination.component';
import BlogCard from './blog-card.component';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';



class BlogViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            formItems: {
                keySearch: {
                    id: 'keySearch',
                    label: 'Procurar por',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                author:{
                    id: 'author',
                    label: 'Autor',
                    placeholder: '',
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                }
            },
            sources: [
                {id: 0, name: 'Todos'}
            ],
            results: [],
            pagination: {
                page: 1,
                pages: 1,
                total: 0
            },
            noResults: false

        }
    }

    getPosts = (page) => {

        this.setState({
          ...this.state,
          loading: true,
          results: []
        });

        const author = (this.props.author) ? this.props.author : (this.props?.urlParameters?.author) ? parseInt(this.props.urlParameters.author) : 0;
  
        const searchKey = this.state.formItems.keySearch.value;
  
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            app: window.$appToken,
            page: page,
            searchKey: searchKey,
            author: author,
            category: this.props.category,
            subcategory: this.props.subcategory
          }),
        };
  
        fetch("/api/get_blog.php", requestOptions)
        .then(async (response) => {
  
          const data = await response.json();
  
          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          
          const pagination = this.state.pagination;
          pagination.total = data.totalItems;
          pagination.page= data.currentPage;
          pagination.pages = data.totalPages;
          const results = data.items;

          const noResults = (results.length === 0);
  
          this.setState({ 
            ...this.state, 
            pagination: pagination,
            results: results,
            loading: false,
            noResults: noResults
          });
  
        })
        .catch((error) => {
          console.dir(error);
        });
  
      }

    componentDidMount(){

        const formItems = this.state.formItems;
        formItems.keySearch.value = (this.props.keyword) ? this.props.keyword : '';

        this.setState({
            ...this.state,
            formItems: formItems
        }, this.getPosts(1));
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // Format Data
        const formatDate = (date, withHour) => {

            let d = date.substr(8, 2);
            let m = date.substr(5, 2);
            let y = date.substr(0, 4);
            let sDate = '';

            let mNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

            if(withHour){

            let h = date.substr(11,2);
            let n = date.substr(14,2);
            sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

            }else{

            sDate = d + ' de ' + mNames[m - 1] + ', ' + y;

            }

            return sDate;

        }

        const submitByKeyboard = (e) => {
            
            if(e.keyCode === 13){
                this.getPosts(1);
            }
        }

        const goto = (url) => {
            window.location = '/page/' + url;
        }

        return(
            <div className="module__blog">
                <div className="search-bar">
                    
                        <TextField
                        name={this.state.formItems.keySearch.id}
                        id={this.state.formItems.keySearch.id}
                        value={this.state.formItems.keySearch.value}
                        label={this.state.formItems.keySearch.label}
                        placeholder={this.state.formItems.keySearch.placeholder}
                        error={this.state.formItems.keySearch.hasError}
                        helperText={this.state.formItems.keySearch.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={submitByKeyboard}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                aria-label="Procurar conteúdo"
                                edge="end"
                                onClick={() => this.getPosts(1)}
                                >
                                <SearchIcon /> 
                                </IconButton>
                            </InputAdornment>,
                        }}
                        />
                   
                   
                </div>
                <div className="blog-items">
                    <div className={(this.state.loading) ? 'loading' : 'loading hidden'}>
                        <CircularProgress size={48} />
                    </div>
                    <div className={(this.state.noResults) ? 'no-results' : 'no-results hidden'}>
                        <HighlightOffIcon fontSize="large" />
                        <p>
                            <span>Ops, nenhum abriccó por aqui...</span>
                            Nenhum conteúdo encontrado, tente refazer sua busca.
                        </p>
                    </div>
                    {
                        this.state.results.map((item, index) => {

                            let postKey = 'post-' + this.props.contentId + '-' + index;
                            let thumb = (item.thumb) ? 'url("' + window.$imagesPath + item.thumbSrc + '")' : 'none';

                            return(
                                <BlogCard key={postKey} id={postKey} thumbFile={thumb} {...item} />
                            )
                        })
                    }
                    
                </div>
                <div className={(this.state.pagination.pages > 1) ? 'pagination-bar' : 'pagination-bar hidden'}>
                    <Pagination 
                      currentPage={this.state.pagination.page} 
                      totalPages={this.state.pagination.pages} 
                      totalItems={this.state.pagination.total}
                      goToFunction={this.getPosts}
                    />
                </div>
            </div>
        );
    }

}

export default BlogViewMode;