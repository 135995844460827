//Initial State
const INITIAL_STATE = {  
    url: '/',
    params: ''
}

//Reducer and Actions
const main = (state = INITIAL_STATE, action) => {
switch (action.type) {

    case 'GOTO_PAGE':
        return{
            ...state,
            url: action.payload.url,
            params: action.payload.params
        }

    default:
        return state
}
}

//Export Reducer
export default main;