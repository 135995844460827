import React from 'react';
import './quotes.styles.scss';

// Import UI Component


const QuotesViewMode = (props) =>{

    const renderAuthor = () => {

        let obj = '';

        if(props.author !== '' && props.jobTitle !== ''){
            obj += ', ' + props.jobTitle;
        }




        if(props.author !== ''){
            if(props.company !== '' && props.companyLink !== ''){
                return(
                    <div className="author">
                        <span className="name">{props.author}</span>{obj} em <a href={props.companyLink} target="_blank" rel="noopener noreferrer">{props.company}</a> 
                    </div>
                );

            }else if(props.company !== '' && props.companyLink === ''){
                return(
                    <div className="author">
                        <span className="name">{props.author}</span>{obj} em {props.company} 
                    </div>
                );
            }else{
                return(
                    <div className="author">
                        <span className="name">{props.author}</span>{obj}
                    </div>
                );
            }
            
        }

        return;

    }


    

    return(
    <div className='module-quotes'>
       <div className="quote">
           <div className="text">{props.text}</div>
           {renderAuthor()}
        </div>
    </div>
    );

}

export default QuotesViewMode;