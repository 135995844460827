import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './image.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

// Import components
import IptImage from '../../components/ipt-image/ipt-image.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class ImageEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            sizes: [
                {
                    label: 'Pequeno',
                    value: 'small'
                },
                {
                    label: 'Médio',
                    value: 'medium'
                },
                {
                    label: 'Grande',
                    value: 'large'
                },
                {
                    label: 'Real',
                    value: 'module'
                },
                {
                    label: 'Avatar Pequeno',
                    value: 'avatar-small'
                },
                {
                    label: 'Avatar Grande',
                    value: 'avatar-large'
                },
                

            ],
            align: [
                {
                    label: 'Centralizada',
                    value: 'centered'
                },
                {
                    label: 'À esquerda',
                    value: 'on-left'
                },
                {
                    label: 'À direita',
                    value: 'on-right'
                }
            ],
            selectedImage: null,
            formItems: {
                src: {
                    id: 'src',
                    label: 'Imagem',
                    placeholder: 'Selecione uma imagem',
                    value: this.props.src,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                srcId: {
                    id: 'srcId',
                    label: 'Imagem',
                    placeholder: '',
                    value: this.props.srcId,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                source: {
                    id: 'source',
                    label: 'Fonte',
                    placeholder: 'Fonte da Imagem (opcional)',
                    value: this.props.source,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                sourceUrl: {
                    id: 'sourceUrl',
                    label: 'Site da Fonte',
                    placeholder: 'Website da fonte (opcional)',
                    value: this.props.sourceUrl,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        website: true
                    }
                },
                url: {
                    id: 'url',
                    label: 'Destino',
                    placeholder: 'http://',
                    value: this.props.url,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        link: true
                    }
                },
                alignment: {
                    id: 'alignment',
                    label: 'Alinhamento',
                    placeholder: null,
                    value: this.props.alignment,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                size: {
                    id: 'size',
                    label: 'Tamanho',
                    placeholder: null,
                    value: (this.props.size) ? this.props.size : 'medium',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                legend: {
                    id: 'legend',
                    label: 'Legenda',
                    placeholder: 'Insira uma legenda (opcional)',
                    value: this.props.legend,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                content: {
                    id: 'content',
                    label: 'Conteúdo Interno',
                    placeholder: '',
                    value: this.props.content,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                width: {
                    id: 'width',
                    label: '',
                    placeholder: '',
                    value: this.props.width,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                height: {
                    id: 'height',
                    label: '',
                    placeholder: '',
                    value: this.props.height,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                imgAltText: {
                    id: 'imgAltText',
                    label: '',
                    placeholder: '',
                    value: this.props.imgAltText,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
            }
        }
    }

    componentDidMount(){
       
    }

    render(props){

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
            ...this.state.formItems
        };

        // Get State of the Input
        const formItem = {
            ...formItems[name]
        };

        // Validate Element if it has been Touched Before
        if(formItem.hasTouched){
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
        }

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

        if(callbacks){
          callbacks.map(item => {
            return item(event);
          })
        }

    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        let src = (this.state.formItems.src.value.length > 0) ? this.state.formItems.src.value : 'none.png';
        let srcId = this.state.formItems.srcId.value;
        let source = this.state.formItems.source.value;
        let sourceUrl = this.state.formItems.sourceUrl.value;
        let url = this.state.formItems.url.value;
        let content = this.state.formItems.content.value;
        let alignment = this.state.formItems.alignment.value;
        let legend = this.state.formItems.legend.value;
        let width = (this.state.formItems.width.value > 0) ? this.state.formItems.width.value : 400;
        let height = (this.state.formItems.height.value > 0) ? this.state.formItems.height.value : 300;
        let imgAltText = this.state.formItems.imgAltText.value;
        let size = this.state.formItems.size.value;

        const parameters = {
            src: src,
            srcId: srcId,
            source: source,
            sourceUrl: sourceUrl,
            url: url,
            content: content,
            alignment: alignment,
            legend: legend,
            width: width,
            height: height,
            imgAltText: imgAltText,
            size: size
        }

        this.props.functions.updateModule(this.props.contentId, parameters);

    }

    const setUrl = (itemObj) => {

        const url = '/' + itemObj[0].url;
        const id = itemObj[0].id;

        const formItems = this.state.formItems;
        formItems.url.value = url;
        formItems.content.value = id;

        formItems.url.hasTouched = true;
        const validation = validate(url, formItems.url.rules);
        formItems.url.hasError = validation[0];
        formItems.url.errorMsg = validation[1];

        this.setState({
            ...this.state,
            formItems: formItems
        });

        this.props.closeDirectory();

    }

    const setImage = (obj) => {

        const formItems = this.state.formItems;

        formItems.src.value = obj[0].mediaFile;
        formItems.srcId.value = obj[0].mediaId;
        formItems.width.value = obj[0].mediaWidth;
        formItems.height.value = obj[0].mediaHeight;
        formItems.imgAltText.value = obj[0].mediaTitle;

        this.setState({
            ...this.state,
            formItems: formItems,
        })

        this.props.closeGallery();
    }

    const unsetImage = () => {

        const formItems = this.state.formItems;
        formItems.src.value = '';
        formItems.srcId.value = 0;
        formItems.width.value = 400;
        formItems.height.value = 300;

        this.setState({
            ...this.state,
            formItems: formItems,
        });

    }


        return(
            <form className='image-edit-mode'>
                <Grid container spacing={2}>
                    
                    { /* IMAGE FIELD */ }
                    <Grid item xs={12} sm={4}>
                        <IptImage 
                        className="image" 
                        limit={1} 
                        action={setImage}
                        clearFunction = {unsetImage}
                        selectedFile={this.state.formItems.src.value} 
                        selectedImage={this.state.formItems.srcId.value} 
                        />
                    </Grid>
                    
                    { /* FORM FIELDS */ }
                    <Grid item xs={12} sm={8} className="margin-top-mobile">
                        <Grid container spacing={2}>
                            { /* Alignment */ }
                            <Grid item xs={12} sm={6}>
                            <div className="margin-bottom--sm">
                                <FormControl variant="outlined" fullWidth error={this.state.formItems.alignment.hasError} ref={this.headingType} size="small">
                                    <InputLabel htmlFor="updatedCollection">{this.state.formItems.alignment.label}</InputLabel>
                                    <Select
                                    autoFocus
                                    native
                                    fullWidth
                                    
                                    name={this.state.formItems.alignment.id}
                                    id={this.state.formItems.alignment.id}
                                    value={this.state.formItems.alignment.value}
                                    label={this.state.formItems.alignment.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    >
                                    {this.state.align.map((item) => {
                                        return (
                                        <option
                                            key={"align-type-" + item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </option>
                                        );
                                    })}
                                    </Select>
                                    <FormHelperText>
                                    {this.state.formItems.alignment.errorMsg}
                                    </FormHelperText>
                                </FormControl>
                            </div>
                        </Grid>
                            
                            { /* Size */ }
                            <Grid item xs={12} sm={6}>
                        <div className="margin-bottom--sm">
                            <FormControl variant="outlined" fullWidth error={this.state.formItems.size.hasError} ref={this.headingType} size="small">
                                <InputLabel htmlFor="updatedCollection">{this.state.formItems.size.label}</InputLabel>
                                <Select
                                native
                                fullWidth
                                
                                name={this.state.formItems.size.id}
                                id={this.state.formItems.size.id}
                                value={this.state.formItems.size.value}
                                label={this.state.formItems.size.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                >
                                {this.state.sizes.map((item) => {
                                    return (
                                    <option
                                        key={"align-type-" + item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </option>
                                    );
                                })}
                                </Select>
                                <FormHelperText>
                                {this.state.formItems.size.errorMsg}
                                </FormHelperText>
                            </FormControl>
                        </div>
                    </Grid>
                        </Grid>

                        { /* Legend */ }
                        <Grid item xs={12}>
                            <div className="margin-bottom--sm">
                                <TextField
                                name={this.state.formItems.legend.id}
                                id={this.state.formItems.legend.id}
                                value={this.state.formItems.legend.value}
                                label={this.state.formItems.legend.label}
                                placeholder={this.state.formItems.legend.placeholder}
                                error={this.state.formItems.legend.hasError}
                                helperText={this.state.formItems.legend.errorMsg}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </div>
                        </Grid>

                        { /* Source */ }
                        <Grid item xs={12}>
                            <div className="margin-bottom--sm">
                                <TextField
                                name={this.state.formItems.source.id}
                                id={this.state.formItems.source.id}
                                value={this.state.formItems.source.value}
                                label={this.state.formItems.source.label}
                                placeholder={this.state.formItems.source.placeholder}
                                error={this.state.formItems.source.hasError}
                                helperText={this.state.formItems.source.errorMsg}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </div> 
                        </Grid>
                        
                        { /* Source Url */ }
                        <Grid item xs={12}>
                            <div className="margin-bottom--sm">
                                <TextField
                                name={this.state.formItems.sourceUrl.id}
                                id={this.state.formItems.sourceUrl.id}
                                value={this.state.formItems.sourceUrl.value}
                                label={this.state.formItems.sourceUrl.label}
                                placeholder={this.state.formItems.sourceUrl.placeholder}
                                error={this.state.formItems.sourceUrl.hasError}
                                helperText={this.state.formItems.sourceUrl.errorMsg}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </div> 
                        </Grid>

                        { /*  Url */ }
                        <Grid item xs={12}>
                            
                            <div>
                                <TextField
                                name={this.state.formItems.url.id}
                                id={this.state.formItems.url.id}
                                value={this.state.formItems.url.value}
                                label={this.state.formItems.url.label}
                                placeholder={this.state.formItems.url.placeholder}
                                error={this.state.formItems.url.hasError}
                                helperText={this.state.formItems.url.errorMsg}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        aria-label="Procurar conteúdo"
                                        edge="end"
                                        onClick={() => this.props.openDirectory(1, setUrl)}
                                        >
                                        <DescriptionIcon /> 
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                />

                            </div> 
                            
                        </Grid>

                    </Grid>
                    
                    { /* Buttons */ }
                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={(this.state.formItems.src.value === '')}
                            >Atualizar</Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

  
const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(ImageEditMode));

