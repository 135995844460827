import React from 'react';
import * as IconsLibrary from './index';

const SvgIcon = ({icon, ...rest}) => {

    if(icon){
        let Component = IconsLibrary[icon];

        if(Component && typeof(Component) !== 'undefined'){
            return <Component {...rest} />;
        }

        console.log('ERROR: Icon [' + icon + '] does not exist');
        return null;
    }

    console.log('ERROR: props (icon) is missing');
    return null;

}
export default SvgIcon;