import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./page-editor.styles.scss";

// Import UI components
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SaveIcon from "@material-ui/icons/Save";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StarsIcon from '@material-ui/icons/Stars';
import Switch from "@material-ui/core/Switch";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { CircularProgress } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";

// Import components
import ModuleWrapper from "../../containers/module-wrapper/module-wrapper.component";
import ModuleSelector from "../../components/module-selector/module-selector.component";
import ContentDirectory from "../../components/content-directory/content-directory.component";
import MediaGallery from "../../components/media-gallery/media-gallery.component";
import CategoryManager from "../../components/category-manager/category-manager.component";
import ImageSelector from "../../components/image-selector/image-selector.component";

// Import Redux Actions
import { openModal } from "../../redux/modal/modal.actions";
import {
  openSelector,
  closeSelector,
} from "../../redux/modules-selector/modules-selector.actions";
import {
  openGallery,
  closeGallery,
} from "../../redux/media-gallery/media-gallery.actions";
import {openCategoryManager} from "../../redux/category-manager/category-manager.actions";
import {
  getToken,
  authAction,
} from "../../utils/user-validation/user-validation.utils";

// Import Utils
import validate from "../../utils/form-validation/form-validation.utils";

class PageEditor extends Component {
  //Constructor
  constructor(props) {
    super(props);
    this.KeywordInput = React.createRef();

    this.state = {
      loader: true,
      editingInfo: false,
      savingLoad: false,
      publishingLoad: false,
      hasDataToSave: false,
      categoryManager: false,
      selectedCategory: {
        categoryTitle: null,
        categoryId: 0
      },
      lastSaving: null,
      pageData: {},
      editingModule: 0,
      modulesToDelete: [],
      modules: [],
      categories: [],
      keywords: [],
      formItems: {
        pageTitle: {
          id: "pageTitle",
          label: "Título",
          placeholder: "Digite um título",
          value: "",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: {
            required: true,
          },
        },
        pageCategory: {
          id: "pageCategory",
          label: "Categoria",
          placeholder: "Selecione uma categoria",
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: "Selecione uma categoria",
          rules: [],
        },
        pageDescription: {
          id: "pageDescription",
          label: "Descrição",
          placeholder: "Digite uma Descrição",
          value: "",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: {
            maxLength: 255
          },
        },
        pageKeywords: {
          id: "pageKeywords",
          label: "Palavras-chave",
          placeholder:
            "Keywords são palavras chaves que serão usadas por ferramentas de SEO",
          value: "",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: null,
        },
        pageThumb: {
          id: "pageThumb",
          label: "Thumb",
          placeholder: "",
          value: {
            thumbId: 0,
            thumbFile: "",
          },
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: null,
        },
        pageComments: {
          id: "pageComments",
          label: "Comentários",
          placeholder: "Permitir comentários",
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: null,
        },
        pageSharing: {
          id: "pageSharing",
          label: "Sharing Bar",
          placeholder: "Exibir barra de compartilhamento",
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: null,
        },
        pageHeader: {
          id: "pageHeader",
          label: "Cabeçalho",
          placeholder: "Exibir cabeçalho",
          value: 1,
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: null,
        },
        pageFooter: {
          id: "pageFooter",
          label: "Rodapé",
          placeholder: "Exibir rodapé",
          value: 1,
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: null,
        },
        pagePremium: {
            id: "pagePremium",
            label: "Conteúdo Premium",
            placeholder: "Conteúdo Premium",
            value: 0,
            hasTouched: false,
            hasError: false,
            errorMsg: "",
            rules: null,
          },
      },
      bgOptions: [
        {
          id: 0,
          label: "Branco sólido",
          class: "bg-white",
        },
        {
          id: 1,
          label: "Cinza sólido",
          class: "bg-gray",
        },
      ],
      next: [],
      previous: [],
      categories: []
    };
  }

  componentDidMount() {
    // Verify for Session Modals
    const hasModalToOpen = localStorage.getItem("openModal");
    if (hasModalToOpen && hasModalToOpen === "1") {
      const modalObj = JSON.parse(localStorage.getItem("modalObj"));
      this.props.openModal(modalObj);
    }

    const pageId = this.props.match.params.pageId;
    const userToken = getToken();

    this.setState({
      ...this.state,
      loader: true,
    });

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken,
        id: pageId,
      }),
    };

    fetch("/api/content_edit.php", requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

        if (data.success) {
          const formItems = this.state.formItems;
          formItems.pageTitle.value = data.pageData.title;

          const selectedCategory = {
            categoryTitle: null,
            categoryId: 0
          }

          if (
            data.pageData.categoryId > 0 &&
            data.pageData.category.length > 0
          ) {
            formItems.pageCategory.value = data.pageData.categoryId;
            selectedCategory.categoryTitle = data.pageData.category;
            selectedCategory.categoryId = data.pageData.categoryId;
          } else {
            formItems.pageCategory.value = 0;
          }
          formItems.pageThumb.value = {
            thumbId: data.pageData.thumbId,
            thumbFile: data.pageData.thumbFile,
          };
          if (data.pageData.description) {
            formItems.pageDescription.value = data.pageData.description;
          }

          if (data.pageData.comments) {
            formItems.pageComments.value = data.pageData.comments;
          }

          if (data.pageData.sharing) {
            formItems.pageSharing.value = data.pageData.sharing;
          }
          
          formItems.pageHeader.value = data.pageData.header;
          formItems.pageFooter.value = data.pageData.footer;
          

          if (data.pageData.premium) {
            formItems.pagePremium.value = data.pageData.premium;
          }

          let getkeywords = "";
          if (data.pageData.keywords !== null) {
            getkeywords = data.pageData.keywords
              .split(",")
              .filter(function(el) {
                return el !== null && el !== "";
              });
          }

          let keywords = [];
          if (getkeywords.length > 0) {
            keywords = getkeywords.map((item) => {
              return item.trim();
            });
          }

          this.setState({
            ...this.state,
            loader: false,
            pageData: data.pageData,
            modules: data.modules,
            previous: data.previous,
            next: data.next,
            formItems: formItems,
            selectedCategory: selectedCategory,
            keywords: keywords,
            categories: data.categories
          });
        } else {
          const modalObj = {
            modalIcon: "priority_high",
            modalType: "error",
            modalComponent: "GENERAL_ALERT",
            modalTitle: "Ops...",
            modalParameters: {
              text: data.feedback,
            },
            modalActions: null,
          };

          localStorage.setItem("openModal", 1);
          localStorage.setItem("modalObj", JSON.stringify(modalObj));
          window.location = "/pages";
        }
      })
      .catch((error) => {
        console.dir(error);
      });
  }

  // Get categories
  getCategories = () => {
    
    const userToken = getToken();
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken,
        deep: true,
      }),
    };

    fetch("/api/get_categories.php", requestOptions)
      .then((data) => data.json())
      .then((data) => {
        this.setState({
          categories: data.items,
        });
      });

};

  //Rendering
  render(props) {
    
    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {
      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // Get current FormItems object from State
      const formItems = {
        ...this.state.formItems,
      };

      // Get State of the Input
      const formItem = {
        ...formItems[name],
      };

      // Validate Element if it has been Touched Before
      if (formItem.hasTouched) {
        if (formItem.rules) {
          const validation = validate(value, formItem.rules);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
        } else {
          formItem.hasError = false;
          formItem.errorMsg = "";
        }
      }

      // Set the new value to element
      formItem.value = value;

      // Update formItems collection
      formItems[name] = formItem;

      // Update State
      this.setState({
        ...this.state,
        formItems: formItems,
      });

      if (callbacks) {
        callbacks.map((item) => {
          return item(event);
        });
      }
    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // formItems collection
      const formItems = {
        ...this.state.formItems,
      };

      // state of modified element
      const formItem = {
        ...formItems[name],
      };

      formItem.hasTouched = true;
      if (formItem.rules) {
        const validation = validate(value, formItem.rules);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
      } else {
        formItem.hasError = false;
        formItem.errorMsg = "";
      }

      // Update formItems collection
      formItems[name] = formItem;

      // Update state
      this.setState({
        ...this.state,
        formItems: formItems,
      });
    };

    const handleSwitch = (event) => {
      // Get Name and Value from element
      const name = event.target.name;
      const checked = event.target.checked;

      const formItems = this.state.formItems;
      formItems[name].value = checked ? 1 : 0;

      this.setState({
        ...this.state,
        formItems: formItems,
      });
    };

    const toggleMode = (contentId) => {
      let editing = contentId;
      if (this.state.editingModule === contentId) {
        editing = 0;
      }

      this.setState({
        ...this.state,
        editingModule: editing,
      });
    };

    const toggleWide = (contentId) => {
      const contentData = this.state.modules;

      for (let i = 0; i < contentData.length; ++i) {
        if (contentData[i].contentId === contentId) {
          if (contentData[i].wide === "normal") {
            contentData[i].wide = "full";
          } else {
            contentData[i].wide = "normal";
          }
        }
      }

      this.setState({
        ...this.state,
        modules: contentData,
      });
    };

    const changeContainer = (container, type) => {
      const contentData = this.state.contentData;
      const containers = contentData.containers;

      for (let i = 0; i < containers.length; ++i) {
        if (containers[i].item === container) {
          containers[i].module = type;
        }
      }

      contentData.containers = containers;

      this.setState({
        ...this.state,
        contentData: contentData,
      });
    };

    const orderModule = (position, action) => {
      const modules = this.state.modules;
      const totalModules = modules.length;
      const currentPos = position;
      let checkPos = 1;
      let setPos = -1;

      if (action === "down") {
        checkPos = totalModules;
        setPos = 1;
      }

      if (currentPos !== checkPos && totalModules > 1) {
        const newPos = currentPos + setPos;

        for (let i = 0; i < totalModules; ++i) {
          if (modules[i].order === newPos) {
            modules[i].order = currentPos;
          } else if (modules[i].order === currentPos) {
            modules[i].order = newPos;
          }
        }

        modules.sort((a, b) => (a.order > b.order ? 1 : -1));

        this.setState({
          ...this.state,
          modules: modules,
        });
      }
    };

    const updateModule = (contentId, parameters) => {
      console.dir("Update Module " + contentId);
      console.dir(parameters);
      console.dir("");

      const modules = this.state.modules;

      for (let i = 0; i < modules.length; ++i) {
        if (modules[i].contentId === contentId) {
          modules[i].parameters = parameters;
        }
      }

      this.setState({
        ...this.state,
        editingModule: 0,
        modules: modules,
      });
    };

    const addModule = (module, position) => {
      // Get current page modules
      const pageModules = this.state.modules;

      // Set content ID
      const posItems = this.state.modules.map((item) => {
        return item.contentId;
      });
      let index = 0;
      let found = false;
      while (!found) {
        index = index + 1;
        if (posItems.indexOf(index) === -1) {
          found = true;
        }
      }
      const contentId = index;
      const content = "module-" + index;

      // Set new module to add
      const newModule = {
        id: 0,
        contentId: contentId,
        content: content,
        module: module.module,
        type: module.type,
        hasChild: module.hasChild,
        parent: module.parent,
        willDelete: false,
        wide: "normal",
        parameters: module.parameters,
        order: position + 1,
      };

      // Insert new module into page modules array
      pageModules.splice(position, 0, newModule);

      for (let i = 0; i < pageModules.length; ++i) {
        pageModules[i].order = i + 1;
      }

      this.setState({
        ...this.state,
        modules: pageModules,
        editingModule: contentId,
      });

      this.props.closeSelector();
    };

    const requestConfirmationForDeleteModule = (contentId) => {
      const modalObj = {
        modalIcon: "clear",
        modalType: "error",
        modalComponent: "CONFIRMATION",
        modalTitle: "Tem certeza?",
        modalParameters: {
          text:
            "Este bloco de conteúdo será apagado. Essa operação é irreversível.<br>Tem certeza que quer continuar?",
        },
        modalActions: {
          action: deleteModule,
          parameters: contentId,
        },
      };

      this.props.openModal(modalObj);
    };

    const deleteModule = (contentId) => {
      const modules = this.state.modules;
      const modulesToDelete = this.state.modulesToDelete;
      let itemPos = null;
      let itemId = null;

      for (let i = 0; i < modules.length; ++i) {
        if (modules[i].contentId === contentId) {
          itemPos = i;
          itemId = modules[i].id;
        }
      }

      modules.splice(itemPos, 1);
      modulesToDelete.push(itemId);

      this.setState({
        ...this.state,
        editingModule: 0,
        modules: modules,
        modulesToDelete: modulesToDelete,
      });
    };

    const containerFunctions = {
      toggleMode: toggleMode,
      toggleWide: toggleWide,
      changeContainer: changeContainer,
      orderModule: orderModule,
      updateModule: updateModule,
      deleteModule: requestConfirmationForDeleteModule,
    };

    const backToDirectory = (e) => {
      e.preventDefault();
      const gotoPage = "/pages";
      this.props.history.push(gotoPage);
    };

    const save = (publish) => {
      let valid = true;

      // Check if all the fields were filled
      if (this.state.formItems.pageTitle.value.trim() === "") {
        valid = false;
        const modalObj = {
          modalIcon: "clear",
          modalType: "error",
          modalComponent: "GENERAL_ALERT",
          modalTitle: "Erro",
          modalParameters: {
            text: "O Título é um campo obrigatório, por favor preencha-o.",
          },
          modalActions: null,
        };

        this.props.openModal(modalObj);

        setPageInfoEdit(1);
        return;
      }

      // Validate category
      if (this.state.formItems.pageCategory.value.categoryId === 0) {
        valid = false;
        const modalObj = {
          modalIcon: "clear",
          modalType: "error",
          modalComponent: "GENERAL_ALERT",
          modalTitle: "Erro",
          modalParameters: {
            text: "A categoria é um campo obrigatório, por favor preencha-o.",
          },
          modalActions: null,
        };

        this.props.openModal(modalObj);

        setPageInfoEdit(1);
        return;
      }

      if (valid) {
        const formData = {
          id: this.state.pageData.id,
          versionId: this.state.pageData.versionId,
          title: this.state.formItems.pageTitle.value,
          categoryId: this.state.formItems.pageCategory.value,
          thumbId: this.state.formItems.pageThumb.value.thumbId,
          thumbFile: this.state.formItems.pageThumb.value.thumbFile,
          description: this.state.formItems.pageDescription.value,
          premium: this.state.formItems.pagePremium.value,
          comments: this.state.formItems.pageComments.value,
          sharing: this.state.formItems.pageSharing.value,
          header: this.state.formItems.pageHeader.value,
          footer: this.state.formItems.pageFooter.value,
          keywords: this.state.keywords.toString(),
          background: this.state.pageData.background,
        };

        let toPublish = false;
        if (publish === 1) {
          toPublish = true;

          this.setState({
            ...this.state,
            publishingLoad: true,
          });
        } else {
          this.setState({
            ...this.state,
            savingLoad: true,
          });
        }

        const pageId = this.props.match.params.pageId;
        const userToken = getToken();

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: pageId,
            formData: formData,
            modules: this.state.modules,
            modulesToDelete: this.state.modulesToDelete,
            toPublish: toPublish,
          }),
        };

        fetch("/api/content_save.php", requestOptions)
          .then(async (response) => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            authAction(data.authenticated);

            // If page has been published
            if (data.pageData.status === 2) {
              let modalObj = {};
              let msg = "";
              if (data.pageData.approved === 0) {
                msg =
                  "A página <b>" +
                  data.pageData.title +
                  "</b> foi enviada para análise. Após a verificação dos administradores será publicada.";
                modalObj = {
                  modalIcon: "priority_high",
                  modalType: "error",
                  modalComponent: "GENERAL_ALERT",
                  modalTitle: "Verificação Necessária",
                  modalParameters: {
                    text: msg,
                  },
                  modalActions: null,
                };
              } else {
                msg =
                  "A página <b>" +
                  data.pageData.title +
                  "</b> foi publicada com sucesso.";
                modalObj = {
                  modalIcon: "done",
                  modalType: "success",
                  modalComponent: "GENERAL_ALERT",
                  modalTitle: "Página Publicada",
                  modalParameters: {
                    text: msg,
                  },
                  modalActions: null,
                };
              }
              localStorage.setItem("openModal", 1);
              localStorage.setItem("modalObj", JSON.stringify(modalObj));
              window.location = "/pages";
            }

            this.setState({
              ...this.state,
              loader: false,
              savingLoad: false,
              categories: data.categories,
              publishingLoad: false,
              pageData: data.pageData,
              modules: data.modules,
            });

          })
          .catch((error) => {
            console.dir(error);
          });
      }
    };

    const setPageInfoEdit = (action) => {
      let str = false;
      if (action === 1) {
        str = true;
      }
      this.setState({
        ...this.state,
        editingInfo: str,
      });
    };

    const removeKeyword = (index) => {
      const keywords = this.state.keywords;
      keywords.splice(index, 1);
      this.setState({
        ...this.state,
        keywords: keywords,
      });
    };

    const addKeywordByKeyboard = (e) => {
      if (e.keyCode === 13) {
        addKeyword();
      }
    };

    const addKeyword = () => {
      const keywords = this.state.keywords;
      const formItems = this.state.formItems;
      const newKey = formItems.pageKeywords.value.trim();

      if (keywords.indexOf(newKey) === -1) {
        if (newKey !== "") {
          keywords.push(newKey);
        }
      } else {
        const modalObj = {
          modalIcon: "priority_high",
          modalType: "error",
          modalComponent: "GENERAL_ALERT",
          modalTitle: "De novo?",
          modalParameters: {
            text:
              "A keyword <b>" + newKey + "</b> já está associada ao conteúdo",
          },
          modalActions: [],
        };

        this.props.openModal(modalObj);
      }

      formItems.pageKeywords.value = "";

      this.setState({
        ...this.state,
        keywords: keywords,
        formItems: formItems,
      });
    };

    const formatDate = (date, withHour) => {
      let d = date.substr(8, 2);
      let m = date.substr(5, 2);
      let y = date.substr(0, 4);
      let sDate = "";

      if (withHour) {
        let h = date.substr(11, 2);
        let n = date.substr(14, 2);
        sDate = d + "/" + m + "/" + y + " " + h + "h" + n;
      } else {
        sDate = d + "/" + m + "/" + y;
      }

      return sDate;
    };

    const author = () => {
      let info = "";
      if (this.state.pageData.created) {
        info +=
          "Criada em " +
          formatDate(this.state.pageData.created, true) +
          " por " +
          this.state.pageData.authorName;
      }
      return info;
    };

    const published = () => {
      let info = "";
      if (this.state.pageData.published) {
        info +=
          "Versão " +
          this.state.pageData.publishedversion +
          " publicada desde " +
          formatDate(this.state.pageData.published, true);
      } else {
        info += "Ainda não publicada";
      }
      return info;
    };

    const setImage = (obj) => {
      const formItems = this.state.formItems;

      formItems.pageThumb.value.thumbId = obj[0].mediaId;
      formItems.pageThumb.value.thumbFile = obj[0].mediaFile;

      this.setState({
        ...this.state,
        formItems: formItems,
      });

      this.props.closeGallery();
    };

    const unsetImage = () => {
      const formItems = this.state.formItems;
      formItems.pageThumb.value.thumbId = 0;
      formItems.pageThumb.value.thumbFile = "";

      this.setState({
        ...this.state,
        formItems: formItems,
      });
    };

    const setBackground = (bgOption) => {
      const pageData = this.state.pageData;
      pageData.background = bgOption;
      this.setState({
        ...this.state,
        pageData: pageData,
      });
    };

    const previewVersion = (version) => {
        window.location = '/preview/' + version + '/edit';
    }

    const background = (option) => {
      let newArray = this.state.bgOptions.filter(function(el) {
        return el.id === option;
      });

      if (newArray.length > 0) {
        return newArray[0].class;
      }
      return;
    };

    const updateCategory = (id) => {

        const updatedFormItems = this.state.formItems;
        updatedFormItems.pageCategory.value = id;

        this.setState({
          formItems: updatedFormItems,
          categoryManager: false
        })
    
    }

    const toggleCategoryManager = (action) => {
      let a = (action);
      this.setState({categoryManager: a});
    }

    const selectedcategory = (id) => {

      if(id){
        let selectedCategory = this.state.categories.filter((item) => item.categoryId === id);
        let categoryName = selectedCategory[0].categoryTitle;
        let parentId = selectedCategory[0].categoryParent;

        if(parentId > 0){
          let parentObj = this.state.categories.filter((item) => item.categoryId === parentId);
          let parentName = parentObj[0].categoryTitle;

          return(<><span className='cat-parent'>{parentName}</span><span className='cat-title'>{categoryName}</span></>);
        }else{
          return(<span className='cat-title'>{categoryName}</span>);
        }

      }else{
        return(<span className='cat-none'>Sem categoria</span>);
      }
    }

    const selectedcategorytitle = (id) => {

      if(id){
        let selectedCategory = this.state.categories.filter((item) => item.categoryId === id);
        let categoryName = selectedCategory[0].categoryTitle;
        let parentId = selectedCategory[0].categoryParent;
        return categoryName;
      }
      
      return 'Sem categoria';

    }

    

    return (
      <div className={"page " + background(this.state.pageData.background)}>

        <div className={"content page-editor " + background(this.state.pageData.background)}>
          
          { /* Loader */ }
          <div className={this.state.loader ? "loading" : "loading hidden"}>
            <CircularProgress size={72} />
          </div>

          {/* Page Update Box */}
          <div className={this.state.editingInfo ? "overlay" : "overlay hidden"} onClick={() => setPageInfoEdit(0)}></div>
          <div className={this.state.editingInfo ? "page-update opened" : "page-update"}>
            {/* THUMB */}
            <div className="row">
              <div className="thumb">
                <ImageSelector
                  limit={1}
                  label="Thumb"
                  action={setImage}
                  clearFunction={unsetImage}
                  selectedFile={this.state.formItems.pageThumb.value.thumbFile}
                  selectedImage={this.state.formItems.pageThumb.value.thumbId}
                />
              </div>
            </div>

            {/* TITLE */}
            <div className="row">
              <InputLabel className="label" htmlFor="pageTitle">
                {this.state.formItems.pageTitle.label}
              </InputLabel>
              <TextField
                name="pageTitle"
                id={this.state.formItems.pageTitle.id}
                value={this.state.formItems.pageTitle.value}
                placeholder={this.state.formItems.pageTitle.placeholder}
                error={this.state.formItems.pageTitle.hasError}
                helperText={this.state.formItems.pageTitle.errorMsg}
                InputLabelProps={{
                  shrink: true,
                  width: 0,
                }}
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            {/* CATEGORY */}
            <div className="row">
            
              <InputLabel className="label" htmlFor="pageCategory">
              {this.state.formItems.pageCategory.label}
              </InputLabel>
              <button className="category-button" type="button" onClick={() => toggleCategoryManager(true)}>
                {selectedcategory(this.state.formItems.pageCategory.value)}
              </button>
            
            </div>

            {/* DESCRIPTION */}
            <div className="row">
              <InputLabel className="label" htmlFor="pageDescription">
                Descrição
              </InputLabel>
              <TextField
                name={this.state.formItems.pageDescription.id}
                id={this.state.formItems.pageDescription.id}
                value={this.state.formItems.pageDescription.value}
                error={this.state.formItems.pageDescription.hasError}
                helperText={this.state.formItems.pageDescription.errorMsg} 
                multiline
                onChange={handleChange}
                onBlur={handleBlur}
                InputLabelProps={{
                  shrink: true,
                  width: 0,
                }}
                placeholder={this.state.formItems.pageDescription.placeholder}
                fullWidth
                variant="outlined"
              />
            </div>

            {/* BACKGROUND */}
            <div className="row">
              <InputLabel className="label" htmlFor="pageDescription">
                Background
              </InputLabel>
              <div className="background-options">
                {this.state.bgOptions.map((item, index) => {
                  let bgKey = "bgOption-" + index;
                  let selected =
                    this.state.pageData.background === item.id
                      ? " selected"
                      : "";

                  return (
                    <Tooltip
                      key={bgKey}
                      title={item.label}
                      aria-label={item.label}
                      arrow
                    >
                      <div
                        className={"bg " + item.class + selected}
                        onClick={() => setBackground(item.id)}
                      ></div>
                    </Tooltip>
                  );
                })}
              </div>
            </div>

            {/* KEYWORDS */}
            <div className="row">
              <InputLabel className="label" htmlFor="KeywordInput">
                {this.state.formItems.pageKeywords.label}
              </InputLabel>
              <TextField
                name={this.state.formItems.pageKeywords.id}
                id={this.state.formItems.pageKeywords.id}
                value={this.state.formItems.pageKeywords.value}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={addKeywordByKeyboard}
                InputLabelProps={{
                  shrink: true,
                  width: 0,
                }}
                placeholder="Adicione uma keyword"
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={addKeyword}
                        edge="end"
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            {/* TAGS */}
            <div className="row">
              {this.state.keywords.map((item, index) => {
                let keyIndex = "key-" + index;
                return (
                  <span
                    key={keyIndex}
                    className="keyword"
                    onClick={() => removeKeyword(index)}
                  >
                    {item}
                  </span>
                );
              })}
            </div>

            {/* OPTION: PREMIUM */}
            <div className="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.formItems.pagePremium.value === 1
                        ? true
                        : false
                    }
                    onChange={handleSwitch}
                    name={this.state.formItems.pagePremium.id}
                    color="primary"
                  />
                }
                label={this.state.formItems.pagePremium.placeholder}
              />
            </div>

            {/* OPTION: COMMENTS */}
            <div className="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.formItems.pageComments.value === 1
                        ? true
                        : false
                    }
                    onChange={handleSwitch}
                    name={this.state.formItems.pageComments.id}
                    color="primary"
                  />
                }
                label={this.state.formItems.pageComments.placeholder}
              />
            </div>

            {/* OPTION: SHARING */}
            <div className="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.formItems.pageSharing.value === 1
                        ? true
                        : false
                    }
                    onChange={handleSwitch}
                    name={this.state.formItems.pageSharing.id}
                    color="primary"
                  />
                }
                label={this.state.formItems.pageSharing.placeholder}
              />
            </div>
            
            {/* OPTION: HEADER */}
            <div className="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.formItems.pageHeader.value === 1
                        ? true
                        : false
                    }
                    onChange={handleSwitch}
                    name={this.state.formItems.pageHeader.id}
                    color="primary"
                  />
                }
                label={this.state.formItems.pageHeader.placeholder}
              />
            </div>

            {/* OPTION: FOOTER */}
            <div className="row">
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      this.state.formItems.pageFooter.value === 1
                        ? true
                        : false
                    }
                    onChange={handleSwitch}
                    name={this.state.formItems.pageFooter.id}
                    color="primary"
                  />
                }
                label={this.state.formItems.pageFooter.placeholder}
              />
            </div>

            {/* BUTTONS */}
            <div className="btn-wrapper centered">
              <button
                className="btn-circle other"
                onClick={() => setPageInfoEdit(0)}
              >
                <ArrowBackIcon />
              </button>
            </div>
          </div>

          {/* Page Tools */}
          <div className="page-tools">
            <div className="back">
              <div className="btn-wrapper">
                <button
                  className="btn-circle ghost-dark"
                  onClick={backToDirectory}
                >
                  <ArrowBackIcon />
                </button>
              </div>
            </div>

            <div className="resume">
              <div className="page-info" onClick={() => setPageInfoEdit(1)}>
                <div className="category">
                  <span>{selectedcategorytitle(this.state.formItems.pageCategory.value)}</span>
                  {(this.state.formItems.pagePremium.value === 1) ? (<span className='tag-premium'><StarsIcon />Conteúdo Premium</span> ): ''}
                </div>
                <div className="title">
                  {this.state.formItems.pageTitle.value}
                  <span className="tag-version">
                    {this.state.pageData.version}
                  </span>
                </div>
                <div className="info">{author()}</div>
                <div className="info">{published()}</div>
              </div>
            </div>

            <div className="actions">
              <div className="btn-wrapper side-by-side on-right">
                <ButtonGroup color="primary">
                  <Button 
                  className="btn-icon-right"
                  disableElevation
                  variant="outlined"
                  onClick={() => previewVersion(this.state.pageData.versionId)} 
                  disabled={
                    this.state.savingLoad || this.state.publishingLoad
                  }
                  endIcon={<VisibilityIcon />}
                  >
                      Preview
                  </Button>
                  <Button
                    className="btn-icon-right"
                    disableElevation
                    variant="contained"
                    onClick={() => save(0)}
                    disabled={
                      this.state.savingLoad || this.state.publishingLoad
                    }
                    endIcon={
                      this.state.savingLoad ? (
                        <CircularProgress size={16} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                  >
                    Salvar
                  </Button>
                </ButtonGroup>

                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={() => save(1)}
                  disabled={this.state.savingLoad || this.state.publishingLoad}
                  endIcon={
                    this.state.publishingLoad ? (
                      <CircularProgress size={16} />
                    ) : null
                  }
                >
                  {this.state.publishingLoad
                    ? "Publicando..."
                    : "Salvar e Publicar"}
                </Button>
              </div>
            </div>
          </div>

          { /* Page Content */ }
          <div className="contents">
            <div
              className={
                this.state.modules.length === 0
                  ? "btn-wrapper centered margin-bottom--sm"
                  : "hidden"
              }
            >
              <button
                className="btn-circle medium other"
                onClick={() => this.props.openSelector(0, 0)}
              >
                <AddIcon />
              </button>
            </div>
            {// eslint-disable-next-line
            this.state.modules.map((item) => {
              if (!item.willDelete) {
                return (
                  <ModuleWrapper
                    key={item.content}
                    active={item.contentId === this.state.activeModule}
                    editing={item.contentId === this.state.editingModule}
                    totalModules={this.state.modules.length}
                    pageData={this.state.pageData}
                    functions={containerFunctions}
                    {...item}
                  />
                );
              }
            })}
          </div>
          
          <CategoryManager 
            selected={this.state.formItems.pageCategory.value} 
            action={updateCategory} 
            visible={this.state.categoryManager}
            closeFunction={toggleCategoryManager}
            getCategories={this.getCategories}
            source={this.state.categories}
          />
          <ModuleSelector add={addModule} />
          <MediaGallery />
          <ContentDirectory />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: (modalData) => dispatch(openModal(modalData)),
  openSelector: (type, position) => dispatch(openSelector(type, position)),
  closeSelector: () => dispatch(closeSelector()),
  openGallery: (limit, action) => dispatch(openGallery(limit, action)),
  closeGallery: () => dispatch(closeGallery()),
});

export default withRouter(connect(null, mapDispatchToProps)(PageEditor));
