import React from 'react';
import { connect } from "react-redux";
import './module-wrapper.styles.scss';

// Import UI components
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

// Import Redux Actions
import { openSelector } from '../../redux/modules-selector/modules-selector.actions';

// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import HeadingEditMode from '../../modules/headings/heading.edit.component';
import BannerTitleViewMode from '../../modules/banner-title/banner-title.view.component';
import BannerTitleEditMode from '../../modules/banner-title/banner-title.edit.component';
import BannerImageViewMode from '../../modules/banner-image/banner-image.view.component';
import BannerImageEditMode from '../../modules/banner-image/banner-image.edit.component';
import RichtextViewMode from '../../modules/richtext/richtext.view.component';
import RichtextEditMode from '../../modules/richtext/richtext.edit.component';
import LeadtextViewMode from '../../modules/leadtext/leadtext.view.component';
import LeadtextEditMode from '../../modules/leadtext/leadtext.edit.component';
import HighlightedTextEditMode from '../../modules/highlighted-text/highlighted-text.edit.component';
import HighlightedTextViewMode from '../../modules/highlighted-text/highlighted-text.view.component';
import ImageViewMode from '../../modules/image/image.view.component';
import ImageEditMode from '../../modules/image/image.edit.component';
import ImageTextEditMode from '../../modules/image-with-text/image-with-text.edit.component';
import ImageTextViewMode from '../../modules/image-with-text/image-with-text.view.component';
import QuotesViewMode from '../../modules/quotes/quotes.view.component';
import QuotesEditMode from '../../modules/quotes/quotes.edit.component';
import BannerViewMode from '../../modules/banner/banner.view.component';
import BannerEditMode from '../../modules/banner/banner.edit.component';
import CardsEditMode from '../../modules/cards/cards.edit.component';
import CardsViewMode from '../../modules/cards/cards.view.component';
import AuthorViewMode from '../../modules/author/author.view.component';
import AuthorEditMode from '../../modules/author/author.edit.component';
import CtaEditMode from '../../modules/cta/cta.edit.component';
import CtaViewMode from '../../modules/cta/cta.view.component';
import TestimonialsEditMode from '../../modules/testimonials/testimonials.edit.component';
import TestimonialsViewMode from '../../modules/testimonials/testimonials.view.component';
import ExpandCollapseEditMode from '../../modules/expand-collapse/expand-collapse.edit.component';
import ExpandCollapseViewMode from '../../modules/expand-collapse/expand-collapse.view.component';
import YoutubeViewMode from '../../modules/youtube/youtube.view.component';
import YoutubeEditMode from '../../modules/youtube/youtube.edit.component';
import PostsEditMode from '../../modules/posts/posts.edit.component';
import PostsViewMode from '../../modules/posts/posts.view.component';
import NotesViewMode from '../../modules/notes/notes.view.component';
import NotesEditMode from '../../modules/notes/notes.edit.component';
import FormViewMode from '../../modules/form/form.view.component';
import FormEditMode from '../../modules/form/form.edit.component';
import ContactViewMode from '../../modules/contact/contact.view.component';
import ContactEditMode from '../../modules/contact/contact.edit.component';
import BlogEditMode from '../../modules/blog/blog.edit.component';
import BlogViewMode from '../../modules/blog/blog.view.component';
import ReferencesEditMode from '../../modules/references/references.edit.component';
import ReferencesViewMode from '../../modules/references/references.view.component';
import PartnersEditMode from '../../modules/partners/partners.edit.component';
import PartnersViewMode from '../../modules/partners/partners.view.component';
import GiftEditMode from '../../modules/gift/gift.edit.component';
import GiftViewMode from '../../modules/gift/gift.view.component';
import DirectoryViewMode from '../../modules/directory/directory.view.component';
import DirectoryEditMode from '../../modules/directory/directory.edit.component';
import SpacingViewMode from '../../modules/spacing/spacing.view.component';
import SpacingEditMode from '../../modules/spacing/spacing.edit.component';

const ModuleWrapper = (props) => {

    const classes = () => {
        let classes = 'module-wrapper';

        if(props.wide === 'full'){
            classes += ' full';
        }

        if(props.editing){
            classes += ' edit-mode';
        }else{
            classes += ' view-mode mdl-' + props.module;
        }

        return classes;
    }

    const renderModule = (contentId, module, editing, props, functions, pageData) => {

        switch (module) {

            case 'heading':
                
                if(editing){
                    return(<HeadingEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<HeadingViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'spacing':
            
                if(editing){
                    return(<SpacingEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<SpacingViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'banner-title':
                
                if(editing){
                    return(<BannerTitleEditMode contentId={contentId} {...props} functions={functions} pageData={pageData} />);
                }else{
                    return(<BannerTitleViewMode contentId={contentId} {...props} functions={functions} pageData={pageData} />);
                }

            case 'banner-image':
            
                if(editing){
                    return(<BannerImageEditMode contentId={contentId} {...props} functions={functions} pageData={pageData} />);
                }else{
                    return(<BannerImageViewMode contentId={contentId} {...props} functions={functions} pageData={pageData} />);
                }
            
            case 'banner':
            
                if(editing){
                    return(<BannerEditMode contentId={contentId} {...props} functions={functions} pageData={pageData} />);
                }else{
                    return(<BannerViewMode contentId={contentId} {...props} functions={functions} pageData={pageData} />);
                }

            case 'richtext':

                if(editing){
                    return(<RichtextEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<RichtextViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'leadtext':

                if(editing){
                    return(<LeadtextEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<LeadtextViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'highlighted-text':

                if(editing){
                    return(<HighlightedTextEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<HighlightedTextViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'image':

                if(editing){
                    return(<ImageEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<ImageViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'image-richtext':

                if(editing){
                    return(<ImageTextEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<ImageTextViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'quotes':

                if(editing){
                    return(<QuotesEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<QuotesViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'cards':

                if(editing){
                    return(<CardsEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<CardsViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'author':

                if(editing){
                    return(<AuthorEditMode contentId={contentId} {...pageData} functions={functions} />);
                }else{
                    return(<AuthorViewMode contentId={contentId} {...pageData} functions={functions} />);
                }
            
            case 'cta':

                if(editing){
                    return(<CtaEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<CtaViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'testimonials':

                if(editing){
                    return(<TestimonialsEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<TestimonialsViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'expand-collapse':

                if(editing){
                    return(<ExpandCollapseEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<ExpandCollapseViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'youtube':

                if(editing){
                    return(<YoutubeEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<YoutubeViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'posts':

                if(editing){
                    return(<PostsEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<PostsViewMode contentId={contentId} {...props} functions={functions} wide={props.wide}/>);
                }
            
            case 'notes':

                if(editing){
                    return(<NotesEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<NotesViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'form':

                if(editing){
                    return(<FormEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<FormViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'contact':

                if(editing){
                    return(<ContactEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<ContactViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'blog':

                if(editing){
                    return(<BlogEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<BlogViewMode contentId={contentId} {...props} functions={functions} />);
                }

            case 'directory':

                if(editing){
                    return(<DirectoryEditMode contentId={contentId} {...props} functions={functions} />);
                }else{
                    return(<DirectoryViewMode contentId={contentId} {...props} functions={functions} />);
                }
            
            case 'references':

                if(editing){
                    return(<ReferencesEditMode contentId={contentId} {...pageData} functions={functions} />);
                }else{
                    return(<ReferencesViewMode contentId={contentId} {...pageData} functions={functions} />);
                }
            
            case 'partners':

                if(editing){
                    return(<PartnersEditMode contentId={contentId} {...pageData} {...props} functions={functions} />);
                }else{
                    return(<PartnersViewMode contentId={contentId} {...pageData} {...props} functions={functions} />);
                }
            
            case 'gift':

                if(editing){
                    return(<GiftEditMode contentId={contentId} {...pageData} {...props} functions={functions} />);
                }else{
                    return(<GiftViewMode contentId={contentId} {...pageData} {...props} functions={functions} />);
                }

            default:
                return(<div>ERROR: Module Render is not found</div>);
        }

    }

    return(
        <div className={classes()}> 
            
            <div className="module">
                {renderModule(props.contentId, props.module, props.editing, props.parameters, props.functions, props.pageData)}
            </div>
            <div className="actions">
                <div className="toolbar">
                    <div className="btn-wrapper centered">
                        <button className="btn-circle medium other" onClick={() => props.openSelector(props.type, props.order)}><AddIcon /></button>
                        <button className="btn-circle medium other" onClick={() => props.functions.toggleMode(props.contentId)}
                            ><EditIcon />
                        </button>
                        <button className="btn-circle medium other rotate-90" onClick={() => props.functions.toggleWide(props.contentId)}>
                            {(props.wide === 'normal')? <UnfoldMoreIcon /> :<UnfoldLessIcon /> }
                        </button>
                        <button className="btn-circle medium other" onClick={() => props.functions.orderModule(props.order, 'up')} disabled={(props.order===1)}>
                            <ArrowUpwardIcon />
                        </button>
                        <button className="btn-circle medium other" onClick={() => props.functions.orderModule(props.order, 'down')} disabled={(props.order === props.totalModules)}>
                            <ArrowDownwardIcon />
                        </button>
                        <button className="btn-circle medium attention" onClick={() => props.functions.deleteModule(props.contentId)}>
                            <DeleteIcon />
                        </button>
                    </div>
                </div>
            </div>
            

            

        </div>
    );
}

const mapDispatchToProps = dispatch => ({
    openSelector: (type, position) => dispatch(openSelector(type, position))
  });
  
export default connect(null, mapDispatchToProps)(ModuleWrapper);