import React from 'react';

const FacebookIcon = (props) => {
    const viewBoxWidth = 64;
    const viewBoxHeight = 64;
    const viewBox = '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight;
    const width = (props.width) ? props.width : viewBoxWidth;
    const height = (props.height) ? props.height : viewBoxHeight;

    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox={viewBox}
        className={(props.className) ? `svg-icon ${props.className}` : 'svg-icon'}
        width = {width + 'px'}
        height ={height + 'px'}
        >
            <path 
                d="M44.58,35.42l1.5-9.79H36.69V19.28A4.89,4.89,0,0,1,41.13,14a5,5,0,0,1,1.09,0h4.27V5.66A52.19,52.19,0,0,0,38.9,5C31.17,5,26.11,9.69,26.11,18.17v7.46h-8.6v9.79h8.6V59.07H36.69V35.42Z"
            />
        </svg>
    );

}
export default FacebookIcon;