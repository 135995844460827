import React, {Component} from 'react';
import './expand-collapse.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Icon from '@material-ui/core/Icon';

// Import components
import ExpandCollapseForm from './expand-collapse.form.component';


class ExpandCollapseEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(){

        const initialItems = (this.props.items) ? this.props.items : [];
        const editingItems = JSON.parse(JSON.stringify(initialItems));

        this.setState({
            ...this.state,
            items: editingItems
        })

        
    }


    render(props){

        // Handle Submit
        const handleSubmit = (event) => {

            event.preventDefault();
            let items = this.state.items;
            
            const parameters = {
                items: items
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        // Set if submit button is enabled
        const submitIsEnabled = () => {
            let disabled = false;

            // If there is no card registered
            if(this.state.items.length === 0){
                disabled = true;
            }

            // If any card has an error
            for(let i = 0; i< this.state.items.length; ++i){

                if(this.state.items[i].hasError){
                    disabled= true;
                }

            }

            return disabled;

        }

        // Set if add button is enabled
        const addIsEnabled = () => {
            let disabled = false;

            // If there is no card registered
            if(this.state.items.length >= 10){
                disabled = true;
            }

            return disabled;

        }

        // Add Item
        const addItem = () => {

            const items = this.state.items;

            const newItem = {
                title: '',
                text: '',
                hasError: true
            }

            items.push(newItem);

            this.setState({
                ...this.state,
                items: items
            })

        }

        // Change Item
        const changeItem = (index, name, value) => {

            const items = this.state.items;
            items[index][name] = value;

            console.dir('index: ' + index);
            console.dir('campo: ' + name);
            console.dir('value: ' + value);
            console.dir('');


            this.setState({
                ...this.state,
                items: items
            })

        };

        // Validate Item
        const validateItem = (index, error) => {

            const items = this.state.items;
            items[index].hasError = error;

            this.setState({
                ...this.state,
                items: items
            })

        }

        // Remove Item
        const removeItem = (event, idx) => {

            event.preventDefault();

            const items = this.state.items;
            items.splice(idx, 1);

            this.setState({
                ...this.state,
                items: items
            })
        }

        // Sort items
        const sortItems = (oldIndex, newIndex) => {

            if(newIndex && (parseInt(oldIndex) !== parseInt(newIndex))){
                const itemsToSort = this.state.items;
                const movedItem = itemsToSort[oldIndex];
                const remainingItems = itemsToSort.filter((item, index) => index !== parseInt(oldIndex));
                let reorderedItems = [];

                if(parseInt(newIndex) === itemsToSort.length -1){

                    reorderedItems = remainingItems;
                    reorderedItems.push(movedItem);

                }else{

                    for(let i = 0; i < remainingItems.length; i++){

                        if(i === parseInt(newIndex)){
                            reorderedItems.push(movedItem);
                        }
                        reorderedItems.push(remainingItems[i]);
    
                    }

                }

                console.dir(reorderedItems);
                
                this.setState({
                    ...this.state,
                    items: reorderedItems
                });

                
            }
        }

        return(
            <div className='expand-collapse--edit-mode'>
                <div className="module-title-bar">Módulo Expand & Collapse : {this.props.contentId}</div>
                <form>
                    <Grid container spacing={2}>

                        { /* Add Item Button */ }
                        <Grid item xs={12} >
                            <div className='right'>
                                <Button 
                                color="primary" 
                                variant="outlined" 
                                disableElevation 
                                onClick={addItem} 
                                endIcon={<Icon>add</Icon>}
                                disabled={addIsEnabled()}
                                >Adicionar</Button>
                            </div>
                        </Grid>

                        { /* Items */ }
                        <Grid item xs={12}>
                            
                            <div id={'items-wrapper-' + this.props.contentId} className="items">
                            <div className={(this.state.items.length === 0) ? 'no-results center' : 'no-results hidden'}>Clique em adicionar para criar novos accordions neste módulo</div>
                            {
                                this.state.items.map((item, index) => {
                                    let itemId = 'expand-collapse-' + this.props.contentId + '-' + index;
                                    return(  
                                        <ExpandCollapseForm 
                                        key={itemId} 
                                        itemId={index} 
                                        {...item} 
                                        contentId={this.props.contentId} 
                                        changeItem={changeItem} 
                                        validateItem={validateItem} 
                                        removeItem={removeItem} 
                                        sortItems={sortItems}
                                        />
                                    )
                                })
                            } 
                            </div>
                        </Grid>

                        { /* Buttons */ }
                        <Grid item xs={12}>
                            <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                                <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                                <Button 
                                type="submit" 
                                color="primary" 
                                variant="contained" 
                                disableElevation 
                                onClick={(e) => handleSubmit(e)} 
                                disabled={submitIsEnabled()}
                                >Atualizar</Button>
                            </div>
                        </Grid>

                    </Grid>
                </form>
            </div>
        )
    }
}
  
export default ExpandCollapseEditMode;