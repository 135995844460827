import React from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import './login-button.styles.scss';

import Avatar from '../avatar/avatar.component';

import {toggleUserMenu} from '../../redux/menus/menus.actions';


const LoginButton = (props) => {

    const getUserData = () => {
            
        let userData = {};
        if(localStorage.getItem('abricco')){
            userData = JSON.parse(localStorage.getItem('abricco'));
        }else{
            userData = {
            id: 0,
            token: '',
            avatar: '',
            name: '',
            type: 0
            };
        }

        return userData;
    }

    const goTo = (url) => {
        window.location = url;
    }

    const renderButton = () => {

        const user = getUserData();
        const windowWidth = window.innerWidth;

            if(parseInt(user.id) === 0){
                return(
                <Link to="/login">
                    <Avatar source="" size="small" />
                </Link>
                );
            }else if(parseInt(user.id) > 0 && windowWidth > 767){
                return(
                    
                    <button className="btn" onClick={props.toggleUserMenu}>
                    <Avatar source={user.avatar} size="small" />
                    </button>
                );
            }else if(parseInt(user.id) > 0 && windowWidth < 768){
                return(
                    <button className="btn" onClick={() => goTo('/dashboard')}>
                        <Avatar source={user.avatar} size="small" />
                    </button>
                );
            }

    }

   return (
    <div className={(props.activeUser.userIsLogged)?'btn-login user-connected':'btn-login user-disconnected'}>
        {renderButton()}
    </div>
   );
}

const mapStateToProps = state => ({
    activeUser: state.activeUser, 
});

const mapDispatchToProps = dispatch => ({
    toggleUserMenu: () => dispatch(toggleUserMenu()),
  });
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginButton));