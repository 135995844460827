import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import './cards.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Icon from '@material-ui/core/Icon';

// Import components
import CardForm from '../cards/card-form.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class CardsEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.bgPlaceholder = (this.props.bg === 1) ? 'Fundo cinza' : 'Fundo branco';
        this.initialCards = (this.props.cards) ? this.props.cards : [];
        this.state = {
            types: [
                {label: 'Com imagens (Cartão)', value: 0},
                {label: 'Com Ícones (Plano)', value: 1}
            ],
            formItems: {
                title: {
                    id: 'title',
                    label: 'Título',
                    placeholder: '',
                    value: (this.props.title) ? this.props.title : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                type: {
                    id: 'type',
                    label: 'Tipo',
                    placeholder: '',
                    value: (this.props.type === '') ? 0 : this.props.type,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                desc: {
                    id: 'desc',
                    label: 'Texto',
                    placeholder: '',
                    value: (this.props.desc) ? this.props.desc : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                label: {
                    id: 'label',
                    label: 'Label',
                    placeholder: '',
                    value: (this.props.label) ? this.props.label : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                link: {
                    id: 'link',
                    label: 'Link',
                    placeholder: '',
                    value: (this.props.link) ? this.props.link : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                src: {
                    id: 'Imagem',
                    label: 'Imagem',
                    placeholder: '',
                    value: (this.props.src) ? this.props.src : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                srcId: {
                    id: 'Imagem',
                    label: 'Imagem',
                    placeholder: '',
                    value: (this.props.srcId) ? this.props.srcId : 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                bg: {
                    id: 'bg',
                    label: 'Background',
                    placeholder: this.bgPlaceholder,
                    value: (this.props.bg) ? this.props.bg : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                
                
            },
            cards: []
        }
    }

    componentDidMount(){

        const initialCards = (this.props.cards) ? this.props.cards : [];
        const editingCards = JSON.parse(JSON.stringify(initialCards));

        this.setState({
            ...this.state,
            cards: editingCards
        })
        
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const handleSubmit = (event) => {

            event.preventDefault();
            let cards = this.state.cards;
            let title = this.state.formItems.title.value;
            let desc = this.state.formItems.desc.value;
            let label = this.state.formItems.label.value;
            let link = this.state.formItems.link.value;
            let src = this.state.formItems.src.value;
            let srcId = this.state.formItems.srcId.value;
            let bg = this.state.formItems.bg.value;
            let type = this.state.formItems.type.value;
            
            const parameters = {
                cards: cards,
                title: title,
                desc: desc,
                label: label,
                link: link,
                src: src,
                srcId: srcId,
                bg: bg,
                type: type
            }

            return this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const submitIsEnabled = () => {
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            // If any field in component form has an error
            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            // If there is no card registered
            if(this.state.cards.length === 0){
                disabled = true;
            }

            // If any card has an error
            for(let i = 0; i< this.state.cards.length; ++i){

                if(this.state.cards[i].hasError){
                    disabled= true;
                }

            }

            return disabled;

        }

        const setCtaLink = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.link.value = url;
            
    
            formItems.link.hasTouched = true;
            const validation = validate(url, formItems.link.rules, formItems);
            formItems.link.hasError = validation[0];
            formItems.link.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });
    
            this.props.closeDirectory();
    
        }

        const addCard = () => {

            const cards = this.state.cards;

            const newCard = {
                srcId: 0,
                src: '',
                title: '',
                desc: '',
                link: '',
                hasError: true
            }

            cards.push(newCard);

            this.setState({
                ...this.state,
                cards: cards
            })

        }

        const cardChange = (index, name, value) => {

            const cards = this.state.cards;
            cards[index][name] = value;
            this.setState({
                ...this.state,
                cards: cards
            })

        };

        const cardValidation = (index, error) => {

            const cards = this.state.cards;
            cards[index].hasError = error;

            this.setState({
                ...this.state,
                cards: cards
            })

        }

        const removeCard = (idx) => {
            const cardsToDelete = JSON.parse(JSON.stringify(this.state.cards));
            cardsToDelete.splice(idx, 1);

            console.dir('Excluir indice ' + idx);

            this.setState({
                ...this.state,
                cards: cardsToDelete
            })
        }

        const setIcon = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.src.value = obj[0].mediaFile;
            formItems.srcId.value = obj[0].mediaFile;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();
    
        }

        const unsetIcon = () => {

            const formItems = this.state.formItems;
    
            formItems.src.value = '';
            formItems.srcId.value = 0;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
        }

        const handleBg = () => {

            let value = 1;
            let placeholder = ''

            if(this.state.formItems.bg.value === 1){
                value = 0;
                placeholder = 'Fundo branco';
            }else{
                value = 1;
                placeholder = 'Fundo cinza';
            }

            const formItems = this.state.formItems;

            formItems.bg.value = value;
            formItems.bg.placeholder = placeholder;

            this.setState({
                ...this.state,
                formItems: formItems
            })

        }

        const sortCards = (oldIndex, newIndex) => {

            if(newIndex && (parseInt(oldIndex) !== parseInt(newIndex))){
                const cardsToSort = this.state.cards;
                const movedItem = cardsToSort[oldIndex];
                const remainingItems = cardsToSort.filter((item, index) => index !== parseInt(oldIndex));
                let reorderedItems = [];

                if(parseInt(newIndex) === cardsToSort.length -1){

                    reorderedItems = remainingItems;
                    reorderedItems.push(movedItem);

                }else{

                    for(let i = 0; i < remainingItems.length; i++){

                        if(i === parseInt(newIndex)){
                            reorderedItems.push(movedItem);
                        }
                        reorderedItems.push(remainingItems[i]);
    
                    }

                }

                this.setState({
                    ...this.state,
                    cards: reorderedItems
                });
            }
        }

       
        return(
            <div className='cards-edit-mode'>
                <div className="module-title-bar">Módulo Cards : {this.props.contentId}</div>
                <form>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <TextField
                        name={this.state.formItems.title.id}
                        id={this.state.formItems.title.id}
                        value={this.state.formItems.title.value}
                        label={this.state.formItems.title.label}
                        placeholder={this.state.formItems.title.placeholder}
                        error={this.state.formItems.title.hasError}
                        helperText={this.state.formItems.title.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.type.hasError} ref={this.headingType} >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.type.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.type.id}
                            id={this.state.formItems.type.id}
                            value={this.state.formItems.type.value}
                            label={this.state.formItems.type.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            {this.state.types.map((item) => {
                                
                                return (
                                <option
                                    key={"cards-type-" + item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.type.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                        name={this.state.formItems.desc.id}
                        id={this.state.formItems.desc.id}
                        value={this.state.formItems.desc.value}
                        label={this.state.formItems.desc.label}
                        placeholder={this.state.formItems.desc.placeholder}
                        error={this.state.formItems.desc.hasError}
                        helperText={this.state.formItems.desc.errorMsg}
                        fullWidth
                        multiline
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.label.id}
                        id={this.state.formItems.label.id}
                        value={this.state.formItems.label.value}
                        label={this.state.formItems.label.label}
                        placeholder={this.state.formItems.label.placeholder}
                        error={this.state.formItems.label.hasError}
                        helperText={this.state.formItems.label.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.link.id}
                        id={this.state.formItems.link.id}
                        value={this.state.formItems.link.value}
                        label={this.state.formItems.link.label}
                        placeholder={this.state.formItems.link.placeholder}
                        error={this.state.formItems.link.hasError}
                        helperText={this.state.formItems.link.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                aria-label="Procurar conteúdo"
                                edge="end"
                                onClick={() => this.props.openDirectory(1, setCtaLink)}
                                >
                                <DescriptionIcon /> 
                                </IconButton>
                            </InputAdornment>,
                        }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.src.id}
                        id={this.state.formItems.src.id}
                        value={this.state.formItems.src.value}
                        label={this.state.formItems.src.label}
                        placeholder={this.state.formItems.src.placeholder}
                        error={this.state.formItems.src.hasError}
                        helperText={this.state.formItems.src.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                aria-label="Procurar ícone"
                                edge="end"
                                onClick={() => this.props.openGallery(1, setIcon)}
                                >
                                <DescriptionIcon /> 
                                </IconButton>
                                <IconButton
                                aria-label="Remover ícone"
                                edge="end"
                                onClick={() => unsetIcon()}
                                >
                                <ClearIcon /> 
                                </IconButton>
                            </InputAdornment>,
                        }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3} className='vertical-middle'>
                        <FormControlLabel
                        control={
                        <Switch
                            checked={(this.state.formItems.bg.value === 1) ? true : false }
                            onChange={handleBg}
                            name="bg"
                            color="primary"
                        />
                        }
                        label={this.state.formItems.bg.placeholder}
                        />
                    </Grid>

                
                
                        { /* Add Item Button */ }
                        <Grid item xs={12} >
                            <div className='right'>
                                <Button 
                                color="primary" 
                                variant="outlined" 
                                disableElevation 
                                onClick={addCard}
                                endIcon={<Icon>add</Icon>}
                                >Adicionar</Button>
                            </div>
                        </Grid>

                    </Grid>
                </form>
                <div id={'cards-wrapper-' + this.props.contentId} className="cards">
                
                   {
                       
                       this.state.cards.map((item, index) => {
                           let cardId = 'card-' + index;
                           return(
                            
                           <CardForm key={cardId} cardId={index} {...item} contentId={this.props.contentId} cardChange={cardChange} cardValidation={cardValidation} removeCard={removeCard} sortCards={sortCards} />
                           
                           )
                       })
                       
                    }
                    
                   
                </div>
                

                <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                    <Button 
                    type="submit" 
                    color="default" 
                    variant="contained" 
                    disableElevation 
                    onClick={() => this.props.functions.toggleMode(0)}
                    >Cancelar</Button>

                    <Button 
                    type="submit" 
                    color="primary" 
                    variant="contained" 
                    disableElevation 
                    onClick={(e) => handleSubmit(e)} 
                    disabled={submitIsEnabled()}
                    >Atualizar</Button>
                </div>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(CardsEditMode));