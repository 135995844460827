import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import './forgot-password.styles.scss';

// Import Redux Action
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {CircularProgress} from '@material-ui/core';
 
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formItems: {
        email: {
          id: 'email',
          label: 'E-mail',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            email: true,
          }
        }
      }
    };
    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }
      
    }

  render(props){
  
    

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event) => {

      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // Get current FormItems object from State
      const formItems = this.state.formItems;

      // Get State of the Input
      const formItem = {
          ...formItems[name]
      };

      // Validate Element if it has been Touched Before
      if(formItem.hasTouched){
          if(formItem.rules){
              const validation = validate(value, formItem.rules, formItems);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }
      }

      // Set the new value to element
      formItem.value = value;

      // Update formItems collection
      formItems[name] = formItem;


      // Update State
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  };

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = async(event) => {
              
      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // formItems collection
      const formItems = {
          ...this.state.formItems
      };

      // state of modified element
      const formItem = {
          ...formItems[name]
      };

      formItem.hasTouched = true;
      if(formItem.rules){
          const validation = await validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }

      // Update formItems collection
      formItems[name] = formItem;

      // Update state
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  }

  // DISABLED SUBMIT
  const submitIsDisabled = () => {

    const email = this.state.formItems.email;
    let disabled = false;

    if(email.value ===''){
      disabled = true;
    }

    if(email.hasError){
      disabled = true;
    }

    return disabled;

  }

  // SUBMIT HANDLE
  const handleSubmit = (e) => {

    e.preventDefault();

    this.setState({
      ...this.state,
      loading: true
    });

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            app: window.$appToken,
            email: this.state.formItems.email.value
          }),
        };
  
        fetch('/api/my_lost_password.php', requestOptions)
        .then(data => data.json())
        .then(data => {

            if(data.success){
              
              const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Pronto! :)',
                modalParameters: {
                    text: 'Enviamos uma nova senha para o e-mail ' + this.state.formItems.email.value
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

            }else{

              const modalObj = {
                modalIcon: 'priority_high',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ooops!',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

            }
            
            this.setState({ 
                ...this.state,
                loading: false
             });
        })

  }

  return (
    <div className="page forgot-password">

      { /* PAGE */ }
      <div className="content dashboard-page">
        
          { /* PAGE TITTLE */ }
          <div className="module-heading margin-bottom--lg">
            <h1 className="heading-1">Reset de Senha</h1>
          </div>
          

          { /* FORM PERSONAL DATA*/ }
          <form className="container compact">
          <div className="module-heading">
            <h4 className="heading-4 left">Esqueceu sua senha?</h4>
          </div>
          <p>Não tem problema, basta informar seu e-mail registrado no campo abaixo e enviaremos uma nova senha para você.</p>
          <div className="form-panel">
            
          <Grid container spacing={2}>

              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.email.id}
                  id={this.state.formItems.email.id}
                  value={this.state.formItems.email.value}
                  label={this.state.formItems.email.label}
                  placeholder={this.state.formItems.email.placeholder}
                  error={this.state.formItems.email.hasError}
                  helperText={this.state.formItems.email.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>


              <Grid item xs={12}>
                  <div className="btn-wrapper on-right">
                      <Button 
                      type="submit" 
                      color="primary" 
                      variant="contained" 
                      disableElevation 
                      disabled={submitIsDisabled() || this.state.loading}
                      onClick={handleSubmit}
                      endIcon={(this.state.loading) ? <CircularProgress size={16} /> : null}
                      >
                      {(this.state.loading) ? 'Enviando...' : 'Continuar'}
                      </Button>
                  </div>
              </Grid>

          </Grid>
          </div>
          </form>

      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));