import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./partner-page.styles.scss";

// Import components
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

// Import Redux Action
import { openModal } from "../../redux/modal/modal.actions";
import { getToken } from "../../utils/user-validation/user-validation.utils";

// Import modules
import PostsViewMode from "../../modules/posts/posts.view.component";
import CalendarScheduler from '../../components/calendar-scheduler/calendar-scheduler.component';
import SvgIcon from "../../icons/SvgIcon";
import ContainerWrapper from '../../containers/container-wrapper/container-wrapper.component';
import ModalPage from '../../modals/modal-page/modal-page.component';

// Format Date Function
export const formatDate = (date, withHour) => {
  let sDate = null;

  let obj = date.split(" ");
  const dt = obj[0];

  const data = dt.split("-");
  let d = data[2];
  let m = data[1];
  let y = data[0];

  if (withHour) {
    const tm = obj[1];
    const hora = tm.split(":");
    let h = hora[0];
    let n = hora[1];
    sDate = d + "/" + m + "/" + y + " às " + h + "h" + n;
  } else {
    sDate = d + "/" + m + "/" + y;
  }

  return sDate;
};

// Partner About Section
export const PartnerAbout = (props) => {
  return(
    <>
      <div className={`mdl mdl-heading ${(props.className) ? props.className : ''}`}>
        <div className="module-heading">
          <h3 className="heading-3 left">Sobre</h3>
        </div>
      </div>

      <div className={`mdl mdl-richtext ${(props.className) ? props.className : ''}`}>
        <div className="module-richtext">
          <div className="left">{props.about}</div>
          <div className="since">
          Abriccó desde {formatDate(props.created, false)}
          </div>
        </div>
      </div>
    </>
  );
};


// Partner Contacts Box
export const PartnerContacts = (props) => {

  const formatPhone = (phone) => {
    let response = "";
    if (phone) {
      // Has DDD
      if (phone.length > 9) {

        let d = phone.length - 6;
        let ddd = phone.substr(0, 2);
        let prefix = phone.substr(2, d);
        let sufix = phone.substr(2 + d, 4);
        response = "(" + ddd + ") " + prefix + "-" + sufix;
      } else {

        let d = phone.length - 4;
        let prefix = phone.substr(0, d);
        let sufix = phone.substr(d, 4);
        response = prefix + "-" + sufix;
        console.dir(response);
      }
    }
    return response;
  };

  let complement = null;
  if(props.address){
    
    if(props.neighborhood && props.city && props.state){
      complement = props.neighborhood + ', ' + props.city + '-' + props.state;
    }

    if(props.neighborhood && props.city && !props.state){
      complement = props.neighborhood + ', ' + props.city;
    }

    if(props.neighborhood && !props.city && !props.state){
      complement = props.neighborhood;
    }

    if(!props.neighborhood && props.city && props.state){
      complement = props.city + '-' + props.state;
    }

    if(!props.neighborhood && props.city && !props.state){
      complement = props.city;
    }

  }

  let showSocial = (props.facebook || props.instagram || props.linkedin || props.whatsapp || props.youtube);

  return(
    <div className="mdl mdl-contact-box">
                    
                    <PartnerAbout className="hidden-in-mobile" {...props} />
                    

                    
                    <div className="contacts">
                      {(props.address) ?
                        <div className="address">
                          <span className="title">Endereço</span>
                          <span className="street">{props.address}</span>
                          (complement) ? <span className="street">{complement}</span> : null
                          (props.postal) ? <span className="street">{props.postal}</span> : null
                        </div>: 
                        null
                      }

                      {props.phone ? (
                        <div className="phone">
                          <span className="material-icons">phone</span>
                          {formatPhone(props.phone)}
                        </div>
                      ) : null}

                      {props.mobile ? (
                        <div className="phone">
                          <span className="material-icons">phone_iphone</span>
                          {formatPhone(props.mobile)}
                        </div>
                      ) : null}

                      {
                        (props.email)?
                        <div className="phone">
                        <span className="material-icons">mail</span>
                        <a href={'mailto:' + props.email }>
                          {props.email}
                        </a>
                        </div>:
                        null
                      }
                      
                      
                      {(props.site) ?
                        <div className="phone">
                          <span className="material-icons">web_asset</span>
                          <a href={props.site} target='_blank' rel="noopener noreferrer">
                            {props.site}
                          </a>
                        </div>: 
                        null
                      }
                      
                    </div>

                    <div className="schedule">
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={props.scheduler}
                      >
                        Agendar atendimento
                      </Button>
                    </div>

                    <div className={(showSocial) ? 'social': 'social hidden'}>
                      {(props.facebook)?
                      <Tooltip title="Facebook" arrow>
                      <a
                        href={props.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SvgIcon icon="FacebookIcon" />
                      </a>
                    </Tooltip>:
                    null
                    }
                      
                    {(props.instagram) ?
                    <Tooltip title="Instagram" arrow>
                    <a
                      href={props.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SvgIcon icon="InstagramIcon" />
                    </a>
                  </Tooltip>:
                  null
                  }
                      
                      {
                        (props.linkedin)?
                        <Tooltip title="LinkedIn" arrow>
                        <a
                          href={props.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgIcon icon="LinkedinIcon" />
                        </a>
                      </Tooltip>:
                      null
                      }

                      
                      {
                        (props.youtube)?
                        <Tooltip title="Youtube" arrow>
                        <a
                          href={props.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgIcon icon="YoutubeIcon" />
                        </a>
                      </Tooltip>:
                      null
                      }
                      
                      {
                        (props.whatsapp)?
                        <Tooltip title="WhatsApp" arrow>
                        <a
                          href={'https://api.whatsapp.com/send?phone='+ props.whatsapp}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SvgIcon icon="WhatsappIcon" />
                        </a>
                      </Tooltip>:
                      null
                      }
                      
                    </div>
                  </div>
  );

}

// Partner Content List
export const PartnerContentList = (props) => {

  return(
    <>
      <div className="mdl mdl-heading">
        <div className="module-heading">
          <h3 className="heading-3 left no-margin">{props.title}</h3>
        </div>
      </div>
      <table className='partner-content-list border-top'>
        <tbody>
            {
              props.list.map((item, index) => {
                return(
                  <tr key={index} >
                    <td>
                      {
                        (item.url) ?
                        <a href={item.url}>{item.title}</a>:
                        <span>{item.title}</span>
                      }
                      
                    </td>
                    <td className="cell-xs">
                      <div className="btn-wrapper">
                        <button className="btn-circle primary medium" onClick={() => props.openDirectory(item)} disabled={(item.contents.length === 0)}><span className="material-icons">list</span></button>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          
          </tbody>
      </table>
    </>
  );
}


class PartnerPage extends Component {
  constructor(props) {
    super(props);
    this.partnerId = this.props.partnerId
      ? this.props.partnerId
      : this.props.match.params.partnerId;
    this.state = {
      loading: true,
      partner: [],
      courses: [],
      showScheduler: false,
      pageData: [],
      modules: [],
      contentList: {
        isOpen: false,
        title: 'Conteúdo Programático',
        subtitle: '',
        list: []
      }
    };
  }

  componentDidMount() {
    // Verify for Session Modals
    const hasModalToOpen = localStorage.getItem("openModal");
    if (hasModalToOpen && hasModalToOpen === "1") {
      const modalObj = JSON.parse(localStorage.getItem("modalObj"));
      this.props.openModal(modalObj);
    }

    const abriccoAuth = {
      error: false,
      currentPage: window.location,
      title: null,
      feedback: null,
    };
    localStorage.setItem("abriccoAuth", JSON.stringify(abriccoAuth));

    this.fetchPartner();
  }

  fetchPartner = () => {
    const userToken = getToken();

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken,
        id: this.partnerId,
      }),
    };

    fetch("/api/get_partner.php", requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        if (data.success) {

          const courses = data.partner.trainings.map((item) => {
            let obj = {};
            obj.id = item.id;
            obj.title = item.title;
            obj.url = item.url;
            obj.nextClass = item.nextClass;
            obj.places = (item.capacity - item.registered);

            return obj;
          });

          const callback = () => {
            if(data.partner.profile){
              this.fetchProfile(data.partner.profile);
            }
          };


          this.setState({
            loading: false,
            partner: data.partner,
            courses: courses
          }, callback);
        
        }else{
          window.location = '/404';
        } 
        
      })
      .catch((error) => {
        console.dir(error);
      });
  };

  fetchProfile = (id) => {
    const userToken = getToken();

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
      },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken,
        id: 'content-' + id,
      }),
    };

    fetch("/api/get_content.php", requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        if (data.success) {

          this.setState({
            pageData: data.pageData,
            modules: data.modules
          });
        }
      })
      .catch((error) => {
        console.dir(error);
      });
  };

  convertToDate = (date, withHour) => {
    let sDate = null;

    let obj = date.split(" ");
    const dt = obj[0];

    const data = dt.split("-");
    let d = data[2];
    let m = data[1];
    let y = data[0];

    if (withHour) {
      const tm = obj[1];
      const hora = tm.split(":");
      let h = hora[0];
      let n = hora[1];
      sDate = new Date(y, m, d, h, n);
    } else {
      sDate = new Date(y, m, d);
    }

    return sDate;
  };

  toggleScheduler = () => {
    this.setState({showScheduler: !this.state.showScheduler});
  }

  openDirectory = (obj) => {

    const { contentList } = this.state;
    const updatedObj = contentList;
    updatedObj.isOpen = true;
    updatedObj.subtitle = obj.title;
    updatedObj.list = obj.contents;

    this.setState({contentList: updatedObj});

  }

  closeDirectory = () => {
    const { contentList } = this.state;
    const updatedObj = contentList;
    updatedObj.isOpen = false;
    updatedObj.subtitle = '';
    updatedObj.list = [];

    this.setState({contentList: updatedObj});

  }

  render(props) {


    if (this.state.loading) {
      return (
        <div className="page">
          <div className="content partner-page">
            <div className="loading">
              <CircularProgress size={72} />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.partner) {
      const { partner, contentList } = this.state;

      // Set avatar image
      const avatarImg =
        partner && partner.avatar
          ? 'url("' + window.$usersPath + partner.avatar + '")'
          : 'url("' + window.$usersPath + '0.jpg")';
      
      const banner = 'url("' + window.$imagesPath + '26.jpg")';

      // Set name to show
      const names = partner.fullname.split(" ");
      const name =
        names.length > 1
          ? names[0] + " " + names[names.length - 1]
          : partner.fullname;

      return (
        <div className="page has-sidebar">

          

          <div className="content content-page partner-page">
            <div className="mdl mdl-banner-title full">
              <div className="partner-heading">
                <div className="avatar" style={{ backgroundImage: avatarImg }}></div>
                {partner.company ? (
                  <span className="company-name">{partner.company}</span>
                ) : null}
                <div className="module-heading">
                  <h1 className="heading-1 center">{name}</h1>
                </div>
              </div>
              <div
                className="module-banner-title"
                style={{ backgroundImage: banner }}
              >
                <div className="banner"></div>
              </div>
            </div>

            <div className="container">
              <Grid container spacing={4}>

                {/* MAIN */}
                <Grid item xs={12} md={8}>
                 
                  {/* About Section */}
                  <Grid item xs={12} className="hidden-on-tablet">
                    <PartnerAbout {...partner} />
                  </Grid>

                  {/* Profile Custom Content */}
                  <Grid item xs={12}>
                  {
                    // eslint-disable-next-line
                    this.state.modules.map((item) => {
                      return(
                        <ContainerWrapper 
                          key={item.content}
                          pageData={this.state.pageData}
                          {...item} 
                        />
                      );
                    })
                  }
                  </Grid>

                  {/* Trainings Section */}
                  {
                    (partner.trainings?.length > 0) ?
                    <Grid item xs={12} className="margin-bottom--md">
                      <PartnerContentList title="Treinamentos" list={partner.trainings} openDirectory={this.openDirectory}/>
                    </Grid>:
                    null
                  }

                  {/* Keynnotes Section */}
                  {
                    (partner.keynotes?.length > 0) ?
                    <Grid item xs={12} className="margin-bottom--md">
                      <PartnerContentList title="Palestras" list={partner.keynotes} openDirectory={this.openDirectory}/>
                    </Grid>:
                    null
                  }
                  
                
                  {/* Blog Section  */}
                  {
                    (partner.hasPosts) ?
                      <Grid item xs={12}>
                        <div className="mdl mdl-posts">
                            <PostsViewMode
                              contentId='partner-blog'
                              category={3}
                              subcategory={0}
                              author={this.partnerId}
                              wide="sidebar"
                            />
                        </div>
                      </Grid>: 
                      null
                  }
                  

                </Grid>

                {/* SIDEBAR */}
                <Grid item xs={12} md={4} className="on-sidebar">
                  <PartnerContacts scheduler ={this.toggleScheduler} {...partner} />
                </Grid>
               
              </Grid>

              
              
            
            </div>
          </div>
          <CalendarScheduler user={this.state.partner.id} isOpen={this.state.showScheduler} toggle={this.toggleScheduler} partner={partner}/>

          {/* Content Modal*/}
          <ModalPage isOpen={contentList.isOpen} title={contentList.title} close={this.closeDirectory}>
                  <>
                    <h3 className="margin-top--none">{contentList.subtitle}</h3>
                    <table className="content-list border-top">
                      <tbody>
                      {
                        contentList.list.map((item, index) => {
                          return(
                            <tr key={index}>
                              <td>{item.title}</td>
                            </tr>
                          );
                        })
                      }
                      </tbody>
                    </table>
                  </>
          </ModalPage>
        </div>
      );
    }
    return <></>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: (modalData) => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(PartnerPage));
