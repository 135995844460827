// return the user data from the session storage

export const getUser = () => {
    const userObj = localStorage.getItem('abricco');
    if (userObj) return JSON.parse(userObj);
    else return null;
  }

  export const getId = () => {
    const userObj = JSON.parse(localStorage.getItem('abricco'));

    if(userObj){
      return userObj.id;
    }else{
      return null;
    }
  }

  export const getType = () => {
    const userObj = JSON.parse(localStorage.getItem('abricco'));

    if(userObj){
      return parseInt(userObj.type);
    }else{
      return 0;
    }
  }
   
  // return the token from the session storage
  export const getToken = () => {
    const userObj = JSON.parse(localStorage.getItem('abricco'));

    if(userObj){
      return userObj.token;
    }else{
      return null;
    }
  }
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('abricco');
  }
   
  // check if a token is still valid
  export const checkToken = (token) => {

    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
      body: JSON.stringify({
          userToken: token
      })
    };

    fetch('/api/auth_token.php', requestOptions)
    .then(async response => {
        const data = await response.json();
        if (data.authenticated){
          return true;
        }else{
          localStorage.removeItem('userToken');
          return false;
        }
    })

    

  }

  // Redirect to login if user is not authenticated
  export const authUser = (addLoader, removeLoader) => {

      addLoader();
      const token = getToken();
      const abriccoAuth = {
        error: false,
        currentPage: window.location,
        title: null,
        feedback: null
      }
      
      if(token){

        // Authenticate user on server
  
        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
          body: JSON.stringify({
              userToken: token,
              app: window.$appToken 
          })
        };
        fetch("/api/auth_token.php", requestOptions)
        .then(async response => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            
            if(data.authenticated){

              abriccoAuth.error = false;
              abriccoAuth.title = null;
              abriccoAuth.feedback = null;
              abriccoAuth.currentPage = window.location;
              localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));
              removeLoader();
              return true;

            }else{

              abriccoAuth.error = true;
              abriccoAuth.title = 'Sua sessão expirou';
              abriccoAuth.feedback = data.feedback;
              abriccoAuth.currentPage = window.location;
              localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));
              localStorage.removeItem('abricco');
              removeLoader();
              return false;

            }

        })
        .catch(error => {

          abriccoAuth.error = true;
          abriccoAuth.title = 'Sua sessão expirou';
          abriccoAuth.feedback = 'Não foi possível validar suas credencias de acesso no momento. O servidor retornou a seguinte mensagem: ' + error;
          abriccoAuth.currentPage = window.location;
          localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));
          localStorage.removeItem('abricco');
          removeLoader();
          return false;

        });


    }else{

      abriccoAuth.error = true;
      abriccoAuth.title = 'Quem é você?';
      abriccoAuth.feedback = 'Você precisa se logar para acessar este conteúdo';
      abriccoAuth.currentPage = window.location;
      localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));
      localStorage.removeItem('abricco');
      removeLoader();
      
      return false;

    }


  }

  export const authAction = (authenticated) => {

    if(!authenticated){

      const abriccoAuth = {
        error: true,
        currentPage: window.location,
        title: 'Sua sessão expirou',
        feedback: 'Você precisa se logar novamente para ter acesso a este conteúdo'
      }
      localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));
      localStorage.removeItem('abricco');

      let goTo = 'http://' + window.location.host + '/login';
      window.location = goTo;

    }

  }
  