import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './banner.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

// Import components
import IptImage from '../../components/ipt-image/ipt-image.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class BannerEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.parallaxPlaceholder = (this.props.parallax === 1) ? 'Desativar efeito parallax' : 'Ativar efeito parallax';
        this.state = {
            formItems: {
                src: {
                    id: 'src',
                    label: 'Imagem',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                srcId: {
                    id: 'srcId',
                    label: 'Imagem',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                label: {
                    id: 'label',
                    label: 'Label',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
                link: {
                    id: 'link',
                    label: 'Link',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
                
            },
            banners: (Array.isArray(this.props.banners)) ? this.props.banners : []
        }
    }

    componentDidMount(){

        
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const handleSubmit = (event) => {

            event.preventDefault();
            let banners = this.state.banners;
            
            const parameters = {
                banners: banners
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const addIsEnabled = () => {
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            if(formItems.link.value === '' || formItems.text.value === '' || formItems.src.value === ''){
                disabled = true;
            }

            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            return disabled;

        }

        const submitIsEnabled = () => {

            let disabled = false;
            if(this.state.banners.length === 0){
                disabled= true;
            }
            return disabled;

        }

        const setCtaLink = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.link.value = url;
            
    
            formItems.link.hasTouched = true;
            const validation = validate(url, formItems.link.rules, formItems);
            formItems.link.hasError = validation[0];
            formItems.link.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });
    
            this.props.closeDirectory();
    
        }

        const setImage = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.src.value = obj[0].mediaFile;
            formItems.srcId.value = obj[0].mediaId;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();
    
        }

        const unsetImage = () => {
    
            const formItems = this.state.formItems;
            formItems.src.value = '';
            formItems.srcId.value = 0;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            });
    
        }

        const addBanner = () => {

            const banners = this.state.banners;

            const newBanner = {
                srcId: this.state.formItems.srcId.value,
                src: this.state.formItems.src.value,
                label: this.state.formItems.label.value,
                link: this.state.formItems.link.value,
                text: this.state.formItems.text.value,
            }

            banners.push(newBanner);

            const formItems = this.state.formItems;
            formItems.src.value = '';
            formItems.srcId.value = 0;
            formItems.label.value = '';
            formItems.link.value = '';
            formItems.text.value = '';

            this.setState({
                ...this.state,
                formItems: formItems,
                banners: banners
            })

        }

        const removeBanner = (idx) => {
            const banners = this.state.banners;
            banners.splice(idx, 1);

            this.setState({
                ...this.state,
                banners: banners
            })
        }


        return(
            <div className='banner-edit-mode'>
                <div className="module-title-bar">Módulo Banner : {this.props.contentId}</div>

                <form className="add-banner-form">

                    {/* Image */}
                    <div className="image-area">
                        <IptImage 
                        className="image" 
                        limit={1} 
                        action={setImage}
                        clearFunction = {unsetImage}
                        selectedFile={this.state.formItems.src.value} 
                        selectedImage={this.state.formItems.srcId.value} 
                        />
                    </div>

                    {/* Other fields */}
                    <div className="fields-area">
                    <Grid container spacing={2}>
                    { /* Label */ }
                            <Grid item xs={12} sm={6}>
                                <TextField
                                name={this.state.formItems.label.id}
                                id={this.state.formItems.label.id}
                                value={this.state.formItems.label.value}
                                label={this.state.formItems.label.label}
                                placeholder={this.state.formItems.label.placeholder}
                                error={this.state.formItems.label.hasError}
                                helperText={this.state.formItems.label.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </Grid>

                            { /* Link */ }
                            <Grid item xs={12} sm={6}>
                                <TextField
                                name={this.state.formItems.link.id}
                                id={this.state.formItems.link.id}
                                value={this.state.formItems.link.value}
                                label={this.state.formItems.link.label}
                                placeholder={this.state.formItems.link.placeholder}
                                error={this.state.formItems.link.hasError}
                                helperText={this.state.formItems.link.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        aria-label="Procurar conteúdo"
                                        edge="end"
                                        onClick={() => this.props.openDirectory(1, setCtaLink)}
                                        >
                                        <DescriptionIcon /> 
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                />
                            </Grid>

                            { /* Text */ }
                            <Grid item xs={12} >
                                <TextField
                                name={this.state.formItems.text.id}
                                id={this.state.formItems.text.id}
                                value={this.state.formItems.text.value}
                                label={this.state.formItems.text.label}
                                placeholder={this.state.formItems.text.placeholder}
                                error={this.state.formItems.text.hasError}
                                helperText={this.state.formItems.text.errorMsg}
                                fullWidth
                                multiline
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </Grid>
                    </Grid>
                    </div>

                    <div className="buttons-area btn-wrapper">
                        <button className="btn-circle primary medium" onClick={addBanner} disabled={addIsEnabled()}><AddIcon /></button>
                    </div>

                </form>

                { /* Registered Banners */}
                <div className="banners-list">
                    {
                    this.state.banners.map((item, index)=> {

                        let background = (item.src !== '')? 'url("' + window.$imagesPath + item.src + '")' : 'none';
                        let bannerKey = 'banner-' + index;

                        return(
                            <div key={bannerKey} className="banner">
                                <div className="image" style={{backgroundImage: background}}></div>
                                <div className="info">
                                    <div className="label">{item.label}</div>
                                    <div className="text">{item.text}</div>
                                    <div className="link"><a href={item.link} target="_blank" rel="noopener noreferrer">{item.link}</a></div>
                                </div>
                                <div className="buttons">
                                    <div className="btn-wrapper">
                                        <button className="btn-circle other small" onClick={() => removeBanner(index)} ><RemoveIcon /></button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                 { /* Buttons */ }
                 <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                        <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                        <Button 
                        type="submit" 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        onClick={(e) => handleSubmit(e)} 
                        disabled={submitIsEnabled()}
                        >Atualizar</Button>
                        </div>
                     </Grid>
                 </Grid>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(BannerEditMode));