import React from 'react';
import './highlighted-text.styles.scss';

// Import UI Component
import Button from "@material-ui/core/Button";

const HighlightedTextViewMode = (props) =>{

    const goto = (link) => {
        window.location = link;
    }

    const background = (props?.bgcolor) ? props.bgcolor : 'whitesmoke';

    return(
    <div className='module-highlighted-text' style={{backgroundColor: background}}>
        <div className="text" dangerouslySetInnerHTML={{__html: props.text}}></div>
        <div className={((props.primaryLabel !== '' && props.primaryLink !== '') || (props.secondaryLabel !== '' && props.secondaryLink !== '')) ? 'btn-wrapper side-by-side margin-top--sm' : 'btn-wrapper hidden'}>
            {
                (props?.primaryLabel && props?.primaryLink) ?
                <Button color="primary" variant="contained" disableElevation onClick={() => goto(props.primaryLink)}>{props.primaryLabel}</Button>:
                null
            }
            {
                (props?.secondaryLabel && props?.secondaryLink)?
                <Button color="primary" variant="outlined" disableElevation onClick={() => goto(props.secondaryLink)}>{props.secondaryLabel}</Button>:
                null
            }
        </div>
    </div>
    );

}

export default HighlightedTextViewMode;