import React from 'react';
import './calendar-month.styles.scss';

const CalendarMonth = (props) => {

    
        return(
            <div className="calendar-month">
                
                <div className="weekdays">
                    {
                        props.weekdays.map((item, index) => {
                            let keyWeek = 'week-' + index;
                            return(
                                <div key={keyWeek} className="weekday">{item}</div>
                            )
                        })
                    }
                </div>

                <div className="days">
                {
                    props.days.map((item) => {

                        let dayClasses = 'day';
                        if(item.outOfMonth){
                            dayClasses += ' disabled';
                        }else{
                            dayClasses += ' regular';
                        }
                        if(item.weekday === 0 || item.weekday === 6){
                            dayClasses += ' weekend';
                        }

                        if(item.day === props.today.d && item.month === props.today.m && item.year === props.today.y && !item.outOfMonth){
                            dayClasses += ' today';
                        }

                        if(item.day === 1 && item.month === (props.month - 1) && item.year === props.year){
                            dayClasses += ' first';
                        }

                        let keyItem = 'calendar-day-' + item.day + '-' + item.month + '-' + item.year;
                        let slotsOfDay = props.slots.filter((obj) => (obj.day === item.day));
                        let eventsOfDay = slotsOfDay.filter((obj) => (obj.status !== 0 && obj.status !==6 && !obj.past));

                        let dayObj = {
                            dt: item.date,
                            d: item.day,
                            m: item.month,
                            y: item.year,
                            w: item.weekday
                        }

                        let action = (item.outOfMonth) ? null : () => props.setDay(dayObj);
                        
                        return(
                            <div key={keyItem} className={dayClasses} onClick={action}>
                                <span className="day-label">{item.day}</span>
                                {
                                    eventsOfDay.map((obj, index) => {

                                        let keyObj = 'event-' + obj.id;

                                        if(index < 3){
                                            return(
                                                <span 
                                                key={keyObj} 
                                                className="event"
                                                onClick={() => props.openEvent(obj.id)}
                                                >
                                                    {obj.startTime} - {obj.title}
                                                </span>
                                            )
                                        }
                                        return null;

                                    })
                                }
                                
                                
                            </div>
                        )

                    })
                }
                </div>
            </div>
        );
    }

export default CalendarMonth;