import React, { createRef, useEffect } from 'react';
import { connect } from 'react-redux';
import './modal.styles.scss';

// Import Modals Types
import GeneralAlerts from '../general-alerts/general-alerts.component';
import Confirmation from '../confirmation/confirmation.component';
import {lockScrollbar} from '../../utils/utils';


const Modal = (props) => {

    useEffect(() => {
        if(props.modal.modalIsOpen){
            lockScrollbar('add');
        }else{
            lockScrollbar('remove');
        }
     });

    const modalRef = createRef();

    const modals = (modalId) => {
        switch (modalId) {
    
            case 'GENERAL_ALERT':
                return (<GeneralAlerts  />)
            case 'CONFIRMATION':
                return (<Confirmation  />)
            default:
                return (<GeneralAlerts  />)
        }
    }

    const modalIsOpen = (isOpen) => {

        let classes = 'modals__holder';
        if(isOpen){
            classes += ' opened';
            document.getElementById('root').classList.add('scroll-locked');
        }else{
            document.getElementById('root').classList.remove('scroll-locked');
        }
        return classes;

    }
    
    return(
        <div className={modalIsOpen(props.modal.modalIsOpen)}>
            <div className="modal__window" ref={modalRef}>
                <div className="modal">{modals(props.modal.modalComponent)}</div>
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    modal: state.modal
  });
  
  export default connect(mapStateToProps, null)(Modal);
  