import React, {Component} from 'react';
import { connect } from "react-redux";
import './calendar-scheduler.styles.scss';

import { getToken } from "../../utils/user-validation/user-validation.utils";
import { openModal } from "../../redux/modal/modal.actions";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";

class CalendarScheduler extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            isOpen: false,
            slots: [],
            weekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            weekdaysFull: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            partner: []
        };
    }

    componentDidMount(){
        this.setState({partner: this.props.partner} ,this.fetchSlots);
    }

    componentDidUpdate(prevProps){

        if(prevProps.isOpen !== this.props.isOpen){
            this.setState({isOpen: this.props.isOpen});

            const site = document.getElementById('site');
            if(this.props.isOpen){
                site.classList.add('scroll-lock');
            }else{
                site.classList.remove('scroll-lock');
            }
        }

        if(prevProps.partner !== this.props.partner){
            this.setState({partner: this.props.partner}, this.fetchSlots);
        }
    }

    fetchSlots = () => {
        if(this.state.partner?.id){
            const userToken = getToken();
            const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
            },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            owner: this.props.partner.id,
            }),
            };

            fetch("/api/scheduler_get_dates.php", requestOptions)
            .then(async (response) => {
            const data = await response.json();

                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                if (data.success) {

                    this.setState({
                    loading: false,
                    slots: data.slots,
                    });

                } 

            })
            .catch((error) => {
                console.dir(error);
            });
        }
    }

    confirmScheduleTime = (id) => {

        const { partner, slots } = this.state;
        
        if(partner?.id && id){
            const slot = slots.filter((item) => (item.id === id));
            let message = 'Confirma o agendamento com <b>' + partner.fullname + '</b> em <b>' + slot[0].dt + ' às ' + slot[0].startTime + '</b>?';

            const callback = () => {
                this.setState({loading: true}, () => this.scheduleTime(id));
            }

            const modalObj = {
                modalIcon: 'event_available',
                modalType: 'normal',
                modalComponent: 'CONFIRMATION',
                modalTitle: 'Confirmação',
                modalParameters: {
                    text: message
                },
                modalActions:{
                    action: callback
                }
            }
            this.props.openModal(modalObj);
        }
        
    }

    scheduleTime = (id) => {
        if(id){

            const userToken = getToken();
            const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
            },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            owner: this.props.partner.id,
            id: id
            }),
            };


            fetch("/api/scheduler_set_date.php", requestOptions)
            .then(async (response) => {
            const data = await response.json();

                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                if (data.success) {

                    const modalObj = {
                        modalIcon: 'done',
                        modalType: 'success',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Confirmado!',
                        modalParameters: {
                            text: 'Ok, agendamento realizado! Enviamos um e-mail com os dados do seu agendamento ;)'
                        },
                        modalActions:{
                            action: this.fetchSlots
                        }
                    }
                    this.props.openModal(modalObj);

                } else{

                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'fail',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Ops!',
                        modalParameters: {
                            text: 'Não foi possível fazer o agendamento no momento. O servidor retornou a seguinte mensagem: ' + data.feedback
                        },
                        modalActions:{
                            action: this.fetchSlots
                        }
                    }
                    this.props.openModal(modalObj);

                }

            })
            .catch((error) => {
                console.dir(error);
            });
        }
    }

    gotoLogin = () => {
        window.location = '/login';
    }

    render(){
        const { isOpen, slots, loading, partner } = this.state;

        const user = getToken();

        const getUniqueDays = (obj) => {

            let uniqueDays = [];
            obj.forEach((item) => {
                if(uniqueDays.indexOf(item.dt) === -1){
                    uniqueDays.push(item.dt);
                }
            })

            return uniqueDays;

        }

        const daysWithEvents = getUniqueDays(slots);

        return(
            <div className={(isOpen) ? 'calendar-scheduler' : 'calendar-scheduler hidden'}>
                <div className="scheduler">

                    <div className="close-button">
                        <button className="btn-close" onClick={this.props.toggle}><span className="material-icons">clear</span></button>
                    </div>

                    

                    {
                        (user && daysWithEvents.length > 0)?
                        <>
                        <div className="mdl-heading">
                            <div className="module-heading">
                                <h3 className="heading-3 left">Horários disponíveis</h3>
                            </div>
                        </div>
                        <div className="days">
                        <div className={(loading) ? 'loading' : 'loading hidden'}><CircularProgress size={48} /></div>
                        {
                            daysWithEvents.map((dt, dtIndex) => {

                                let dKey = 'day-' + dtIndex;
                                let aDate = dt.split('-');
                                let day = aDate[0];
                                let month = parseInt(aDate[1] - 1);
                                let year = aDate[2];
                                let jDate = new Date(year, month, day);
                                let week = jDate.getDay();
                                let eventsOfDay = slots.filter((item) => item.dt === dt);
                                
                                return(
                                    <div key={dKey} className="day">
                                        <div className="header">
                                            <div className="weekday">{this.state.weekdays[week]}</div>
                                            <div className="date">{day}<span className="month">{this.state.months[month]}</span></div>
                                        </div>
                                        <div className="body">
                                            {
                                                eventsOfDay.map((time, timeIndex) => {
                                                    let tKey = dKey + '-time-' + timeIndex;
                                                    return(
                                                        <button key={tKey} className="time" disabled={(time.status !== 0)} onClick={() => this.confirmScheduleTime(time.id)}>{time.startTime}</button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )

                            })
                        }
                        
                    </div>
                    </>:
                    (user && daysWithEvents.length === 0) ?
                    <>
                    <div className="mdl-heading">
                        <div className="module-heading">
                            <h3 className="heading-3 left">Agendamento Online</h3>
                        </div>
                    </div>
                    <div className="no-user">
                        <p>No momento, não há horários disponíveis para agendamento com {partner.fullname}. Pode tentar entrar em contato diretamente com o parceiro ou tentar novamente mais tarde.</p>
                        <div className="btn-wrapper side-by-side centered">
                            <Button variant="contained" color="default" disableElevation onClick={this.props.toggle}>Fechar</Button>
                        </div>
                    </div>
                    </>:
                    <>
                    <div className="mdl-heading">
                        <div className="module-heading">
                            <h3 className="heading-3 left">Agendamento Online</h3>
                        </div>
                    </div>
                    <div className="no-user">
                        <p>Para agendar seu atendimento online você precisa ser registrado na Abriccó.</p>
                        <p>Registre-se. É rápido, gratuito e com muitos benefícios. Caso já possua registro, basta fazer o login para continuar.</p>
                        <div className="btn-wrapper side-by-side on-right">
                            <Button variant="outlined" color="primary" disableElevation onClick={this.gotoLogin}>Registrar</Button>
                            <Button variant="contained" color="primary" disableElevation onClick={this.gotoLogin}>Entrar</Button>
                        </div>
                    </div>
                    </>
                    }

                    

                </div>
            </div>
        )
    }
} 

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalData) => dispatch(openModal(modalData)),
  });
  
  export default connect(null, mapDispatchToProps)(CalendarScheduler);