// Import libraries
import React,  { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';

// Import assets and styles
import './user-menu.styles.scss';

// Import Material UI components
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

// Import Utils
import {getUser, getToken} from '../../utils/user-validation/user-validation.utils';
import validate from '../../utils/form-validation/form-validation.utils';
import {toggleUserMenu} from '../../redux/menus/menus.actions';
import { openModal } from '../../redux/modal/modal.actions';

class UserMenu extends Component{

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            newComments: 0,
            loadings: {
                upload: false
              },
              option: 'my-account',
              options: [
                {
                  id: 'my-account',
                  label: 'Minha conta',
                  icon: 'assignment_ind',
                  type: 1,
                  counter: null,
                  page: '/dashboard'
                },
                {
                  id: 'my-favorites',
                  label: 'Meus favoritos',
                  icon: 'favorite',
                  type: 1,
                  counter: null,
                  page: '/meus-favoritos'
                },
                {
                  id: 'my-bookmarks',
                  label: 'Meus bookmarks',
                  icon: 'bookmark',
                  type: 1,
                  counter: null,
                  page: '/bookmarks'
                },
                {
                  id: 'appointments',
                  label: 'Agenda',
                  icon: 'today',
                  type: 0,
                  counter: null,
                  page: '/agenda'
                },
                {
                  id: 'schedule',
                  label: 'Agendamentos',
                  icon: 'today',
                  type: 5,
                  counter: null,
                  page: '/schedule'
                },
                {
                  id: 'users',
                  label: 'Usuários',
                  icon: 'people',
                  type: 5,
                  counter: null,
                  page: '/users'
                },
                {
                  id: 'leads',
                  label: 'Leads',
                  icon: 'contact_mail',
                  type: 3,
                  counter: 0,
                  page: '/leads'
                },
                {
                    id: 'content',
                    label: 'Conteúdo',
                    icon: 'description',
                    type: 3,
                    counter: 0,
                    page: '/pages'
                  },
                {
                  id: 'comments',
                  label: 'Comentários',
                  icon: 'comment',
                  type: 5,
                  counter: 4,
                  page: '/comments'
                },
                {
                  id: 'navigation',
                  label: 'Navegação',
                  icon: 'menu_open',
                  type: 5,
                  counter: 0,
                  page: '/navigation'
                },
              ],
              avatar: null,
              formItems: {
                avatar: {
                  id: 'avatar',
                  label: 'Avatar',
                  placeholder: '',
                  value: '',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: []
                },
                keySearch: {
                  id: 'keySearch',
                  label: 'Procurar por',
                  placeholder: '',
                  value: '',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: {
                      maxLength: 255
                  }
              },
              }
        }
    }

    getCommentsCounter(){

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          app: window.$appToken,
        }),
      };

      fetch("/api/comments_counter.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
  
        if(data.success){

          const options = this.state.options;
          for(let i = 0; i < options.length; i++){

            if(options[i].id === 'comments'){
              options[i].counter = data.total;
            }

          }

          this.setState({
              ...this.state,
              options: options
          })

        }

      })
      .catch((error) => {
        console.dir(error);
      });
    }

    getContentsCounter(){

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          app: window.$appToken,
        }),
      };

      fetch("/api/content_counter.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
  
        if(data.success){

          const options = this.state.options;
          for(let i = 0; i < options.length; i++){

            if(options[i].id === 'content'){
              options[i].counter = data.total;
            }

          }

          this.setState({
              ...this.state,
              options: options
          })

        }

      })
      .catch((error) => {
        console.dir(error);
      });
    }

    componentDidMount(){
        const user = this.getUserData();
        this.setState({
          ...this.state,
          avatar: user.avatar,
          user: user
        })

        this.getCommentsCounter();
        this.getContentsCounter();
    }

    getUserData = () => {
            
        let userData = {};
        if(localStorage.getItem('abricco')){
            userData = JSON.parse(localStorage.getItem('abricco'));
        }else{
            userData = {
            id: 0,
            token: '',
            avatar: '',
            name: '',
            type: 0
            };
        }

        return userData;
    }
    
    //Rendering
    render(props){

        // Get user data
        const userObj = this.getUserData();

        // handle click event of logout button
        const handleLogout = () => {
            localStorage.removeItem('abricco');
            window.location = '/login';
        }

        // Allowed File Size
        const allowedFileSize = {
            handler: 2000000,
            label: '2Mb'
        };

        // Allowed File Types Array
        const allowedFileTypes = {
            types: [
            'png',
            'jpg',
            'jpeg',
            'gif'
            ],
            label: 'JPG, PNG ou GIF'
        };

        // Get display site
        const displayFileSize = (size) => {

            let fileSize;

            if (size>=1073741824){
            fileSize = Math.round(size/1073741824) + 'Gb';
            }else if(size>=1048576){
            fileSize = Math.round(size/1048576) + 'Mb';
            }else if(size>=1024){
            fileSize = Math.round(size/1024) + 'Kb';
            }else{
            fileSize = size + ' bytes';
            }

            return fileSize;
            
        }

        // Get Image Extension
        const getExtension = (filename) => {

            let tam = filename.length;
            let pos1 = filename.lastIndexOf('.') + 1;
            let dif = tam - pos1;
            let ext = filename.substr(pos1, dif);
            return ext.toLowerCase();
        }

        // File Input Handler
        const selectFileHandler = e => {

            let selectedFile = e.target.files[0];
            if(selectedFile){

            const loadings = this.state.loadings;
            loadings.upload = true;

            let selectedFileExt = getExtension(selectedFile.name);
            const formItems = this.state.formItems;
            const formItem = formItems.avatar;

            if(allowedFileTypes.types.indexOf(selectedFileExt) !== -1){

                if(selectedFile.size > allowedFileSize.handler){

                    formItem.value = '';
                    formItem.hasError = true;
                    formItem.hasTouched = true;
                    formItem.errorMsg = 'O tamanho da imagem (' + displayFileSize(selectedFile.size) + ') ultrapassa o limite máximo de ' + allowedFileSize.label;

                    formItems.avatar = formItem;

                    // File is too big
                    loadings.upload = false;

                    const modalObj = {
                      modalIcon: 'clear',
                      modalType: 'error',
                      modalComponent: 'GENERAL_ALERT',
                      modalTitle: 'Ops!',
                      modalParameters: {
                          text: formItem.errorMsg
                      },
                      modalActions: []
                    };
    
                    const callback = () => {this.props.openModal(modalObj)};
          
                    this.setState({
                    formItems: formItems,
                    loadings: loadings
                    }, callback);

                }else{

                    formItem.value = e.target.files[0];
                    formItem.hasError = false;
                    formItem.hasTouched = true;
                    formItem.errorMsg = '';

                    formItems.avatar = formItem;

                    // File is valid
                    loadings.upload = true;
                    this.setState({
                    ...this.state,
                    formItems: formItems,
                    loadings: loadings
                    });

                    uploadFile();
                
                }

            }else{

                formItem.value = '';
                formItem.hasError = true;
                formItem.hasTouched = true;
                formItem.errorMsg = 'Arquivos ' + selectedFileExt.toUpperCase() + ' não são permitidos. Utilize apenas imagens do tipo ' + allowedFileTypes.label;

                formItems.avatar = formItem;

                const modalObj = {
                  modalIcon: 'clear',
                  modalType: 'error',
                  modalComponent: 'GENERAL_ALERT',
                  modalTitle: 'Ops!',
                  modalParameters: {
                      text: formItem.errorMsg
                  },
                  modalActions: []
                };

                const callback = () => {this.props.openModal(modalObj)};
      

                // File type is invalid
                loadings.upload = false;
                this.setState({
                formItems: formItems,
                loadings: loadings
                }, callback);

                

            }
            }
            
        }

        // Upload File
        const uploadFile = () => {

            const loadings = this.state.loadings;

            const formItems = this.state.formItems;

            // User Validation Info
            const userObj = this.getUserData();

            if(userObj){
            
                const formData  = new FormData();
                formData.append('imagem', this.state.formItems.avatar.value);
                formData.append('userToken', getToken());
                formData.append('userId', userObj.id);
                formData.append('app', window.$appToken);
                
                fetch('/api/avatar_upload.php', {
                method: 'POST',
                body: formData
                })
                .then(async (response) => {
                
                const data = await response.json();

                if(data.success){

                  loadings.upload = false;
                  formItems.avatar.value = '';
                  const user = getUser();
                  user.avatar = data.avatar;
                  localStorage.setItem('abricco', JSON.stringify(user));
                  this.setState({
                      loadings: loadings,
                      formItems: formItems,
                      avatar: data.avatar
                  });

                }else{

                  const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops!',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                  };
        
                  const callback = () => {this.props.openModal(modalObj)}

                  loadings.upload = false;
                  this.setState({
                      loadings: loadings
                  }, callback);

                }

                

                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }


                })
                .catch((error) => {
                console.dir(error);
                });

            }

        }

        // Call Page
        const goTo = (page) => {
            //this.props.toggleUserMenu();
            window.location = page;
        }
            
        // Set avatar image
        const avatarImg = (this.state.avatar) ? 'url("' + window.$usersPath + this.state.avatar + '")' : 'url("' + window.$usersPath +  '0.jpg")';

        // Set avatar progress loader size
        const avatarProgressSize = () => {
          const windowSize = window.innerWidth;
          if(windowSize > 767){
            return '100';
          }
          return '80';
        }

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // Get current FormItems object from State
          const formItems = this.state.formItems;

          // Get State of the Input
          const formItem = {
              ...formItems[name]
          };

          // Validate Element if it has been Touched Before
          if(formItem.hasTouched){
              if(formItem.rules){
                  const validation = validate(value, formItem.rules, formItems);
                  formItem.hasError = validation[0];
                  formItem.errorMsg = validation[1];
              }else{
                  formItem.hasError = false;
                  formItem.errorMsg = '';
              }
          }

          // Set the new value to element
          formItem.value = value;

          // Update formItems collection
          formItems[name] = formItem;


          // Update State
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });

      };

      // FORMS: INPUT BLUR HANDLE FUNCTION
      const handleBlur = (event) => {
                  
          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // formItems collection
          const formItems = this.state.formItems;

          // state of modified element
          const formItem = {
              ...formItems[name]
          };

          formItem.hasTouched = true;
          if(formItem.rules){
              const validation = validate(value, formItem.rules, formItems);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }

          // Update formItems collection
          formItems[name] = formItem;

          // Update state
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });

      }

      // GO TO SEARCH
      const submitByKeyboard = (e) => { 

        if(e.keyCode === 13){
          submitSearch();
        }
         
      }

      const submitSearch = () => { 

        const keySearch = this.state.formItems.keySearch.value;
        if(keySearch){
          const urlStr = '/busca/?key=' + keySearch;
          goTo(urlStr);
        }
         
      }

        const containerClasses = (this.props.menus.userMenuIsOpen) ? 'user-menu is-open' : 'user-menu';

        return(
            <div className={containerClasses}>
                <div className="mask" onClick={this.props.toggleUserMenu}></div>

                <div className="menu-wrapper">

                  { /* MAIN BLOCK */}
                  <div className="menu-block menu-options">
                
                        { /* AVATAR CHOICE */ }
                        <Tooltip arrow title="Trocar seu avatar">
                        <div className="avatar-wrapper">
                        <div className={(this.state.loadings.upload) ? 'loading' : 'loading hidden'} ><CircularProgress size={avatarProgressSize()} /></div>
                        <input
                        id={this.state.formItems.avatar.id}
                        name={this.state.formItems.avatar.id}
                        className="ipt-file"
                        type="file"
                        onChange={(e) => selectFileHandler(e)}
                        
                        ref={this.fileInput}
                        tabIndex="-1"
                        disabled={(!this.state.user || this.state.user.id === 0)}
                        />
                        <div className="avatar" style={{backgroundImage: avatarImg}}></div>
                        </div>
                        </Tooltip>

                        { /* GREETING */ }
                        <div className="module-heading">
                        <h2 className="heading-2 center">Olá {userObj.name}!</h2>
                        </div>

                        { /* SEARCH BOX - MOBILE ONLY */ }
                        <div className="search-box mobile-only">
                          <TextField
                          name={this.state.formItems.keySearch.id}
                          id={this.state.formItems.keySearch.id}
                          value={this.state.formItems.keySearch.value}
                          label={this.state.formItems.keySearch.label}
                          placeholder={this.state.formItems.keySearch.placeholder}
                          error={this.state.formItems.keySearch.hasError}
                          helperText={this.state.formItems.keySearch.errorMsg}
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onKeyDown={submitByKeyboard}
                          size="small"
                          InputProps={{
                              endAdornment: <InputAdornment position="end">
                                  <IconButton
                                  aria-label="Procurar conteúdo"
                                  edge="end"
                                  onClick={submitSearch}
                                  >
                                  <SearchIcon /> 
                                  </IconButton>
                              </InputAdornment>,
                          }}
                          />
                        </div>

                        { /* MAIN MENU - MOBILE ONLY */ }
                        <div className="main-menu mobile-only">
                          <ul className="menu">
                            {
                              this.props.menus.menuItems.filter(item => item.placeholder === 6).map((item, index) => {

                                let menuKey = 'user-menu-main-' + index;
                                return(
                                  <li key={menuKey} className="item" onClick={() => goTo(item.url)}>{item.label}</li>
                                )
                              })
                            }
                          </ul>
                        </div>

                  </div>
                        
                  { /* ADMIN BLOCK */ }
                  <div className={(this.state.user && this.state.user.id > 0) ? 'menu-block admin-tools' : 'menu-block admin-tools hidden'}>

                        { /* TOOLBAR */ }
                        <div className="toolbar">
                            <div className="btn-wrapper centered">
                                
                                {
                                this.state.options
                                .filter((option) => option.type === 0 || option.type <= parseInt(userObj.type))
                                .map((item, index) => {

                                let itemKey = 'option-' + index;
                                let cssClasses = (this.state.option === item.id) ? 'btn selected' : 'btn';
                                cssClasses += (parseInt(userObj.type) === 5 && parseInt(item.type) === 0) ? ' hidden' : '';

                                return(
                                    
                                    <div key={itemKey} className={cssClasses} onClick={() => goTo(item.page)} role="button" tabIndex="0">
                                        <span className="material-icons">{item.icon}</span>
                                        <span className="title">{item.label}</span>
                                        <span className={(item.counter > 0) ? 'counter' : 'counter hidden'}>{item.counter}</span>
                                    </div>
                                    
                                )

                                })}

                                <div className="btn" onClick={handleLogout}>
                                    
                                    <span className="material-icons">exit_to_app</span>
                                    <span className="title">Sair</span>
                                    
                                </div>

                            </div>
                        </div>

                  </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    menus: state.menus, 
});

const mapDispatchToProps = dispatch => ({
    toggleUserMenu: () => dispatch(toggleUserMenu()),
    openModal: modalData => dispatch(openModal(modalData))
});
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserMenu));