import React, {Component} from 'react';
import './testimonials.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Icon from '@material-ui/core/Icon';


// Import components
import TestimonialForm from './testimonial-form.component';




class TestimonialsEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.bgPlaceholder = (this.props.bg === 1) ? 'Fundo cinza' : 'Fundo branco';
        this.state = {
            items: (this.props.items) ? this.props.items : [],
            formItems: {
                bg: {
                    id: 'bg',
                    label: 'Background',
                    placeholder: this.bgPlaceholder,
                    value: parseInt(this.props.bg),
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                }
            }
        }
    }

    componentDidMount(){

        
    }


    render(props){

        // Handle Submit
        const handleSubmit = (event) => {

            event.preventDefault();
            let items = this.state.items;
            let bg = this.state.formItems.bg.value;
            
            const parameters = {
                items: items,
                bg: bg
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        // Set if submit button is enabled
        const submitIsEnabled = () => {
            let disabled = false;

            // If there is no card registered
            if(this.state.items.length === 0){
                disabled = true;
            }

            // If any card has an error
            for(let i = 0; i< this.state.items.length; ++i){

                if(this.state.items[i].hasError){
                    disabled= true;
                }

            }

            return disabled;

        }

        // Set if add button is enabled
        const addIsEnabled = (this.state.items.length >= 10) ? true : false;

        // Add Item
        const addItem = () => {

            const items = this.state.items;

            const newItem = {
                avatarId: 0,
                avatar: '',
                text: '',
                author: '',
                job: '',
                company: '',
                companyLink: '',
                hasError: true
            }

            items.push(newItem);

            this.setState({
                ...this.state,
                items: items
            })

        }

        // Change Item
        const changeItem = (index, name, value) => {

            const items = this.state.items;
            items[index][name] = value;
            this.setState({
                ...this.state,
                items: items
            })

        };

        // Validate Item
        const validateItem = (index, error) => {

            const items = this.state.items;
            items[index].hasError = error;

            this.setState({
                ...this.state,
                items: items
            })

        }

        // Remove Item
        const removeItem = (event, idx) => {

        event.preventDefault();
        
        const items = this.state.items;
        
        items.splice(idx, 1);

        this.setState({
            ...this.state,
            items: items
        });

    }

        

        // Handle Background Switch
        const handleBg = () => {

            let value = 1;
            let placeholder = ''

            if(this.state.formItems.bg.value === 1){
                value = 0;
                placeholder = 'Fundo branco';
            }else{
                value = 1;
                placeholder = 'Fundo cinza';
            }

            const formItems = this.state.formItems;

            formItems.bg.value = value;
            formItems.bg.placeholder = placeholder;

            this.setState({
                ...this.state,
                formItems: formItems
            })

        }

        return(
            <div className='testimonials-edit-mode'>
                <div className="module-title-bar">Módulo Depoimentos : {this.props.contentId}</div>
                
                    <Grid container spacing={2}>

                        { /* Background Switcher */ }
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                            control={
                                <Switch
                                    checked={(this.state.formItems.bg.value === 1) ? true : false }
                                    onChange={handleBg}
                                    name="bg"
                                    color="primary"
                                />
                            }
                            label={this.state.formItems.bg.placeholder}
                            />
                        </Grid>

                        { /* Add Item Button */ }
                        <Grid item xs={12} sm={6}>
                            <div className='right'>
                                <Button 
                                color="primary" 
                                variant="outlined" 
                                disableElevation 
                                onClick={addItem} 
                                endIcon={<Icon>add</Icon>}
                                disabled={addIsEnabled}
                                >Adicionar</Button>
                            </div>
                        </Grid>

                        { /* Items */ }
                        <Grid item xs={12}>
                            
                            <div className="items">
                            <div className={(this.state.items.length === 0) ? 'no-results center' : 'no-results hidden'}>Clique em adicionar para inserir um depoimento à este módulo.</div>
                            {
                                this.state.items.map((item, index) => {
                                    let itemId = 'testimonial-' + index;
                                    return(
                                    
                                        <TestimonialForm key={itemId} itemId={index} {...item} changeItem={changeItem} validateItem={validateItem} removeItem={removeItem} />
                                    
                                    )
                                })
                            } 
                            </div>
                        </Grid>

                        { /* Buttons */ }
                        <Grid item xs={12}>
                            <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                                <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                                <Button 
                                type="submit" 
                                color="primary" 
                                variant="contained" 
                                disableElevation 
                                onClick={(e) => handleSubmit(e)} 
                                disabled={submitIsEnabled()}
                                >Atualizar</Button>
                            </div>
                        </Grid>

                    </Grid>
                
            </div>
        )
    }
}
  
export default TestimonialsEditMode;