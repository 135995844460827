// Import libraries
import React, {Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'

// Import styles and assets
import Logo from '../../assets/images/logo_abricco.svg';
import LogoMobile from '../../assets/images/logo_mobile.svg';
import './header.styles.scss';

// Import UI components
import SearchField from '../../components/search-field/search-field.component';

// Import components
import MainMenu from '../../components/main-menu/main-menu.component';
import LoginButton from '../../components/login-button/login-button.component';
import UserMenu from '../../components/user-menu/user-menu.component';

import {toggleUserMenu} from '../../redux/menus/menus.actions';

class Header extends Component{

    constructor(props) {
        super(props);
        this.state = {
                isOpened: false,
                formError: false,
                formItems: {
                    searchKey:{
                        id: 'searchKey',
                        label: 'Procurar por',
                        value: '',
                        hasTouched: false,
                        hasError: false,
                        errorMsg: '',
                        rules: {
                            required: true,
                        }
                    }
                },
                exceptions: [],
                headerIsHidden: false,
        }

    }


    componentDidUpdate(prevProps){
        if(prevProps.exceptions !== this.props.exceptions){
            
            const updatedExceptions = this.props.exceptions;
            const noHeaderPages = updatedExceptions.map(item => item.page);
            const currentIdx = noHeaderPages.indexOf(this.props.location.pathname);
            let updatedHeader = false;

            if(currentIdx > -1){
                updatedHeader = (updatedExceptions[currentIdx].header === 0);
            }
            
            this.setState({exceptions: updatedExceptions, headerIsHidden: updatedHeader}, this.toggleHeaderAndFooterClasses);

        }
    }

    toggleHeaderAndFooterClasses = () => {

        const { headerIsHidden } = this.state;
        const body = document.getElementById('root');

        if(headerIsHidden){
            body.classList.add('no-header');
        }else{
            body.classList.remove('no-header');
        }

    }

    openMenuMobile = () => {
        this.setState({isOpened: !this.state.isOpened}, this.props.toggleUserMenu);
    }
    
    //Rendering
    render(){

        const { headerIsHidden } = this.state;

        if(headerIsHidden){
            return null;
        }
        
        return(
            <header className={(this.state.isOpened) ? 'header-page opened' : 'header-page'}>

                <div className="site-logo">
                        <Link to="/">
                        <img src={Logo} className="hide-in-mobile" alt="Logo Abriccó" />
                        <img src={LogoMobile} className="hide-in-desktop" alt="Logo Abriccó" />
                        </Link>
                   
                </div>

                <div className="header-bar">

                    <div className="mobile-menu-button" onClick={this.openMenuMobile}>
                        <div className="sup"></div>
                        <div className="mid"></div>
                        <div className="inf"></div>
                    </div>

                    <div className="header-bar__menu">
                        <MainMenu {...this.props}/>
                    </div>
                    
                    <div className="desktop-search-box">
                        <SearchField />
                    </div>

                    <div className="header-bar__user-panel">
                        <LoginButton />
                    </div>

                </div>
                <UserMenu {...this.props}/>
                
            </header>
        );
    }

}

const mapStateToProps = state => ({
    menus: state.menus 
});

const mapDispatchToProps = dispatch => ({
    toggleUserMenu: () => dispatch(toggleUserMenu()),
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header));