import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

class Delete extends Component{

    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        return(
            <div className="delete">
                <div className="title">
                  <span className="text">Cancelar Agendamento</span>
                </div>
                <p>O evento será excluído.</p>
                <div className="btn-wrapper side-by-side on-right">
                    <Button color="default" variant="outlined" disableElevation onClick={() => this.props.toggleView('view')}>Voltar</Button>
                </div>
            </div>
        )
    }
}
export default Delete;