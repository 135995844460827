import React, {Component} from 'react';
import './carrousel.styles.scss';
import { CircularProgress } from "@material-ui/core";

class Carrousel extends Component{

    constructor(props){
        super(props);
        this.current = 1;
        this.active = 0;
        this.touchStart = 0;
        this.touchEnd = 0;
        this.viewMode = (window.innerWidth < 768 ) ? 'mobile' : (window.innerWidth < 1025) ? 'tablet' : 'desktop';

        this.state = {
            active: 0,
            loading: true,
            itemsPerPage: 1,
            itemWidth: 0,
            showControls: false,
            showDots: false,
            itemsTotal: 0,
            autoplay: false,
            dots: [] 
        };

    }

    componentDidMount(){

        this.init();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps){

        /*
        if(prevProps.children !== this.props.children && this.props.children.length > 0){
            this.init();
        }
        
        if(prevProps.itemsPerPage !== this.props.itemsPerPage){
            this.init();
        }
        */

    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
    }

    // Initialize the carousel
    init = () => {

        // Calculate slides per page
        let itemsPerPage = (this.props.itemsPerPage) ? this.props.itemsPerPage : 1;
        const windowWidth = window.innerWidth;
        if(windowWidth === 1024){
            itemsPerPage = 3;
        }
        if(windowWidth < 1024){
            itemsPerPage = 2;
        }
        if(windowWidth < 768){
            itemsPerPage = 1;
        }

        // Set variables and select objects
        const carrousel = document.getElementById(this.props.id);
        const slidesWrapper = carrousel.querySelector('.slides');
        const totalItems = slidesWrapper.childElementCount;
        const slides = slidesWrapper.children;
        const itemWidth = (100 / itemsPerPage) + '%';
        const dots = [];

        // Handle children as slides
        for(let i=0; i<slides.length; i++){
            slides[i].classList.add('slide');
            slides[i].setAttribute('data-index', i);
            dots.push(i);
            slides[i].style.width = itemWidth;
        }

        // Activate slider if total is bigger than items per page
        if(totalItems > itemsPerPage ){
            const slideWidth = slides[0].clientWidth;
            const showControls = (slides.length > 1 && this.props.showControls) ? true : false;
            const showDots = (slides.length > 1 && this.props.showDots) ? true : false;
            const autoplay = (this.props.autoplay) ? this.props.autoplay : false;

            // Create Clones
            const beforeItems = Array.from(slides).splice(0).reverse();
            const afterItems = Array.from(slides).splice(0);

            // Inject clones into beginning
            let counter = 1;
            beforeItems.forEach((item) => {
                if(counter <= itemsPerPage){
                    let cloneItem = item.cloneNode(true);
                    cloneItem.classList.add('clone');
                    if(counter === itemsPerPage){
                        cloneItem.classList.add('end-reseter');
                    }
                    slidesWrapper.prepend(cloneItem);
                }
                counter++;
            })

            // Inject clones into the end
            counter = 1;
            afterItems.forEach((item) => {
                if(counter <= itemsPerPage){
                    let cloneItem = item.cloneNode(true);
                    cloneItem.classList.add('clone');
                    if(counter === itemsPerPage){
                        cloneItem.classList.add('start-reseter');
                    }
                    slidesWrapper.append(cloneItem);
                }
                counter++;
            })

            // Recalculate slides position after clones insertion
            this.current = itemsPerPage;
            slidesWrapper.style.transform = `translateX(-${slideWidth * this.current}px)`;

            // Add events to (1) update pagination and dots and (2) handle with clones
            slidesWrapper.addEventListener('transitionstart', this.updateNavigate);
            slidesWrapper.addEventListener('transitionend', this.checkClones);

            // Active swipe support
            this.detectSwipe()

            // Update the state
            this.setState({
                itemsPerPage: itemsPerPage,
                itemsTotal: totalItems,
                itemWidth: slideWidth,
                showControls: showControls,
                showDots: showDots,
                autoplay: autoplay,
                dots: dots
            });
        }

    }

    // Destroy the carousel
    destroy = () => {

        // Select object
        const carrousel = document.getElementById(this.props.id);
        
        // Remove clones
        const clones = carrousel.querySelectorAll('.clone');
        for(let i = 0; i < clones.length; i++){
            clones[i].remove();
        }
        
        // Remove class slide from childrens
        const slides = carrousel.querySelectorAll('.slide');
        for(let i=0; i < slides.length; i++){
            slides[i].classList.remove('slide');
        }
        
        // Remove event listeners
        const slidesWrapper = carrousel.querySelector('.slides');
        slidesWrapper.removeEventListener('transitionstart', this.updateNavigate);
        slidesWrapper.removeEventListener('transitionend', this.checkClones);
        slidesWrapper.removeEventListener('touchstart', this.getTouchStart, false);
        slidesWrapper.removeEventListener('touchend', this.getTouchEnd, false);
        
        // Reset wrapper position
        slidesWrapper.style.transform = 'translateX(0px)';

        // Update State
        this.setState({
            showControls: false,
            showDots: false,
            autoplay: false,
            dots: []
        })

    }

    // Restart carousel when breakpoint changes
    handleResize = () => {

        const windowWidth = window.innerWidth;
        const viewMode = this.viewMode;
        let newViewMode = this.viewMode;
        let shouldUpdate = false;

        if(windowWidth >= 1025 && viewMode !== 'desktop'){
            shouldUpdate = true;
            newViewMode = 'desktop';
        }

        if(windowWidth < 1025 && viewMode !== 'tablet'){
            shouldUpdate = true;
            newViewMode = 'tablet';
        }

        if(windowWidth < 768 && viewMode !== 'mobile'){
            shouldUpdate = true;
            newViewMode = 'mobile';
        }

        if(shouldUpdate){
            this.viewMode = newViewMode;
            this.init();
        }

    }

    // Update dots and pagination
    updateNavigate = () => {
        const carrousel = document.getElementById(this.props.id);
        const slides = carrousel.querySelectorAll('.slide');
        const current = slides[this.current];
        const active = current.getAttribute('data-index');
        const dots = carrousel.querySelectorAll('.dot');

        

        dots.forEach((item) => {
            let dotIdx = item.getAttribute('data-index');
            if(dotIdx === active){
                this.active = dotIdx;
                item.classList.add('active');
            }else{
                item.classList.remove('active');
            }
        })

    }

    // Check if clones has been reached for slides position recalculate
    checkClones = () => {
        const {itemWidth, itemsPerPage, itemsTotal} = this.state;
        const carrousel = document.getElementById(this.props.id);

        if(carrousel){
            const slidesWrapper = carrousel.querySelector('.slides');
            const slides = carrousel.querySelectorAll('.slide');
            const current = slides[this.current];
            const isClone = current.classList.contains('clone');
        
            if(isClone && parseInt(this.active) === 0){
                this.current = itemsPerPage;
                slidesWrapper.style.transition = 'none';
                slidesWrapper.style.transform = `translateX(-${itemWidth * this.current}px)`;
            }

            const limit = itemsTotal - itemsPerPage;

            if(isClone && this.active == limit){
                this.current = parseInt(this.active) + parseInt(itemsPerPage);
                slidesWrapper.style.transition = 'none';
                slidesWrapper.style.transform = `translateX(-${itemWidth * this.current}px)`;
            }

        }

    }

    // Go to next slide
    next = () => {
        const {itemWidth} = this.state;
        const carrousel = document.getElementById(this.props.id);

        if(carrousel){
            const slidesWrapper = carrousel.querySelector('.slides');
            const slides = carrousel.querySelectorAll('.slide');
            if(this.current >= slides.length){
                return;
            }else{
                this.current++;
                slidesWrapper.style.transition = '.7s ease-out';
                slidesWrapper.style.transform = `translateX(${-itemWidth * this.current}px)`;
            }
        } 
    }

    // Go to previous slide
    prev = () => {
        const {itemWidth} = this.state;
        const carrousel = document.getElementById(this.props.id);

        if(carrousel){
            const slidesWrapper = carrousel.querySelector('.slides');
            const slides = carrousel.querySelectorAll('.slide');

            if(this.current <= 0){
                return;
            }else{
                this.current--;
                slidesWrapper.style.transition = '.7s ease-out';
                slidesWrapper.style.transform = `translateX(${-itemWidth * this.current}px)`;
            }
        } 
    }

    // Go to a given slide
    gotoSlide = (index, animate) => {
        const {itemWidth} = this.state;
        const carrousel = document.getElementById(this.props.id);

        if(carrousel && index){
            const slidesWrapper = carrousel.querySelector('.slides');
            this.current = index;
            if(animate){
                slidesWrapper.style.transition = '.7s ease-out';
                slidesWrapper.style.transform = `translateX(${-itemWidth * this.current}px)`;
            }else{
                slidesWrapper.style.transition = 'none';
                slidesWrapper.style.transform = `translateX(${-itemWidth * this.current}px)`;
                this.updateNavigate();
            }
        }
    }

    // Capture start of the touch
    getTouchStart = (event) => {
        this.touchStart = event.changedTouches[0].screenX;
    }

    // Capture end of the touch
    getTouchEnd = (event) => {
        this.touchEnd = event.changedTouches[0].screenX;
        this.handleGesture();
    }

    // Swipe to next or previous slide
    handleGesture = () => {
        const touchStart = this.touchStart;
        const touchEnd = this.touchEnd;
        const distance = touchEnd - touchStart;

        // Left Swipe
        if(touchEnd < touchStart && distance < -100){
            this.next();
        }

        // Right Swipe
        if(touchEnd > touchStart && distance > 100){
            this.prev();
        }
    }

    // Activate swipe support
    detectSwipe = () => {
        this.touchStart = 0;
        this.touchEnd = 0;
        const carrousel = document.getElementById(this.props.id);

        if(carrousel){
            const swipeArea = carrousel.querySelector('.slides');
            swipeArea.addEventListener('touchstart', this.getTouchStart, false);
            swipeArea.addEventListener('touchend', this.getTouchEnd, false);
        }
    }

    render(){

        const { showControls, showDots, dots } = this.state;

        return(
            <div key={this.props.id} id={this.props.id} className="carrousel">
                <div className="slides">
                    {this.props.children}
                </div>
                {
                    (showControls)?
                    <div className="controls">
                        <button className="previous" onClick={this.prev}><span className="material-icons">navigate_before</span></button>
                        <button className="next" onClick={this.next}><span className="material-icons">navigate_next</span></button>
                    </div>:
                    null
                }
                {
                    (showDots)?
                    <div className="navigate">
                        {
                            dots.map((item, index) => {
                                let sKey = 'dot-' + this.props.id + index;
                                return(
                                    <div key={sKey} className={(this.active === index) ? 'dot active' : 'dot'} data-index={item} onClick={() => this.gotoSlide(index, true)}></div>
                                )
                            })
                        }
                    </div>:
                    null
                }
            </div>
        )
    }

}
export default Carrousel;