import React from 'react';
import './pagination.styles.scss';
import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const Pagination = (props) => {

    const firstBtn = (props.totalItems === 0 || props.currentPage === 1);

    const previousBtn = (props.totalItems === 0 || props.currentPage === 1);

    const nextBtn = (props.totalItems === 0 || props.currentPage === props.totalPages);
    
    const lastBtn = (props.totalItems === 0 || props.currentPage === props.totalPages);

    const showPagination = (props.totalItems === 0)?'pagination hidden':'pagination';
    
    return (
      <div className={showPagination}>
        <div className="btn-first">
          <IconButton aria-label="Primeira Página" onClick={() => props.goToFunction(1)} disabled={firstBtn}>
            <SkipPreviousIcon />
          </IconButton>
        </div>
        <div className="btn-previous">
          <IconButton aria-label="Página Anterior" onClick={() => props.goToFunction(props.currentPage - 1)} disabled={previousBtn}>
            <NavigateBeforeIcon />
          </IconButton>
        </div>
        <div className="results hide-in-mobile">
          <span className="pages-desc">
            Página {props.currentPage} de {props.totalPages}
          </span>
          <span className="results-desc"><br />
            {props.totalItems === 0
              ? "Nenhum resultado encontrado"
              : props.totalItems === 1
              ? "Apenas um resultado encontrado"
              : props.totalItems + " resultados encontrados"}
          </span>
        </div>
        <div className="btn-next">
          <IconButton aria-label="Próxima Página" onClick={() => props.goToFunction(props.currentPage + 1)} disabled={nextBtn}>
            <NavigateNextIcon />
          </IconButton>
        </div>
        <div className="btn-last">
          <IconButton aria-label="Última Página" onClick={() => props.goToFunction(props.totalPages)} disabled={lastBtn}>
            <SkipNextIcon />
          </IconButton>
        </div>
      </div>
    );
}

export default Pagination;