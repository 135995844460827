import React from 'react';

import Fade from '@material-ui/core/Fade';
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";

import './modal-page.styles.scss';


const ModalPage = (props) => {
    
        const { isOpen, isLoaded } = props;

        return(
            <>
            {
                
                <Fade in={isOpen}>
                <div className="mdl-modal-page">
                    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
                        <div className="modal">
                            <div className="title-bar">
                                <div className="title">{props.title}</div>
                                <button className="close-button" onClick={() => props.close(false, props.closeCallback)}>
                                    <CloseIcon />
                                </button>
                            </div>
                            <div className="modal-body">
                                {props.children}
                            </div>
                        </div>
                    </Slide>
                </div>
                </Fade>
            }
            </>
            
        );
};
export default ModalPage;