import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import './users.styles.scss';

// Import Redux Action
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';

// Import Components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import Pagination from '../../components/pagination/pagination.component';
import Avatar from '../../components/avatar/avatar.component';

import {lockScrollbar} from '../../utils/utils';

 
class Users extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    
    this.state = {
      creating: false,
      loading: false,
      locking: 0,
      reseting: 0,
      submitting: false,
      deleting: 0,
      typeOptions: [
        {label: 'Todos', value: 0},
        {label: 'Membro', value: 1},
        {label: 'Assinante', value: 2},
        {label: 'Editor', value: 3},
        {label: 'Parceiro', value: 4},
        {label: 'Administrador', value: 5}
      ],
      userTypes: [
        {label: 'Membro', value: 1},
        {label: 'Assinante', value: 2},
        {label: 'Editor', value: 3},
        {label: 'Parceiro', value: 4},
        {label: 'Administrador', value: 5}
      ],
      statusOptions: [
        {label: 'Todos', value: 0},
        {label: 'Ativo', value: 1},
        {label: 'Inativo', value: 2},
      ],
      orderingOptions: [
        {label: 'Nome A-Z', value: 0},
        {label: 'Nome Z-A', value: 1},
        {label: 'Mais recentes', value: 2},
        {label: 'Mais antigos', value: 3},
      ],
      results: [],
      pagination: {
        page: 1,
        pages: 1,
        total: 0
      },
      formMode: 'search',
      formAction: null,
      editingUser: {
        LastAccess: '',
        avatar: '',
        birthdate: '',
        blocked: 0,
        created: '',
        email: '',
        fullName: '',
        id: 0,
        screenName: '',
        source: '',
        type: 1,
        url: '',
        verified: ''
      },
      formItems: {
        searchKey: {
          id: 'searchKey',
          label: 'Procurar por',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {}
        },
        searchType: {
          id: 'searchType',
          label: 'Tipo',
          placeholder: '',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {}
        },
        searchStatus: {
          id: 'searchStatus',
          label: 'Situação',
          placeholder: '',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        searchOrdering: {
          id: 'searchOrdering',
          label: 'Ordenar por',
          placeholder: '',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        userId: {
          id: 'userId',
          label: 'Id',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: []
        },
        userName: {
          id: 'userName',
          label: 'Nome Completo',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255
          }
        },
        userEmail: {
          id: 'userEmail',
          label: 'E-mail',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            email: true,
            maxLength: 255
          }
        },
        userBirthdate: {
          id: 'userBirthdate',
          label: 'Data de Nascimento',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            isData: true,
            isAdult: true
          }
        },
        userType: {
          id: 'userType',
          label: 'Tipo',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            haveToSelect: true
          }
        },
      }
    };
    }

    // Get users data from database
    getUsers = (page) => {

      this.setState({loading: true});

      const searchKey = this.state.formItems.searchKey.value;
      const searchType = this.state.formItems.searchType.value;
      const searchStatus = this.state.formItems.searchStatus.value;
      const searchOrdering = this.state.formItems.searchOrdering.value;
      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          page: page,
          key: searchKey,
          type: searchType,
          situation: searchStatus,
          ordering: searchOrdering
        }),
      };

      fetch("/api/users_list.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

          if(data.success){
          
          const pagination = this.state.pagination;
          pagination.total = data.totalItems;
          pagination.page= data.currentPage;
          pagination.pages = data.totalPages;
          const results = data.items;

          this.setState({ 
            loading: false,
            pagination: pagination,
            results: results,
          });

        } else {

          localStorage.setItem('openModal', 1);
          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }
          localStorage.setItem('modalObj', JSON.stringify(modalObj));
          let goTo = '/';
          window.location = goTo;
          
        }

      })
      .catch((error) => {
        console.dir(error);
      });

    }

    // Lock window scrolling
    scrollLock(){

      

    }

    // Component Did Mount
    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === 1){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.getUsers(1);
      
    }

  render(props){
  
  // FORMS: INPUT CHANGE HANDLE FUNCTION
  const handleChange = (event) => {

  // Get Name and Value from element
  const name = event.target.name;
  const value = event.target.value;

  // Get current FormItems object from State
  const formItems = this.state.formItems;

  // Get State of the Input
  const formItem = {
      ...formItems[name]
  };

  // Validate Element if it has been Touched Before
  if(formItem.hasTouched){
      if(formItem.rules){
          const validation = validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }
  }

  // Set the new value to element
  formItem.value = value;

  // Update formItems collection
  formItems[name] = formItem;


  // Update State
  this.setState({ 
      ...this.state, 
      formItems: formItems
  });

  }

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = (event) => {
          
  // Get Name and Value from element
  const name = event.target.name;
  const value = event.target.value;

  // formItems collection
  const formItems = {
      ...this.state.formItems
  };

  // state of modified element
  const formItem = {
      ...formItems[name]
  };

  formItem.hasTouched = true;
  if(formItem.rules){
      const validation = validate(value, formItem.rules, formItems);
      formItem.hasError = validation[0];
      formItem.errorMsg = validation[1];
  }else{
      formItem.hasError = false;
      formItem.errorMsg = '';
  }

  // Update formItems collection
  formItems[name] = formItem;

  // Update state
  this.setState({ 
      ...this.state, 
      formItems: formItems
  });

  }

  // FORMS: HANDLE SUBMIT
  const handleSubmit = () => {

    this.getUsers(1);

  }

  // Format Data
  const formatDate = (date, withHour) => {

    if(date){

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }
    return '-';

  }

  // Set Edit User
  const setEditMode = (userObj) => {

    const formItems = this.state.formItems;
    formItems.userId.value = userObj.id;
    formItems.userName.value = userObj.fullName;
    formItems.userEmail.value = userObj.email;
    formItems.userBirthdate.value = (userObj.birthdate) ? formatDate(userObj.birthdate, false) : '';
    formItems.userType.value= userObj.type;

    this.setState({
      ...this.state,
      formMode: 'edit',
      formItems: formItems,
      editingUser: userObj,
      formAction: userUpdate
    }, lockScrollbar);

  }

  // Set Search Mode
  const setSearchMode = () => {

    const formItems = this.state.formItems;
    formItems.userName.value = '';
    formItems.userName.hasError = false;
    formItems.userName.hasTouched = false;
    formItems.userEmail.value = '';
    formItems.userEmail.hasError = false;
    formItems.userEmail.hasTouched = false;
    formItems.userType.value = 0;
    formItems.userType.hasError = false;
    formItems.userType.hasTouched = false;
    formItems.userBirthdate.value = '';
    formItems.userBirthdate.hasError = false;
    formItems.userBirthdate.hasTouched = false;

    lockScrollbar('remove');

    this.setState({
      ...this.state,
      formMode: 'search',
      formItems: formItems,
      formAction: null
    });
  }

  // Set New User Mode
  const setNewMode = () => {
    const formItems = this.state.formItems;
    formItems.userName.value = '';
    formItems.userEmail.value = '';
    formItems.userBirthdate.value = '';
    formItems.userType.value= 1;

    const userObj = {
        LastAccess: '',
        avatar: '',
        birthdate: '',
        blocked: 0,
        created: '',
        email: '',
        fullName: '',
        id: 0,
        screenName: '',
        source: '',
        type: 1,
        url: '',
        verified: ''
    }

    this.setState({
      ...this.state,
      formMode: 'new',
      formItems: formItems,
      editingUser: userObj,
      formAction: userCreate
    }, lockScrollbar);
  }

  // Lock/Unlock User
  const lockUser = (e, userId) => {

    e.preventDefault();

    this.setState({
      ...this.state,
      locking: userId
    })

    const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          userId: userId
        }),
      };

      fetch("/api/users_lock.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
  
        if(!data.success){

          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          this.props.openModal(modalObj);

        }else{

          // Change status on results
          const results = this.state.results;
          for(let i = 0; i < results.length; i++){
              if(parseInt(results[i].id) === parseInt(data.id)){
                results[i].blocked = data.lock;
              }
          }

          let x = 'Usuário desbloqueado';
          if(parseInt(data.lock) === 1){
          x = 'Usuário bloqueado';
          }

          const modalObj = {
            modalIcon: 'done',
            modalType: 'success',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: x
            },
            modalActions: null
          }

          // Update State
          this.setState({
            ...this.state,
            locking: 0,
            results: results
          });

          this.props.openModal(modalObj);

        }

      })
      .catch((error) => {
        console.dir(error);
      });

  }

  // Reset Password
  const resetPassword = (e, userId) => {

    e.preventDefault();

    this.setState({
      ...this.state,
      reseting: userId
    })

    const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          userId: userId
        }),
      };

      fetch("/api/users_password.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
  
        if(!data.success){

          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          this.props.openModal(modalObj);

        }else{

          const modalObj = {
            modalIcon: 'done',
            modalType: 'success',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          // Update State
          this.setState({
            ...this.state,
            reseting: 0
          });

          this.props.openModal(modalObj);

        }

      })
      .catch((error) => {
        console.dir(error);
      });

  }

  // Create new user
  const userCreate = () => {

    this.setState({
      ...this.state,
      submitting: true
    })

    const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          userName: this.state.formItems.userName.value,
          userEmail: this.state.formItems.userEmail.value,
          userType: this.state.formItems.userType.value,
          userBirthdate: this.state.formItems.userBirthdate.value
        }),
      };

      fetch("/api/users_create.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
  
        if(!data.success){

          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          this.props.openModal(modalObj);

        }else{

          const modalObj = {
            modalIcon: 'done',
            modalType: 'success',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          const formItems = this.state.formItems;
          formItems.userName.value = '';
          formItems.userName.hasError = false;
          formItems.userName.hasTouched = false;
          formItems.userEmail.value = '';
          formItems.userEmail.hasError = false;
          formItems.userEmail.hasTouched = false;
          formItems.userType.value = 0;
          formItems.userType.hasError = false;
          formItems.userType.hasTouched = false;
          formItems.userBirthdate.value = '';
          formItems.userBirthdate.hasError = false;
          formItems.userBirthdate.hasTouched = false;


          // Update State
          this.setState({
            ...this.state,
            submitting: false,
            formItems: formItems,
            formMode: 'search'
          }, this.getUsers(1));

          this.props.openModal(modalObj);

        }

      })
      .catch((error) => {
        console.dir(error);
      });

  }

  // Update User
  const userUpdate = () => {

    this.setState({
      ...this.state,
      submitting: true
    })

    const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          userId: this.state.formItems.userId.value,
          userName: this.state.formItems.userName.value,
          userEmail: this.state.formItems.userEmail.value,
          userType: this.state.formItems.userType.value,
          userBirthdate: this.state.formItems.userBirthdate.value
        }),
      };

      fetch("/api/users_update.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
  
        if(!data.success){

          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }
          this.props.openModal(modalObj);

        }else{

          const modalObj = {
            modalIcon: 'done',
            modalType: 'success',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          const formItems = this.state.formItems;
          formItems.userId.value = 0;
          formItems.userId.hasError = false;
          formItems.userId.hasTouched = false;
          formItems.userName.value = '';
          formItems.userName.hasError = false;
          formItems.userName.hasTouched = false;
          formItems.userEmail.value = '';
          formItems.userEmail.hasError = false;
          formItems.userEmail.hasTouched = false;
          formItems.userType.value = 0;
          formItems.userType.hasError = false;
          formItems.userType.hasTouched = false;
          formItems.userBirthdate.value = '';
          formItems.userBirthdate.hasError = false;
          formItems.userBirthdate.hasTouched = false;


          // Update State
          this.setState({
            ...this.state,
            submitting: false,
            formItems: formItems,
            formMode: 'search'
          }, this.getUsers(1));

          this.props.openModal(modalObj);

        }

      })
      .catch((error) => {
        console.dir(error);
      });

  }

  // Ask confirmation to delete
  const confirmDelete = (userObj) => {
      
    const modalObj = {
      modalIcon: 'clear',
      modalType: 'error',
      modalComponent: 'CONFIRMATION',
      modalTitle: 'Excluir Usuário',
      modalParameters: {
          text: 'Se continuar,  ' + userObj.screenName + ' deixará de ter acesso ao site e todos os seus registros serão excluídos permanentemente do banco de dados, isso inclui comentários, agendamentos e avaliações. Essa operação é irreversível.<br/><br/><b>Tem certeza que quer prosseguir?</b>'
      },
      modalActions: {
          action: userDelete,
          parameters: userObj.id
      }
      }

      this.props.openModal(modalObj);

  }

  // Delete User
  const userDelete = (userId) => {

    this.setState({
      ...this.state,
      deleting: userId
    });

    const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          userId: userId
        }),
      };

      fetch("/api/users_delete.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
  
        if(!data.success){

          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          this.props.openModal(modalObj);

        }else{

          const modalObj = {
            modalIcon: 'done',
            modalType: 'success',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }

          // Update State
          this.setState({
            ...this.state,
            deleting: 0,
            formMode: 'search'
          }, this.getUsers(1));

          this.props.openModal(modalObj);
        }

      })
      .catch((error) => {
        console.dir(error);
      });

  }

  // Submit Disabled
  const submitDisabled = () => {

    let disabled = false;
    const formItems = this.state.formItems;
    let name = formItems.userName;
    let email = formItems.userEmail;
    let type = formItems.userType;

    if(name.value === '' || name.hasError){
      disabled = true;
    }

    if(email.value === '' || email.hasError){
      disabled = true;
    }

    if(parseInt(type.value) === 0 || type.hasError){
      disabled = true;
    }

    return disabled;

  }


  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content users-page">
        
          {/* HEADING */}
          <HeadingViewMode type={1} title="Usuários" />

          { /* TOOLBAR */ }
          <div className="container toolbar right">
          <Button color="primary" variant="contained" onClick={setNewMode} endIcon={(this.state.creating)? <CircularProgress size={24}/> : <AddIcon />} disabled={this.state.creating}>Novo Usuário</Button>
          </div>

          {/* SEARCH FORM */}
          <div className="container">
            <form className="form-content-search" onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <Grid item xs>
                  <TextField
                      name="searchKey"
                      id={this.state.formItems.searchKey.id}
                      value={this.state.formItems.searchKey.value}
                      label={this.state.formItems.searchKey.label}
                      placeholder={this.state.formItems.searchKey.placeholder}
                      error={this.state.formItems.searchKey.hasError}
                      helperText={this.state.formItems.searchKey.errorMsg}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.searchType.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.searchType.id}>{this.state.formItems.searchType.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.searchType.id}
                  id={this.state.formItems.searchType.id}
                  value={this.state.formItems.searchType.value}
                  label={this.state.formItems.searchType.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >
                  {this.state.typeOptions.map((item) => {
                    let itemKey = 'item-' + item.value;
                    return(<option key={itemKey} value={item.value}>{item.label}</option>);
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.searchType.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.searchStatus.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.searchStatus.id}>{this.state.formItems.searchStatus.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.searchStatus.id}
                  id={this.state.formItems.searchStatus.id}
                  value={this.state.formItems.searchStatus.value}
                  label={this.state.formItems.searchStatus.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >
                  {this.state.statusOptions.map((item) => {
                    let itemKey = 'sit-' + item.value;
                    return(<option key={itemKey} value={item.value}>{item.label}</option>);
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.searchStatus.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.searchOrdering.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.searchOrdering.id} >{this.state.formItems.searchOrdering.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.searchOrdering.id}
                  id={this.state.formItems.searchOrdering.id}
                  value={this.state.formItems.searchOrdering.value}
                  label={this.state.formItems.searchOrdering.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >

                  {this.state.orderingOptions.map((item, index) => {
                  return (
                  <option
                  key={"ordering-" + index}
                  value={item.value}
                  >
                  {item.label}
                  </option>
                  );
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.searchOrdering.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={1}>
                <Button color="primary" variant="outlined" onClick={handleSubmit} fullWidth >Filtrar</Button>
              </Grid>

            </Grid>
            </form>
          </div>

          { /* RESULTS */ }
          <div className='container results'>
                      
                      {/* LOADING */}
                      <div className={(this.state.loading)?'results-loading':'results-loading hidden'}>
                        <CircularProgress size={48} />
                      </div>
                      
                      {/* LIST OF RESULTS */}
                      <div className={(this.state.results.length > 0) ? 'results-list' : 'results-list hidden'}>
                          <table>
                              <thead>
                                <tr>
                                  <th className="cell-xs center"></th>
                                  <th className="cell-sm center hide-in-mobile"></th>
                                  <th className="cell-sm center hide-in-mobile">Id</th>
                                  <th className="left">Usuário</th>
                                  <th className="cell-lg left hide-in-mobile">Origem</th>
                                  <th className="cell-lg left hide-in-mobile">Tipo</th>
                                  <th className="cell-md center hide-in-mobile">últ. Acesso</th>
                                  <th className="cell-xs center"></th>
                                  <th className="cell-xs center hide-in-mobile"></th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  this.state.results.map((item, index) => {

                                    let keyTable = 'table-row-' + index;
                                    let avatarBorder = 'primary';
                                    let avatarLabel = 'Ativo';

                                    if(!item.verified){
                                      avatarBorder = 'attention';
                                      avatarLabel = 'E-mail não verificado';
                                    }

                                    if(item.blocked === 1){
                                      avatarBorder = 'alert';
                                      avatarLabel = 'Bloqueado';
                                    }

                                    let createdInfo = item.source + "<br><span class='email'>em " + formatDate(item.created, false) + "</span>";
                                    return(
                                      <tr key={keyTable} >
                                          <td className="cell-xs center">
                                            <Tooltip arrow title={avatarLabel}>
                                              {(this.state.locking === item.id) ? <CircularProgress size={24} /> : <div className={'badge ' + avatarBorder} onClick={(e) => lockUser(e, item.id)}></div>}
                                            </Tooltip>
                                          </td>
                                          <td className="cell-sm center hide-in-mobile" ><Avatar source={item.avatar} size="small" color={avatarBorder}/></td>
                                          <td className="cell-sm center hide-in-mobile">{item.id}</td>
                                          <td className="left padding-sm" onClick={() => setEditMode(item)}>{item.fullName}<br /><span className='email'>{item.email}</span></td>
                                          <td className="cell-lg left hide-in-mobile" dangerouslySetInnerHTML={{__html: createdInfo}}></td>
                                          <td className="cell-lg left hide-in-mobile">{this.state.typeOptions[item.type].label}</td>
                                          <td className="cell-md center hide-in-mobile">{formatDate(item.LastAccess, false)}</td>
                                          <td className="cell-xs center">
                                            {
                                              (this.state.reseting === item.id) ?
                                              <CircularProgress size={24} /> :
                                            <Tooltip title="Reset de Senha" arrow>
                                              <span>
                                                <IconButton color="default" onClick={(e) => resetPassword(e, item.id)}><VpnKeyIcon /></IconButton>
                                              </span>
                                            </Tooltip>
                                            }
                                          </td>
                                          <td className="cell-xs center hide-in-mobile">
                                            <Tooltip title="Excluir Usuário" arrow>
                                              <span>
                                                <IconButton 
                                                color="default"
                                                onClick={() => confirmDelete(item)}>
                                                  {(this.state.deleting === item.id) ? <CircularProgress size={24} /> : <DeleteIcon />}
                                                </IconButton>
                                              </span>
                                            </Tooltip>
                                          </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>

                          </table>
                          <Pagination 
                          currentPage={this.state.pagination.page} 
                          totalPages={this.state.pagination.pages} 
                          totalItems={this.state.pagination.total}
                          goToFunction={this.getUsers}
                          />
                      </div>
                      
                      {/* NO RESULTS */}
                      <div className={(this.state.results.length === 0) ? 'no-results' : 'no-results hidden'}>
                          <HighlightOffIcon size={48} />
                          <p>
                            <b>Nenhum resultado encontrado</b>
                            <br/>Verifique os filtros e tente novamente.
                          </p>
                      </div>

          </div>

          { /* USER EDIT FORM */ }
          <div className={(this.state.formMode === 'search') ? 'user-edit hidden' : 'user-edit'}>
              <div className="edit-form">
                <div className={(this.state.submitting) ? 'loading' : 'loading hidden'}>
                  <CircularProgress size={48} />
                </div>
                <Grid container spacing={1}>

                  { /* USER AVATAR */ }
                  <Grid item xs={12} className="margin-bottom--sm">
                    <Avatar source={this.state.editingUser.avatar} size="medium" />
                  </Grid>
                  
                  { /* FORM TITLE */ }
                  <Grid item xs={12} className="margin-bottom--sm">
                    <HeadingViewMode type={2} title={(this.state.formMode === 'edit') ? 'Editar Usuário' : 'Criar Usuário'} />
                  </Grid>

                  { /* USER NAME */ }
                  <Grid item xs={12} className="margin-bottom--sm">
                    <TextField
                    name={this.state.formItems.userName.id}
                    id={this.state.formItems.userName.id}
                    value={this.state.formItems.userName.value}
                    label={this.state.formItems.userName.label}
                    placeholder={this.state.formItems.userName.placeholder}
                    error={this.state.formItems.userName.hasError}
                    helperText={this.state.formItems.userName.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    />
                  </Grid>
                  
                  { /* USER EMAIL */ }
                  <Grid item xs={12} className="margin-bottom--sm">
                    <TextField
                    name={this.state.formItems.userEmail.id}
                    id={this.state.formItems.userEmail.id}
                    value={this.state.formItems.userEmail.value}
                    label={this.state.formItems.userEmail.label}
                    placeholder={this.state.formItems.userEmail.placeholder}
                    error={this.state.formItems.userEmail.hasError}
                    helperText={this.state.formItems.userEmail.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    />
                  </Grid>
                  
                  { /* USER BIRTHDATE */ }
                  <Grid item xs={12} sm={6} className="margin-bottom--sm">
                    <TextField
                    name={this.state.formItems.userBirthdate.id}
                    id={this.state.formItems.userBirthdate.id}
                    value={this.state.formItems.userBirthdate.value}
                    label={this.state.formItems.userBirthdate.label}
                    placeholder={this.state.formItems.userBirthdate.placeholder}
                    error={this.state.formItems.userBirthdate.hasError}
                    helperText={this.state.formItems.userBirthdate.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    />
                  </Grid>
                  
                  { /* USER TYPE */ }
                  <Grid item xs={12} sm={6} className="margin-bottom--sm">
                    <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={this.state.formItems.userType.hasError}
                    >
                      <InputLabel htmlFor={this.state.formItems.userType.id}>{this.state.formItems.userType.label}</InputLabel>
                      <Select
                      native
                      fullWidth
                      name={this.state.formItems.userType.id}
                      id={this.state.formItems.userType.id}
                      value={this.state.formItems.userType.value}
                      label={this.state.formItems.userType.label}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      >
                      <option key="user-type-0" value="0">Selecione</option>
                      {
                        this.state.userTypes.map((item) => {
                          let typeKey = 'user-type-' + item.value;
                          return(
                          <option key={typeKey} value={item.value}>{item.label}</option>
                          );
                        })
                      }
                      </Select>
                      <FormHelperText>
                      {this.state.formItems.userType.errorMsg}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  
                  { /* BUTTONS */ }
                  <Grid item xs={12}>
                      <div className="btn-wrapper side-by-side inverted-in-mobile on-right">

                        <Button color="default" variant="outlined" disableElevation onClick={setSearchMode}>Cancelar</Button>
                        <Button 
                        color="primary" 
                        variant="contained" 
                        disabled={submitDisabled()}
                        onClick={this.state.formAction}
                        disableElevation
                        >
                        {(this.state.formMode === 'edit')? 'Atualizar' : 'Registrar'}
                        </Button>
                        
                      </div>
                  </Grid>

                </Grid>
              </div>
          </div>   
      
      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(Users));