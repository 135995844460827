import React from 'react';

const LinkedinIcon = (props) => {
    const viewBoxWidth = 64;
    const viewBoxHeight = 64;
    const viewBox = '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight;
    const width = (props.width) ? props.width : viewBoxWidth;
    const height = (props.height) ? props.height : viewBoxHeight;

    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox={viewBox}
        className={(props.className) ? `svg-icon ${props.className}` : 'svg-icon'}
        width = {width + 'px'}
        height ={height + 'px'}
        >
            <path 
                d="M17.16 59.15H6v-36h11.16zm-5.58-40.87A6.5 6.5 0 1 1 18 11.74h0a6.52 6.52 0 0 1-6.42 6.54zM59 59.15H47.81V41.63c0-4.17-.09-9.51-5.81-9.51s-6.69 4.53-6.69 9.22v17.81H24.16v-36h10.7v4.91H35a11.72 11.72 0 0 1 10.57-5.81c11.3 0 13.37 7.44 13.37 17.1v19.8z"
            />
        </svg>
    );

}
export default LinkedinIcon;