//Define selected item on menu
export const getModules = modules => ({
    type: 'GET_MODULES',
    payload: modules
})

export const openSelector = (type, position) => ({
    type: 'OPEN_SELECTOR',
    payload: {
        type: type,
        position: position
    }
})

export const closeSelector = () => ({
    type: 'CLOSE_SELECTOR'
})

export const toggleLoader = () => ({
    type: 'TOGGLE_LOADER'
})