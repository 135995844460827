import React from 'react';
import './banner-image.styles.scss';

import Headings from '../headings/heading.view.component';
import Button from "@material-ui/core/Button";


const BannerImageViewMode = (props) =>{

    const background = (props.src)? 'url("' + window.$imagesPath + props.src + '")' : 'none';
    const classes = (props.parallax === 1) ? 'module-banner-image parallax' : 'module-banner-image';
    const mask = (parseInt(props.mask) === 1) ? 'banner-content mask' : 'banner-content';

    
    const renderIcon = () => {
        if(props.icon){
            let src = window.$imagesPath + props.icon;
            return(<img className='banner-icon' src={src} alt='banner icon' />);
        }
        return;
    }

    const renderTitle = () => {

        let dark = true;
        if(props.mask === 0){
            dark = false;
        }

        if(props.title){
            return(<Headings type={3} title={props.title} darkmode={dark} />);
        }

        return;

    }

    const renderDesc = () => {

        if(props.desc){
            let desc = (props.desc) ? props.desc.replace(/\r?\n|\r/g, "<br>") : '';
            return (<div className='banner-text' dangerouslySetInnerHTML={{__html: desc}}></div>);
        }
        return;
    }

    const goto = link => {
        window.location =link;
    }

    return(
    <div className={classes} style={{backgroundImage: background}}>
        <div className={mask}>
            {renderIcon()}
            {renderTitle()}
            {renderDesc()}
            {
                (props?.ctaLabel && props?.ctaLink) ?
                <div className='banner-buttons btn-wrapper centered'>
                    <Button 
                    color="primary" 
                    variant="contained" 
                    disableElevation 
                    onClick={(e) => goto(props.ctaLink)} 
                    >{props.ctaLabel}</Button>
                </div>:
                null
            }
        </div>
    </div>
    );

}

export default BannerImageViewMode;