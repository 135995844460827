import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import validate from "../../utils/form-validation/form-validation.utils";
import {getToken, authAction} from "../../utils/user-validation/user-validation.utils";

class SetLink extends Component{

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            saving: false,
            hasError: false,
            errorMessage: '',
            formItems: {
                link: {
                    id: "link",
                    label: "Link",
                    placeholder: "Cole aqui o link para a video conferência",
                    value: (props.slot.url) ? props.slot.url : '',
                    hasTouched: true,
                    hasError: false,
                    errorMsg: "",
                    rules: {
                      required: true,
                      website: true,
                      maxLength: 255
                    },
                },
                send: {
                    id: "send",
                    label: "Enviar link ao cliente por e-mail",
                    placeholder: "",
                    value: true,
                    hasTouched: true,
                    hasError: false,
                    errorMsg: "",
                    rules: [],
                },
            }
        }
    }

  // Handle Inputs Change
  handleChange = (event, callbacks) => {
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // Get current FormItems object from State
    const formItems = {
      ...this.state.formItems,
    };

    // Get State of the Input
    const formItem = {
      ...formItems[name],
    };

    // Validate Element if it has been Touched Before
    if (formItem.hasTouched) {
      if (formItem.rules) {
        const validation = validate(value, formItem.rules, formItems);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
      } else {
        formItem.hasError = false;
        formItem.errorMsg = "";
      }
    }

    // Set the new value to element
    formItem.value = value;

    // Update formItems collection
    formItems[name] = formItem;

    // Update State
    this.setState({
      ...this.state,
      formItems: formItems
    });

    if (callbacks) {
      callbacks.map((item) => {
        return item(event);
      });
    }
  };

  // Handle Inputs Blur
  handleBlur = (event) => {
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // formItems collection
    const formItems = {
      ...this.state.formItems,
    };

    // state of modified element
    const formItem = {
      ...formItems[name],
    };

    formItem.hasTouched = true;
    if (formItem.rules) {
      const validation = validate(value, formItem.rules, formItems);
      formItem.hasError = validation[0];
      formItem.errorMsg = validation[1];
    } else {
      formItem.hasError = false;
      formItem.errorMsg = "";
    }

    // Update formItems collection
    formItems[name] = formItem;

    // Update state
    this.setState({
      ...this.state,
      formItems: formItems,
    });
  };

  handleCheckbox = (event) => {

    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.checked;

    // formItems collection
    const formItems = {
    ...this.state.formItems,
    };

    // state of modified element
    const formItem = {
    ...formItems[name],
    };

    formItem.value = value;

     // Update formItems collection
     formItems[name] = formItem;

     // Update State
    this.setState({
        ...this.state,
        formItems: formItems
    });

  }

  handleSubmit = () => {
    
    // Validate formItems
    const {formItems} = this.state;
    let hasError = false;
    let updatedFormItems = formItems;

    for (let key in updatedFormItems) {

        let obj = updatedFormItems[key];
        
        if (obj.rules) {
          let validation = validate(obj.value, obj.rules, formItems);
          if (validation[0]) {
            hasError = true;
          }
          obj.hasError = validation[0];
          obj.errorMsg = validation[1];
        } else {
          obj.hasError = false;
          obj.errorMsg = "";
        } 
        
    }

    if(hasError){
        this.setState({
            formItems: updatedFormItems,
            hasError: true,
            errorMessage: 'O formulário possui um ou mais erros'
        });
    }else{
        this.setState({
            saving: true, 
            hasError: false, 
            errorMessage: ''
        }, this.setLink);
    }
  
}

setLink = () => {

    const { formItems } = this.state;
    const userToken = getToken();
    const formData = {
        userToken: userToken,
        app: window.$appToken,
        slot: this.props.slot.id,
        link: formItems.link.value,
        send: formItems.send.value
    }

    const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
        },
        body: JSON.stringify(formData)
    };

    fetch("/api/slots_set_link.php", requestOptions)
    .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        authAction(data.authenticated);

        if(data.success){

            this.setState({
                saving: false,
                hasError: false,
                errorMessage: ''
            },this.props.update);

        }else{
            
            this.setState({
                saving: false,
                hasError: true, 
                errorMessage: data.feedback
            });
        }

    });

}


    render(){
        return(
            <div className="set-conference-link">
                <div className="title">
                  <span className="text">Link para Video Conferência</span>
                </div>

                <div className={(this.state.hasError) ? 'form-error-alert' : 'form-error-alert hidden'}>
                    {this.state.errorMessage}
                </div>

                <p className="aditional-info">Na Abriccó incentivamos o uso de plataformas de video conferência para a realização dos atendimentos. Escolha entre o <a href="https://zoom.us/" target="_blank" rel="noopener noreferrer">Zoom</a>, o <a href="https://meet.google.com/" target="_blank" rel="noopener noreferrer">Google Meet</a> ou outra plataforma de sua preferência e crie uma reunião na data e hora deste agendamento, em seguida cole aqui o link disponibilizado para compartilhar com o cliente.</p>

                <Grid container spacing={2}>    
                    <Grid item xs={12}>
                        <TextField
                            id={this.state.formItems.link.id}
                            name={this.state.formItems.link.id}
                            label={this.state.formItems.link.label}
                            variant="outlined"
                            value={this.state.formItems.link.value}
                            placeholder={this.state.formItems.link.placeholder}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            error={this.state.formItems.link.hasError}
                            helperText={this.state.formItems.link.errorMsg}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <FormControlLabel
                            control={
                            <Checkbox
                                id={this.state.formItems.send.id}
                                name={this.state.formItems.send.id}
                                checked={this.state.formItems.send.value}
                                onChange={this.handleCheckbox}
                                color="primary"
                            />
                            }
                            label={this.state.formItems.send.label}
                        />
                    </Grid>
                </Grid>
                
                <div className="btn-wrapper side-by-side on-right">
                    <Button 
                        color="default" 
                        variant="outlined" 
                        disableElevation 
                        disabled={(this.state.loading || this.state.saving)}
                        onClick={() => this.props.toggleView('view')}
                    >
                        Voltar
                    </Button>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        disabled={(this.state.loading || this.state.saving)}
                        onClick={this.handleSubmit}
                    >
                        {(this.state.saving) ? 'Salvando...' : 'Definir'}
                    </Button>
                </div>

            </div>
        )
    }
}
export default SetLink;