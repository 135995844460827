import {combineReducers} from 'redux';
import menusReducer from './menus/menus.reducer';
import activeUserReducer from './active-user/active-user.reducer';
import modalReducer from './modal/modal.reducer';
import searchReducer from './search/search.reducer';
import pagesDirectoryReducer from './page-directory/page-directory.reducer';
import mediaGalleryReducer from './media-gallery/media-gallery.reducer';
import contentDirectoryReducer from './content-directory/content-directory.reducer';
import authReducer from './auth/auth.reducer';
import ModuleSelectorReducer from './modules-selector/modules-selector.reducer';
import main from './main/main.reducer';
import categoryManagerReducer from './category-manager/category-manager.reducer';

const Reducers = combineReducers({
    menus: menusReducer,
    activeUser: activeUserReducer,
    modal: modalReducer,
    pagesDirectory: pagesDirectoryReducer,
    search: searchReducer,
    mediaGallery: mediaGalleryReducer,
    categoryManager: categoryManagerReducer,
    contentDirectory: contentDirectoryReducer,
    moduleSelector: ModuleSelectorReducer,
    auth: authReducer,
    main: main
});

export default Reducers;