//Import Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';

//Import UI components
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

import './login.styles.scss';

class Login extends Component {
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
                formError: false,
                formErrorMsg: '',
                loaderActive: false,
                formItems: {
                    user: {
                        id: 'user',
                        label: 'Usuário',
                        value: '',
                        hasTouched: false,
                        hasError: false,
                        errorMsg: '',
                        rules: {
                            required: true,
                            email: true
                        }
                    },
                    password: {
                        id: 'password',
                        label: 'Senha',
                        value: '',
                        hasTouched: false,
                        hasError: false,
                        errorMsg: '',
                        rules: {
                            required: true
                        }
                    },
                    userName: {
                        id: 'userName',
                        label: 'Nome',
                        value: '',
                        hasTouched: false,
                        hasError: false,
                        errorMsg: '',
                        rules: {
                            required: true
                        }
                    },
                    userEmail: {
                        id: 'userEmail',
                        label: 'E-mail',
                        value: '',
                        hasTouched: false,
                        hasError: false,
                        errorMsg: '',
                        rules: {
                            required: true,
                            email: true
                        }
                    },
                    terms: {
                        id: 'terms',
                        label: 'Termos de Uso',
                        value: false,
                        hasTouched: false,
                        hasError: false,
                        errorMsg: '',
                        rules: {
                            terms: true
                        }
                    },
                },
                showPassword: false,
                btnLoading: false,
                registerLoading: false,
                btnSuccess: false,
        }

    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        
        if(localStorage.getItem('abriccoAuth')){

            const authObj = JSON.parse(localStorage.getItem('abriccoAuth'));
            const clearFeedback = () => {
                
                const toggleAuthObj = JSON.parse(localStorage.getItem('abriccoAuth'));
                toggleAuthObj.error = false;
                localStorage.setItem('abriccoAuth', JSON.stringify(toggleAuthObj));
            }

                if(authObj.error){

                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: authObj.title,
                        modalParameters: {
                            text: authObj.feedback
                        },
                        modalActions: {
                            action: clearFeedback,
                        }
                    }

                    this.props.openModal(modalObj);

                }

        }
        
    }

    //Rendering
    render(props) {

        // CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = {
                ...this.state.formItems
            };

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;

            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        //  BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // CHANGE SWITCHERS FUNCTION
        const handleSwitchers = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.checked;

            console.dir(value);
            

            // Get current FormItems object from State
            const formItems = this.state.formItems;
           

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
            

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;

            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // SHOW OR HIDE PASSWORD FUNCTION 
        const handleClickShowPassword = (e) => {
            e.preventDefault();
            let showPass = !this.state.showPassword;
            this.setState({...this.state, showPassword: showPass});
        }

        // CLICK PASSWORD PREVENT FUNCTION
        const handleMouseDownPassword = (event) => {
            event.preventDefault();
        };

        // LOGIN SUBMIT FUNCTION
        const handleLoginSubmit = (e) => {
            
            e.preventDefault();
            this.setState({...this.state, btnLoading: true});

            const requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: { 
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*"
                },
                body: JSON.stringify({
                    userEmail: this.state.formItems.user.value,
                    userPassword: this.state.formItems.password.value,
                    app: window.$appToken
                })
            };

            fetch("/api/auth_user.php", requestOptions)
            .then(async response => {
                const data = await response.json();
    
                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                // if user doesnt exist 
                if(!data.success){   

                    this.setState({
                        ...this.state, 
                        btnLoading: false,
                    });
    
                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Ops, algo correu mal :(',
                        modalParameters: {
                            text: data.feedback
                        },
                        modalActions: []
                      }
    
                      this.props.openModal(modalObj);


                // if user has been found
                }else{

                    this.setState({
                        ...this.state, 
                        btnLoading: false,
                    });

                    const userObj = data.obj;
                    localStorage.setItem('abricco', JSON.stringify(userObj));


                    let from = '/dashboard';
                    if(localStorage.getItem('abriccoAuth')){
                        const authObj = JSON.parse(localStorage.getItem('abriccoAuth'));
                        if(authObj.currentPage){
                        from = authObj.currentPage.pathname;
                        }
                    }
                    
                    //this.props.history.push(from);
                    window.location = from;

                }

            })
            .catch(error => {

                this.setState({
                    ...this.state, 
                    btnLoading: false,
                });

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        Text: 'Não foi possível aceder aos serviços de login no momento. Tente novamente mais tarde ou contate o suporte.'
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);
            });

        }

        // REGISTER SUBMIT FUNCTION
        const handleRegisterSubmit = (e) => {

            e.preventDefault();
            this.setState({...this.state, registerLoading: true});

            const requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: { 
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*"
                },
                body: JSON.stringify({
                    userName: this.state.formItems.userName.value,
                    userEmail: this.state.formItems.userEmail.value,
                    source: 'login',
                    app: window.$appToken
                })
            };

            fetch("/api/user_create.php", requestOptions)
            .then(async response => {
                const data = await response.json();
    
                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                // if user doesnt exist 
                if(!data.success){   

                    this.setState({
                        ...this.state, 
                        registerLoading: false,
                    });
    
                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Ops, algo correu mal :(',
                        modalParameters: {
                            text: data.feedback
                        },
                        modalActions: []
                      }
    
                      this.props.openModal(modalObj);


                // if user has been found
                }else{

                    const modalObj = {
                        modalIcon: 'done',
                        modalType: 'success',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Bem-vindo :)',
                        modalParameters: {
                            text: 'Bem-vindo à Abriccó. Enviamos um e-mail para concluir seu registro. Verifique sua caixa de e-mail e até logo.'
                        },
                        modalActions: []
                      }
                      this.props.openModal(modalObj);

                      const formItems = this.state.formItems;
                      formItems.userName.value = '';
                      formItems.userEmail.value = '';
                      formItems.terms.value = false;

                      this.setState({
                        ...this.state, 
                        formItems: formItems,
                        registerLoading: false,
                    });

                }

            })
            .catch(error => {

                this.setState({
                    ...this.state, 
                    registerLoading: false,
                });

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        Text: 'Não foi possível aceder aos serviços de login no momento. Tente novamente mais tarde ou contate o suporte.'
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);
            });

        }

        // ENABLE OR DISABLE SUBMIT FUNCTION
        const loginDisabled = !this.state.formItems.user.value.length || !this.state.formItems.password.value.length || this.state.btnLoading;
        const registerDisabled = !this.state.formItems.userName.value.length || !this.state.formItems.userEmail.value.length || this.state.formItems.userEmail.hasError || !this.state.formItems.terms.value || this.state.registerLoading;


        return (
            <div className="page">
                <div className="content login-page">
                    <div className="container compact top-justified">
                        
                        {/* FORM LOGIN */}
                        <div className="half-panel login-panel">
                            <form>
                                <div className="heading"><HeadingViewMode type={2} title="Entre" /></div>
                                <p className="center">Para participar e ter acesso a conteúdo exclusivo, você precisa se identificar.</p>
                                <div className="panel-body">
                                    <div className="ipt-wrapper">
                                        <TextField 
                                            autoFocus 
                                            fullWidth
                                            variant="outlined"
                                            name= {this.state.formItems.user.id} 
                                            label={this.state.formItems.user.label} 
                                            value={this.state.formItems.user.value} 
                                            error={this.state.formItems.user.hasError}
                                            helperText={this.state.formItems.user.errorMsg} 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                                
                                        />
                                    </div>
                                    <div className="ipt-wrapper">
                                        <TextField
                                        fullWidth
                                        variant="outlined" 
                                        name= {this.state.formItems.password.id} 
                                        label={this.state.formItems.password.label} 
                                        value={this.state.formItems.password.value} 
                                        error={this.state.formItems.password.hasError}
                                        type={(this.state.showPassword)?'text':'password'}
                                        helperText={this.state.formItems.password.errorMsg} 
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                            </InputAdornment>,
                                        }}
                                        />
                                    
                                    
                                    </div>
                                    <div className="ipt-wrapper"><a href="/esqueci-a-senha">Esqueceu sua senha?</a></div>
                                </div>
                                <div className="btn-wrapper">
                                    <Button type="submit" onClick={handleLoginSubmit} color="primary" variant="contained" disableElevation fullWidth disabled={loginDisabled}>Login</Button>
                                    {this.state.btnLoading && <LinearProgress variant="query" className="btn-loading" />}
                                    
                                </div>
                            </form>
                        </div>
                        
                        <div className="or-label">Ou</div>
                        
                        {/* FORM REGISTRO */}
                        <div className="half-panel register-panel">
                        <div className="heading"><HeadingViewMode type={2} title="Registre-se" /></div>
                        <div className="panel-body">
                        <p className="center">Faça parte da nossa comunidade, participe e fique por dentro das novidades.</p>
                        <div className="ipt-wrapper">
                            <TextField 
                                fullWidth
                                variant="outlined"
                                name= {this.state.formItems.userName.id} 
                                label={this.state.formItems.userName.label} 
                                value={this.state.formItems.userName.value} 
                                error={this.state.formItems.userName.hasError}
                                helperText={this.state.formItems.userName.errorMsg} 
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className="ipt-wrapper">
                            <TextField 
                                fullWidth
                                variant="outlined"
                                name= {this.state.formItems.userEmail.id} 
                                label={this.state.formItems.userEmail.label} 
                                value={this.state.formItems.userEmail.value} 
                                error={this.state.formItems.userEmail.hasError}
                                helperText={this.state.formItems.userEmail.errorMsg} 
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className={(this.state.formItems.terms.hasError) ? 'terms-agreement has-error' : 'terms-agreement'}>
                            <Switch checked={this.state.formItems.terms.value} onChange={handleSwitchers} name={this.state.formItems.terms.id} color="primary" />
                            <div className="text">Aceito receber novidades da Abriccó por e-mail e estou de acordo com os <a href="/termos-de-uso" target="_blank">termos de uso</a></div>
                        </div>
                                
                        </div>
                        <div className="btn-wrapper">
                            <Button color="primary" variant="outlined" fullWidth disabled={registerDisabled} onClick={handleRegisterSubmit}>Registrar</Button>
                            {this.state.registerLoading && <LinearProgress variant="query" className="btn-loading" />}
                        </div>
                    </div>

                        
                    </div>
                </div>
            </div>
        );

    }

}
const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(Login));