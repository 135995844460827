import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
//import { useJsonToCsv } from 'react-json-csv';

import './leads.styles.scss';

// Import Redux Action
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';


// Import Components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import Pagination from '../../components/pagination/pagination.component';

 
class Leads extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    
    this.state = {
      downloading: false,
      fileIsReady: false,
      downloadFile: {
        fields: {
          'id': 'Cod',
          'name': 'Nome',
          'email': 'Email',
          'created': 'Criado em',
          'updated': 'Atualizado em',
          'sources': 'Origem',
        },
        style: null,
        data: [],
        filename: 'leads.csv',
        text: 'Leads'
      },
      loading: false,
      orderingOptions: [
        {label: 'Nome A-Z', value: 0},
        {label: 'Nome Z-A', value: 1},
        {label: 'Mais recentes', value: 2},
        {label: 'Mais antigos', value: 3},
      ],
      results: [],
      pagination: {
        page: 1,
        pages: 1,
        total: 0
      },
      formItems: {
        searchKey: {
          id: 'searchKey',
          label: 'Procurar por',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {}
        },
        searchOrdering: {
          id: 'searchOrdering',
          label: 'Ordenar por',
          placeholder: '',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
      }
    };
    }

    // Get users data from database
    getLeads = (page) => {

      this.setState({loading: true});

      const searchKey = this.state.formItems.searchKey.value;
      const searchOrdering = this.state.formItems.searchOrdering.value;
      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          page: page,
          key: searchKey,
          ordering: searchOrdering
        }),
      };

      fetch("/api/get_leads.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

          if(data.success){
          
          const pagination = this.state.pagination;
          pagination.total = data.totalItems;
          pagination.page= data.currentPage;
          pagination.pages = data.totalPages;
          const results = data.items;

          const callback = () => {
            if(page === 1){
              this.exportToFile();
            }
          }

          let file = this.state.fileIsReady;
          if(page === 1){
            file = false;
          }

          this.setState({ 
            fileIsReady: file,
            loading: false,
            pagination: pagination,
            results: results,
          }, callback);

        } else {

          localStorage.setItem('openModal', 1);
          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }
          localStorage.setItem('modalObj', JSON.stringify(modalObj));
          let goTo = '/';
          window.location = goTo;
          
        }

      })
      .catch((error) => {
        console.dir(error);
      });

    }


    // Export to file
    exportToFile = () => {

      const searchKey = this.state.formItems.searchKey.value;
      const searchOrdering = this.state.formItems.searchOrdering.value;
      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          key: searchKey,
          ordering: searchOrdering
        }),
      };

      fetch("/api/leads_to_file.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
        const { downloadFile } = this.state;
        const fileObj = downloadFile;

        fileObj.data = data.items;
        fileObj.filename = data.filename;

        this.setState({downloading: false, fileIsReady: true, downloadFile: fileObj});

      })
      .catch((error) => {
        console.dir(error);
      });

    }


    // Component Did Mount
    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === 1){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.getLeads(1);
      
    }

  render(props){
  
  // FORMS: INPUT CHANGE HANDLE FUNCTION
  const handleChange = (event) => {

  // Get Name and Value from element
  const name = event.target.name;
  const value = event.target.value;

  // Get current FormItems object from State
  const formItems = this.state.formItems;

  // Get State of the Input
  const formItem = {
      ...formItems[name]
  };

  // Validate Element if it has been Touched Before
  if(formItem.hasTouched){
      if(formItem.rules){
          const validation = validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }
  }

  // Set the new value to element
  formItem.value = value;

  // Update formItems collection
  formItems[name] = formItem;


  // Update State
  this.setState({ 
      ...this.state, 
      formItems: formItems
  });

  }

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = (event) => {
          
  // Get Name and Value from element
  const name = event.target.name;
  const value = event.target.value;

  // formItems collection
  const formItems = {
      ...this.state.formItems
  };

  // state of modified element
  const formItem = {
      ...formItems[name]
  };

  formItem.hasTouched = true;
  if(formItem.rules){
      const validation = validate(value, formItem.rules, formItems);
      formItem.hasError = validation[0];
      formItem.errorMsg = validation[1];
  }else{
      formItem.hasError = false;
      formItem.errorMsg = '';
  }

  // Update formItems collection
  formItems[name] = formItem;

  // Update state
  this.setState({ 
      ...this.state, 
      formItems: formItems
  });

  }

  // FORMS: HANDLE SUBMIT
  const handleSubmit = () => {

    this.getLeads(1);

  }

  // Format Data
  const formatDate = (date, withHour) => {

    if(date){

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }
    return '-';

  }

  //const { saveAsCsv } = useJsonToCsv();

  const { data, fields, filename } = this.state.downloadFile;


  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content leads-page">
        
          {/* HEADING */}
          <HeadingViewMode type={1} title="Leads" />

          { /* TOOLBAR */ }
          <div className="container toolbar right">
            {
              (this.state.fileIsReady)?
                /*<JsonToCsv
                  className="btn-download"
                  data={this.state.downloadFile.data}
                  filename={this.state.downloadFile.filename}
                  fields={this.state.downloadFile.fields}
                  style={this.state.downloadFile.style}
                  text={this.state.downloadFile.text}
                />
                */
                <Button 
                color="primary" 
                variant="contained" 
                endIcon={<GetAppIcon />} 
                disabled
                //onClick={() => saveAsCsv({ data, fields, filename })}
              >
                Download
              </Button>
               :
                <Button 
                color="primary" 
                variant="contained" 
                disabled
                endIcon={<CircularProgress size={24}/>} 
                onClick={this.askForFile}
              >
                Preparando arquivo...
              </Button>
            }
            
          </div>

          {/* SEARCH FORM */}
          <div className="container">
            <form className="form-content-search" onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <Grid item xs>
                  <TextField
                      name="searchKey"
                      id={this.state.formItems.searchKey.id}
                      value={this.state.formItems.searchKey.value}
                      label={this.state.formItems.searchKey.label}
                      placeholder={this.state.formItems.searchKey.placeholder}
                      error={this.state.formItems.searchKey.hasError}
                      helperText={this.state.formItems.searchKey.errorMsg}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                  />
                </Grid>
                
                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.searchOrdering.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.searchOrdering.id} >{this.state.formItems.searchOrdering.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.searchOrdering.id}
                  id={this.state.formItems.searchOrdering.id}
                  value={this.state.formItems.searchOrdering.value}
                  label={this.state.formItems.searchOrdering.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >

                  {this.state.orderingOptions.map((item, index) => {
                  return (
                  <option
                  key={"ordering-" + index}
                  value={item.value}
                  >
                  {item.label}
                  </option>
                  );
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.searchOrdering.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={1}>
                <Button color="primary" variant="outlined" onClick={handleSubmit} fullWidth >Filtrar</Button>
              </Grid>

            </Grid>
            </form>
          </div>

          { /* RESULTS */ }
          <div className='container results'>
                      
                      {/* LOADING */}
                      <div className={(this.state.loading)?'results-loading':'results-loading hidden'}>
                        <CircularProgress size={48} />
                      </div>
                      
                      {/* LIST OF RESULTS */}
                      <div className={(this.state.results.length > 0) ? 'results-list' : 'results-list hidden'}>
                          <table>
                              <thead>
                                <tr>
                                  <th className="cell-sm center">Id</th>
                                  <th className="cell-xl left">Nome</th>
                                  <th className="cell-xl left">Email</th>
                                  <th className="cell-l left">Criado em</th>
                                  <th className="cell-l left">Atualização</th>
                                  <th className="left">Sources</th>
                                  <th className="cell-xs center"></th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  this.state.results.map((item, index) => {

                                    let keyTable = 'table-row-' + index;

                                    return(
                                      <tr key={keyTable} >
                                          <td className="cell-sm center">{item.id}</td>
                                          <td className="cell-xl left">{item.name}</td>
                                          <td className="cell-xl left">{item.email}</td>
                                          <td className="cell-l left">{formatDate(item.created, false)}</td>
                                          <td className="cell-l left">{formatDate(item.updated, false)}</td>
                                          <td className="left">
                                          <span  className="source">
                                            {
                                            item.sources.map((source, index) => {

                                              //let url = '/page/content-' + source.id;

                                              return(
                                                  <span key={index} className="source-tag">{source.tag}</span>
                                              )
                                            })
                                            
                                          }
                                          </span>
                                          </td>
                                          <td className="cell-xs center"></td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>

                          </table>
                          <Pagination 
                          currentPage={this.state.pagination.page} 
                          totalPages={this.state.pagination.pages} 
                          totalItems={this.state.pagination.total}
                          goToFunction={this.getUsers}
                          />
                      </div>
                      
                      {/* NO RESULTS */}
                      <div className={(this.state.results.length === 0) ? 'no-results' : 'no-results hidden'}>
                          <HighlightOffIcon size={48} />
                          <p>
                            <b>Nenhum resultado encontrado</b>
                            <br/>Verifique os filtros e tente novamente.
                          </p>
                      </div>

          </div>

      
      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(Leads));