import React from 'react';

// Import Styles
import './container-wrapper.styles.scss';

// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import BannerTitleViewMode from '../../modules/banner-title/banner-title.view.component';
import BannerImageViewMode from '../../modules/banner-image/banner-image.view.component';
import RichtextViewMode from '../../modules/richtext/richtext.view.component';
import LeadtextViewMode from '../../modules/leadtext/leadtext.view.component';
import HighlightedTextViewMode from '../../modules/highlighted-text/highlighted-text.view.component';
import ImageViewMode from '../../modules/image/image.view.component';
import ImageTextViewMode from '../../modules/image-with-text/image-with-text.view.component';
import QuotesViewMode from '../../modules/quotes/quotes.view.component';
import BannerViewMode from '../../modules/banner/banner.view.component';
import CardsViewMode from '../../modules/cards/cards.view.component';
import AuthorViewMode from '../../modules/author/author.view.component';
import CtaViewMode from '../../modules/cta/cta.view.component';
import TestimonialsViewMode from '../../modules/testimonials/testimonials.view.component';
import ExpandCollapseViewMode from '../../modules/expand-collapse/expand-collapse.view.component';
import YoutubeViewMode from '../../modules/youtube/youtube.view.component';
import PostsViewMode from '../../modules/posts/posts.view.component';
import NotesViewMode from '../../modules/notes/notes.view.component';
import FormViewMode from '../../modules/form/form.view.component';
import ContactViewMode from '../../modules/contact/contact.view.component';
import BlogViewMode from '../../modules/blog/blog.view.component';
import PartnersViewMode from '../../modules/partners/partners.view.component';
import GiftViewMode from '../../modules/gift/gift.view.component';
import DirectoryViewMode from '../../modules/directory/directory.view.component';
import SpacingViewMode from '../../modules/spacing/spacing.view.component';

const ContainerWrapper = (props) => {

    const classes = () => {
        let classes = 'mdl mdl-' + props.module;

        if(props.wide === 'full'){
            classes += ' full';
        }

        return classes;
    }

    const renderModule = (contentId, wide, module, props, pageData, urlParameters) => {

        switch (module) {

            case 'heading':
                
                return(<HeadingViewMode contentId={contentId} {...props}  urlParameters={urlParameters}/>);
            
            case 'spacing':
            
                return(<SpacingViewMode contentId={contentId} {...props}  urlParameters={urlParameters}/>);
            
            case 'banner-title':
                
                return(<BannerTitleViewMode contentId={contentId} {...props}  pageData={pageData} urlParameters={urlParameters} />);

            case 'banner-image':
            
                return(<BannerImageViewMode contentId={contentId} {...props} pageData={pageData} urlParameters={urlParameters} />);
            
            case 'banner':
            
                return(<BannerViewMode contentId={contentId} {...props} pageData={pageData} urlParameters={urlParameters} />);

            case 'richtext':

                return(<RichtextViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'leadtext':

                return(<LeadtextViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'highlighted-text':

                return(<HighlightedTextViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'image':

                return(<ImageViewMode contentId={contentId} {...props}  urlParameters={urlParameters} />);
            
            case 'image-richtext':

                return(<ImageTextViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'quotes':

                return(<QuotesViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'cards':

                return(<CardsViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'author':

                return(<AuthorViewMode contentId={contentId} {...pageData} urlParameters={urlParameters} />);
            
            case 'cta':

                return(<CtaViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'testimonials':

                return(<TestimonialsViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'expand-collapse':

                return(<ExpandCollapseViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'youtube':

                return(<YoutubeViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'posts':

                return(<PostsViewMode contentId={contentId} {...props} wide={wide} urlParameters={urlParameters} />);
            
            case 'notes':

                return(<NotesViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'form':

                return(<FormViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'contact':

                return(<ContactViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'blog':

                return(<BlogViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'directory':

                return(<DirectoryViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'partners':

                return(<PartnersViewMode contentId={contentId} {...props} urlParameters={urlParameters} />);
            
            case 'gift':

                return(<GiftViewMode contentId={contentId} {...pageData} {...props} urlParameters={urlParameters} />);

            default:

                return;
        }

    }

    return(
            <div className={classes()}>
                {renderModule(props.contentId, props.wide, props.module, props.parameters, props.pageData, props.urlParameters)}
            </div>
    );
}

  
export default ContainerWrapper;
