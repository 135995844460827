import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import './classes.styles.scss';

// Import Redux Action
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';

// Import Components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import Pagination from '../../components/pagination/pagination.component';
import NewClass from './new-class.component';
import { weekdays } from "moment";


 
class Classes extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    
    this.state = {
      viewMode: 'search',
      loading: false,
      courses: [],
      weekdays: [
        {
          label: "D",
          name: "Domingo",
          abbreviation: "Dom",
          value: 0,
          selected: false,
        },
        {
          label: "S",
          name: "Segunda",
          abbreviation: "Seg",
          value: 1,
          selected: false,
        },
        {
          label: "T",
          name: "Terça",
          abbreviation: "Ter",
          value: 2,
          selected: false,
        },
        {
          label: "Q",
          name: "Quarta",
          abbreviation: "Qua",
          value: 3,
          selected: false,
        },
        {
          label: "Q",
          name: "Quinta",
          abbreviation: "Qui",
          value: 4,
          selected: false,
        },
        {
          label: "S",
          name: "Sexta",
          abbreviation: "Sex",
          value: 5,
          selected: false,
        },
        {
          label: "S",
          name: "Sábado",
          abbreviation: "Sab",
          value: 6,
          selected: false,
        },
    ],
      statusOptions: [
        {label: 'Todas', value: 0},
        {label: 'Ativas', value: 1},
        {label: 'Canceladas', value: 6},
        {label: 'Arquivadas', value: 11},
      ],
      orderingOptions: [
        {label: 'Mais recentes', value: 0},
        {label: 'Mais antigos', value: 1},
      ],
      results: [],
      pagination: {
        page: 1,
        pages: 1,
        total: 0
      },
      formItems: {
        searchKey: {
          id: 'searchKey',
          label: 'Procurar por',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {}
        },
        searchStatus: {
          id: 'searchStatus',
          label: 'Situação',
          placeholder: '',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        searchOrdering: {
          id: 'searchOrdering',
          label: 'Ordenar por',
          placeholder: '',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
    }
    };
    }

    // Get users data from database
    getClasses(page){

      this.setState({
        ...this.state,
        loading: true
      });

      const searchKey = this.state.formItems.searchKey.value;
      const searchStatus = this.state.formItems.searchStatus.value;
      const searchOrdering = this.state.formItems.searchOrdering.value;
      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          page: page,
          key: searchKey,
          situation: searchStatus,
          ordering: searchOrdering
        }),
      };

      fetch("/api/classes_list.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

          if(data.success){
          
          const pagination = this.state.pagination;
          pagination.total = data.totalItems;
          pagination.page= data.currentPage;
          pagination.pages = data.totalPages;
          const results = data.items;
          const courses = data.courses;

          this.setState({ 
            ...this.state, 
            loading: false,
            pagination: pagination,
            results: results,
            courses: courses
          });

        } else {

          localStorage.setItem('openModal', 1);
          const modalObj = {
            modalIcon: 'priority_high',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Aviso',
            modalParameters: {
                text: data.feedback
            },
            modalActions: null
          }
          localStorage.setItem('modalObj', JSON.stringify(modalObj));
          let goTo = '/';
          window.location = goTo;
          
        }

      })
      .catch((error) => {
        console.dir(error);
      });

    }

    // Component Did Mount
    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === 1){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.getClasses(1);
      
    }

    setMode = (mode) => {
        this.setState({viewMode: mode});
    }

    // Format Data
    formatDate = (date, withHour) => {

    if(date){

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }
    return '-';

    }

    // Format Time
    formatTime = (time) => {
        if(time){
            let t = time.split(':');
            let h = t[0];
            let m = t[1];
            return h + ':' + m;
        }
    }

    // Get selected weekdays
    getWeekdays = (str) => {
        
        if(str === '1,2,3,4,5'){
            return 'Seg - Sex';
        }
        const { weekdays } = this.state;
        const x = str.split(',');

        let days = [];
        x.forEach((item) => {
            let idx = parseInt(item);
            days.push(weekdays[idx].abbreviation);
        });
       
        return days.join(',');
    }

  render(props){
  
  // FORMS: INPUT CHANGE HANDLE FUNCTION
  const handleChange = (event) => {

  // Get Name and Value from element
  const name = event.target.name;
  const value = event.target.value;

  // Get current FormItems object from State
  const formItems = this.state.formItems;

  // Get State of the Input
  const formItem = {
      ...formItems[name]
  };

  // Validate Element if it has been Touched Before
  if(formItem.hasTouched){
      if(formItem.rules){
          const validation = validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }
  }

  // Set the new value to element
  formItem.value = value;

  // Update formItems collection
  formItems[name] = formItem;


  // Update State
  this.setState({ 
      ...this.state, 
      formItems: formItems
  });

  }

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = (event) => {
          
  // Get Name and Value from element
  const name = event.target.name;
  const value = event.target.value;

  // formItems collection
  const formItems = {
      ...this.state.formItems
  };

  // state of modified element
  const formItem = {
      ...formItems[name]
  };

  formItem.hasTouched = true;
  if(formItem.rules){
      const validation = validate(value, formItem.rules, formItems);
      formItem.hasError = validation[0];
      formItem.errorMsg = validation[1];
  }else{
      formItem.hasError = false;
      formItem.errorMsg = '';
  }

  // Update formItems collection
  formItems[name] = formItem;

  // Update state
  this.setState({ 
      ...this.state, 
      formItems: formItems
  });

  }

  // FORMS: HANDLE SUBMIT
  const handleSubmit = () => {
    this.getClasses(1);
  }

  // Format Data
  const formatDate = (date, withHour) => {

    if(date){

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }
    return '-';

  }

  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content classes-page">
        
          {/* HEADING */}
          <HeadingViewMode type={1} title="Turmas" />

          { /* TOOLBAR */ }
          <div className="container toolbar right">
          <Button color="primary" variant="contained" onClick={() => this.setMode('new')}endIcon={(this.state.creating)? <CircularProgress size={24}/> : <AddIcon />} disabled={this.state.creating}>Nova Turma</Button>
          </div>

          {/* SEARCH FORM */}
          <div className="container">
            <form className="form-content-search" onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <Grid item xs>
                  <TextField
                      name="searchKey"
                      id={this.state.formItems.searchKey.id}
                      value={this.state.formItems.searchKey.value}
                      label={this.state.formItems.searchKey.label}
                      placeholder={this.state.formItems.searchKey.placeholder}
                      error={this.state.formItems.searchKey.hasError}
                      helperText={this.state.formItems.searchKey.errorMsg}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                  />
                </Grid>
            
                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.searchStatus.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.searchStatus.id}>{this.state.formItems.searchStatus.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.searchStatus.id}
                  id={this.state.formItems.searchStatus.id}
                  value={this.state.formItems.searchStatus.value}
                  label={this.state.formItems.searchStatus.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >
                  {this.state.statusOptions.map((item) => {
                    let itemKey = 'sit-' + item.value;
                    return(<option key={itemKey} value={item.value}>{item.label}</option>);
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.searchStatus.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.searchOrdering.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.searchOrdering.id} >{this.state.formItems.searchOrdering.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.searchOrdering.id}
                  id={this.state.formItems.searchOrdering.id}
                  value={this.state.formItems.searchOrdering.value}
                  label={this.state.formItems.searchOrdering.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >

                  {this.state.orderingOptions.map((item, index) => {
                  return (
                  <option
                  key={"ordering-" + index}
                  value={item.value}
                  >
                  {item.label}
                  </option>
                  );
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.searchOrdering.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={1}>
                <Button color="primary" variant="outlined" onClick={handleSubmit} fullWidth >Filtrar</Button>
              </Grid>

            </Grid>
            </form>
          </div>

          { /* RESULTS */ }
          <div className='container results'>
                      
                      {/* LOADING */}
                      <div className={(this.state.loading)?'results-loading':'results-loading hidden'}>
                        <CircularProgress size={48} />
                      </div>
                      
                      {/* LIST OF RESULTS */}
                      <div className={(this.state.results.length > 0) ? 'results-list' : 'results-list hidden'}>
                          <table>
                              <thead>
                                <tr>
                                  <th className="cell-xs center"></th>
                                  <th className="cell-sm center hide-in-mobile">Id</th>
                                  <th className="left">Treinamento</th>
                                  <th className="cell-lg left hide-in-mobile">Período</th>
                                  <th className="cell-lg left hide-in-mobile">Horário</th>
                                  <th className="cell-sm center hide-in-mobile">Inscritos</th>
                                  <th className="cell-lg right"></th>
                                </tr>
                              </thead>

                              <tbody>
                                {
                                  this.state.results.map((item, index) => {

                                    let keyTable = 'table-row-' + index;
                                    let iData = this.formatDate(item.startDate, false);
                                    let eData = this.formatDate(item.endDate, false);
                                    let courseDate = (iData !== eData) ? iData + ' - ' + eData : iData;
                                    let courseWeekdays = this.getWeekdays(item.weekdays);
                                    let classColor = 'primary';
                                    let classLabel = 'Ativa';

                                    if(item.status === 11){
                                        classColor = 'past';
                                        classLabel = 'Arquivada';
                                    }

                                    if(item.status === 6){
                                        classColor= 'alert';
                                        classLabel= 'Cancelada';
                                    }


                                    return(
                                      <tr key={keyTable} >
                                          <td className="cell-xs center">
                                              <Tooltip arrow title={classLabel} >
                                                  <div className={'badge ' + classColor}></div>
                                              </Tooltip>
                                          </td>
                                          <td className="cell-sm center hide-in-mobile" >{item.id}</td>
                                          <td className="left padding-sm">{item.course}</td>
                                          <td className="cell-lg left hide-in-mobile">{courseDate}</td>
                                          <td className="cell-md left hide-in-mobile"><span className="weekdays">{courseWeekdays}</span>{this.formatTime(item.startTime)} - {this.formatTime(item.endTime)}</td>
                                          <td className="cell-sm center hide-in-mobile">{item.subscribers}/{item.capacity}</td>
                                          <td className="cell-md right hide-in-mobile">
                                            <IconButton color="default"><EditIcon /></IconButton>
                                            <IconButton color="default"><DeleteIcon /></IconButton>
                                          </td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>

                          </table>
                          <Pagination 
                          currentPage={this.state.pagination.page} 
                          totalPages={this.state.pagination.pages} 
                          totalItems={this.state.pagination.total}
                          goToFunction={this.getUsers}
                          />
                      </div>
                      
                      {/* NO RESULTS */}
                      <div className={(this.state.results.length === 0) ? 'no-results' : 'no-results hidden'}>
                          <HighlightOffIcon size={48} />
                          <p>
                            <b>Nenhum resultado encontrado</b>
                            <br/>Verifique os filtros e tente novamente.
                          </p>
                      </div>

          </div>
          
          <NewClass isOpen={(this.state.viewMode === 'new')} courses={this.state.courses} toggle={() => this.setMode('search')} refresh={()=>this.getClasses(1)}/>
    </div>
    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(Classes));