import React from 'react';

const InstagramIcon = (props) => {
    const viewBoxWidth = 64;
    const viewBoxHeight = 64;
    const viewBox = '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight;
    const width = (props.width) ? props.width : viewBoxWidth;
    const height = (props.height) ? props.height : viewBoxHeight;

    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox={viewBox}
        className={(props.className) ? `svg-icon ${props.className}` : 'svg-icon'}
        width = {width + 'px'}
        height ={height + 'px'}
        >
            <path 
                d="M32 18.18a13.89 13.89 0 1 0 13.9 13.89h0a13.87 13.87 0 0 0-13.85-13.89zm0 22.92a9 9 0 1 1 9-9h0a9 9 0 0 1-9 9zm17.7-23.49a3.24 3.24 0 1 1-3.25-3.23h0a3.22 3.22 0 0 1 3.25 3.22h0zm9.19 3.28a16 16 0 0 0-4.38-11.35 16.13 16.13 0 0 0-11.33-4.35c-4.48-.25-17.89-.25-22.36 0A16.09 16.09 0 0 0 9.47 9.55 16.09 16.09 0 0 0 5.09 20.9c-.25 4.47-.25 17.88 0 22.35A16.09 16.09 0 0 0 9.47 54.6 16.1 16.1 0 0 0 20.82 59c4.47.25 17.88.25 22.36 0a16 16 0 0 0 11.35-4.4 16.19 16.19 0 0 0 4.38-11.35c.25-4.47.25-17.87 0-22.34zM53.11 48A9.09 9.09 0 0 1 48 53.18c-3.57 1.42-12 1.09-16 1.09s-12.42.32-16-1.09A9.09 9.09 0 0 1 10.87 48c-1.42-3.56-1.09-12-1.09-16s-.31-12.41 1.09-16A9.15 9.15 0 0 1 16 11c3.56-1.41 12-1.09 16-1.09S44.42 9.56 48 11a9.18 9.18 0 0 1 5.15 5.15c1.41 3.57 1.08 12 1.08 16s.33 12.42-1.09 16z"
            />
        </svg>
    );

}
export default InstagramIcon;