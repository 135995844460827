// Import libraries
import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// Import styles
import './ipt-image.styles.scss';

// Import redux actions
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

const IptImage = (props) => {

        const hasSelectedImage = (props.selectedImage !== null && props.selectedImage !== undefined && props.selectedImage !== 0 && props.selectedImage !== '');
        const selectedImage = (hasSelectedImage) ? "url('" + window.$thumbsPath + props.selectedFile + "')" : 'none';
        
        
        return(
            <div className='ipt-image'>
                <div className='image' onClick={() => props.openGallery(props.limit, props.action)} data-selected-image={props.selectedImage} style={{ backgroundImage: selectedImage }}></div>
                <button className={(hasSelectedImage) ? 'clear-button' : 'clear-button hidden'} type="button" onClick={() => props.clearFunction()}>Remover Imagem</button>
            </div>
        )
    
}


const mapDispatchToProps = dispatch => ({
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
  });
  
  export default withRouter(connect(null, mapDispatchToProps)(IptImage));
  