// eslint-disable-next-line
import React from "react";
import { getToken } from './user-validation.utils';

async function IsAuthenticated(){

    const token = getToken();
    let authenticated = true;

    if(!token){
        
        console.dir('AUTH: No token');
        authenticated = false;

    }else{

        // Connect to Auth_Token api  
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Request-Headers": "*",
                "Access-Control-Request-Method": "*"
            },
            body: JSON.stringify({
                userToken: token,
                app: window.$appToken 
            })
          };

          fetch("/api/auth_token.php", requestOptions)
          .then((response) => response.json())
          .then((data) => {

            if(data.authenticated){

                //console.dir('AUTH: User is logged')
                authenticated = true;
                
            }else{

                //console.dir('AUTH: Server reject the current token')
                authenticated = false;

            }
            //console.dir('AUTH: ' + data.feedback);

          })
          .catch((error) => {

            //console.dir('AUTH: An error ocurred: ' + error);
            authenticated = false;

          })

    }

    return authenticated;
    
}


  
  export default IsAuthenticated;