import React from 'react';
import './calendar-day.styles.scss';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const CalendarDay = (props) => {

    const { today, slots, weekdays, timeline, now } = props;
    const slotsOfDay = slots.filter((item) => (item.day === today.d && item.month === (today.m + 1) && item.year === today.y));
    const nowPos = (now) ? (now.getHours() * 60) + now.getMinutes() + 'px' : 0;


    return (
      <div className="calendar-today">
        <div className="today-header">
          <div className="btn-wrapper">
            <button className="btn-circle primary small" onClick={props.prev}><ArrowBackIcon /></button>
          </div>
          <div className="day-title">
            <span className="day">{today.d}</span>
            <span className="weekday">{weekdays[today.w]}</span>
          </div>
          <div className="btn-wrapper">
            <button className="btn-circle primary small" onClick={props.next}><ArrowForwardIcon /></button>
          </div>
        </div>
        <div className="today-body">
          <div className="now" style={{ top: nowPos }}>
            <span></span>
          </div>
          <div className="timeline">
            {timeline.map((item) => {
              let whKey = "today-time-" + item.hour;
              return (
                <div id={whKey} key={whKey} className="time">
                  {item.time}
                </div>
              );
            })}
          </div>
          <div className="today">
            <div className="events">
              {
                slotsOfDay.map((event) => {
                    let eventKey = "event-" + event.id;
                    let cardHeight = event.duration + "px";
                    let n = event.hour * 60 + event.minutes;
                    let cardTop = n + "px";

                    let eventClasses = "event";
                    eventClasses += event.past ? " past" : "";
                    eventClasses += event.status === 0 ? " open" : "";
                    eventClasses += event.status !== 0 ? " busy" : "";

                    let title = (event.user && event.user.FullName) ? event.user.FullName : event.title; 

                    return (
                    <div
                        key={eventKey}
                        className={eventClasses}
                        style={{ height: cardHeight, top: cardTop }}
                        onClick={() => props.openEvent(event.id)}
                    >
                        <span className="event-time">{event.startTime}</span>
                        <span className="event-title">{title}</span>
                    </div>
                    );
                })
              }
            </div>
            {timeline.map((line) => {
              let whKey = "day-grade-" + today.d + today.m + line.hour;
              return (
                <div key={whKey} className="line">
                  <div className="subline"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
}

export default CalendarDay;