//Import Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Styles and Assets
import './approve-contents.styles.scss';
import '../pages-directory/pages-directory.component';

//Import UI components
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import Pagination from '../../components/pagination/pagination.component';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, getId, authAction } from '../../utils/user-validation/user-validation.utils';

class ApproveContents extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          loadingResults: false,
          results: [],
          pagination: {
            page:1,
            pages: 1,
            total: 0
          }
        }
    }

    
    getContents = (page) => {

      this.setState({
        ...this.state,
        loadingResults: true
      });

      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          page: page,
        }),
      };

      fetch("/api/get_approval_requests.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
        
        const pagination = this.state.pagination;
        pagination.total = data.totalItems;
        pagination.page= data.currentPage;
        pagination.pages = data.totalPages;
        const results = data.items;

        this.setState({ 
          ...this.state, 
          loadingDuplicate: 0,
          pagination: pagination,
          results: results,
          loadingResults: false,
          toApprove: data.approvalCounter
        });

      })
      .catch((error) => {
        console.dir(error);
      });

    }

    componentDidMount(){

      // Verify for Session Modals
      const hasModalToOpen = localStorage.getItem('openModal');
      if(hasModalToOpen && hasModalToOpen === '1'){
          const modalObj = JSON.parse(localStorage.getItem('modalObj'));
          this.props.openModal(modalObj);
      }

      this.getContents(1);

    }


    approveContent = (id) => {

    }

    reproveContent = (id) => {

    }

    // Format dates
    formatDate = (date, withHour) => {
      let d = date.substr(8, 2);
      let m = date.substr(5, 2);
      let y = date.substr(0, 4);
      let sDate = "";

      if (withHour) {
        let h = date.substr(11, 2);
        let n = date.substr(14, 2);
        sDate = d + "/" + m + "/" + y + " " + h + "h" + n;
      } else {
        sDate = d + "/" + m + "/" + y;
      }

      return sDate;
    };

    //Rendering
    render(props){
      
      return (
        <div className="page">
          

          <div className="content pages-directory">
            
            {/* HEADING */}
            <HeadingViewMode type={1} title="Análise de Conteúdos" />

            { /* RESULTS */ }
            <div className='container results'>
                  
                  {/* LOADING */}
                  <div className={(this.state.loadingResults)?'results-loading':'results-loading hidden'}>
                    <CircularProgress size={48} />
                  </div>
                  
                  {/* LIST OF RESULTS */}
                  <div className={(this.state.results.length > 0) ? 'results-list' : 'results-list hidden'}>
                      <table>
                          <thead>
                            <tr>
                              <th className="cell-xs center"></th>
                              <th className="left">Título</th>
                              <th className="cell-l left hide-in-mobile">Data</th>
                              <th className="cell-l"></th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              this.state.results.map((item, index) => {

                                let isOwner = (parseInt(item.lock) === parseInt(getId()) || parseInt(item.lock) === 0) ? true : false;
                                let hasUnlockTicket = item.hasUnlockTicket;
                                
                                let tooltipText = '';
                                let icon = (<LockIcon />);

                                if(parseInt(item.lock) > 0 && isOwner){
                                  tooltipText = 'Bloqueado. Clique para desbloquear';
                                }else if(parseInt(item.lock)>0 && !isOwner){
                                  if(hasUnlockTicket){
                                    icon = (<AccessTimeIcon />);
                                    tooltipText = 'Bloquedo. Já existe um pedido de desbloqueio desde ' + this.formatDate(item.unlockTicketDate, true);
                                  }else{
                                    tooltipText = 'Bloquedo. Clique para solicitar o desbloqueio a ' + item.owner;
                                  }
                                  
                                }else{
                                  icon = (<LockOpenIcon />);
                                  tooltipText = 'Desbloqueado. Clique para bloquear';
                                }

                                let keyTable = 'cell-content-' + index;
                                
                                let sttsLabel = 'Draft';
                                let sttsColor = '';
                                if(item.hasPublishedVersion && item.approved === 1){
                                  sttsLabel = 'Publicado';
                                  sttsColor = 'primary';
                                }
                                if(item.hasPublishedVersion && item.approved === 0){
                                  sttsLabel = 'Em análise';
                                  sttsColor = 'alert';
                                }

                                return(
                                  <tr key={keyTable}>
                                      <td className="cell-xs center">
                                        <Tooltip title={sttsLabel} arrow>
                                            <div className={'badge ' + sttsColor}></div>
                                        </Tooltip>
                                      </td>
                                      <td className="cell-xxl left padding-md">
                                        <span className="content-label">{(item.categoryName) ? item.categoryName : 'Nenhuma'}</span>
                                        <span className="content-title"><a href={"/preview/" + item.versionId + '/list'}>{item.title}</a></span>
                                        <span className="content-author">Postado por {item.owner}</span>
                                      </td>
                                      <td className="cell-xl left hide-in-mobile">{(item.hasPublishedVersion)? this.formatDate(item.publishDate, true) : 'Não publicado'}</td>
                                      <td className="right">
                                        <div className="btn-wrapper side-by-side at-right">
                                          <Button color="primary" variant="outlined">Aprovar</Button>
                                          <Button color="secondary" variant="outlined">Reprovar</Button>
                                        </div>
                                      </td>
                                  </tr>
                                )
                              })
                            }
                            
                          </tbody>

                      </table>
                      <Pagination 
                      currentPage={this.state.pagination.page} 
                      totalPages={this.state.pagination.pages} 
                      totalItems={this.state.pagination.total}
                      goToFunction={this.getContents}
                      />
                  </div>
                  
                  {/* NO RESULTS */}
                  <div className={(this.state.results.length === 0) ? 'no-results' : 'no-results hidden'}>
                      <HighlightOffIcon size={48} />
                      <p>
                        <b>Nenhum resultado encontrado</b>
                        <br/>Verifique os filtros e tente novamente.
                      </p>
                  </div>

            </div>
            
            { /* REPROVE FORM */ }
            <div className="forms-area">
                  
                  { /* FORM REPROVAL */ }
                  <div className="form-denny">
                    
                  </div>

            </div>

          </div>
        </div>
      );
    }
}


const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(ApproveContents));