import React, {Component} from 'react';
import './expand-collapse.styles.scss';

// Import UI component
import TextField from "@material-ui/core/TextField";
import DeleteIcon from '@material-ui/icons/Delete';

// Import components
import RichtextEditor from '../../components/richtext-editor/richtext-editor.component';


// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class ExpandCollapseForm extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            formItems: {
                title: {
                    id: 'title',
                    label: 'Titulo',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 25
                    }
                },
            },
        }
    }

    componentDidMount(){

        let title = this.props.title;
        let text = this.props.text;
        const formItems = this.state.formItems;

        formItems.title.value = title;
        formItems.text.value = text;

        this.setState({
            ...this.state,
            formItems: formItems
        })

    }

    componentDidUpdate(prevProps, prevState){

        const formItems = this.state.formItems;
        let hasChanges = false;

        if (prevProps.title !== this.props.title) {
            formItems.title.value = this.props.title;
            hasChanges = true;
        }

        if (prevProps.text !== this.props.text) {
            formItems.text.value = this.props.text;
            hasChanges = true;
        }

        if(hasChanges){
            this.setState({
                ...this.state,
                formItems: formItems
            })
        }
        
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            checkErrors();
            this.props.changeItem(this.props.itemId, name, value);


        }

        // Check for errors
        const checkErrors = () => {

            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;
            let hasError = false;
            
            for(let i=0; i < size; ++i){
                
                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    hasError = true;
                }
            }

            this.props.validateItem(this.props.itemId, hasError);

        }

        const richtextChange = (htmlValue) => {

            console.dir('calling change function');

            const formItems = this.state.formItems;
            console.dir(formItems);
            console.dir(htmlValue);
            formItems.text.value = htmlValue;
            
            this.setState({
                ...this.state,
                formItems: formItems
            })

            //this.props.changeItem(this.props.itemId, 'text', this.state.formItems.text.value);

        }

        const richtextBlur = () => {
            checkErrors();
            console.dir('blur function is called');
            console.dir(this.state.formItems.text.value);
            console.dir('');
            this.props.changeItem(this.props.itemId, 'text', this.state.formItems.text.value);
        }


       const editorFunctions = {
           handleChange: richtextChange,
           handleBlur: richtextBlur
       }

        const cardClasses = (this.props.hasError) ? 'item-form  has-error' : 'item-form';

        const allowDrop = (ev) => {
            ev.preventDefault();
          }
          
        const drag = (e) => {
            let objId = e.target.id;
            let obj = document.getElementById(objId);
            let wrapperId = 'items-wrapper-' + this.props.contentId;
            let wrapper = document.getElementById(wrapperId);
            
            e.dataTransfer.setData('itemToSort', e.target.getAttribute('data-index'));
            obj.style.opacity = 0;

            
            let clonedObj = obj.cloneNode(true);
            clonedObj.id = 'cloned-item';
            clonedObj.style.position = 'fixed';
            clonedObj.style.top = -5000;
            clonedObj.style.opacity = 1;
            clonedObj.style.width = obj.style.width;
            clonedObj.classList.add('cloned-item');
            e.dataTransfer.setData('movedObj', objId);
            
            wrapper.appendChild(clonedObj);
            e.dataTransfer.setDragImage(clonedObj, 0, 0);

        }

        const dragEnd = (ev) => {
            let movedObj = document.getElementById(ev.target.id);
            movedObj.style.opacity = 1;
            let clonedObj = document.getElementById('cloned-item');
            clonedObj.remove();
        }
          
        const drop = (ev) => {
            ev.preventDefault();
            const el = ev.currentTarget;
            const oldIndex = ev.dataTransfer.getData('itemToSort');
            const newIndex = el.getAttribute("data-index");
            this.props.sortItems(oldIndex, newIndex);
        }

        return(
            <div 
            id={'item-' + this.props.contentId + '-' + this.props.itemId} 
            data-index={this.props.itemId} 
            className={cardClasses}
            draggable="true" 
            onDragStart={drag} 
            onDrop={drop} 
            onDragOver={allowDrop}
            onDragEnd={dragEnd}
            >

                { /* ID */ }
                <div className="info">

                    { /* Title */ }
                    <div className="title">
                        <TextField
                        name={this.state.formItems.title.id}
                        id={this.state.formItems.title.id + this.props.itemId}
                        value={this.state.formItems.title.value}
                        label={this.state.formItems.title.label}
                        placeholder={this.state.formItems.title.placeholder}
                        error={this.state.formItems.title.hasError}
                        helperText={this.state.formItems.title.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </div>

                </div>

                { /* Text */ }
                <div className="text">
                    <RichtextEditor editor={'richtext-editor__' + this.props.contentId + '-' + this.props.itemId} content={this.props.text} functions={editorFunctions} contentId={this.props.contentId + '-' + this.props.itemId} />
                </div>

                { /* Buttons */ }
                <div className="buttons">
                    <div className="btn-wrapper centered">
                        <button className="btn-circle attention medium" onClick={(e) => this.props.removeItem(e, this.props.itemId)} ><DeleteIcon /></button>
                    </div>
                </div>

            </div>
        )
    }
}

  
export default ExpandCollapseForm;