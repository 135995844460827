import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './highlighted-text.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

// Import components
import RichtextEditor from '../../components/richtext-editor/richtext-editor.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class HighlightedTextEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            colors: [
                {label: 'Default (Light Gray)', value: 'whitesmoke', selected: false},
                {label: 'Amarelo', value: 'antiquewhite', selected: false},
                {label: 'Amarelo', value: 'cornsilk', selected: false},
                {label: 'Amarelo', value: 'aquamarine', selected: false},
                {label: 'Amarelo', value: 'darkseagreen', selected: false},
                {label: 'Amarelo', value: 'pink', selected: false},
            ],
            formItems: {
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: 'Texto',
                    value: this.props.text,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true
                    }
                },
                primaryLabel: {
                    id: 'primaryLabel',
                    label: 'Label Primária',
                    placeholder: 'Label',
                    value: this.props.primaryLabel,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        requiredIfFilled: {id: 'primaryLink', label: 'Link'},
                        maxLength: 25,
                    }
                },
                primaryLink: {
                    id: 'primaryLink',
                    label: 'Link Primário',
                    placeholder: 'Link',
                    value: this.props.primaryLink,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      requiredIfFilled: {id: 'primaryLabel', label: 'Label'},
                      link: true,
                      maxLength: 255,
                    }
                },
                secondaryLabel: {
                    id: 'secondaryLabel',
                    label: 'Label Secundária',
                    placeholder: 'Label',
                    value: this.props.secondaryLabel,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        requiredIfFilled: {id: 'secondaryLink', label: 'Link'},
                        maxLength: 25,
                    }
                },
                secondaryLink: {
                    id: 'secondaryLink',
                    label: 'Link Secundário',
                    placeholder: 'Link',
                    value: this.props.secondaryLink,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      requiredIfFilled: {id: 'secondaryLabel', label: 'Label'},
                      link: true,
                      maxLength: 255,
                    }
                },
            }
        }
    }

    componentDidMount(){

        const background = (this.props.bgcolor) ? this.props.bgcolor : 'whitesmoke';
        const updatedState = {...this.state}
            
        for(let i = 0; i < updatedState.colors.length; i++){
            updatedState.colors[i].selected = false;
            if(updatedState.colors[i].value === background){
                updatedState.colors[i].selected = true;
            }
        }

        this.setState(updatedState);

    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const handleSubmit = (event) => {

            event.preventDefault();
            let text = this.state.formItems.text.value;
            let primaryLabel = this.state.formItems.primaryLabel.value;
            let primaryLink = this.state.formItems.primaryLink.value;
            let secondaryLabel = this.state.formItems.secondaryLabel.value;
            let secondaryLink = this.state.formItems.secondaryLink.value;
            let backgroundColor = this.state.colors.filter((item) => item.selected)[0].value;
            
            console.dir(backgroundColor)

            const parameters = {
                text: text,
                primaryLabel: primaryLabel,
                primaryLink: primaryLink,
                secondaryLabel: secondaryLabel,
                secondaryLink: secondaryLink,
                bgcolor: backgroundColor
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const setPrimaryUrl = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.primaryLink.value = url;
            
    
            formItems.primaryLink.hasTouched = true;
            const validation = validate(url, formItems.primaryLink.rules, formItems);
            formItems.primaryLink.hasError = validation[0];
            formItems.primaryLink.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });
    
            this.props.closeDirectory();
    
        }

        const setSecondaryUrl = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.secondaryLink.value = url;
            
    
            formItems.secondaryLink.hasTouched = true;
            const validation = validate(url, formItems.secondaryLink.rules, formItems);
            formItems.secondaryLink.hasError = validation[0];
            formItems.secondaryLink.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });
    
            this.props.closeDirectory();
    
        }

        const handleEditor = (htmlValue) => {

            const formItems = this.state.formItems;
            formItems.text.value = htmlValue;

            this.setState({
                ...this.state,
                formItems: formItems
            });
        }

        const editorFunctions = {
            handleChange: handleEditor,
        }

        const submitIsEnabled = () => {
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            return disabled;

        }

        const selectColor = (color) => {

            const updatedState = {...this.state}
            for(let i = 0; i < updatedState.colors.length; i++){
                updatedState.colors[i].selected = false;
                if(updatedState.colors[i].value === color){
                    updatedState.colors[i].selected = true;
                }
            }

            this.setState(updatedState);
            

        }
        
        return(
            <form className='highlighted-text-edit-mode'>
                 <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <RichtextEditor editor={this.props.contentId} content={this.props.text} functions={editorFunctions} hasError={this.state.formItems.text.hasError} errorMsg={this.state.formItems.text.errorMsg} />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            this.state.colors.map((color, index) => {

                                let classes = 'background-color';
                                if(color.selected){
                                    classes += ' selected';
                                }

                                return(
                                    <div 
                                        key={`bgcolor-${index}`} 
                                        className={classes} 
                                        style={{backgroundColor: color.value}} 
                                        onClick={() => selectColor(color.value)}
                                    />
                                )
                            })
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        name={this.state.formItems.primaryLabel.id}
                        id={this.state.formItems.primaryLabel.id}
                        value={this.state.formItems.primaryLabel.value}
                        label={this.state.formItems.primaryLabel.label}
                        placeholder={this.state.formItems.primaryLabel.placeholder}
                        error={this.state.formItems.primaryLabel.hasError}
                        helperText={this.state.formItems.primaryLabel.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        name={this.state.formItems.primaryLink.id}
                        id={this.state.formItems.primaryLink.id}
                        value={this.state.formItems.primaryLink.value}
                        label={this.state.formItems.primaryLink.label}
                        placeholder={this.state.formItems.primaryLink.placeholder}
                        error={this.state.formItems.primaryLink.hasError}
                        helperText={this.state.formItems.primaryLink.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                aria-label="Procurar conteúdo"
                                edge="end"
                                onClick={() => this.props.openDirectory(1, setPrimaryUrl)}
                                >
                                <DescriptionIcon /> 
                                </IconButton>
                            </InputAdornment>,
                        }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        name={this.state.formItems.secondaryLabel.id}
                        id={this.state.formItems.secondaryLabel.id}
                        value={this.state.formItems.secondaryLabel.value}
                        label={this.state.formItems.secondaryLabel.label}
                        placeholder={this.state.formItems.secondaryLabel.placeholder}
                        error={this.state.formItems.secondaryLabel.hasError}
                        helperText={this.state.formItems.secondaryLabel.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                        name={this.state.formItems.secondaryLink.id}
                        id={this.state.formItems.secondaryLink.id}
                        value={this.state.formItems.secondaryLink.value}
                        label={this.state.formItems.secondaryLink.label}
                        placeholder={this.state.formItems.secondaryLink.placeholder}
                        error={this.state.formItems.secondaryLink.hasError}
                        helperText={this.state.formItems.secondaryLink.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                aria-label="Procurar conteúdo"
                                edge="end"
                                onClick={() => this.props.openDirectory(1, setSecondaryUrl)}
                                >
                                <DescriptionIcon /> 
                                </IconButton>
                            </InputAdornment>,
                        }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={submitIsEnabled()}
                            >Atualizar</Button>
                        </div>
                    </Grid>
                 </Grid>
            </form>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
});
  
export default withRouter(connect(null, mapDispatchToProps)(HighlightedTextEditMode));