import React from 'react';
import './posts.styles.scss';
import StarsIcon from '@material-ui/icons/Stars';

const Post = (props) =>{

    const formatDate = (date, withHour) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' às ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }

    const goto = (pageUrl) => {
        window.location = '/page/' + pageUrl;
    }

    const date = (props.published !== null && props.published !== '' && props.published !== undefined) ? props.published : props.created;

    const background = (props.thumb > 0) ? 'url("' + window.$thumbsPath + props.thumbSrc + '")' : 'none.jpg';

    return(
    <a className='post' href={'/page/' + props.url}>
        <div className="body" style={{backgroundImage: background}}>
            {(props.premium === 1)? (<div className="label"><span><StarsIcon />Premium</span></div>) : null}
            <div className='info'>
                <div className='date'>{formatDate(date, true)}</div>
                <div className='title'>{props.title}</div>
            </div>
        </div>
    </a>
    );

}

export default Post;