import React from 'react';
import './references.styles.scss';
import Headings from '../headings/heading.view.component';

const ReferencesViewMode = (props) =>{

    const previousThumb = (props.previous.thumb) ? 'url("' + window.$thumbsPath + props.previous.thumbSrc + '")' : 'url("' + window.$thumbsPath + 'none.jpg")';
    const nextThumb = (props.next.thumb) ? 'url("' + window.$thumbsPath + props.next.thumbSrc + '")' : 'url("' + window.$thumbsPath + 'none.jpg")';

    const goTo = (link) => {
        window.location = link
    }
    
    return(
        <div className='module-references'>

            <Headings type={3} title="Veja também"  />

            <div className="references">

                <div className="card previous-post" onClick={() => goTo(props.previous.url)}>
                    <div className="item">
                        <div className="thumb" style={{backgroundImage: previousThumb}}></div>
                        <div className="info">
                            <div className="category"><span>{props.previous.category}</span></div>
                            <div className="title">{props.previous.title}</div>
                        </div>   
                    </div>
                </div>

                <div className="card next-post" onClick={() => goTo(props.next.url)}>
                    <div className="item">
                        <div className="thumb" style={{backgroundImage: nextThumb}}></div>
                        <div className="info">
                            <div className="category"><span>{props.next.category}</span></div>
                            <div className="title">{props.next.title}</div>
                        </div>   
                    </div>
                </div>

            </div>
            
        </div>
    )
}
  
export default ReferencesViewMode;