import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import './main-menu.styles.scss';

const MainMenu = (props) => {

    const MenuId = 6;
    
    const MenuItems = props.menus.menuItems.filter(
        menuObj => parseInt(menuObj.placeholder) === parseInt(MenuId)
    );

    return(
        <div className="main-menu">
            <div className="menu">
                {
                    MenuItems.map((item) => {
                        let menuState = (props.location.pathname === item.url) ? 'menu-item menu-active' : 'menu-item';
                        let menuKey = 'menu-' + item.id;

                        return (
                            <Link key={menuKey} className={menuState} to={item.url}>{item.label}</Link> 
                        );
                    })
                }
            </div>
        </div>
    );
}
export default withRouter(MainMenu);