import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './partners-schedule.styles.scss';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import {CircularProgress} from '@material-ui/core';

// Import Components
import Toolbar from './toolbar';
import CalendarList from '../../components/calendar-list/calendar-list.component';
import CalendarDay from '../../components/calendar-day/calendar-day.component';
import CalendarWeek from '../../components/calendar-week/calendar-week.component';
import CalendarMonth from '../../components/calendar-month/calendar-month.component';
import CalendarConfig from '../../components/calendar-config/calendar-config.component';
import CalendarEvent from '../../components/calendar-event/calendar-event.component';
import HeadingViewMode from '../../modules/headings/heading.view.component';

// Import Actions
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
import {lockScrollbar} from '../../utils/utils';


 
class PartnersSchedule extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.token = getToken();
    this.pastCheck = setInterval(this.setEventsOnThePast, 1800000);

    this.state = {
      loading: true,
      view: 'list',
      showSlotsForm: false,
      showEmpty: false,
      showPast: false,
      current: null,
      month: null,
      year: null,
      weekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      weekdaysFull: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      days: [],
      monthStartsOnWeekday: 0,
      lastDay: 0,
      weekPage: 0,
      weekPages: 0,
      slots: [],
      now: new Date(),
      today: null,
      timeline: [],
      showSlotCard: false,
      selectedSlot: []
    }
  };
    

  // Component MOUNT  
  componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        // Build time
        let newTimeline = [];
        for(let i=0; i<24; i++){
            let item = {
                time: i + ':00',
                hour: i
            }
            newTimeline.push(item);
        }

        // Get today 
        const tdy = new Date();
        const todayDate = {
            d: tdy.getDate(),
            m: tdy.getMonth(),
            y: tdy.getFullYear(),
            w: tdy.getDay(),
            dt: tdy
        };

        // Set initial month and year
        const month = todayDate.m + 1;
        const year = todayDate.y;

        // Set daysConstructor as callback state update
        const callback = () => {
          this.fetchSlots(month, year, 'current');
        }

        // Update State
        this.setState({loading: true, current: todayDate, today: todayDate, timeline: newTimeline}, callback);
      
    }

    // Component UNMOUNT
    componentWillUnmount(){
      this.pastCheck = null;
    }

    // Calculate days and weeks of a month
    daysConstructor = (month, year, initialPage) => {

        const monthBegin = new Date(year, month - 1, 1);
        let monthFinish = new Date(year, month - 1, 1);
        monthFinish.setMonth( monthFinish.getMonth() + 1 );
        monthFinish.setDate(monthFinish.getDate() - 1);
        const lastDay = monthFinish.getDate();
        const firstWeekDay = monthBegin.getDay();
        const lastWeekDay = monthFinish.getDay();
        const slots = [];
        
        // Fill month's first week with previous month's days when appiccable
        for(let i=0; i<firstWeekDay; i++){
            
            let dt = new Date(year, month - 1, 1);
            let subIdx = firstWeekDay - i;
            dt.setDate(dt.getDate() - subIdx);
            let prevDate = dt;
            
            let obj = {
                day: prevDate.getDate(),
                month: prevDate.getMonth(),
                year: prevDate.getFullYear(),
                date: prevDate,
                weekday: prevDate.getDay(),
                outOfMonth: true
            }
            slots.push(obj);
        }

        // Fill month with its days
        for(let i=0; i<lastDay; i++){

            let d = i + 1;
            let dt = new Date(year, month -1, d);
            let m = dt.getMonth();
            let y = dt.getFullYear();
            let w = dt.getDay();
            let obj = {
                day: d,
                month: m,
                year: y,
                date: dt,
                weekday: w,
                outOfMonth: false
            }
            slots.push(obj);
        }

        // Fill month's last week with next month's days when appicable
        let toEnd = 6 - lastWeekDay;
        let refDay = monthFinish.getDate();
        let refMonth = monthFinish.getMonth();
        let refYear = monthFinish.getFullYear();
        for(let i=0; i<toEnd; i++){
            
            let dt = new Date(refYear, refMonth, refDay);;
            let subIdx = i + 1;
            dt.setDate(dt.getDate() + subIdx);
            let nextDate = dt;
            
            let obj = {
                day: nextDate.getDate(),
                month: nextDate.getMonth(),
                year: nextDate.getFullYear(),
                date: nextDate,
                weekday: nextDate.getDay(),
                outOfMonth: true
            }
            slots.push(obj);
        }

        // Calculate needed week pages
        let pg = 1;
        let iCount = 0;
        let days = [];
        slots.forEach((item) => {

            let obj = {...item, page: pg};
            days.push(obj);

            if(iCount === 6){
                iCount = 0;
                pg = pg + 1;
            }else{
                iCount++;
            }

        });
        let totalPages = pg - 1;

        // Set initial week page according to initialPage parameter
        let currentPage = 1;
        if(initialPage && initialPage === 'current'){
            currentPage = this.getCurrentDayPage(days, this.state.current);
        }
        if(initialPage && initialPage === 'last'){
            currentPage = totalPages;
        }
        if(initialPage && initialPage !== 'last' && initialPage !== 'current'){
          currentPage = initialPage;
        }

        // Update current day when weekpage is restarted
        let currentDay = this.state.current;

        if(currentPage === 1 && !initialPage){
          let currentDate = new Date(year, month - 1, 1);
          currentDay = {
            d: 1,
            m: month - 1,
            y: year,
            dt: currentDate,
            w: currentDate.getDay()
          }
        }

        // Prepare object to return
        const constructor = {
          days: days,
          lastDay: lastDay,
          monthStartsOnWeekday: firstWeekDay,
          weekPage: currentPage,
          weekPages: totalPages,
          current: currentDay,
        }
       
        return constructor;
       
    }

    // Get schedule slots of a month
    fetchSlots = (month, year, initial) => {

      // Construct days structure of the month
      const daysConstructor = this.daysConstructor(month, year, initial);

      const days = daysConstructor.days;
      const lastDay = daysConstructor.lastDay;
      const monthStartsOnWeekday = daysConstructor.monthStartsOnWeekday;
      const weekPage = daysConstructor.weekPage;
      const weekPages = daysConstructor.weekPages;
      const currentDay = daysConstructor.current;

      // Build the formdata
      const startDate = days[0].year + '-' + (days[0].month + 1) + '-' + days[0].day;
      const endDate = days[days.length - 1].year + '-' + (days[days.length - 1].month + 1) + '-' + days[days.length - 1].day;
      const userToken = getToken();
      const formData = {
          userToken: userToken,
          app: window.$appToken,
          start: startDate,
          end: endDate,
          month: month,
          year: year
      }

      // Build request header parameters
      const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify(formData)
      };

      // Call the API
      fetch("/api/slots_get.php", requestOptions)
      .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          
          // Check if user is still authenticated
          authAction(data.authenticated);

          if(data.success){

            const updatedSlots = data.slots.map((item) => {

                let today = new Date();
              
                let date = new Date(item.year, item.month - 1, item.day, item.hour, item.minutes);
                item.date = date;

                let end =  new Date(item.year, item.month - 1, item.day, item.hour, item.minutes);
                end.setMinutes( end.getMinutes() + item.duration );
                item.start = date;
                item.end = end;

                let time = item.startTime + ' - ' + item.endTime;
                item.time = time;

                let past = (date < today);
                item.past = past;

                return(item);


            });

            // Update State
            this.setState({
              loading: false,
              slots: updatedSlots,
              month: month,
              year: year,
              lastDay: lastDay,
              monthStartsOnWeekday: monthStartsOnWeekday,
              days: days,
              weekPage: weekPage,
              weekPages: weekPages,
              current: currentDay,
            });

          }
      })

    }

    // Toggle [Show Past Events] and [Show Free Slots] Switches
    changeShowOptions = (event) => {
      this.setState({[event.target.name]: event.target.checked });
    };

    // Get week page of a date
    getCurrentDayPage = (days, current) => {
        
      let page = 1;
      
      days.forEach((item) => {
          
          if(item.day === current.d && item.month === current.m && item.year === current.y){
              page = item.page;
          }

      });
      
      
      return page;
    }

    // Set past events (it's used with the setInterval)
    setEventsOnThePast = () => {

      const currentDate = new Date();
      const {slots} = this.state;
      const updatedSlots = slots;

      if(slots && slots.length > 0){

        let counter = 0;
        updatedSlots.forEach((item, index) => {
            if(item.date < currentDate && !item.past){
              counter++;
              item.past = true;
            }
        })

        if(counter > 0){
          this.setState({slots: updatedSlots, now: currentDate});
        }else{
          this.setState({now: currentDate});
        }

      }
      
    }

    // Change view between [list], [day], [week] and [month]
    toggleView = (view) => {
      this.setState({view: view});
    }

    // Open or Close the form to slots creation
    toggleSlotsForm = (stts, shouldFetch) => {

      if(stts){
        lockScrollbar('add');
      }else{
        lockScrollbar('remove');
      }

      if(shouldFetch){

        const callback = () => {
          this.fetchSlots(this.state.month, this.state.year, 'current');
        }
        this.setState({showSlotsForm: stts}, callback);

      }else{
        this.setState({showSlotsForm: stts});
      }
      
    }

    // Set a date as current date
    setCurrentDay = (obj) => {
      const pg = this.getCurrentDayPage(this.state.days, obj);
      this.setState({current: obj, view: 'day', weekPage: pg});
    }

    // Go to next month
    nextMonth = (where) => {
      const { month, year } = this.state;
      let nMonth;
      let nYear;
      if(month < 12){
        nMonth = month + 1;
        nYear = year;
      }else{
        nMonth = 1;
        nYear = year + 1;
      }

      this.setState({loading: true}, () => this.fetchSlots(nMonth, nYear, where));
      
    }

    // Go to previous month
    prevMonth = (where) => {
      const { month, year } = this.state;
      let nMonth;
      let nYear;
      if(month > 1){
        nMonth = month - 1;
        nYear = year;
      }else{
        nMonth = 12;
        nYear = year - 1;
      }

      this.setState({loading: true}, () => this.fetchSlots(nMonth, nYear, where));
    }

    // Go to previous day
    prevDay = () => {

      const {current} = this.state;
      let currentDate = current.dt;
      currentDate.setDate(currentDate.getDate() - 1);

      const updatedCurrent = {
        d: currentDate.getDate(),
        m: currentDate.getMonth(),
        y: currentDate.getFullYear(),
        w: currentDate.getDay(),
        dt: currentDate
      }

      const month = updatedCurrent.m + 1;
      const year = updatedCurrent.y;
      const callback = () => {
        this.fetchSlots(month, year, 'current');
      }

      if(month !== this.state.month){
        this.setState({loading: true, current: updatedCurrent}, callback);
      }else{
        this.setState({current: updatedCurrent});
      }
    }

    // Go to next day
    nextDay = () => {
      
      const {current} = this.state;
      let currentDate = current.dt;
      currentDate.setDate(currentDate.getDate() + 1);

      const updatedCurrent = {
        d: currentDate.getDate(),
        m: currentDate.getMonth(),
        y: currentDate.getFullYear(),
        w: currentDate.getDay(),
        dt: currentDate
      }

      const month = updatedCurrent.m + 1;
      const year = updatedCurrent.y;
      const callback = () => {
        this.fetchSlots(month, year, 'current');
      }

      if(month !== this.state.month){
        this.setState({loading: true, current: updatedCurrent}, callback);
      }else{
        this.setState({current: updatedCurrent});
      }

    }

    // Go to today's date
    gotoToday = () => {

      const today = new Date();

      const updatedCurrent = {
        d: today.getDate(),
        m: today.getMonth(),
        y: today.getFullYear(),
        w: today.getDay(),
        dt: today
      }

      const month = updatedCurrent.m + 1;
      const year = updatedCurrent.y;
      const callback = () => {
        if(month !== this.state.month){
        this.fetchSlots(month, year, 'current');
        }
        return;
      }

      this.setState({current: updatedCurrent}, callback);
     
    }

    // Go to a given week page
    gotoWeekPage = (page) => {
      this.setState({weekPage: page});
    }

    // Open an event card
    openSlotCard = (id) => {

      const { slots } = this.state;
      const selected = slots.filter((item) => (item.id === id));
      this.setState({selectedSlot: selected[0], showSlotCard: true}, () => lockScrollbar('add'));

    }

    // Close event card
    closeSlotCard = (shouldUpdate) => {

      let callback = () => {
        lockScrollbar('remove');
        if(shouldUpdate){
          this.fetchSlots(this.state.month, this.state.year, 'current');
        }
      }

      this.setState({showSlotCard: false, selectedSlot: []}, callback);

    }

  render(props){
    
    const {current, today} = this.state;
  
    // Set if the selected day is today's date
    const DayIsToday = (current && today && (current.d === today.d && current.m === today.m && current.y === today.y));

    if(this.state.loading){
      return(
        <div className="page">
          <div className='loading'>
            <CircularProgress size={72} />
          </div>
        </div>
        )
    }

  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content partners-schedule">
      
        
          { /* PAGE TITTLE */ }
          <HeadingViewMode type={1} title="Agenda" />

          {/* TOOLBAR */}
          <div className="container">
            <Toolbar 
              month = {this.state.month}
              year = {this.state.year}
              monthsList = {this.state.months}
              dayIsToday = {DayIsToday}
              view = {this.state.view}
              createSlotsFormIsVisible = {this.state.showSlotsForm}
              actions = {{
                previousMonth: this.prevMonth,
                nextMonth: this.nextMonth,
                goToToday: this.gotoToday,
                createSlots: this.toggleSlotsForm,
                toggleView: this.toggleView
              }}
            />
          </div>
          

          { /* FORM PERSONAL DATA*/ }
          <div className="container">
            
          <Grid container spacing={2}>

              <Grid item xs={12}>
                { 
                  (this.state.loading) ?
                    <div className="calendar-week">
                        <div className="loader">
                            <CircularProgress size={48}/>
                        </div>
                    </div>:
                  (this.state.view === 'month') ?
                  <CalendarMonth 
                    month={this.state.month} 
                    year={this.state.year} 
                    slots={this.state.slots} 
                    days={this.state.days}
                    today={this.state.today}
                    weekdays={this.state.weekdays} 
                    months={this.state.months}
                    setDay={this.setCurrentDay}
                    openEvent = {this.openSlotCard}
                  /> :
                  (this.state.view === 'week') ?
                  <CalendarWeek  
                    month={this.state.month} 
                    year={this.state.year} 
                    slots={this.state.slots} 
                    days={this.state.days}
                    page ={this.state.weekPage}
                    pages ={this.state.weekPages}
                    weekdays={this.state.weekdays} 
                    months={this.state.months} 
                    now={this.state.now}
                    today={this.state.today}
                    current={this.state.current}
                    nextMonth = {this.nextMonth}
                    prevMonth = {this.prevMonth}
                    gotoPage = {this.gotoWeekPage}
                    timeline = {this.state.timeline}
                    openEvent = {this.openSlotCard}
                  /> :
                  (this.state.view === 'day')?
                  <CalendarDay 
                    days={this.state.days} 
                    slots={this.state.slots} 
                    today={this.state.current} 
                    weekdays={this.state.weekdaysFull} 
                    months={this.state.months} 
                    timeline = {this.state.timeline}
                    now={this.state.now}
                    prev={this.prevDay}
                    next={this.nextDay}
                    openEvent = {this.openSlotCard}
                  />:
                  (this.state.view === 'list')?
                  <CalendarList  
                    month={this.state.month} 
                    year={this.state.year} 
                    slots={this.state.slots} 
                    weekdays={this.state.weekdaysFull} 
                    months={this.state.months} 
                    days={this.state.days}
                    now={this.state.now}
                    showEmpty = {this.state.showEmpty}
                    showPast = {this.state.showPast}
                    changeOption={this.changeShowOptions}
                    openEvent = {this.openSlotCard}
                  />:
                  null
                  
                }
              </Grid>

              
          </Grid>
          </div>
    
      </div>
      {
        (this.state.showSlotsForm)?
        <Fade in={this.state.showSlotsForm}>
          <CalendarConfig 
            toggle={this.toggleSlotsForm}
          />
        </Fade>:
        null
      }

      {
        (this.state.showSlotCard)?
        <Fade in={this.state.showSlotCard}>
          <CalendarEvent 
            slot = {this.state.selectedSlot.id}
            close = {this.closeSlotCard}
            weekdays = {this.state.weekdays}
            months = {this.state.months}
          />
        </Fade>:
        null
      }
    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(PartnersSchedule));