import React, {Component} from 'react';
import './testimonials.styles.scss';



class TestimonialsViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            items: (this.props.items) ? this.props.items : []
        }
    }

    componentDidMount(){


    }

    render(props){

        const hasGrayBackground = (parseInt(this.props.bg) === 1) ? ' gray-mode' : '';

        const moduleClasses = 'module-testimonials' + hasGrayBackground;

        return(
            <div className={moduleClasses}>
                <div className="items">
                    {
                        this.state.items.map((item, index) => {

                            let testimonialId = 'testimonial-' + this.props.contentId + '-' + index;
                            let avatarImg = (item.avatar !== '') ? 'url("' + window.$imagesPath + item.avatar + '")' : 'none';
                            
                            let strJunction = '';
                            let strCompany = '';

                            if(item.job !== '' && item.company !== ''){
                                strJunction = <span>&nbsp;em&nbsp;</span>;
                            }

                            if(item.company !== '' && item.companyLink === ''){
                                strCompany = item.company;
                            }else if(item.company !== '' && item.companyLink !== ''){
                                strCompany = <a href={item.companyLink} target='_blank' rel='noopener noreferrer'>{item.company}</a>;
                            }

                            return(
                                <div key={testimonialId} className="item">

                                    { /* Avatar */ }
                                    <div className={(item.avatar !== '') ? 'avatar' : 'avatar hidden'} style={{backgroundImage: avatarImg}} ></div>

                                    { /* Text */ }
                                    <div className="text">{item.text}</div>

                                    { /* Author */ }
                                    <div className="author">{item.author}</div>

                                    { /* Signature */ }
                                    <div className={(item.company !== '' || item.job !== '') ? 'signature' : 'signature hidden'}>
                                        <div className={(item.job !== '') ? 'job' : 'job hidden'}>{item.job}</div>
                                        <div className={(item.company !== '') ? 'company' : 'company hidden'}>{strJunction}{strCompany}</div>
                                    </div>

                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

}

export default TestimonialsViewMode;