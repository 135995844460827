import React, {Component} from "react";
import { connect } from "react-redux";
import moment from 'moment';
import './calendar-event.styles.scss';

// Import UI components
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";

// Import Icons
import CloseIcon from "@material-ui/icons/Close";

// Import Components
import View from './view.component';
import Reschedule from './reschedule.component';
import Delete from './delete.component';
import AddNote from './add-note.component';
import SetLink from './set-link.component';
import {CircularProgress} from '@material-ui/core';

// Import Actions
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
import { openModal } from '../../redux/modal/modal.actions';


class CalendarEvent extends Component{

  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      loading: true,
      slotId: 0,
      slot: [],
      view: 'view' 
    };
  }

  componentDidMount(){
    this.setState({loaded: true, slotId: this.props.slot}, this.fetchSlotInfo);
  }

  toggleView = (view) => {
    this.setState({view: view});
  }

  fetchSlotInfo = () => {

      // Build form data
      const userToken = getToken();
      const formData = {
          userToken: userToken,
          app: window.$appToken,
          slot: this.state.slotId
      }

      // Build request header parameters
      const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify(formData)
      };

    // Call the API
    fetch("/api/slots_get_info.php", requestOptions)
    .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        
        // Check if user is still authenticated
        authAction(data.authenticated);

        if(data.success){

          console.dir(data);
          this.setState({loading: false, slot: data.obj});

        }
    })


  }

  updateInfo = () => {
    this.setState({loading: true, view: 'view'}, this.fetchSlotInfo);
  }

  render(){

    const { months, weekdays } = this.props;
    const { slot } = this.state;
    const slotFullDate = weekdays[slot.weekday] + ', ' + slot.day + ' de ' + months[(slot.month - 1)] + ' de ' + slot.year;

    const userBox = (user) => {

      if(user){

        // Calculate Age
        let age = '';
        if(user.BirthDate){
          let duration = moment().diff(user.BirthDate, 'years');
          age = '(' + duration + ' anos)';
        }

        // Check if it is the first time
        let first = null;
        if(parseInt(user.Total) === 1){
          first = <span className="tag">Primeira Vez</span>;
        }

        const avatarImg = (user.Avatar) ? 'url("' + window.$usersPath + user.Avatar + '")' : 'url("' + window.$usersPath +  '0.jpg")';
        

        return(
          <div className="user-box">
                <div className="avatar" style={{backgroundImage: avatarImg}}></div>
                <div className="user">
                    <span className="screen-name">{user.FullName}<span className="age">{age}</span>{first}</span>
                    <a href={'mailto:' + user.Email}>{user.Email}</a>
                    {(user.Phone) ? <span className="phone"><span className="material-icons">phone</span>{user.Phone}</span> : null}
                    {(user.Mobile) ? <span className="phone"><span className="material-icons">phone_iphone</span>{user.Mobile}</span>: null}
                </div>
            </div>
        );

      }else{

        return(
          <div className="free-box">Horário Disponível</div>
        );

      }

    }


    return (
      <div className={(this.state.loaded) ? 'calendar-event loaded' : 'calendar-event'}>
        <div className="mask" onClick={this.props.close}></div>
        <Slide direction="down" in={this.state.loaded} mountOnEnter unmountOnExit>
          <div className="form">
            <div className="form-body">
            {
              (this.state.loading)?
              <div className="loading">
                <CircularProgress size={48} />
              </div>:
              <>
            <Grid container spacing={2}>    
                
                <Grid item xs={6}>
                  <span className="slot-date">{slotFullDate}</span>
                  <span className="slot-time">{slot.time}</span>
                </Grid>
                <Grid item xs={6} className="right">

                <button className="close-button" onClick={() => this.props.close(false)}>
                <CloseIcon />
                </button>
                  
                </Grid>

            </Grid>

            {userBox(slot.user)}

            {
            (this.state.view === 'view')?
            <View slot={slot} toggleView={this.toggleView} closeCard={this.props.close} />:
            (this.state.view === 'reschedule')?
            <Reschedule slot={slot} toggleView={this.toggleView} update={this.updateInfo} months={this.props.months} closeCard={this.props.close} />:
            (this.state.view === 'conference')?
            <SetLink slot={slot} toggleView={this.toggleView} update={this.updateInfo} />:
            (this.state.view === 'delete')?
            <Delete slot={slot} toggleView={this.toggleView} update={this.updateInfo} />:
            (this.state.view === 'note')?
            <AddNote slot={slot} toggleView={this.toggleView} update={this.updateInfo} />:
            null
            }
            </>
          }
            </div>
          </div>
        </Slide>
      </div>
    );

  }

};

const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData))
});

export default connect(null, mapDispatchToProps)(CalendarEvent);
