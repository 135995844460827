import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './gift.styles.scss';

// Import UI component
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

// Import components
import RichtextEditor from '../../components/richtext-editor/richtext-editor.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';

// Import Utils
import { getToken } from '../../utils/user-validation/user-validation.utils';
import validate from '../../utils/form-validation/form-validation.utils';


class GiftEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();

        this.state = {
            loading: true,
            attachment: {
                uploading: false,
                file: null,
                fileId: 0,
                name: null,
                extension: null,
                hasError: false,
                errorMsg: '' 
            },
            formItems: {
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: 'Texto',
                    value: this.props.text,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true
                    }
                },
                tag: {
                    id: 'tag',
                    label: 'Tag',
                    placeholder: 'Identificador',
                    value: (this.props.tag) ? this.props.tag : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                successPage: {
                    id: 'successPage',
                    label: 'Página de Sucesso',
                    placeholder: 'Nenhuma',
                    value: (this.props.successPage) ? this.props.successPage : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        link: true,
                        maxLength: 255
                    }
                },
            },
        }
    }
    

    // Component Did Mount
    componentDidMount(){

        if(this.props.file > 0){

            const attachmentObj = this.state.attachment;
            attachmentObj.uploading = false;
            attachmentObj.file = this.props.fileUrl;
            attachmentObj.fileId = this.props.file;
            attachmentObj.name = this.props.fileTitle;
            attachmentObj.extension = null;
            attachmentObj.hasError = false;
            attachmentObj.errorMsg = '';
            
            this.setState({attachment: attachmentObj, loading: false});

        }

        
       
    }

    render(props){

        // Get User Data
        const getUserData = () => {
            let userData = [];
            if(localStorage.getItem('abricco')){
                userData = JSON.parse(localStorage.getItem('abricco'));
            }else{
                userData = {
                id: 0,
                name: '',
                token: '',
                type: 0
                };
            }
    
            return userData;
        }

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event, callbacks) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = {
                ...this.state.formItems
            };

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;

            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            if(callbacks){
            callbacks.map(item => {
                return item(event);
            })
            }
        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMS: FORM SUBMIT FUNCTION
        const handleSubmit = (event) => {

            event.preventDefault();
            let file = this.state.attachment.fileId;
            let fileTitle = this.state.attachment.name;
            let fileUrl = this.state.attachment.file;
            let text = this.state.formItems.text.value;
            let tag = this.state.formItems.tag.value;
            let successPage = this.state.formItems.successPage.value;
            let author = getUserData();

            const parameters = {
                file: file,
                fileUrl: fileUrl,
                fileTitle: fileTitle,
                authorId: parseInt(author.id),
                tag: tag,
                successPage: successPage,
                text: text
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const labelFileButton = (this.state.attachment.fileId > 0) ? 'Alterar Arquivo' : 'Anexar Arquivo';

        const handleEditor = (htmlValue) => {

            const formItems = this.state.formItems;
            formItems.text.value = htmlValue;

            this.setState({
                ...this.state,
                formItems: formItems
            });
        }

        const editorFunctions = {
            handleChange: handleEditor,
        }

        // FILE MANIPULATION ******************************************************
        // Allowed File Size
        const allowedFileSize = {
            handler: 2000000,
            label: '2Mb'
        };
  
        // Get display site
        const displayFileSize = (size) => {
    
            let fileSize;
    
            if (size>=1073741824){
            fileSize = Math.round(size/1073741824) + 'Gb';
            }else if(size>=1048576){
            fileSize = Math.round(size/1048576) + 'Mb';
            }else if(size>=1024){
            fileSize = Math.round(size/1024) + 'Kb';
            }else{
            fileSize = size + ' bytes';
            }
    
            return fileSize;
            
        }
    
        // Allowed File Types Array
        const allowedFileTypes = {
            types: [
            'pdf',
            'doc',
            'docx',
            'xls',
            'xlsx',
            'ppt',
            'pptx',
            'pps',
            'ppsx'
            ],
            label: 'PDF, DOC, XLS, PPT ou PPS'
        };

        // Get File Extension
        const getExtension = (filename) => {
            let tam = filename.length;
            let pos1 = filename.lastIndexOf('.') + 1;
            let dif = tam - pos1;
            let ext = filename.substr(pos1, dif);
            return ext.toLowerCase();
        }

        const selectFileHandler = (e) => {

            const attachmentObj = this.state.attachment;
            attachmentObj.uploading = true;

            this.setState({
                attachment: attachmentObj
            });
      
            let selectedFile = e.target.files[0];
            let selectedFileExt = getExtension(selectedFile.name);
            let error = false;
            let errorMsg = '';

            // Validation Rules
            if(selectedFile){

                if(allowedFileTypes.types.indexOf(selectedFileExt) === -1){
                    error = true;
                    errorMsg = 'Arquivos ' + selectedFileExt.toUpperCase() + ' não são permitidos. Utilize apenas arquivos do tipo ' + allowedFileTypes.label;
                }

                if(selectedFile.size > allowedFileSize.handler){
                    error = true;
                    errorMsg = 'O tamanho da arquivo (' + displayFileSize(selectedFile.size) + ') ultrapassa o limite máximo de ' + allowedFileSize.label;
                }

                if(error){
                    
                    attachmentObj.uploading = false;
                    attachmentObj.file = null;
                    attachmentObj.fileId = 0;
                    attachmentObj.name = null;
                    attachmentObj.extension = null;
                    attachmentObj.hasError = true;
                    attachmentObj.errorMsg = errorMsg;

                    this.setState({
                        attachment: attachmentObj,
                    });

                }else{

                    // Call upload service
                    const formData  = new FormData();
                    formData.append('file', e.target.files[0]);
                    formData.append('userToken', getToken());
                    formData.append('app', window.$appToken);

                    fetch('/api/file_upload.php', {
                        method: 'POST',
                        body: formData
                    })
                    .then(async (response) => {
                        
                        const data = await response.json();
                        this.fileInput.current.value= '';
            
                        if(data.success){

                            attachmentObj.uploading = false;
                            attachmentObj.file = data.fileUploaded;
                            attachmentObj.fileId = data.fileId;
                            attachmentObj.name = selectedFile.name;
                            attachmentObj.extension = selectedFileExt;
                            attachmentObj.hasError = false;
                            attachmentObj.errorMsg = '';

                        }else{

                            attachmentObj.uploading = false;
                            attachmentObj.file = null;
                            attachmentObj.fileId = 0;
                            attachmentObj.name = null;
                            attachmentObj.extension = null;
                            attachmentObj.hasError = true;
                            attachmentObj.errorMsg = 'Houve uma falha ao salvar o anexo';

                        }

                        this.setState({
                            attachment: attachmentObj,
                        });
            
                    })
                    .catch((error) => {
                        console.dir(error);
                    });

                }

            }
        
        }

        const renderAttachmentLabel = () => {

            if(this.state.attachment.uploading){
                return(<span>Anexando...</span>);
            }

            if(this.state.attachment.hasError){
                return(<span className="error">{this.state.attachment.errorMsg}</span>)
            }

            if(this.state.attachment.fileId > 0){
                let link = window.$drivePath + this.state.attachment.file;
                return(<a href={link} target='_blank' rel='noopener noreferrer'>{this.state.attachment.name}</a>);
            }
            
            return(<span>Nenhum anexo selecionado</span>);

        }

        const setSuccessPage = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.successPage.value = url;
            
    
            formItems.successPage.hasTouched = true;
            const validation = validate(url, formItems.successPage.rules, formItems);
            formItems.successPage.hasError = validation[0];
            formItems.successPage.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });
    
            this.props.closeDirectory();
    
        }


        return(
            <form className='gift-edit-mode'>
                <div className="module-title-bar">Módulo Gift : {this.props.contentId}</div>
                 <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="tag"
                            id={this.state.formItems.tag.id}
                            value={this.state.formItems.tag.value}
                            label={this.state.formItems.tag.label}
                            placeholder={this.state.formItems.tag.placeholder}
                            error={this.state.formItems.tag.hasError}
                            helperText={this.state.formItems.tag.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                                <TextField
                                name={this.state.formItems.successPage.id}
                                id={this.state.formItems.successPage.id}
                                value={this.state.formItems.successPage.value}
                                label={this.state.formItems.successPage.label}
                                placeholder={this.state.formItems.successPage.placeholder}
                                error={this.state.formItems.successPage.hasError}
                                helperText={this.state.formItems.successPage.errorMsg}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        aria-label="Procurar conteúdo"
                                        edge="end"
                                        onClick={() => this.props.openDirectory(1, setSuccessPage)}
                                        >
                                        <DescriptionIcon /> 
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                />
                            </Grid>

                    <Grid item xs={12}>
                        <RichtextEditor 
                            editor={'editor-' + this.props.contentId} 
                            content={this.props.text} 
                            functions={editorFunctions} 
                            hasError={this.state.formItems.text.hasError} 
                            errorMsg={this.state.formItems.text.errorMsg} 
                        />
                    </Grid>

                    

                    <Grid item xs={12}>
                        <div className="attachment-area">
                            <Button
                                variant="outlined"
                                size="small"
                                component="label"
                                disableElevation
                                color="default"
                                disabled={this.state.attachment.uploading}
                                endIcon={(this.state.attachment.uploading) ? <CircularProgress size={16} /> : <AttachFileIcon />}
                                >
                                
                                    {labelFileButton}
                                    <input
                                        id={'selectedFile' + this.props.contentId}
                                        name={'selectedFile' + this.props.contentId}
                                        ref={this.fileInput}
                                        type="file"
                                        onChange={(e) => selectFileHandler(e)}
                                        accept={allowedFileTypes}
                                        tabIndex="-1"
                                        hidden
                                    />
                                    
                            </Button>
                            <div className="attachment-label">
                                {renderAttachmentLabel()}
                            </div>
                        </div>
                    </Grid>
                    

                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            >Atualizar</Button>
                        </div>
                    </Grid>
                 </Grid>
            </form>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
});
  
export default withRouter(connect(null, mapDispatchToProps)(GiftEditMode));