import moment from 'moment';

const validate = (value, rules, formItems) => {
    let isValid = true;
    let errorFound = false;
    let errorMessage = '';

    for(let rule in rules){

        if(!errorFound){

            switch(rule){

                case 'required' : 
                    isValid = isValid && isRequired(value); 
                    errorMessage = 'Campo obrigatório';
                    break;
                case 'terms' : 
                    isValid = isValid && value; 
                    errorMessage = 'Precisa aceitar os termos de uso';
                    break;
                case 'haveToSelect' : 
                    isValid = isValid && haveToSelect(value); 
                    errorMessage = 'Selecione uma das opções';
                    break;
                case 'minLength' : 
                    isValid = isValid && minLength(value, rules[rule]);
                    errorMessage = 'Digite no mínimo ' + rules[rule] + ' caracteres';
                    break;
                case 'maxLength' : 
                    isValid = isValid && maxLength(value, rules[rule]); 
                    errorMessage = 'Digite no máximo ' + rules[rule] + ' caracteres';
                    break;
                case 'exactLength' : 
                    isValid = isValid && exactLength(value, rules[rule]); 
                    errorMessage = 'Digite exatamente ' + rules[rule] + ' caracteres';
                    break;

                case 'biggerThan' : 
                    isValid = isValid && biggerThan(value, rules[rule]); 
                    errorMessage = 'O valor precisa ser maior que ' + rules[rule];
                    break;
                
                case 'afterDate' : 
                    isValid = isValid && afterDate(value, rules[rule].id, formItems);
                    errorMessage = "A data deve ser posterior a " + rules[rule].label;
                    break;
                
                case 'afterTime' : 
                    isValid = isValid && afterTime(value, rules[rule].id, rules[rule].mandatory, formItems);
                    errorMessage = "O horário deve ser posterior " + rules[rule].label;
                    break;
                
                case 'lessThan' : 
                    isValid = isValid && lessThan(value, rules[rule]); 
                    errorMessage = 'O valor precisa ser menor que ' + rules[rule];
                    break;

                case 'digits' : 
                    isValid = isValid && digits(value); 
                    errorMessage = "Digite apenas números";
                    break;
                case 'identifier' : 
                    isValid = isValid && identifier(value); 
                    errorMessage = "São permitidos apenas letras, números e hífens";
                    break;
                case 'phone' : 
                    isValid = isValid && phone(value); 
                    errorMessage = "Use apenas números, sem espaços ou separadores";
                    break;
                case 'email' : 
                    isValid = isValid && email(value);
                    errorMessage = "E-mail inválido";
                    break;
                case 'isFullName' :
                    isValid = isValid && isFullName(value);
                    errorMessage = "Muito curto. Não use abreviações.";
                    break;
                case 'link' : 
                    isValid = isValid && link(value);
                    errorMessage = "Link inválido";
                    break;
                case 'website' : 
                    isValid = isValid && website(value);
                    errorMessage = "URL inválida";
                    break;
                case 'isDate' : 
                    isValid = isValid && isDate(value);
                    errorMessage = "Data inválida. Use o formato dd/mm/aaaa";
                    break;
                case 'isTime' : 
                    isValid = isValid && isTime(value);
                    errorMessage = "Horário inválido. Use o formato hh:mm";
                    break;
                case 'isAdult' : 
                    isValid = isValid && isAdult(value);
                    errorMessage = "É preciso ter mais de 18 anos";
                    break;
                case 'requiredIfFilled' :
                    isValid = isValid && requiredIfFilled(value, rules[rule].id, formItems);
                    errorMessage = "Obrigatório quando o campo " + rules[rule].label + " está preenchido";
                    break;
                case 'isEqual' :
                    isValid = isValid && isEqual(value, rules[rule].id, formItems);
                    errorMessage = "Deve ser igual a " + rules[rule].label;
                    break;
                case 'minItems' : 
                    isValid = isValid && minItems(value, rules[rule].size);
                    errorMessage = 'Selecione ao menos ' + rules[rule].size + ' ' + rules[rule].label;
                    break;

                default: 
                    isValid = true;
    
            }

            if(!isValid){
                errorFound = true;
            }

        }

    }
    
    if(isValid){
        errorMessage = '';
    }

    return [!isValid, errorMessage];
}

//Rules Methods ***********************************************************************************************
//Required
const isRequired = value => {
    let valueToCheck = value.toString();
    return (valueToCheck && valueToCheck.length > 0 && valueToCheck.trim() !== '');
}

//Min Length
const minLength = (value, minLength) => {
    return (value.length > 0) ? value.length >= minLength : true;
}

//Max Length
const maxLength = (value, maxLength) => {
    return (value.length > 0) ? value.length <= maxLength : true;
}

//Exact Length
const exactLength = (value, exactLength) => {
    return (value.length > 0) ? value.length === exactLength : true;
}

// More than a reference
const biggerThan = (value, ref) => {
    return (parseInt(value) > parseInt(ref));
}

// Less than a reference
const lessThan = (value, ref) => {
    return (parseInt(value) < parseInt(ref));
}

//Only Digits
const digits = value => {
    return (value.length > 0) ? /^[0-9]*$/i.test(value) : true;
}

//Only Letters, Digits and hifen
const identifier = value => {
    return (value.length > 0) ? /^[0-9A-Za-z-]*$/i.test(value) : true;
}

//Only Digits
const phone = value => {
    return (value.length > 0) ? /^[0-9]*$/i.test(value) : true;
}

// have to be selected (option must be different of zero)
const haveToSelect = value => {
 return(parseInt(value) !== 0)
}

// Date
const isDate = value => {

    let check = false;

    if(value.length > 0){
        let a = value.split('/');
       
        if(a.length === 3){

            let d = a[0];
            let m = a[1];
            let y = a[2];
            let strDate = y + '-' + m + '-' + d;
            let dateCheck = moment(strDate, 'YYYY-MM-DD');
            check = dateCheck.isValid();

        }
    }
    return (value.length > 0) ? check : true;
}

// Time
const isTime = value => {

    let check = false;

    if(value.length > 0){
        let a = value.split(':');
        if(a.length === 2){
            let h = a[0];
            if(h.length === 1){
                h = '0' + h;
            }
            let m = a[1];
            let timeToCheck = h + ':' + m;
            check = moment(timeToCheck, "HH:mm", true).isValid();
        }
    }
    return (value.length > 0) ? check : true;
}

//it is equal
const afterTime = (value, dependencies, mandatory, formItems) => {

    let check = true;
    
    dependencies.forEach((dependency, index) => {

        let dependencyValue = formItems[dependency].value;
        if(dependencyValue.length > 0 && isTime(dependencyValue)){

        // Build dates
        let a = dependencyValue.split(':');
        let b = value.split(':');

        // Compare dates
        if(a.length === 2 && b.length === 2){

            let iHour = a[0];
            if(iHour.length === 1){
                iHour = '0' + iHour;
            }
            let iMinute = a[1];
            let iTime = iHour + ':' + iMinute;
            let timeToCompare = moment(iTime, 'HH:mm');

            let eHour = b[0];
            if(eHour.length === 1){
                eHour = '0' + eHour;
            }
            let eMinute = b[1];
            let eTime = eHour + ':' + eMinute;
            let timeToCheck = moment(eTime, 'HH:mm');

            // Verify
            let isAfter = moment(timeToCheck).isAfter(timeToCompare);
            if(!isAfter){
                check = false;
            };

        }else{

            if(mandatory[index]){
                check = false;
            }

        }     
    }

    });

    return (value.length > 0) ? check : true;
}

//it is equal
const afterDate = (value, dependency, formItems) => {

    let dependencyValue = formItems[dependency].value;
    let check = false;
    if(dependencyValue.length > 0 && isDate(dependencyValue)){

        // Build dates
        let a = dependencyValue.split('/');
        let b = value.split('/');

        // Compare dates
        if(a.length === 3 && b.length === 3){

            let iDay = a[0];
            let iMonth = a[1];
            let iYear = a[2];
            let iDate = iYear + '-' + iMonth + '-' + iDay;
            let dateToCompare = moment(iDate, 'YYYY-MM-DD');

            let eDay = b[0];
            let eMonth = b[1];
            let eYear = b[2];
            let eDate = eYear + '-' + eMonth + '-' + eDay;
            let dateToCheck = moment(eDate, 'YYYY-MM-DD');

            // Verify
            check = (moment(dateToCheck).isAfter(dateToCompare) || moment(dateToCheck).isSame(dateToCompare));

        }
        
    }

    return (dependencyValue.length > 0 && value.length > 0) ? check : true;
}

// Is An Adult
const isAdult = value => {

    if(value.length === 0) return true;
    if(isDate(value)){

        let d = value.substring(0,2);
        let m = value.substring(3,5);
        let y = value.substring(6);
        let strDate = y + '-' + m + '-' + d;
        let mDate = moment(strDate, 'YYYY-MM-DD');
        let age = parseInt(moment().diff(mDate, 'years', true));
        if(age < 18){
            return false;
        }else{
            return true;
        }


    }else{
        return false;
    }

}

//Email
const email = value => {
    return (value.length > 0) ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i.test(value) : true;
}

//Link
const link = value => {
    // eslint-disable-next-line
    return (value.length > 0) ? /(^(http(s)?):\/\/(www.)?[a-z0-9.\-_\/?=&]{2,240}$)|(^(\/){1}([a-z0-9\/\-\_\+\?]){0,255}$)|(^(mailto\:\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3}))$)/i.test(value) : true;
}

//website
const website = value => {
    return (value.length > 0) ? /^(http(s)?):\/\/(www.)?[a-z0-9.\-_/?=&]{1,240}/i.test(value) : true;
}

//required if filled
const requiredIfFilled = (value, dependency, formItems) => {

    let dependencyValue = formItems[dependency].value;
    return (dependencyValue.length > 0) ? value.length > 0 : true;

}

//it is equal
const isEqual = (value, dependency, formItems) => {

    let dependencyValue = formItems[dependency].value;
    return (dependencyValue.length > 0) ? value === dependencyValue : true;
}

//it is equal
const minItems = (value, size) => {
    return (value.length >= size);
}

// check full name
const isFullName = (value) => {

    if(value.length === 0) return true;

    let names = value.split(' ');
    if(names.length < 2) return false;

    // Check names
    let tooShort = false;
    for(let i = 0; i < names.length; i++){
        if(names[i].length < 2){
            tooShort = true;
        }
    }

    if(tooShort){
        return false;
    }else{
        return true;
    }

}





export default validate;