import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './search.styles.scss';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import components
import BlogViewMode from '../../modules/blog/blog.view.component';


class Search extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    getUrlParameters = (param) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(param)
    }


    componentDidMount(){
        
        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

    }
    

    //Rendering
    render(props){

        return(
            <div className="page search">
                <div className="content search">
                    <div className="module-heading">
                        <h1 className='heading-1'>Busca</h1>
                    </div>

                   
                    <div className="mdl mdl-blog">
                        <BlogViewMode category={0} contentId={0} keyword={this.getUrlParameters('key')} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(Search));