import React from 'react';
import './avatar.styles.scss';

const Avatar = (props) => {

    const avatarImg = (props.source) ? 'url("' + window.$usersPath + props.source + '")' : 'url("' + window.$usersPath +  '0.jpg")';
    const size = (props.size) ? props.size : "medium";

   return (
    <div className={`img-avatar ${size} ${props.color}`} style={{backgroundImage: avatarImg}}>
    </div>
   );
}

  
export default Avatar;