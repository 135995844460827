import React from 'react';
import Avatar from '../../components/avatar/avatar.component';

const Partner = (props) => {

    // Format Data
    const formatDate = (date) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        let mNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        sDate = mNames[m - 1] + '/' + y;

        return sDate;

    }

    const cutIntro = (props.about && props.about.length > 100) ? props.about.substring(0, 100) + '...' : props.about;

    const names = props.fullname.split(' ');
    const cutName = (names.length > 1) ? names[0] + ' ' + names[names.length - 1] : props.fullname; 

    // Set avatar image
    const avatarImg = (props.avatar) ? 'url("' + window.$usersPath + props.avatar + '")' : 'url("' + window.$usersPath +  '0.jpg")';

    return(
        <a className='partner' href={'/parceiros/' + props.id}>
            <div className="avatar" style={{backgroundImage: avatarImg}}></div>
            <div className="info">
                {
                    (props.company && props.company.length > 0) ?
                    <span className="company"><span className="company-name">{props.company}</span></span>:
                    null
                }
                <span className="name">{cutName}</span>
                <span className="since">Abriccó desde {formatDate(props.created)}</span>
                <span className="intro">{cutIntro}</span>
                <div className="services hidden">
                {
                    props.services.map((item) => {
                        let sKey = 'service-' + props.id + '-' + item.id;
                        return(
                            <span key={sKey} className="service">{item.service}</span>
                        )
                    })
                }
                </div>
            </div>
        </a>
    )
}

export default Partner;