import React, {useState, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import IsAuthenticated from '../user-validation/authentication';
 
// handle the public routes
const PrivateRoute = ({ component: Component, ...rest }) => {

  const [obj, setObj] = useState({isLoading: true, isAuthenticated: null});

  useEffect(() => {

    async function checkUser() {
      let response = await IsAuthenticated();
      setObj({isLoading: false, isAuthenticated: response});
    }

    checkUser()
  }, []);


  if(obj.isLoading){
    return(<div className="route-loading">LOADING</div>)
  }
  return(<Route
    {...rest}
    render={(props) => obj.isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />}
  />);

  
}
 
export default PrivateRoute;

