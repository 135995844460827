import React from 'react';
import './references.styles.scss';

// Import UI component
import Button from "@material-ui/core/Button";

const ReferencesEditMode = (props) =>{
        
        return(
            <form className='references-edit-mode'>
                <div className="module-title-bar">Módulo Author : {props.contentId}</div>
                <p><b>Este módulo não permite edição</b></p>
                <p>Este módulo é preenchido automaticamente com o conteúdo anterior e posterior da mesma categoria.</p>
                <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                <Button color="primary" variant="contained" disableElevation onClick={() => props.functions.toggleMode(props.contentId)}>Ok, entendi</Button>
                </div>
            </form>
        )
}
  
export default ReferencesEditMode;