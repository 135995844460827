import validate from './form-validation/form-validation.utils';
import { authAction } from './user-validation/user-validation.utils';

export const getUrlParameters = (location) => {

    if(location?.search){
        
        let params = {};
        const locationSearch = location.search.trim();

        if(locationSearch.length > 0){

            const queryParams = decodeURIComponent(locationSearch.slice(1)).split('&');
            queryParams.forEach((pair) => {

                let item = pair.split('=');
                let keyName = item[0];
                let keyValue = item[1];
                params[keyName] = keyValue;

            });

        }

        return params;
    }
    return null; 

};

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const isArray = (str) => {
    if(str.constructor === Array){
        return true;
    }
    return false;
};

export const formatDate = (date, withHour) => {

    let d = date.substr(8, 2);
    let m = date.substr(5, 2);
    let y = date.substr(0, 4);
    let sDate = '';

    if(withHour){

    let h = date.substr(11,2);
    let n = date.substr(14,2);
    sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

    }else{

    sDate = d + '/' + m + '/' + y;

    }

    return sDate;

}

export const lockScrollbar = (action) => {

    const site = document.getElementById('root');
    let siteClasses = Array(site.classList);
    let found = (siteClasses.indexOf('scroll-locked-by-modal') === -1) ? false : true;
    
    if(action){

        if(action === 'remove'){
            site.classList.remove('scroll-locked-by-modal');
        }else{
            site.classList.add('scroll-locked-by-modal');
        }

    }else{

        if(found){
            site.classList.remove('scroll-locked-by-modal');
        }else{
            site.classList.add('scroll-locked-by-modal');
        }

    }
    
}

export const getParameterByName = (name, url = window.location.href) => {
    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const htmlToText = html => {

    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/ig, '\n');
    html = html.replace(/<\/li>/ig, '\n');
    html = html.replace(/<li>/ig, '  *  ');
    html = html.replace(/<\/ul>/ig, '\n');
    html = html.replace(/<\/p>/ig, '\n');
    html = html.replace(/<br\s*[/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/ig, '');
    return html;

}

export const handleChange = (event) => {
    
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // Get current FormItems object from State
    const {formItems} = this.state;
    const updatedForm = formItems;

    // Get State of the Input
    const formItem = {...updatedForm[name]};
        
    // Validate Element if it has been Touched Before
    if(formItem.hasTouched){
        if(formItem.rules){
            const validation = validate(value, formItem.rules, updatedForm);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }
    }

    // Set the new value to element
    formItem.value = value;

    // Update formItems collection
    updatedForm[name] = formItem;

    // Update State
    this.setState({formItems: updatedForm});
}

export const handleBlur = (event) => {

    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // formItems collection
    const {formItems} = this.state;
    const updatedForm = formItems;

    // state of modified element
    const formItem = {...updatedForm[name]};

    formItem.hasTouched = true;
    if(formItem.rules){
        const validation = validate(value, formItem.rules, updatedForm);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
    }else{
        formItem.hasError = false;
        formItem.errorMsg = '';
    }

    // Update formItems collection
    updatedForm[name] = formItem;

    // Update state
    this.setState({formItems: updatedForm});

}

export const fetchData = (service, data, auth, success, fail) => {

    const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify(data),
    };

    fetch(service, requestOptions)
    .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        // Check if user is authenticated
        if(auth){
            authAction(data.authenticated);
        }

        // SUCCESS CASE
        if(data.success){
          success(data);
        } else {
          fail(data.feedback);
        }

      })
      .catch((error) => {
        fail(error);
      });

}
