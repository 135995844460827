import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './banner-image.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ClearIcon from '@material-ui/icons/Clear';

// Import components
import IptImage from '../../components/ipt-image/ipt-image.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class BannerImageEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            formItems: {
                icon: {
                    id: 'icon',
                    label: 'Ícone',
                    placeholder: '',
                    value: (this.props.icon)?this.props.icon: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                title: {
                    id: 'title',
                    label: 'Título',
                    placeholder: '',
                    value: (this.props.title) ? this.props.title : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                desc: {
                    id: 'desc',
                    label: 'Descrição',
                    placeholder: '',
                    value: (this.props.desc) ? this.props.desc : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                src: {
                    id: 'src',
                    label: 'Imagem',
                    placeholder: '',
                    value: (this.props.src) ? this.props.src : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                srcId: {
                    id: 'srcId',
                    label: 'Imagem',
                    placeholder: '',
                    value: (this.props.srcId) ? this.props.srcId : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                ctaLabel: {
                    id: 'ctaLabel',
                    label: 'Label do Botão',
                    placeholder: '',
                    value: (this.props.ctaLabel) ? this.props.ctaLabel : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        requiredIfFilled: { id: 'ctaLink', label: 'Link'},
                        maxLength: 255
                    }
                },
                ctaLink: {
                    id: 'ctaLink',
                    label: 'Link do Botão',
                    placeholder: '',
                    value: (this.props.ctaLink) ? this.props.ctaLink : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        requiredIfFilled: { id: 'ctaLabel', label: 'Label'},
                        link: true,
                        maxLength: 255
                    }
                },
                parallax: {
                    id: 'parallax',
                    label: 'Parallax',
                    placeholder: 'Efeito Parallax',
                    value: (parseInt(this.props.parallax) === 1) ? 1 : 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                mask: {
                    id: 'mask',
                    label: 'Máscara',
                    placeholder: 'Máscara',
                    value: (parseInt(this.props.mask) === 1) ? 1: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                
            }
        }
    }

    componentDidMount(){

        
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const handleSubmit = (event) => {

            event.preventDefault();
            let src = this.state.formItems.src.value;
            let srcId = this.state.formItems.srcId.value;
            let icon = this.state.formItems.icon.value;
            let title = this.state.formItems.title.value;
            let desc = this.state.formItems.desc.value;
            let ctaLabel = this.state.formItems.ctaLabel.value;
            let ctaLink = this.state.formItems.ctaLink.value;
            let parallax = this.state.formItems.parallax.value;
            let mask = this.state.formItems.mask.value;
            

            const parameters = {
                src: src,
                srcId: srcId,
                icon: icon,
                title: title,
                desc: desc,
                ctaLabel: ctaLabel,
                ctaLink: ctaLink,
                parallax: parallax,
                mask: mask
                
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const submitIsEnabled = () => {
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            return disabled;

        }

        const setCtaLink = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.ctaLink.value = url;
            
    
            formItems.ctaLink.hasTouched = true;
            const validation = validate(url, formItems.ctaLink.rules, formItems);
            formItems.ctaLink.hasError = validation[0];
            formItems.ctaLink.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });
    
            this.props.closeDirectory();
    
        }

        const setImage = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.src.value = obj[0].mediaFile;
            formItems.srcId.value = obj[0].mediaId;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();
    
        }

        const setIcon = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.icon.value = obj[0].mediaFile;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();
    
        }

        const unsetIcon = () => {

            const formItems = this.state.formItems;
    
            formItems.icon.value = '';
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
        }

        const unsetImage = () => {
    
            const formItems = this.state.formItems;
            formItems.src.value = '';
            formItems.srcId.value = 0;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            });
    
        }

        const handleSwitch = (event) => {

            const name = event.target.name;

            let value = 1;
    
            if(this.state.formItems[name].value === 1){
                value = 0;
            }else{
                value = 1;
            }

            const formItems = this.state.formItems;

            formItems[name].value = value;
            

            this.setState({
                ...this.state,
                formItems: formItems
            })

        }

        return(
            <form className='banner-image-edit-mode'>
                 <Grid container spacing={2}>

                    { /* Graphic Inputs */ }
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2}>

                            {/* Image */}
                            <Grid item xs={12}>
                                <IptImage 
                                className="image" 
                                limit={1} 
                                action={setImage}
                                clearFunction = {unsetImage}
                                selectedFile={this.state.formItems.src.value} 
                                selectedImage={this.state.formItems.srcId.value} 
                                />
                            </Grid>

                            

                        </Grid>
                    </Grid>
                    
                    { /* Other Inputs */ }
                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={2}>

                            { /* Title */ }
                            <Grid item xs={12}>
                                <TextField
                                name={this.state.formItems.title.id}
                                id={this.state.formItems.title.id}
                                value={this.state.formItems.title.value}
                                label={this.state.formItems.title.label}
                                placeholder={this.state.formItems.title.placeholder}
                                error={this.state.formItems.title.hasError}
                                helperText={this.state.formItems.title.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </Grid>

                            { /* Description */ }
                            <Grid item xs={12}>
                                <TextField
                                name={this.state.formItems.desc.id}
                                id={this.state.formItems.desc.id}
                                value={this.state.formItems.desc.value}
                                label={this.state.formItems.desc.label}
                                placeholder={this.state.formItems.desc.placeholder}
                                error={this.state.formItems.desc.hasError}
                                helperText={this.state.formItems.desc.errorMsg}
                                fullWidth
                                multiline
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </Grid>

                            { /* CTA Label */ }
                            <Grid item xs={12} sm={6}>
                                <TextField
                                name={this.state.formItems.ctaLabel.id}
                                id={this.state.formItems.ctaLabel.id}
                                value={this.state.formItems.ctaLabel.value}
                                label={this.state.formItems.ctaLabel.label}
                                placeholder={this.state.formItems.ctaLabel.placeholder}
                                error={this.state.formItems.ctaLabel.hasError}
                                helperText={this.state.formItems.ctaLabel.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </Grid>

                            { /* CTA Link */ }
                            <Grid item xs={12} sm={6}>
                                <TextField
                                name={this.state.formItems.ctaLink.id}
                                id={this.state.formItems.ctaLink.id}
                                value={this.state.formItems.ctaLink.value}
                                label={this.state.formItems.ctaLink.label}
                                placeholder={this.state.formItems.ctaLink.placeholder}
                                error={this.state.formItems.ctaLink.hasError}
                                helperText={this.state.formItems.ctaLink.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        aria-label="Procurar conteúdo"
                                        edge="end"
                                        onClick={() => this.props.openDirectory(1, setCtaLink)}
                                        >
                                        <DescriptionIcon /> 
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                />
                            </Grid>

                            { /* Icon */ }
                            <Grid item xs={12} sm={6}>
                                <TextField
                                name={this.state.formItems.icon.id}
                                id={this.state.formItems.icon.id}
                                value={this.state.formItems.icon.value}
                                label={this.state.formItems.icon.label}
                                placeholder={this.state.formItems.icon.placeholder}
                                error={this.state.formItems.icon.hasError}
                                helperText={this.state.formItems.icon.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton
                                        aria-label="Procurar ícone"
                                        edge="end"
                                        onClick={() => this.props.openGallery(1, setIcon)}
                                        >
                                        <DescriptionIcon /> 
                                        </IconButton>
                                        <IconButton
                                        aria-label="Remover ícone"
                                        edge="end"
                                        onClick={() => unsetIcon()}
                                        >
                                        <ClearIcon /> 
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                                />
                            </Grid>

                            { /* Mask */}
                            <Grid item xs={12} sm={3} className='vertical-middle'>
                                <FormControlLabel
                                control={
                                <Switch
                                    checked={(this.state.formItems.mask.value === 1) ? true : false }
                                    onChange={handleSwitch}
                                    name="mask"
                                    color="primary"
                                />
                                }
                                label={this.state.formItems.mask.placeholder}
                                />
                            </Grid>

                            { /* Parallax */}
                            <Grid item xs={12} sm={3} className='vertical-middle'>
                                <FormControlLabel
                                control={
                                <Switch
                                    checked={(this.state.formItems.parallax.value === 1) ? true : false }
                                    onChange={handleSwitch}
                                    name="parallax"
                                    color="primary"
                                />
                                }
                                label={this.state.formItems.parallax.placeholder}
                                />
                            </Grid>

                            { /* Buttons */ }
                            <Grid item xs={12}>
                                <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                                <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                                <Button 
                                type="submit" 
                                color="primary" 
                                variant="contained" 
                                disableElevation 
                                onClick={(e) => handleSubmit(e)} 
                                disabled={submitIsEnabled()}
                                >Atualizar</Button>
                                </div>
                            </Grid>

                        </Grid>
                    </Grid>

                   
                 </Grid>
            </form>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(BannerImageEditMode));