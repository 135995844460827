import React from 'react';

const WhatsappIcon = (props) => {
    const viewBoxWidth = 64;
    const viewBoxHeight = 64;
    const viewBox = '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight;
    const width = (props.width) ? props.width : viewBoxWidth;
    const height = (props.height) ? props.height : viewBoxHeight;

    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox={viewBox}
        className={(props.className) ? `svg-icon ${props.className}` : 'svg-icon'}
        width = {width + 'px'}
        height ={height + 'px'}
        >
            <path 
                d="M32 58.31a26.87 26.87 0 0 1-11.81-2.76 3 3 0 0 0-2.12-.16Q12 57 6 58.53c-.78.2-.79.2-.57-.6l3.2-11.86A2.83 2.83 0 0 0 8.4 44 26.35 26.35 0 0 1 6 26a25.92 25.92 0 0 1 8.74-14.62 26 26 0 0 1 15.43-6.31c9.67-.64 17.49 3 23.33 10.74a25.41 25.41 0 0 1 5 19.28 26.46 26.46 0 0 1-21.66 22.79 25.12 25.12 0 0 1-4.84.43zM11.55 52.5l7-1.87a3.16 3.16 0 0 1 2.55.3 21.11 21.11 0 0 0 18.95 1.37C48.11 49.08 53 43 54 34.41c1.13-9.69-2.83-17.21-11.2-22.19a20.38 20.38 0 0 0-14.7-2.41A21.58 21.58 0 0 0 11.24 24a20.93 20.93 0 0 0 1.38 18.26 5.53 5.53 0 0 1 .38 4.91 49.82 49.82 0 0 0-1.45 5.33zm27.32-8.08a17.82 17.82 0 0 1-7.8-2.54 30.45 30.45 0 0 1-11.58-12.24A7.83 7.83 0 0 1 21 20.09a3.76 3.76 0 0 1 3.72-.53 1.25 1.25 0 0 1 .51.62l2.24 5.35a1.64 1.64 0 0 1-.17 1.38 9.53 9.53 0 0 1-1.43 1.91 1.25 1.25 0 0 0-.15 1.67 17.94 17.94 0 0 0 9 7.72c.79.32 1.09.21 1.64-.48l1.76-2.18c.6-.71.92-.77 1.73-.39l4.86 2.34a1.34 1.34 0 0 1 .86 1.38 5.82 5.82 0 0 1-5.91 5.54z"
            />
        </svg>
    );

}
export default WhatsappIcon;