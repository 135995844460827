import React, {Component} from 'react';
import './notes.styles.scss';

// Import Material UI 
import Button from "@material-ui/core/Button";

// Import components
import RichtextEditor from '../../components/richtext-editor/richtext-editor.component';


class NotesEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            htmlContent: this.props.text
        }
    }

    componentDidMount(){
       
    }

    render(props){

        const handleSubmit = (e) => {
            e.preventDefault();
            let text = this.state.htmlContent;
    
            const parameters = {
                text: text
            }
    
            this.props.functions.updateModule(this.props.contentId, parameters);
        }

        const handleChange = (htmlValue) => {

            this.setState({
                ...this.state,
                htmlContent: htmlValue
            });
        }

       const editorFunctions = {
           handleChange: handleChange,
       }


        return(
            <form className='notes-edit-mode'>
                <RichtextEditor editor={this.props.contentId} content={this.props.text} functions={editorFunctions} />
                <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                    <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                    <Button 
                        type="submit" 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        onClick={(e) => handleSubmit(e)} 
                        disabled={(this.state.htmlContent.length === 0)}
                        >Atualizar</Button>
                </div>
            </form>
        )
    }
}

export default NotesEditMode;
