import React, {Component} from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CircularProgress } from '@material-ui/core';

import validate from "../../utils/form-validation/form-validation.utils";
import {getToken, authAction} from "../../utils/user-validation/user-validation.utils";
import { openModal } from '../../redux/modal/modal.actions';

class Reschedule extends Component{

    constructor(props){
        super(props);
        this.state = {
            getting: true,
            transfering: false,
            formItems: {
                start: {
                    id: "start",
                    label: "De",
                    placeholder: "dd/mm/aaaa",
                    value: '',
                    hasTouched: true,
                    hasError: false,
                    errorMsg: "",
                    rules: {
                      required: true,
                      isDate: true
                    },
                },
                end: {
                    id: "end",
                    label: "Até",
                    placeholder: "dd/mm/aaaa",
                    value: '',
                    hasTouched: true,
                    hasError: false,
                    errorMsg: "",
                    rules: {
                        required: true,
                        isDate: true,
                        afterDate: {
                            id: 'start',
                            label: 'data de início'
                        }
                    },
                },
                selected: {
                    id: "selected",
                    label: "",
                    placeholder: "",
                    value: 0,
                    hasTouched: true,
                    hasError: false,
                    errorMsg: "",
                    rules: [],
                },
                
            },
            slots: [],
            uniqueDays: [],
            expandedDates: [] 
        }
    }

    componentDidMount(){

        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 7);
        
        const formStart = startDate.getDate() + '/' + (startDate.getMonth() + 1) + '/' + startDate.getFullYear();
        const formEnd = endDate.getDate() + '/' + (endDate.getMonth() + 1) + '/' + endDate.getFullYear();

        const {formItems} = this.state;
        const updatedFormItems = formItems;

        updatedFormItems.start.value = formStart;
        updatedFormItems.end.value = formEnd;

        this.setState({formItems: updatedFormItems}, this.fetchSlots);

    }

    // Fetch slots
    fetchSlots = () => {

        const { formItems } = this.state;

        // Format Dates
        let start = formItems.start.value.split('/');
        let end = formItems.end.value.split('/');
        let start_to_sql = start[2] + '-' + start[1] + '-' + start[0] + ' 0:00:00';
        let end_to_sql = end[2] + '-' + end[1] + '-' +  end[0] + ' 23:59:59';



        const userToken = getToken();
        const formData = {
            userToken: userToken,
            app: window.$appToken,
            start: start_to_sql,
            end: end_to_sql,
        }

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
            },
            body: JSON.stringify(formData)
        };

        fetch("/api/slots_search.php", requestOptions)
        .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            authAction(data.authenticated);

            if(data.success){

                let uniqueDays = [];
                data.slots.forEach((item) => {

                    let found = false;
                    uniqueDays.forEach((dt) => {

                        if(dt.date === item.date){
                            found = true;
                        }

                    })

                    if(!found){

                        let obj = {
                            date: item.date,
                            day: item.day,
                            month: item.month,
                            year: item.year
                        };
                        uniqueDays.push(obj);

                    }

                })
        

                this.setState({
                    getting: false,
                    hasError: false,
                    errorMessage: '',
                    slots: data.slots,
                    uniqueDays: uniqueDays
                });

            }else{
                
                this.setState({
                    getting: false,
                    hasError: true, 
                    errorMessage: data.feedback
                });
            }

        });

    }

    // Transfer data from a slot to another
    transferSlot = () => {


        // Format Dates
        const slot = parseInt(this.props.slot.id);
        const destination = parseInt(this.state.formItems.selected.value);

        const userToken = getToken();
        const formData = {
            userToken: userToken,
            app: window.$appToken,
            slot: slot,
            destination: destination,
        }

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
            },
            body: JSON.stringify(formData)
        };

        fetch("/api/slots_transfer.php", requestOptions)
        .then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
            }
            authAction(data.authenticated);

            if(data.success){

                const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Evento Reagendado',
                modalParameters: {
                    text: 'O evento foi reagendado com sucesso.'
                },
                modalActions: {
                    action: this.props.closeCard,
                    parameters: true
                }
                };
    
                const callback = () => {this.props.openModal(modalObj)}
                this.setState({transfering: false}, callback);

            }else{
                
                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops!',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                };
        
                const callback = () => {this.props.openModal(modalObj)}
                this.setState({transfering: false}, callback);
            }

        });

    }

    // Handle Inputs Change
    handleChange = (event, callbacks) => {
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
        ...this.state.formItems,
        };

        // Get State of the Input
        const formItem = {
        ...formItems[name],
        };

        // Validate Element if it has been Touched Before
        if (formItem.hasTouched) {
        if (formItem.rules) {
            const validation = validate(value, formItem.rules, formItems);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        } else {
            formItem.hasError = false;
            formItem.errorMsg = "";
        }
        }

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({
        ...this.state,
        formItems: formItems
        });

        if (callbacks) {
        callbacks.map((item) => {
            return item(event);
        });
        }
    };

    // Handle Inputs Blur
    handleBlur = (event) => {
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
        ...this.state.formItems,
        };

        // state of modified element
        const formItem = {
        ...formItems[name],
        };

        formItem.hasTouched = true;
        if (formItem.rules) {
        const validation = validate(value, formItem.rules, formItems);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
        } else {
        formItem.hasError = false;
        formItem.errorMsg = "";
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({
        ...this.state,
        formItems: formItems,
        });
    };

    // Handle Radio Button
    handleRadioButton = (event) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        console.dir(name);
        console.dir(value); 

        const { formItems } = this.state;
        const updatedFormItems = formItems;

        updatedFormItems[name].value = parseInt(value);

        console.dir(updatedFormItems);

        this.setState({formItems: updatedFormItems});

    }

    // Search slots in according to form given dates
    handleSearch = () => {

        this.setState({getting: true}, this.fetchSlots);

    }

    // Expand or Collapse the dates
    expandCollapseDates = (index) => {

        const {expandedDates} = this.state;
        const updateExpandedDates = expandedDates;

        let pos = updateExpandedDates.indexOf(index)

        if( pos === -1){
            updateExpandedDates.push(index);
        }else{
            updateExpandedDates.splice(pos, 1);
        }

        this.setState({expandedDates: updateExpandedDates})
    }

    // HandleSubmit
    handleSubmit = () => {
        this.setState({transfering: true}, this.transferSlot);
    }



    render(){

        const hasError = (this.state.formItems.start.hasError || this.state.formItems.end.hasError);
        return(
            <div className="reschedule">
                <div className="title">
                  <span className="text">Reagendar</span>
                </div>
                <Grid container spacing={2}> 
                    <Grid item xs={12}><span className="label">Procurar slots disponíveis entre as datas:</span></Grid>   
                    <Grid item xs={4}>
                        <TextField
                            id={this.state.formItems.start.id}
                            name={this.state.formItems.start.id}
                            size="small"
                            label={this.state.formItems.start.label}
                            variant="outlined"
                            value={this.state.formItems.start.value}
                            placeholder={this.state.formItems.start.placeholder}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            error={this.state.formItems.start.hasError}
                            helperText={this.state.formItems.start.errorMsg}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id={this.state.formItems.end.id}
                            name={this.state.formItems.end.id}
                            size="small"
                            label={this.state.formItems.end.label}
                            variant="outlined"
                            value={this.state.formItems.end.value}
                            placeholder={this.state.formItems.end.placeholder}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            error={this.state.formItems.end.hasError}
                            helperText={this.state.formItems.end.errorMsg}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Button color="primary" variant="outlined" disableElevation fullWidth disabled={hasError} onClick={this.handleSearch}>Procurar</Button>
                    </Grid>

                    <Grid item xs={12}><span className="label">Selecione um dos slots da lista:</span></Grid> 

                    <Grid item xs={12}>
                    <div className="slots-list">
                    {
                        (this.state.getting)?
                        <div className="loading"><CircularProgress size={36} /></div>:
                    
                    <RadioGroup 
                        name={this.state.formItems.selected.id} 
                        value={this.state.formItems.selected.value} 
                        onChange={this.handleRadioButton}
                    >
                    {
                        this.state.uniqueDays.map((dt, index) => {

                            let dKey = 'available-date-' + dt.date;
                            let filteredTimes = this.state.slots.filter((item) => (item.date === dt.date));
                            let classes = 'available-date';
                            let icon = "expand_more";
                            if(this.state.expandedDates.indexOf(index) !== -1){
                                classes += ' opened';
                                icon = "expand_less";
                            }

                            return(
                                <div key={dKey} className={classes}>

                                    <div className="available-date-header" onClick={() => this.expandCollapseDates(index)}>
                                        <div className="date">{dt.day + ' de ' + this.props.months[(dt.month - 1)] + ', ' + dt.year}</div>
                                        <span className="material-icons">{icon}</span>
                                    </div>
                                    <div className="available-date-body">
                                        {
                                            filteredTimes.map((time) => {
                                                let tKey = 'available-time-' + time.id;
                                                let label = <span className="time">{time.startTime + ' - ' + time.endTime}</span>
                                                return(
                                                    <div key={tKey} className="available-time">
                                                        <FormControlLabel value={time.id} control={<Radio />} label={label} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            )

                        })
                    }
                    </RadioGroup>
                    }
                    </div>
                    </Grid>   

                </Grid>
                <div className="btn-wrapper side-by-side on-right">
                    <Button color="default" variant="outlined" disableElevation onClick={() => this.props.toggleView('view')}>Voltar</Button>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        disabled={(!this.state.formItems.selected.value || this.state.transfering)}
                        onClick={this.handleSubmit}
                    >
                        {(this.state.transfering) ? 'Aguarde...' : 'Reagendar'}
                    </Button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData))
  });
  
export default connect(null, mapDispatchToProps)(Reschedule);
