import React from 'react';
import './heading.styles.scss';

const HeadingViewMode = (props) =>{

    const alignment = (props.alignment) ? props.alignment : 'center';

    const heading = () => {
        
        if(props.type === 1){
            return(<h1 className={`heading-1 ${alignment}`}>{props.title}</h1>);
        }else if(props.type === 2){
            return(<h2 className={`heading-2 ${alignment}`}>{props.title}</h2>);
        }else if(props.type === 3){
            return(<h3 className={`heading-3 ${alignment}`}>{props.title}</h3>);
        }else if(props.type === 4){
            return(<h4 className={`heading-4 ${alignment}`}>{props.title}</h4>);
        }else if(props.type === 5){
            return(<h5 className={`heading-5 ${alignment}`}>{props.title}</h5>);
        }else{
            return(<h6 className={`heading-6 ${alignment}`}>{props.title}</h6>);
        }

    }

    return(<div className={(props.darkmode)? 'module-heading dark-mode' : 'module-heading'}>{heading()}</div>);

}

export default HeadingViewMode;