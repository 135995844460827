import React, {Component} from 'react';
import './spacing.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";


class SpacingEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.spacingType = React.createRef();
        this.state = {
            types: [
                {
                    label: 'Small',
                    value: 1
                },
                {
                    label: 'Medium',
                    value: 2
                },
                {
                    label: 'Large',
                    value: 3
                },
                {
                    label: 'Extra Large',
                    value: 4
                },
                
            ],
            formItems: {
                space: {
                    id: 'space',
                    label: 'Espaçamento',
                    placeholder: 'Selecione',
                    value: (this.props.space) ? this.props.space : 1,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                  },
            }
        }
    }

    render(props){

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
            ...this.state.formItems
        };

        // Get State of the Input
        const formItem = {
            ...formItems[name]
        };

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let space = this.state.formItems.space.value;

        const parameters = {
            space: parseInt(space),
        }

        this.props.functions.updateModule(this.props.contentId, parameters);
    }


        return(
            <form className='spacing-edit-mode'>
                 <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={2}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.space.hasError} ref={this.spacingType} >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.space.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.space.id}
                            id={this.state.formItems.space.id}
                            value={this.state.formItems.space.value}
                            label={this.state.formItems.space.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            {this.state.types.map((item) => {
                                return (
                                <option
                                    key={"space-type-" + item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.space.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    
                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={(this.state.formItems.space.hasError || this.state.formItems.space.hasError)}
                            >Atualizar</Button>
                        </div>
                    </Grid>

                </Grid>
                
                    
                
                
                
            </form>
        )
    }
}

export default SpacingEditMode;
