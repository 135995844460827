//Initial State
const INITIAL_STATE = {  
    appLoading: false
}

//Reducer and Actions
const authReducer = (state = INITIAL_STATE, action) => {
switch (action.type) {

    case 'ADD_LOADING':
        return{
            ...state,
            appLoading: true
        }
    
    case 'REMOVE_LOADING':
        return{
            ...state,
            appLoading: false
        }

    default:
        return state
}
}

//Export Reducer
export default authReducer;