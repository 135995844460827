const materialTheme = {
  
    palette: {
      primary: {
        main: '#4caf50',
        contrastText: '#fff'
      },
      secondary: {
        main: '#ff9800',
        contrastText: '#fff'
      },
      default: {
        main: '#666',
        contrastText: '#fff'
      },
      info:{
        main: '#f2f2f2',
        constrastText: '#f2f2f2'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  
};

export default materialTheme;