import React, {Component} from 'react';
import './contact.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Import components
import RichtextEditor from '../../components/richtext-editor/richtext-editor.component';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class ContactEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            formItems: {
                tag: {
                    id: 'tag',
                    label: 'Tag',
                    placeholder: '',
                    value: this.props.tag,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        identifier: true,
                        maxLength: 255
                    }
                },
                title: {
                    id: 'title',
                    label: 'Título',
                    placeholder: '',
                    value: this.props.title,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                label: {
                    id: 'label',
                    label: 'Label do Botão',
                    placeholder: '',
                    value: this.props.label,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required:  true,
                        maxLength: 255
                    }
                },
                message: {
                    id: 'message',
                    label: 'Mensagem de Confirmação',
                    placeholder: '',
                    value: this.props.message,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: '',
                    value: this.props.text,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                sendTo: {
                    id: 'sendTo',
                    label: 'Receber em',
                    placeholder: 'Digite o e-mail receptor',
                    value: this.props.sendTo,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        email: true,
                        maxLength: 255
                    }
                },
            }
        }
    }

    componentDidMount(){

        
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // Handle Editor
        const handleEditor = (htmlValue) => {

            const formItems = this.state.formItems;
            formItems.text.value = htmlValue;

            this.setState({
                ...this.state,
                formItems: formItems
            });
        }

        // Editor Functions
        const editorFunctions = {
            handleChange: handleEditor,
        }

        // FORMS: SUBMIT HANDLE
        const handleSubmit = (event) => {

            event.preventDefault();
            let tag = this.state.formItems.tag.value;
            let title = this.state.formItems.title.value;
            let label = this.state.formItems.label.value;
            let message = this.state.formItems.message.value;
            let text = this.state.formItems.text.value;
            let sendTo = this.state.formItems.sendTo.value;
            
            const parameters = {
                tag: tag,
                title: title,
                label: label,
                message: message,
                text: text,
                sendTo: sendTo
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const submitIsEnabled = () => {
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            if(formItems.tag.value === '' || formItems.label.value === ''){
                disabled = true;
            }

            return disabled;

        }

        return(
            <form className='contact-edit-mode'>
                <div className="module-title-bar">Módulo Contato : {this.props.contentId}</div>
                 <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.tag.id}
                        id={this.state.formItems.tag.id}
                        value={this.state.formItems.tag.value}
                        label={this.state.formItems.tag.label}
                        placeholder={this.state.formItems.tag.placeholder}
                        error={this.state.formItems.tag.hasError}
                        helperText={this.state.formItems.tag.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.title.id}
                        id={this.state.formItems.title.id}
                        value={this.state.formItems.title.value}
                        label={this.state.formItems.title.label}
                        placeholder={this.state.formItems.title.placeholder}
                        error={this.state.formItems.title.hasError}
                        helperText={this.state.formItems.title.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.label.id}
                        id={this.state.formItems.label.id}
                        value={this.state.formItems.label.value}
                        label={this.state.formItems.label.label}
                        placeholder={this.state.formItems.label.placeholder}
                        error={this.state.formItems.label.hasError}
                        helperText={this.state.formItems.label.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.sendTo.id}
                        id={this.state.formItems.sendTo.id}
                        value={this.state.formItems.sendTo.value}
                        label={this.state.formItems.sendTo.label}
                        placeholder={this.state.formItems.sendTo.placeholder}
                        error={this.state.formItems.sendTo.hasError}
                        helperText={this.state.formItems.sendTo.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                        name={this.state.formItems.message.id}
                        id={this.state.formItems.message.id}
                        value={this.state.formItems.message.value}
                        label={this.state.formItems.message.label}
                        placeholder={this.state.formItems.message.placeholder}
                        error={this.state.formItems.message.hasError}
                        helperText={this.state.formItems.message.errorMsg}
                        fullWidth
                        multiline
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RichtextEditor editor={this.props.contentId} content={this.props.text} functions={editorFunctions} />
                    </Grid>

                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={submitIsEnabled()}
                            >Atualizar</Button>
                        </div>
                    </Grid>
                    
                 </Grid>
            </form>
        )
    }
}
  
export default ContactEditMode;