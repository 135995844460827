import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './page.styles.scss';

import ContainerWrapper from '../../containers/container-wrapper/container-wrapper.component';
import Comments from '../../components/comments/comments.component';
import Classes from '../../components/classes/classes.component';

import {CircularProgress} from '@material-ui/core';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';
import {getToken} from "../../utils/user-validation/user-validation.utils";
import {getUrlParameters} from '../../utils/utils';

class Page extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.pageId = (this.props.pageId) ? this.props.pageId : this.props.match.params.pageId;
        this.state = {
            page: this.pageId,
            loading: true,
            pageData: [],
            modules: [],
            bgOptions: [
              {
                  id: 0,
                  label: 'Branco sólido',
                  class: 'bg-white'
              },
              {
                  id: 1,
                  label: 'Cinza sólido',
                  class: 'bg-gray',
              }
          ],
          urlParameters: []
        }
    }

    setBackground = (option) => {

      let newArray = this.state.bgOptions.filter(function (el) {
          return el.id === option;
      });

      if(newArray.length > 0){
          return newArray[0].class;
      }
      return;

  }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        const abriccoAuth = {
          error: false,
          currentPage: window.location,
          title: null,
          feedback: null
        }
        localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));

        const userToken = getToken();
        const urlParameters = getUrlParameters(this.props.location);

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              userToken: userToken,
              app: window.$appToken,
              id: this.pageId
            }),
          };
    
          fetch("/api/get_content.php", requestOptions)
          .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            if(data.pageData.approved === 0 || !data.success){
                window.location = '/404';
            }

            if(data.access === 2){

              const modalObj = {
                modalIcon: "priority_high",
                modalType: "error",
                modalComponent: "GENERAL_ALERT",
                modalTitle: "Ops...",
                modalParameters: {
                  text: "Você precisar ser assinante para ter acesso a conteúdos premium.",
                },
                modalActions: null,
              };
    
              localStorage.setItem("openModal", 1);
              localStorage.setItem("modalObj", JSON.stringify(modalObj));
              window.location = "/login";

            }else{

              //Google Analytics
              this.props.history.listen((location) => {
                window.ga('set', 'page', location.pathname + location.search);
                window.ga('set', 'title', data.pageData.title);
                window.ga('send', 'pageview');
              });

              document.getElementById('site').classList.add(this.setBackground(data.pageData.background));

              this.setState({ 
                loading: false,
                pageData: data.pageData,
                modules: data.modules,
                urlParameters: urlParameters
              });

            }

            
    
          })
          .catch((error) => {
            console.dir(error);
          });

    }

    renderComments = (showComments) => {
      if(parseInt(showComments) === 1){
        return(
          <Comments pageData={this.state.pageData} content={this.state.pageData.id} />
        )
      }
      return;
    }

    

    //Rendering
    render(){
        
        if(this.state.loading){
          return(
            <div className='page'>
              <div className="loading"><CircularProgress size={48}/></div>
            </div>
          )
        }else{

          return(
              <div className='page'>
              <div className={(this.state.loading)? 'loading' : 'loading hidden'}><CircularProgress size={48}/></div>
            
              <div className={'content content-page ' + this.setBackground(this.state.pageData.background)}>
              {
                  // eslint-disable-next-line
                  this.state.modules.map((item) => {
                          return(
                              <ContainerWrapper 
                              key={item.content}
                              pageData={this.state.pageData}
                              urlParameters={this.state.urlParameters}
                              {...item} 
                              />
                          );
                  })
              }
              {/*
                ((this.state.pageData.categoryId === 8 || this.state.pageData.categoryId === 16) && this.state.pageData.classes)?
                <Classes classes={this.state.pageData.classes} />:
                null
              */}
              { this.renderComments(this.state.pageData.comments) }
              </div>
              </div>
          );
        }
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(Page));