// Import libraries
import React from 'react';

// Import assets and styles
import './search-field.styles.scss';

// Import UI components
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

const SearchField = (props) => {

        const goto = (link) => {

            window.location = link;

        }


        return(
            <div className="search-field">
                <div className="btn-search">
                    <IconButton
                    aria-label="Procurar conteúdo"
                    edge="end"
                    onClick={() => goto('/busca')}
                    >
                        <SearchIcon /> 
                    </IconButton>
                </div>
            </div>
        );

}

export default SearchField;