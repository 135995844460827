import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './banner-title.styles.scss';

// Import UI component
import Button from "@material-ui/core/Button";

// Import components


// Import Redux Actions
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils



class BannerTitleEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            formItems: {
                img: {
                    id: 'img',
                    label: '',
                    placeholder: '',
                    value: this.props.img,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                imgId: {
                    id: 'imgId',
                    label: '',
                    placeholder: '',
                    value: this.props.imgId,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
            }
        }
    }

    componentDidMount(){
       
    }

    

    render(props){


    const handleSubmit = (e) => {
        
        e.preventDefault();
        let img = this.state.formItems.img.value;
        let imgId = this.state.formItems.imgId.value;
        

        const parameters = {
            img: img,
            imgId: imgId
           
        }

        this.props.functions.updateModule(this.props.contentId, parameters);

    }

    const setImage = (obj) => {

        const formItems = this.state.formItems;

        console.dir(obj);

        formItems.img.value = obj[0].mediaFile;
        formItems.imgId.value = obj[0].mediaId;

        this.setState({
            ...this.state,
            formItems: formItems,
        })

        this.props.closeGallery();

    }
    const unsetImage = () => {

        const formItems = this.state.formItems;
        formItems.img.value = '';
        formItems.imgId.value = 0;

        this.setState({
            ...this.state,
            formItems: formItems,
        });

    }

    const background = (this.state.formItems.img.value !== '')? 'url("' + window.$imagesPath + this.state.formItems.img.value + '")' : 'none';


        return(
            <form className='banner-title-edit-mode'>
                <div className="module-banner-title" style={{backgroundImage: background}}>
                    <div className="banner" >
                        <div className="banner-heading">
                        <div className="btn-wrapper side-by-side centered">
                        
                            <Button className="btn-ghost" variant="contained" disableElevation onClick={() => this.props.openGallery(1, setImage)}>Selecionar Imagem</Button>
                            <Button className="btn-ghost" variant="outlined" disableElevation onClick={() => unsetImage()}>Remover Imagem</Button>
                        
                        </div>
                        </div>
                    </div>
                </div>
                <div className="btn-wrapper side-by-side on-right">
                        
                    <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                    <Button color="primary" variant="contained" disableElevation onClick={(e) => handleSubmit(e)} disabled={(this.state.formItems.img.value === '')}>Atualizar</Button>
                        
                </div>
            </form>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(BannerTitleEditMode));