import React from 'react';

import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import ScheduleIcon from '@material-ui/icons/Schedule';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

const EventCard = (props) => {

    const { slot, months } = props;

    const avatarImg = slot.owner && slot.owner?.Avatar
          ? 'url("' + window.$usersPath + slot.owner.Avatar + '")'
          : 'url("' + window.$usersPath + '0.jpg")'; 

    const noRoom = (!slot.room);

    

    return(
        <div className="event-card" data-hasroom={noRoom}>

            {
                (props.loading)?
                <div className="loading">
                    <CircularProgress />
                </div>:
                null
            }
            
            <div className="card-header">
                <div className="day">{slot.day}</div>
                <div className="month">{months[slot.month - 1]}</div>
                <div className="time">{slot.startTime} - {slot.endTime}</div>
            </div>
            
            <div className="card-body">
                <div className="avatar" style={{backgroundImage: avatarImg}}></div>
                <div className="info">
                    <div className="id"><span>{slot.id}</span></div>
                    <div className="name">{slot.owner.ScreenName}<span className="company">(Empresa)</span></div>
                    <div className="room">
                        <a href={`/parceiros/${slot.owner.CodUser}`} target='_blank'>Ir para a página do parceiro</a>
                    </div>
                    
                </div>

                

            </div>
            
            <div className="card-footer">
                <div className="btn-wrapper">
                    <Button variant="contained" color="primary" aria-label="Reagendar" size="small" disableElevation disabled={noRoom}>Entrar</Button>
                    <button type="button" className="btn btn-delete" onClick={() => props.cancel(slot.id)}>
                        <span className="material-icons">delete</span>
                    </button>
                </div>

            </div>
        </div>
    );
    
};
export default EventCard;