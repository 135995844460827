import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Import UI Components
import Button from '@material-ui/core/Button';

// Import Redux Actions
import { closeModal } from '../../redux/modal/modal.actions';
import {lockScrollbar} from '../../utils/utils';


const GeneralAlerts = (props) => {

        const mainButton = React.createRef();

        useEffect(()=>{
            function keyListener(e) {
                const listener = keyListenersMap.get(e.keyCode);
                return listener && listener(e);
              }
              mainButton.current.focus();
    
              document.addEventListener("keydown", keyListener);
          
              return () => document.removeEventListener("keydown", keyListener);
        });
    
        const keyListenersMap = new Map([[27, props.closeModal]]);

        const executeAction = () => {
            props.closeModal();
            lockScrollbar('remove');
            localStorage.setItem('openModal', false);
            if(props.modal.modalActions){
                if(props.modal.modalActions.action){
                    props.modal.modalActions.action(props.modal.modalActions.parameters);
                }
            }
            
        }

        //Rendering
        return(
            <div className="general-alerts">
                <div className={(props.modal.modalType==='success')?'modal-adornment success':'modal-adornment'}>
                <span className="material-icons">{props.modal.modalIcon}</span>
                </div>
                <div className="heading"><h2>{props.modal.modalTitle}</h2></div>
                <div className="content" dangerouslySetInnerHTML={{ __html: props.modal.modalParameters.text }}></div>
                <div className="btn-wrapper side-by-side inverted-in-mobile centered">
                <Button ref={mainButton} type="button" onClick={executeAction} color="primary" variant="contained" disableElevation>Ok, entendi</Button>
                </div>
            </div>
        );
}

const mapStateToProps = state => ({
    modal: state.modal
  });
  
  
  const mapDispatchToProps = dispatch => ({
      closeModal: () => dispatch(closeModal()),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(GeneralAlerts);