import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//Redux
import { Provider } from 'react-redux';
import store from './redux/store';


window.$appToken = '05J1b4NI10KxsllMRMtd';
window.$domain = 'https://www.abricco.com/';
window.$domainWithoutSlash = 'https://www.abricco.com';
window.$imagesPath = window.$domain + 'gallery/';
window.$thumbsPath = window.$domain + 'gallery/thumbs/';
window.$usersPath = window.$domain + 'gallery/users/';
window.$drivePath = window.$domain + 'gallery/documents/';


ReactDOM.render(
    <Provider store={store}>
        <App />
   </Provider>, 
document.getElementById('root'));
