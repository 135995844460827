//Initial State
const INITIAL_STATE = {
    
    searchIsOpen: false,
    searchIsLoading: false,
    searchKey: 0,
    searchResults: '',
    searchFound: 0,
}

//Reducer and Actions
const searchReducer = (state = INITIAL_STATE, action) => {
switch (action.type) {
  
    case 'TOOGLE_SEARCH_FIELD':
        return {
            ...state,
            searchIsOpen: !state.searchIsOpen
        }
  
    default:
        return state
}
}

//Export Reducer
export default searchReducer;