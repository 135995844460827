//Import Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Styles and Assets
import './pages-directory.styles.scss';

//Import UI components
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarsIcon from '@material-ui/icons/Stars';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArchiveIcon from '@material-ui/icons/Archive';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import Pagination from '../../components/pagination/pagination.component';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, getId, authAction } from '../../utils/user-validation/user-validation.utils';

class PagesDirectory extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          loadingResults: false,
          loadingUnlock: 0,
          loadingDuplicate: 0,
          loadingArchivation: 0,
          loadingDelete: 0,
          creatingContent: false,
          formError: false,
          formErrorMsg: '',
          categories: [],
          formItems: {
              searchKey: {
                  id: 'searchKey',
                  label: 'Procurar por',
                  value: '',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: []                 
              },
              category: {
                  id: 'category',
                  label: 'Categoria',
                  value: 0,
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: []                 
              },
              situation: {
                  id: 'situation',
                  label: 'Situação',
                  value: '0',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: []                 
              },
              ordering: {
                id: 'ordering',
                label: 'Ordenar por',
                value: 'b.Created DESC',
                hasTouched: false,
                hasError: false,
                errorMsg: '',
                rules: []                 
            }
          },
          results: [],
          pagination: {
            page: 1,
            pages: 1,
            total: 0
          },
          situationOptions: [
            {
              label: 'Todas',
              value: 0
            },
            {
              label: 'Drafts',
              value: 1
            },
            {
              label: 'Publicadas',
              value: 2
            },
            {
              label: 'Arquivadas',
              value: 6
            },
          ],
          orderOptions: [
            {
              label: 'Mais recentes',
              value: 'b.Created DESC'
            },
            {
              label: 'Mais antigos',
              value: 'b.Created ASC'
            },
            {
              label: 'Título (A-Z)',
              value: 'b.Title ASC'
            },
            {
              label: 'Título (Z-A)',
              value: 'b.Title DESC'
            },
            {
              label: 'Categoria (A-Z)',
              value: 'CategoryName ASC'
            },
            {
              label: 'Categoria (Z-A)',
              value: 'CategoryName DESC'
            },
          ],
          toApprove: 0
        }
    }

    getCategories = () => {

      const userToken = getToken();
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken
        }),
      };

      fetch('/api/get_categories.php', requestOptions)
      .then(data => data.json())
      .then(data => {
          this.setState({ 
              ...this.state, 
              categories: data.items
           });
      })

    }
    
    getContents = (page) => {

      this.setState({
        ...this.state,
        loadingResults: true
      });

      const searchKey = this.state.formItems.searchKey.value;
      const category = this.state.formItems.category.value;
      const situation = this.state.formItems.situation.value;
      const ordering = this.state.formItems.ordering.value;
      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          page: page,
          searchKey: searchKey,
          category: category,
          situation: situation,
          ordering: ordering
        }),
      };

      fetch("/api/get_contents.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
        
        const pagination = this.state.pagination;
        pagination.total = data.totalItems;
        pagination.page= data.currentPage;
        pagination.pages = data.totalPages;
        const results = data.items;

        this.setState({ 
          ...this.state, 
          loadingDuplicate: 0,
          pagination: pagination,
          results: results,
          loadingResults: false,
          toApprove: data.approvalCounter
        });

      })
      .catch((error) => {
        console.dir(error);
      });

    }

    componentDidMount(){

      // Verify for Session Modals
      const hasModalToOpen = localStorage.getItem('openModal');
      if(hasModalToOpen && hasModalToOpen === '1'){
          const modalObj = JSON.parse(localStorage.getItem('modalObj'));
          this.props.openModal(modalObj);
      }

      this.getCategories();
      this.getContents(1);

    }

    //Rendering
    render(props){

      // FORMS: INPUT CHANGE HANDLE FUNCTION
      const handleChange = (event, callbacks) => {

          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // Get current FormItems object from State
          const formItems = {
              ...this.state.formItems
          };

          // Get State of the Input
          const formItem = {
              ...formItems[name]
          };

          // Validate Element if it has been Touched Before
          if(formItem.hasTouched){
              if(formItem.rules){
                  const validation = validate(value, formItem.rules);
                  formItem.hasError = validation[0];
                  formItem.errorMsg = validation[1];
              }else{
                  formItem.hasError = false;
                  formItem.errorMsg = '';
              }
          }

          // Set the new value to element
          formItem.value = value;

          // Update formItems collection
          formItems[name] = formItem;

          // Update State
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });

          if(callbacks){
            callbacks.map(item => {
              return item(event);
            })
          }

      };

      // FORMS: INPUT BLUR HANDLE FUNCTION
      const handleBlur = (event) => {
                  
          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // formItems collection
          const formItems = {
              ...this.state.formItems
          };

          // state of modified element
          const formItem = {
              ...formItems[name]
          };

          formItem.hasTouched = true;
          if(formItem.rules){
              const validation = validate(value, formItem.rules);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }

          // Update formItems collection
          formItems[name] = formItem;

          // Update state
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });

      }

      // FORMS: SUBMIT
      const handleSubmit = (event) => {
        event.preventDefault();
        this.getContents(1);
      }
      
      // Format Data
      const formatDate = (date, withHour) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

          let h = date.substr(11,2);
          let n = date.substr(14,2);
          sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

          sDate = d + '/' + m + '/' + y;

        }

        return sDate;

      }

      // Create Content
      const createContent = () => {

        this.setState({
          ...this.state,
          creatingContent: true
        });

        const userToken = getToken();

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken
          }),
        };

        fetch("/api/content_create.php", requestOptions)
        .then(async (response) => {

          const data = await response.json();

          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          authAction(data.authenticated);

          const gotoPage = '/edit/' + data.contentId;
          this.props.history.push(gotoPage);

        })
        .catch((error) => {
          console.dir(error);
        });

      }

      // Unlock Content
      const unlockContent = (id) => {

        this.setState({
          ...this.state,
          loadingUnlock: id
        });

        const userToken = getToken();

        const results = this.state.results;

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: id
          }),
        };

        fetch("/api/content_lock.php", requestOptions)
        .then(async (response) => {

          const data = await response.json();

          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          authAction(data.authenticated);

          if(!data.success){

            const modalObj = {
              modalIcon: 'priority_high',
              modalType: 'error',
              modalComponent: 'GENERAL_ALERT',
              modalTitle: 'Aviso',
              modalParameters: {
                  text: data.feedback
              },
              modalActions: null
            }

            this.props.openModal(modalObj);

          }else{

            for(let i=0; i<results.length; ++i){

              if(results[i].id === data.contentId){
                results[i].lock = data.lockId;
              }

            }

          }

          this.setState({
            ...this.state,
            loadingUnlock: 0,
            results: results
          });

        })
        .catch((error) => {
          console.dir(error);
        });

      }

      const confirmDuplicateContent = (id) => {

        const results = this.state.results;

        let pageTitle = '';

        for(let i=0; i<results.length; ++i){
          if(results[i].id === id){
            pageTitle = results[i].title;
          }
        }

        const modalObj = {
          modalIcon: 'content_copy',
          modalType: 'error',
          modalComponent: 'CONFIRMATION',
          modalTitle: 'Duplicar conteúdo',
          modalParameters: {
              text: 'Esta ação fará uma cópia da página "' + pageTitle + '".<br/><br/><b>Tem certeza que quer prosseguir?</b>'
          },
          modalActions: {
              action: duplicateContent,
              parameters: id
          }
          }
  
          this.props.openModal(modalObj);

      }
      
      const duplicateContent = (id) => {

          this.setState({
            ...this.state,
            loadingDuplicate: id
          })

          const userToken = getToken();

          const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              userToken: userToken,
              app: window.$appToken,
              id: id
            }),
          };
  
          fetch("/api/content_duplicate.php", requestOptions)
          .then(async (response) => {
  
            const data = await response.json();
  
            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
  
            authAction(data.authenticated);
  
            if(!data.success){
  
              const modalObj = {
                modalIcon: 'priority_high',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Aviso',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: null
              }
  
              this.props.openModal(modalObj);
  
            }else{
  
              const goTo = '/edit/' + data.contentId;
              this.props.history.push(goTo);

            }
            
          })
          .catch((error) => {
            console.dir(error);
          });

      }

      const confirmDelete = (id) => {

        const results = this.state.results;

        let pageTitle = '';

        for(let i=0; i<results.length; ++i){
          if(results[i].id === id){
            pageTitle = results[i].title;
          }
        }

        const modalObj = {
          modalIcon: 'delete',
          modalType: 'error',
          modalComponent: 'CONFIRMATION',
          modalTitle: 'Excluir',
          modalParameters: {
              text: 'Todas as versões do conteúdo <b>' + pageTitle + '</b> serão excluídas. Serão excluídos ainda os comentários, favoritos e bookmarks associados a este conteúdo.<br/><br/><b>Esta ação é irreversível. Tem certeza que quer prosseguir?</b>'
          },
          modalActions: {
              action: deleteContent,
              parameters: id
          }
          }
  
          this.props.openModal(modalObj);

      }

      const deleteContent = (id) => {

        this.setState({
          ...this.state,
          loadingDelete: id
        })

        const userToken = getToken();

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: id
          }),
        };

        fetch("/api/content_delete.php", requestOptions)
        .then(async (response) => {

          const data = await response.json();

          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          authAction(data.authenticated);

          if(!data.success){

            const modalObj = {
              modalIcon: 'priority_high',
              modalType: 'error',
              modalComponent: 'GENERAL_ALERT',
              modalTitle: 'Aviso',
              modalParameters: {
                  text: data.feedback
              },
              modalActions: null
            }

            this.props.openModal(modalObj);

          }else{

            const results = this.state.results;

            for(let i=0; i<results.length; ++i){
              if(results[i].id === id){
                results.splice(i, 1);
              }
            }
            
            this.setState({
              ...this.state,
              loadingDelete: 0,
              results: results
            })


          }
          
        })
        .catch((error) => {
          console.dir(error);
        });

      }

      const confirmArchivation = (id) => {

        const results = this.state.results;

        let pageTitle = '';

        for(let i=0; i<results.length; ++i){
          if(results[i].id === id){
            pageTitle = results[i].title;
          }
        }

        const modalObj = {
          modalIcon: 'archive',
          modalType: 'error',
          modalComponent: 'CONFIRMATION',
          modalTitle: 'Arquivar',
          modalParameters: {
              text: 'Ao arquivar a página "' + pageTitle + '" ela não estará mais disponível aos visitantes do site.<br/><br/><b>Tem certeza que quer prosseguir?</b>'
          },
          modalActions: {
              action: archiveContent,
              parameters: id
          }
          }
  
          this.props.openModal(modalObj);

      }

      const archiveContent = (id) => {

        this.setState({
          ...this.state,
          loadingArchivation: id
        })

        const userToken = getToken();

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: id
          }),
        };

        fetch("/api/content_archive.php", requestOptions)
        .then(async (response) => {

          const data = await response.json();

          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          authAction(data.authenticated);

          if(!data.success){

            const modalObj = {
              modalIcon: 'priority_high',
              modalType: 'error',
              modalComponent: 'GENERAL_ALERT',
              modalTitle: 'Aviso',
              modalParameters: {
                  text: data.feedback
              },
              modalActions: null
            }

            this.props.openModal(modalObj);

          }else{

            const results = this.state.results;

            for(let i=0; i<results.length; ++i){
              if(results[i].id === id){
                results[i].hasPublishedVersion = false;
              }
            }
            
            this.setState({
              ...this.state,
              loadingArchivation: 0,
              results: results
            })


          }
          
        })
        .catch((error) => {
          console.dir(error);
        });
      }

      const editContent = (id) => {
        const goTo = '/edit/' + id;
        this.props.history.push(goTo);
      }

      const approvalMsg = (counter) => {

        if(counter > 0){

            const userObj = JSON.parse(localStorage.getItem('abricco'));
            if(userObj.type === '5'){

                if(counter === 1){
                  return(
                    <div className="alert-box" onClick={goToApprovalList}>
                      <span className='material-icons'>error</span>Há 1 conteúdo aguardando revisão para ser publicado.
                    </div>
                  )
                }else{
                    return(
                      <div className="alert-box" onClick={goToApprovalList}>
                        <span className='material-icons'>error</span>Há {counter} conteúdos aguardando revisão para serem publicados.
                      </div>
                    )
                }

            }else{

                if(counter === 1){
                  return(
                    <div className="alert-box">
                      <span className='material-icons'>error</span>Um de seus conteúdos aguarda revisão para ser publicado.
                    </div>
                  )
                }else{
                    return(
                      <div className="alert-box">
                        <span className='material-icons'>error</span>{counter} de seus conteúdos aguardam revisão para serem publicados.
                      </div>
                    )
                }

            }

          
        }
        return;
        
      }

      const goToApprovalList = () => {
        window.location = '/approve-contents';
      }
      

      return (
        <div className="page">
          
          <div className="content pages-directory">

            { /* ALERT BOX */ }
            <div className={(this.state.toApprove > 0) ? 'container' : 'container hidden'}>{approvalMsg(this.state.toApprove)}</div>
            
            {/* HEADING */}
            <HeadingViewMode type={1} title="Pages Directory" />

            { /* TOOLBAR */ }
            <div className="container toolbar right">
            <Button color="primary" variant="contained" onClick={createContent} endIcon={(this.state.creatingContent)? <CircularProgress size={24}/> : <AddIcon />} disabled={this.state.creatingContent}>Criar Conteúdo</Button>
            </div>

            {/* SEARCH FORM */}
            <div className="container">
              <form className="form-content-search" onSubmit={handleSubmit}>
                <Grid container spacing={1}>

                  <Grid item xs={12} sm={5}>
                    <TextField
                        name="searchKey"
                        id={this.state.formItems.searchKey.id}
                        value={this.state.formItems.searchKey.value}
                        label={this.state.formItems.searchKey.label}
                        placeholder={this.state.formItems.searchKey.placeholder}
                        error={this.state.formItems.searchKey.hasError}
                        helperText={this.state.formItems.searchKey.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={this.state.formItems.category.hasError}
                    >
                    <InputLabel htmlFor={this.state.formItems.category.id}>{this.state.formItems.category.label}</InputLabel>
                    <Select
                    native
                    fullWidth
                    name={this.state.formItems.category.id}
                    id={this.state.formItems.category.id}
                    value={this.state.formItems.category.value}
                    label={this.state.formItems.category.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
                    <option key="cat-0" value="0">Todas</option>
                    {this.state.categories.map((item) => {
                      let catKey = 'cat-' + item.categoryId;
                      return(<option key={catKey} value={item.categoryId}>{item.categoryTitle}</option>);
                    })}
                    </Select>
                    <FormHelperText>
                    {this.state.formItems.category.errorMsg}
                    </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={this.state.formItems.situation.hasError}
                    >
                    <InputLabel htmlFor={this.state.formItems.situation.id} >{this.state.formItems.situation.label}</InputLabel>
                    <Select
                    native
                    fullWidth
                    name={this.state.formItems.situation.id}
                    id={this.state.formItems.situation.id}
                    value={this.state.formItems.situation.value}
                    label={this.state.formItems.situation.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
    
                    {this.state.situationOptions.map((item, index) => {
                    return (
                    <option
                    key={"ordering-" + index}
                    value={item.value}
                    >
                    {item.label}
                    </option>
                    );
                    })}
                    </Select>
                    <FormHelperText>
                    {this.state.formItems.situation.errorMsg}
                    </FormHelperText>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={2}>
                    <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={this.state.formItems.ordering.hasError}
                    >
                    <InputLabel htmlFor={this.state.formItems.ordering.id} >{this.state.formItems.ordering.label}</InputLabel>
                    <Select
                    native
                    fullWidth
                    name={this.state.formItems.ordering.id}
                    id={this.state.formItems.ordering.id}
                    value={this.state.formItems.ordering.value}
                    label={this.state.formItems.ordering.label}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    >
    
                    {this.state.orderOptions.map((item, index) => {
                    return (
                    <option
                    key={"ordering-" + index}
                    value={item.value}
                    >
                    {item.label}
                    </option>
                    );
                    })}
                    </Select>
                    <FormHelperText>
                    {this.state.formItems.ordering.errorMsg}
                    </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={1}>
                  <Button color="primary" variant="outlined" onClick={handleSubmit} fullWidth >Filtrar</Button>
                </Grid>

              </Grid>
              </form>
            </div>

            {/* RESULTS */}
            <div className='container results'>
                  
                  {/* LOADING */}
                  <div className={(this.state.loadingResults)?'results-loading':'results-loading hidden'}>
                    <CircularProgress size={48} />
                  </div>
                  
                  {/* LIST OF RESULTS */}
                  <div className={(this.state.results.length > 0) ? 'results-list' : 'results-list hidden'}>
                      <table>
                          <thead>
                            <tr>
                              <th className="cell-xs center"></th>
                              <th className="cell-sm center hide-in-mobile">Id</th>
                              <th className="left">Título</th>
                              <th className="cell-md left hide-in-mobile">Tipo</th>
                              <th className="cell-l left hide-in-mobile">Categoria</th>
                              <th className="cell-l left hide-in-mobile">Autor</th>
                              <th className="cell-l left hide-in-mobile">Publicado em</th>
                              <th className="cell-xs center hidden"></th>
                              <th className="cell-xs center hide-in-mobile"></th>
                              <th className="cell-xs center hide-in-mobile"></th>
                              <th className="cell-xs center hide-in-mobile"></th>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              this.state.results.map((item, index) => {

                                let isOwner = (parseInt(item.lock) === parseInt(getId()) || parseInt(item.lock) === 0) ? true : false;
                                let hasUnlockTicket = item.hasUnlockTicket;
                                
                                let tooltipText = '';
                                let icon = (<LockIcon />);

                                if(parseInt(item.lock) > 0 && isOwner){
                                  tooltipText = 'Bloqueado. Clique para desbloquear';
                                }else if(parseInt(item.lock)>0 && !isOwner){
                                  if(hasUnlockTicket){
                                    icon = (<AccessTimeIcon />);
                                    tooltipText = 'Bloquedo. Já existe um pedido de desbloqueio desde ' + formatDate(item.unlockTicketDate, true);
                                  }else{
                                    tooltipText = 'Bloquedo. Clique para solicitar o desbloqueio a ' + item.owner;
                                  }
                                  
                                }else{
                                  icon = (<LockOpenIcon />);
                                  tooltipText = 'Desbloqueado. Clique para bloquear';
                                }

                                let keyTable = 'cell-content-' + index;
                                
                                let sttsLabel = 'Draft';
                                let sttsColor = '';
                                if(item.hasPublishedVersion && item.approved === 1){
                                  sttsLabel = 'Publicado';
                                  sttsColor = 'primary';
                                }
                                if(item.hasPublishedVersion && item.approved === 0){
                                  sttsLabel = 'Em análise';
                                  sttsColor = 'alert';
                                }

                                return(
                                  <tr key={keyTable}>
                                      <td className="cell-xs center" onClick={() => editContent(item.id)}>
                                        <Tooltip title={sttsLabel} arrow>
                                            <div className={'badge ' + sttsColor}></div>
                                        </Tooltip>
                                      </td>
                                      <td className="cell-sm center hide-in-mobile" onClick={() => editContent(item.id)}>{item.id}</td>
                                      <td className="left" onClick={() => editContent(item.id)}>{item.title}</td>
                                      <td className="cell-md left" onClick={() => editContent(item.id)}>
                                        {(item.premium) ? 'Premium' : 'Público'}
                                      </td>
                                      <td className="cell-l left hide-in-mobile" onClick={() => editContent(item.id)}>{(item.category === 0) ? 'Nenhuma' : item.categoryName}</td>
                                      <td className="cell-l left hide-in-mobile" onClick={() => editContent(item.id)}>{item.owner}</td>
                                      <td className="cell-l left hide-in-mobile" onClick={() => editContent(item.id)}>{(item.hasPublishedVersion)? formatDate(item.publishDate, true) : 'Não publicado'}</td>
                                      <td className="cell-xs center hidden">
                                      <Tooltip title={tooltipText} arrow>
                                          <span>
                                            <IconButton color="default" disabled={(this.state.loadingUnlock === item.id)} onClick={() => unlockContent(item.id)}>
                                              {(this.state.loadingUnlock === item.id)? <CircularProgress size={24} /> : icon}
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </td>
                                      {
                                      /*
                                      <td className="cell-xs center hide-in-mobile">
                                        <Tooltip title="Duplicar conteúdo" arrow>
                                        <IconButton onClick={() => confirmDuplicateContent(item.id)}>
                                          {(this.state.loadingDuplicate === item.id) ? <CircularProgress size={24} /> : <FileCopyIcon />}
                                        </IconButton>
                                        </Tooltip>
                                      </td>
                                      */
                                      }
                                      
                                      <td className="cell-xs center hide-in-mobile">
                                        <Tooltip title="Visitar a página" arrow>
                                        <span>
                                        {
                                          (item.hasPublishedVersion)?
                                          <a className="icon-link" href={item.url} target="_blank"><VisibilityIcon /></a>:
                                          <span className="icon-link disabled"><VisibilityIcon /></span>
                                        }  
                                        </span>
                                        </Tooltip>
                                      </td>

                                      <td className="cell-xs center hide-in-mobile">
                                        <Tooltip title="Arquivar conteúdo" arrow>
                                        <span>
                                        <IconButton onClick={() => confirmArchivation(item.id)} disabled={!item.hasPublishedVersion}>
                                          {(this.state.loadingArchivation === item.id) ? <CircularProgress size={24} /> : <ArchiveIcon />}
                                        </IconButton>
                                        </span>
                                        </Tooltip>
                                      </td>

                                      <td className="cell-xs center hide-in-mobile">
                                        <Tooltip title="Excluir conteúdo" arrow>
                                        <span>
                                        <IconButton onClick={() => confirmDelete(item.id)} disabled={(item.category == 1 || item.category == 2)}>
                                        {(this.state.loadingDelete === item.id) ? <CircularProgress size={24} /> : <DeleteIcon />}
                                        </IconButton>
                                        </span>
                                        </Tooltip>
                                      </td>
                                      
                                  </tr>
                                )
                              })
                            }
                            
                          </tbody>

                      </table>
                      <Pagination 
                      currentPage={this.state.pagination.page} 
                      totalPages={this.state.pagination.pages} 
                      totalItems={this.state.pagination.total}
                      goToFunction={this.getContents}
                      />
                  </div>
                  
                  {/* NO RESULTS */}
                  <div className={(this.state.results.length === 0) ? 'no-results' : 'no-results hidden'}>
                      <HighlightOffIcon size={48} />
                      <p>
                        <b>Nenhum resultado encontrado</b>
                        <br/>Verifique os filtros e tente novamente.
                      </p>
                  </div>

            </div>
            
          </div>
        </div>
      );
    }
}


const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(PagesDirectory));