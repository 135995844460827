//Initial State
const INITIAL_STATE = {  
    visible: false,
    contents: [],
    limit: 1,
    onSave: null
}

//Reducer and Actions
const contentDirectoryReducer = (state = INITIAL_STATE, action) => {
switch (action.type) {

    case 'OPEN_DIRECTORY':
        return{
            ...state,
            visible: true,
            limit: action.payload.limit,
            onSave: action.payload.saveFunction
        }
    
    case 'CLOSE_DIRECTORY':
        return{
            ...state,
            visible: false
        }
    
    case 'GET_CONTENTS':
        return {
            ...state,
            contents: action.payload
        }

    default:
        return state
}
}

//Export Reducer
export default contentDirectoryReducer;