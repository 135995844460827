//Initial State
const INITIAL_STATE = {
    
    loadingResults: false,
    error: false,
    errorMsg: '',
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    searchKeyword: '',
    items: []
}

//Reducer and Actions
const PageDirectoryReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
    
        case 'FETCH_PAGES_PENDING' :
            return{
                ...state,
                loadingResults: true
            }
        
        case 'FETCH_PAGES_ERROR' :
            return{
                ...state,
                loadingResults: false,
                error: true,
                errorMsg: action.payload
            }
        
        case 'FETCH_PAGES_SUCCESS':
            return {
                ...state,
                loadingResults: false,
                error: false,
                errorMsg: '',
                currentPage: action.payload.currentPage,
                totalPages: action.payload.totalPages,
                totalItems: action.payload.totalItems,
                items: action.payload.items
            }
    
        default:
            return state
    }
}

//Export Reducer
export default PageDirectoryReducer;