import React, { Component } from "react";
import { connect } from 'react-redux';
import "./calendar-config.styles.scss";

import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import BoxSelection from "../box-selection/box-selection.component";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';

// Import Utils
import validate from "../../utils/form-validation/form-validation.utils";
import {getToken, authAction} from "../../utils/user-validation/user-validation.utils";
import { openModal } from "../../redux/modal/modal.actions";

class CalendarConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      alertIsVisible: false,
      alertType: 'info',
      alertMessage: '',
      shouldUpdateSlots: false,
      weekdays: [
        {
          label: "D",
          name: "Domingo",
          abbreviation: "Dom",
          value: 0,
          selected: false,
        },
        {
          label: "S",
          name: "Segunda",
          abbreviation: "Seg",
          value: 1,
          selected: true,
        },
        {
          label: "T",
          name: "Terça",
          abbreviation: "Ter",
          value: 2,
          selected: true,
        },
        {
          label: "Q",
          name: "Quarta",
          abbreviation: "Qua",
          value: 3,
          selected: true,
        },
        {
          label: "Q",
          name: "Quinta",
          abbreviation: "Qui",
          value: 4,
          selected: true,
        },
        {
          label: "S",
          name: "Sexta",
          abbreviation: "Sex",
          value: 5,
          selected: true,
        },
        {
          label: "S",
          name: "Sábado",
          abbreviation: "Sab",
          value: 6,
          selected: false,
        },
      ],
      formItems: {
        weekdays: {
            id: "weekdays",
            label: "Dias da Semana",
            placeholder: "",
            value: [1,2,3,4,5],
            hasTouched: true,
            hasError: false,
            errorMsg: "",
            rules: {
              minItems: {
                  size: 1,
                  label: 'dia'
              },
            },
        },
        startDate: {
          id: "startDate",
          label: "Data de Início",
          placeholder: "dd/mm/aaaa",
          value: "",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: {
            required: true,
            isDate: true,
          },
        },
        startTime: {
          id: "startTime",
          label: "Entrada",
          placeholder: "hh:mm",
          value: "9:00",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: {
            required: true,
            isTime: true,
          },
        },
        endDate: {
          id: "endDate",
          label: "Data de Término",
          placeholder: "dd/mm/aaaa",
          value: "",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: {
            required: true,
            isDate: true,
            afterDate: {
                id: 'startDate',
                label: 'data de início'
            }
          },
        },
        endTime: {
          id: "endTime",
          label: "Saída",
          placeholder: "hh:mm",
          value: "18:00",
          hasTouched: false,
          hasError: false,
          errorMsg: "",
          rules: {
            required: true,
            isTime: true,
            afterTime: {
                id: ['startInterval', 'endInterval', 'startTime'],
                label: 'ao horário de entrada e do intervalo (quando aplicável)',
                mandatory: [false, false, true]
            },
          },
        },
        startInterval: {
            id: "startInterval",
            label: "Intervalo",
            placeholder: "hh:mm",
            value: "",
            hasTouched: false,
            hasError: false,
            errorMsg: "",
            rules: {
              isTime: true,
              afterTime: {
                id: ['startTime'],
                label: 'ao horário de entrada',
                mandatory: [false]
              },

            },
          },
          endInterval: {
            id: "endInterval",
            label: "Retorno",
            placeholder: "hh:mm",
            value: "",
            hasTouched: false,
            hasError: false,
            errorMsg: "",
            rules: {
              isTime: true,
              afterTime: {
                id: ['startTime'],
                label: 'ao horário de entrada',
                mandatory: [false]
              },
              afterTime: {
                id: ['startInterval'],
                label: 'ao horário de entrada',
                mandatory: [false]
              },
            },
          },
        duration: {
            id: "duration",
            label: "Duração",
            placeholder: "mm",
            value: 30,
            hasTouched: false,
            hasError: false,
            errorMsg: "",
            rules: {
            required: true,
            },
        },
      }
    };
  }

  // Component MOUNT
  componentDidMount() {
    
    const currentDate = new Date();
    const startDate = currentDate.getDate() + "/" + parseInt(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
    currentDate.setDate(currentDate.getDate() + 7);
    const endDate = currentDate.getDate() + "/" + parseInt(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
    const { formItems } = this.state;
    const updatedForm = formItems;
    updatedForm.startDate.value = startDate;
    updatedForm.endDate.value = endDate;

    this.setState({
      loaded: true,
      formItems: updatedForm,
    });
  }

  // Handle weekdays toggle buttons
  handleSelectBox = (index) => {
    
    const { weekdays, formItems } = this.state;
    const updatedWeekdays = weekdays;
    updatedWeekdays[index].selected = !updatedWeekdays[index].selected;
    let selectedWeekdays = updatedWeekdays.filter((item) => (item.selected));
    formItems.weekdays.value = selectedWeekdays;

    // Validate select boxes
    if (formItems.weekdays.rules) {
        const validation = validate(formItems.weekdays.value, formItems.weekdays.rules, formItems);
        formItems.weekdays.hasError = validation[0];
        formItems.weekdays.errorMsg = validation[1];
    } else {
        formItems.weekdays.hasError = false;
        formItems.weekdays.errorMsg = "";
    }

    this.setState({ weekdays: updatedWeekdays, formItems: formItems });
  };

  // Handle Inputs Change
  handleChange = (event, callbacks) => {
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // Get current FormItems object from State
    const formItems = {
      ...this.state.formItems,
    };

    // Get State of the Input
    const formItem = {
      ...formItems[name],
    };

    // Validate Element if it has been Touched Before
    if (formItem.hasTouched) {
      if (formItem.rules) {
        const validation = validate(value, formItem.rules, formItems);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
      } else {
        formItem.hasError = false;
        formItem.errorMsg = "";
      }
    }

    // Set the new value to element
    formItem.value = value;

    // Update formItems collection
    formItems[name] = formItem;

    // Update State
    this.setState({
      ...this.state,
      formItems: formItems,
    });

    if (callbacks) {
      callbacks.map((item) => {
        return item(event);
      });
    }
  };

  // Handle Inputs Blur
  handleBlur = (event) => {
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // formItems collection
    const formItems = {
      ...this.state.formItems,
    };

    // state of modified element
    const formItem = {
      ...formItems[name],
    };

    formItem.hasTouched = true;
    if (formItem.rules) {
      const validation = validate(value, formItem.rules, formItems);
      formItem.hasError = validation[0];
      formItem.errorMsg = validation[1];
    } else {
      formItem.hasError = false;
      formItem.errorMsg = "";
    }

    // Update formItems collection
    formItems[name] = formItem;

    // Update state
    this.setState({
      ...this.state,
      formItems: formItems,
    });
  };

  // Handle Form Submit
  handleSubmit = () => {

    // Validate formItems
    const {formItems} = this.state;
    let hasError = false;
    let updatedFormItems = formItems;

    for (let key in updatedFormItems) {

        let obj = updatedFormItems[key];
        
        if (obj.rules) {
          let validation = validate(obj.value, obj.rules, formItems);
          if (validation[0]) {
            hasError = true;
          }
          obj.hasError = validation[0];
          obj.errorMsg = validation[1];
        } else {
          obj.hasError = false;
          obj.errorMsg = "";
        } 
        
    }

    if(hasError){
        this.setState({formItems: updatedFormItems});
    }else{
        this.setState({loading: true}, this.createSlots);
    }

  }

  // Close Alert Box
  closeAlert = () => {
    this.setState({alertIsVisible: false});
  }

  // Reset Form Data
  getInitialFormItems = () => {
    
    const {formItems} = this.state;
    const updatedFormItems = formItems;

    const dt = formItems.startDate.value.split('/');
    const m = parseInt(dt[1]) - 1;
    const y = parseInt(dt[2]);

    const currentDate = new Date();
    const startDate = currentDate.getDate() + "/" + parseInt(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
    currentDate.setDate(currentDate.getDate() + 7);
    const endDate = currentDate.getDate() + "/" + parseInt(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
    updatedFormItems.startDate.value = startDate;
    updatedFormItems.endDate.value = endDate;
    updatedFormItems.weekdays.value = [];
    updatedFormItems.startTime.value= '09:00';
    updatedFormItems.endTime.value = '18:00';
    updatedFormItems.duration.value = 30;
    updatedFormItems.startInterval.value = '';
    updatedFormItems.endInterval.value = '';

    return updatedFormItems;

  }

  // Call API to Slots Creation 
  createSlots = () => {
    
    const {formItems} = this.state;

    const userToken = getToken();
    const formData = {
        userToken: userToken,
        app: window.$appToken,
        startDate: formItems.startDate.value,
        endDate: formItems.endDate.value,
        weekdays: formItems.weekdays.value,
        startTime: formItems.startTime.value,
        endTime: formItems.endTime.value,
        duration: formItems.duration.value,
        lunchTimeStart: formItems.startInterval.value,
        lunchTimeEnd: formItems.endInterval.value,
    }

    const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
        },
        body: JSON.stringify(formData)
    };

    fetch("/api/slots_create.php", requestOptions)
    .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }

        authAction(data.authenticated);

        if(data.success){

          let icon = '';
          let type = '';
          let title = '';
          let message = '';

          // No slots has been created
          if(data.created === 0){

            icon = 'clear';
            type = 'error';
            title = 'Ops, algo correu mal :(';
            message = 'Nenhum novo slot foi criado devido a conflito com os já existentes na agenda.';

          }

          // Only few slots has been created
          if(data.created > 0 && data.created !== data.total){

            let difference = data.total - data.created;
            icon = 'done';
            type = 'success';
            title = 'Alguns slots não foram criados';
            message = data.created + ' slot(s) criado(s). ' + difference + ' slot(s) ignorado(s) devido a conflito com os já existentes na agenda';

          }

          // All slots has been created
          if(data.created > 0 && data.created === data.total){

            icon = 'done';
            type = 'success';
            title = 'Mais espaço na agenda!'
            message = data.created + ' novos slot(s) criado(s).';

          }

          // Callback to close the form and fetch the created slots when modal closes
          const callback = () => {this.props.toggle(false, true)};

          // Create modal object
          const modalObj = {
            modalIcon: icon,
            modalType: type,
            modalComponent: 'GENERAL_ALERT',
            modalTitle: title,
            modalParameters: {
                text: message
            },
            modalActions:[]
          }

          // Set open modal callback
          const openModal = () => {
            this.props.openModal(modalObj);
          }
          
          // Reset the form to default values
          //const updatedFormItems = this.getInitialFormItems();

          // Update State and Open Modal
          this.setState({loading: false, shouldUpdateSlots: true}, openModal);

        }

        if(!data.success){
            this.setState({alertIsVisible: true, alertMessage: data.feedback, alertType: 'error'});
        }

    })
    .catch((error) => {
        console.dir(error);
    });

  }

  render() {
    return (
      <div className="calendar-config">
        <Slide direction="up" in={this.state.loaded} mountOnEnter unmountOnExit>
          
          <div className="form">

            <div className="title-bar">
              <div className="title">Abrir slots de atendimento</div>
              <button className="close-button" onClick={() => this.props.toggle(false, this.state.shouldUpdateSlots)}>
                <CloseIcon />
              </button>
            </div>

            <div className="form-body">

                <div className={(this.state.alertIsVisible) ? 'alert-box ' + this.state.alertType :  'alert-box hidden'}>
                  <div className="message">Mensagem</div>
                  <button className="close-button" onClick={this.closeAlert}>
                    <CloseIcon />
                  </button>
                </div>
                
                <div className="form-group">
                <Grid container spacing={2}>    
                    <Grid item xs={12}>
                    <BoxSelection
                        id="slot-weekday"
                        label="Dias da Semana"
                        source={this.state.weekdays}
                        action={this.handleSelectBox}
                        error={(this.state.formItems.weekdays.hasError)}
                        errorMsg={this.state.formItems.weekdays.errorMsg}
                    />
                    </Grid>
                </Grid>
                </div>

                <div className="form-group">
                <Grid container spacing={2}>
                    <Grid item xs={12}><label>Período</label></Grid>
                    <Grid item xs={6}>
                    <TextField
                        id={this.state.formItems.startDate.id}
                        name={this.state.formItems.startDate.id}
                        label={this.state.formItems.startDate.label}
                        variant="outlined"
                        value={this.state.formItems.startDate.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        fullWidth
                        error={this.state.formItems.startDate.hasError}
                        helperText={this.state.formItems.startDate.errorMsg}
                    />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField
                        id={this.state.formItems.endDate.id}
                        name={this.state.formItems.endDate.id}
                        label={this.state.formItems.endDate.label}
                        variant="outlined"
                        value={this.state.formItems.endDate.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        fullWidth
                        error={this.state.formItems.endDate.hasError}
                        helperText={this.state.formItems.endDate.errorMsg}
                    />
                    </Grid>
                </Grid>    
                </div>
                
                <div className="form-group">
                <Grid container spacing={2}>
                <Grid item xs={12}><label>Horário de Atendimento</label></Grid>
                
                <Grid item xs={6}>
                <TextField
                    id={this.state.formItems.startTime.id}
                    name={this.state.formItems.startTime.id}
                    label={this.state.formItems.startTime.label}
                    variant="outlined"
                    value={this.state.formItems.startTime.value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={this.state.formItems.startTime.hasError}
                    helperText={this.state.formItems.startTime.errorMsg}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id={this.state.formItems.endTime.id}
                    name={this.state.formItems.endTime.id}
                    label={this.state.formItems.endTime.label}
                    variant="outlined"
                    value={this.state.formItems.endTime.value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    error={this.state.formItems.endTime.hasError}
                    helperText={this.state.formItems.endTime.errorMsg}
                  />
                </Grid>

            </Grid>
            </div>

            <div className="form-group">
                <Grid container spacing={2}>

                    <Grid item xs={12}><label>Intervalo</label></Grid>

                    <Grid item xs={6}>
                    <TextField
                        id={this.state.formItems.startInterval.id}
                        name={this.state.formItems.startInterval.id}
                        label={this.state.formItems.startInterval.label}
                        variant="outlined"
                        value={this.state.formItems.startInterval.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        fullWidth
                        error={this.state.formItems.startInterval.hasError}
                        helperText={this.state.formItems.startInterval.errorMsg}
                    />
                    </Grid>
                    
                    <Grid item xs={6}>
                    <TextField
                        id={this.state.formItems.endInterval.id}
                        name={this.state.formItems.endInterval.id}
                        label={this.state.formItems.endInterval.label}
                        variant="outlined"
                        value={this.state.formItems.endInterval.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        fullWidth
                        error={this.state.formItems.endInterval.hasError}
                        helperText={this.state.formItems.endInterval.errorMsg}
                    />
                    </Grid>

                </Grid>
                </div>

                <div className="form-group">
                <Grid container spacing={2}>
                    <Grid item xs={12}><label>Duração das Sessões</label></Grid>
                    <Grid item xs={2}>
                    <TextField
                        id={this.state.formItems.duration.id}
                        name={this.state.formItems.duration.id}
                        label={this.state.formItems.duration.label}
                        variant="outlined"
                        value={this.state.formItems.duration.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        fullWidth
                        error={this.state.formItems.duration.hasError}
                        helperText={this.state.formItems.duration.errorMsg}
                    />
                    </Grid>
                    <Grid item xs={10} className="aditional-info">Minutos</Grid>
                </Grid>    
                </div>
                
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="btn-wrapper side-by-side on-right">
                            <Button variant="outlined" color="default" disableElevation onClick={() => this.props.toggle(false, this.state.shouldUpdateSlots)}>Cancelar</Button>
                            <Button variant="contained" color="primary" disableElevation onClick={this.handleSubmit} disabled={(this.state.loading)}>Abrir Slots</Button>
                        </div>
                    </Grid>
                </Grid>

            </div>
          </div>
         
        </Slide>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData)),
});

export default connect(null, mapDispatchToProps)(CalendarConfig);