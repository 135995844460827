
import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import Reducers from './reducers';


//Object with the Middlewares List
const middlewares = [thunk];

//Store
const store = createStore(Reducers, applyMiddleware(...middlewares));

export default store;