import React, {Component} from 'react';
import { connect } from "react-redux";
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken } from '../../utils/user-validation/user-validation.utils';
import {lockScrollbar, fetchData} from '../../utils/utils';
import { openModal } from '../../redux/modal/modal.actions';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BoxSelection from "../../components/box-selection/box-selection.component";
import CircularProgress from "@material-ui/core/CircularProgress";

class NewClass extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            creating: false,
            courses: [],
            weekdays: [
                {
                  label: "D",
                  name: "Domingo",
                  abbreviation: "Dom",
                  value: 0,
                  selected: false,
                },
                {
                  label: "S",
                  name: "Segunda",
                  abbreviation: "Seg",
                  value: 1,
                  selected: false,
                },
                {
                  label: "T",
                  name: "Terça",
                  abbreviation: "Ter",
                  value: 2,
                  selected: false,
                },
                {
                  label: "Q",
                  name: "Quarta",
                  abbreviation: "Qua",
                  value: 3,
                  selected: false,
                },
                {
                  label: "Q",
                  name: "Quinta",
                  abbreviation: "Qui",
                  value: 4,
                  selected: false,
                },
                {
                  label: "S",
                  name: "Sexta",
                  abbreviation: "Sex",
                  value: 5,
                  selected: false,
                },
                {
                  label: "S",
                  name: "Sábado",
                  abbreviation: "Sab",
                  value: 6,
                  selected: false,
                },
            ],
            formItems: {
                content: {
                    id: 'content',
                    label: 'Treinamento',
                    placeholder: '',
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        haveToSelect: true
                    }
                  },
                  startDate: {
                    id: 'startDate',
                    label: 'Data de Início',
                    placeholder: 'dd/mm/aaaa',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      isDate: true,
                    }
                  },
                  endDate: {
                    id: 'endDate',
                    label: 'Data de Término',
                    placeholder: 'dd/mm/aaaa',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      isDate: true,
                      afterDate: {
                          id: 'startDate',
                          label: ' a data de início'
                      }
                    }
                  },
                  startTime: {
                    id: 'startTime',
                    label: 'De',
                    placeholder: 'hh:mm',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      isTime: true
                    }
                  },
                  endTime: {
                    id: 'endTime',
                    label: 'Até',
                    placeholder: 'hh:mm',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      isTime: true,
                      afterTime: {
                        id: ['startTime'],
                        label: 'ao horário de início',
                        mandatory: [false]
                      }
                    }
                  },
                  weekdays: {
                    id: 'weekdays',
                    label: 'Dias da Semana',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true
                    }
                  },
                  capacity: {
                    id: 'capacity',
                    label: 'Capacidade',
                    placeholder: 'Máximo de pessoas por turma',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      digits: true,
                      biggerThan: 0
                    }
                  },
                  urlRoom: {
                    id: 'urlRoom',
                    label: 'URL Video Conferência',
                    placeholder: 'https://zoom.us/...',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                  }
            }
        };
    }

    componentDidMount(){
        this.setState({courses: this.props.courses});
    }

    componentDidUpdate(prevProps){
        if(prevProps.courses !== this.props.courses){
            this.setState({courses: this.props.courses});
        }
    }

    // HANDLE INPUTS CHANGE
    handleChange = (event) => {
    
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;
    
        // Get current FormItems object from State
        const {formItems} = this.state;
        const updatedForm = formItems;
    
        // Get State of the Input
        const formItem = {...updatedForm[name]};
            
        // Validate Element if it has been Touched Before
        if(formItem.hasTouched){
            if(formItem.rules){
                const validation = validate(value, formItem.rules, updatedForm);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
        }
    
        // Set the new value to element
        formItem.value = value;
    
        // Update formItems collection
        updatedForm[name] = formItem;
    
        // Update State
        this.setState({formItems: updatedForm});
    }
    
    // HANDLE INPUTS BLUR
    handleBlur = (event) => {
    
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;
    
        // formItems collection
        const {formItems} = this.state;
        const updatedForm = formItems;
    
        // state of modified element
        const formItem = {...updatedForm[name]};
    
        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules, updatedForm);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }
    
        // Update formItems collection
        updatedForm[name] = formItem;
    
        // Update state
        this.setState({formItems: updatedForm});
    
    }

    // HANDLE SELECT BOXES
    handleSelectBox = (index) => {

        const { weekdays, formItems } = this.state;
        const updatedForm = formItems;
        const updatedWeekdays = weekdays;
        updatedWeekdays[index].selected = !updatedWeekdays[index].selected;
        
        let selectedWeekdays = updatedWeekdays.filter((item) => (item.selected)).map((item) => {return item.value});
        updatedForm.weekdays.value = selectedWeekdays.join(',').toString();

        // Validate select boxes
        if (updatedForm.weekdays.rules) {
            const validation = validate(updatedForm.weekdays.value, updatedForm.weekdays.rules, updatedForm);
            updatedForm.weekdays.hasError = validation[0];
            updatedForm.weekdays.errorMsg = validation[1];
        } else {
            updatedForm.weekdays.hasError = false;
            updatedForm.weekdays.errorMsg = "";
        }

        this.setState({ weekdays: updatedWeekdays, formItems: updatedForm });

    }

    // Create class
    createClass = () => {

        const { formItems } = this.state;
        const formIsValid = this.formIsValid();

        if(formIsValid){

            const userToken = getToken();
            const formData = {
                app: window.$appToken,
                token: userToken,
                content: formItems.content.value,
                startDate: formItems.startDate.value,
                endDate: formItems.endDate.value,
                startTime: formItems.startTime.value,
                endTime: formItems.endTime.value,
                weekdays: formItems.weekdays.value,
                capacity: formItems.capacity.value,
                urlRoom: formItems.urlRoom.value
            };

            fetchData("/api/classes_create.php", formData, true, 
                (data) => {

                    const modalCallback = () => {
                        this.props.toggle();
                        this.props.refresh();
                    }

                    const modalObj = {
                        modalIcon: 'done',
                        modalType: 'success',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Pronto!',
                        modalParameters: {
                            text: 'Turma criada com sucesso.'
                        },
                        modalActions:{
                            action: modalCallback
                        }
                    }
                    const openModal = () => {
                        this.props.openModal(modalObj);
                    };

                    this.setState({creating: false}, openModal);

                },
                (error) => {

                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Ops, algo correu mal',
                        modalParameters: {
                            text: error
                        },
                        modalActions:[]
                    }
                    const openModal = () => {
                        this.props.openModal(modalObj);
                    };

                    this.setState({creating: false}, openModal);

                }
            );

        }

    }

    // Form is Valid
    formIsValid = () => {

        let formIsValid = true;
        const { formItems } = this.state;

        for(let key in formItems){

            let value = formItems[key].value;
            let rules = formItems[key].rules;
            if(rules){
                let validation = validate(value, rules, formItems);
                if(validation[0]){
                    formIsValid = false;
                };
            }
        }

        return formIsValid;
        
    }

    render(){

        const {isOpen} = this.props;
        const {formItems, courses, weekdays, creating} = this.state;
        const formHasError = !this.formIsValid();

        // Put or remove scroll locker
        if(isOpen){
            lockScrollbar();
        }else{
            lockScrollbar('remove');
        }


        return(
            <div className={(isOpen) ? 'new-class-form' : 'new-class-form hidden'}>
                <div className="form">
                <Grid container spacing={1}>

                    <Grid item xs={12} className="margin-bottom--md">
                        <div className="mdl-heading">
                            <div className="module-heading">
                                <h3 className="heading-3 left">Nova Turma</h3>
                            </div>
                        </div>
                    </Grid>
                    
                    { /* CONTENT */ }
                    <Grid item xs={12} className="margin-bottom--sm">
                        <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={formItems.content.hasError}
                        >
                        <InputLabel htmlFor={formItems.content.id}>{formItems.content.label}</InputLabel>
                        <Select
                        native
                        fullWidth
                        name={formItems.content.id}
                        id={formItems.content.id}
                        value={formItems.content.value}
                        label={formItems.content.label}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        >
                        <option key="training-0" value={0}>Selecione</option>
                        {courses.map((item) => {
                            let itemKey = 'training-' + item.id;
                            return(<option key={itemKey} value={item.id}>{item.title}</option>);
                        })}
                        </Select>
                        <FormHelperText>
                        {formItems.content.errorMsg}
                        </FormHelperText>
                        </FormControl>
                    </Grid>

                    { /* DATES */ }
                    <Grid item xs={12} className="fieldset-label">Período</Grid>
                    <Grid item xs={12} md={6} className="margin-bottom--sm">
                        <TextField
                            id={formItems.startDate.id}
                            name={formItems.startDate.id}
                            value={formItems.startDate.value}
                            label={formItems.startDate.label}
                            placeholder={formItems.startDate.placeholder}
                            error={formItems.startDate.hasError}
                            helperText={formItems.startDate.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className="margin-bottom--sm">
                        <TextField
                            id={formItems.endDate.id}
                            name={formItems.endDate.id}
                            value={formItems.endDate.value}
                            label={formItems.endDate.label}
                            placeholder={formItems.endDate.placeholder}
                            error={formItems.endDate.hasError}
                            helperText={formItems.endDate.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            size="small"
                        />
                    </Grid>
                    
                    {/* TIMES */}
                    <Grid item xs={12} className="fieldset-label">Horário</Grid>
                    <Grid item xs={12} md={6} className="margin-bottom--sm">
                        <TextField
                            id={formItems.startTime.id}
                            name={formItems.startTime.id}
                            value={formItems.startTime.value}
                            label={formItems.startTime.label}
                            placeholder={formItems.startTime.placeholder}
                            error={formItems.startTime.hasError}
                            helperText={formItems.startTime.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className="margin-bottom--sm">
                        <TextField
                            id={formItems.endTime.id}
                            name={formItems.endTime.id}
                            value={formItems.endTime.value}
                            label={formItems.endTime.label}
                            placeholder={formItems.endTime.placeholder}
                            error={formItems.endTime.hasError}
                            helperText={formItems.endTime.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            size="small"
                        />
                    </Grid>

                    {/* WEEKDAYS */}
                    <Grid item xs={12} className="fieldset-label">Dias da Semana</Grid>
                    <Grid item xs={12} className="margin-bottom--md">
                        <BoxSelection
                            id={formItems.weekdays.id}
                            source={weekdays}
                            action={this.handleSelectBox}
                            error={(formItems.weekdays.hasError)}
                            errorMsg={formItems.weekdays.errorMsg}
                        />
                    </Grid>

                    {/* URL */}
                    <Grid item xs={12} className="margin-bottom--sm">
                        <TextField
                            id={formItems.urlRoom.id}
                            name={formItems.urlRoom.id}
                            value={formItems.urlRoom.value}
                            label={formItems.urlRoom.label}
                            placeholder={formItems.urlRoom.placeholder}
                            error={formItems.urlRoom.hasError}
                            helperText={formItems.urlRoom.errorMsg}
                            variant="outlined"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            size="small"
                            fullWidth
                        />
                    </Grid>

                    {/* CAPACITY */}
                    <Grid item xs={12} className="margin-bottom--sm">
                        <TextField
                            id={formItems.capacity.id}
                            name={formItems.capacity.id}
                            value={formItems.capacity.value}
                            label={formItems.capacity.label}
                            placeholder={formItems.capacity.placeholder}
                            error={formItems.capacity.hasError}
                            helperText={formItems.capacity.errorMsg}
                            variant="outlined"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            size="small"
                        />
                    </Grid>
                    

                    {/* BUTTONS */}
                    <Grid item xs={12}>
                        <div className="btn-wrapper side-by-side on-right">
                            <Button 
                            variant="outlined" 
                            color="default" 
                            disableElevation 
                            disabled={creating}
                            onClick={this.props.toggle}
                            >Cancelar</Button>
                            <Button 
                            variant="contained" 
                            color="primary" 
                            disableElevation 
                            disabled={creating || formHasError}
                            onClick={this.createClass}
                            >{(creating) ? <CircularProgress size={24} />: 'Criar Turma'}</Button>
                        </div>
                    </Grid>

                </Grid>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData))
  });
  
export default connect(null, mapDispatchToProps)(NewClass);