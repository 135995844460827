import React from 'react';

const YoutubeIcon = (props) => {
    const viewBoxWidth = 64;
    const viewBoxHeight = 64;
    const viewBox = '0 0 ' + viewBoxWidth + ' ' + viewBoxHeight;
    const width = (props.width) ? props.width : viewBoxWidth;
    const height = (props.height) ? props.height : viewBoxHeight;

    return(
        <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox={viewBox}
        className={(props.className) ? `svg-icon ${props.className}` : 'svg-icon'}
        width = {width + 'px'}
        height ={height + 'px'}
        >
            <path 
                d="M56.14 19.83a6.36 6.36 0 0 0-4.46-4.48A152.23 152.23 0 0 0 32 14.28a152.33 152.33 0 0 0-19.68 1.07 6.35 6.35 0 0 0-4.45 4.48A66.5 66.5 0 0 0 6.81 32a66.69 66.69 0 0 0 1.06 12.24 6.24 6.24 0 0 0 4.45 4.41A152.23 152.23 0 0 0 32 49.72a152.23 152.23 0 0 0 19.68-1.07 6.24 6.24 0 0 0 4.45-4.41A66.69 66.69 0 0 0 57.19 32a67.38 67.38 0 0 0-1.05-12.17zM26.85 39.52v-15L40 32l-13.15 7.53z" 
            />
        </svg>
    );

}
export default YoutubeIcon;