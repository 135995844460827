import React, {Component} from 'react';
import './banner.styles.scss';


import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


class BannerViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            active: 0,
            isPaused: false,
            banners: (this.props.banners) ? this.props.banners : []
        }
    }

    setPlay = () => {

        if(!this.state.isPaused){

            const total = this.state.banners.length - 1;
            const active = (this.state.active < total) ? this.state.active + 1 : 0;
           
            this.setState({
                ...this.state,
                active: active
            })

        }
        
    }

    setPause = () => {

        this.setState({
            ...this.state,
            isPaused: true
        });

    }

    nextSlide = () => {

            const total = this.state.banners.length - 1;
            const active = (this.state.active < total) ? this.state.active + 1 : 0;
           
            this.setState({
                ...this.state,
                active: active
            })

    }

    prevSlide = () => {

        const total = this.state.banners.length - 1;
        const active = (this.state.active === 0) ? total : this.state.active - 1;
       
        this.setState({
            ...this.state,
            active: active
        })

    }

    unsetPause = () => {

        this.setState({
            ...this.state,
            isPaused: false
        });

    }

    gotoSlide = (index) => {

        this.setState({
            ...this.state,
            active: index
        })

    }

    gotoLink = (link) => {
        window.location = link;
    }

    componentDidMount(){

        this.bannerAutoPlay = window.setInterval(this.setPlay, 7000);
        
    }

    componentWillUnmount() {
        clearInterval(this.bannerAutoPlay);
    }

    render(props){

        return(
            <div className="module-banner">
                
                <div className="btn-previous" tabIndex="0" role="button" arial-label="Slide anterior" onClick={this.prevSlide}><KeyboardArrowLeftIcon fontSize="large" /></div>
                <div className="btn-next" tabIndex="0" role="button" arial-label="Próximo slide" onClick={this.nextSlide}><KeyboardArrowRightIcon fontSize="large" /></div>
                <div className="navigator">
                    {
                        this.state.banners.map((item, index) => {
                            let dotId = 'dot-' + index;
                            let dotLabel = 'slide ' + (index + 1);
                            let classes = (this.state.active === index) ? 'dot active' : 'dot';

                            return(
                                <div key={dotId} role="button" tabIndex="0" className={classes} aria-label={dotLabel} onClick={() => this.gotoSlide(index)}></div>
                            );
                        })
                    }
                </div>

                <div className="banners">
                {
                    this.state.banners.map((item, index) => {
        
                        let bannerId = 'banner-' + index;
                        let bgImg = "url('" + window.$imagesPath + item.src + "')";
                        let classes = (this.state.active === index) ? 'banner active' : 'banner';
        
                        return(
                            <div 
                            key={bannerId} 
                            className={classes} 
                            style={{backgroundImage: bgImg}} 
                            onMouseEnter={this.setPause} 
                            onMouseLeave={this.unsetPause}
                            onClick={() => this.gotoLink(item.link)}
                            tabIndex="0"
                            >
                                <div className="banner-info">
                                    <div className="label"><span>{item.label}</span></div>
                                    <div className="text">{item.text}</div>
                                </div>
                                
                            </div>
                        )
                    })
                }
                </div>
            </div>
            );
    }

}

export default BannerViewMode;