import React from 'react';
import './spacing.styles.scss';

const SpacingViewMode = (props) =>{

    const space = (props.space) ? props.space : 1;
    const classes = 'module-spacing space-' + space;

    return(<div className={classes}></div>);

}

export default SpacingViewMode;