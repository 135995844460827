import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './directory.styles.scss';
import '../page/page.styles.scss';
import '../../modules/blog/blog.styles.scss';


import {CircularProgress} from '@material-ui/core';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';
import {getUrlParameters} from '../../utils/utils';

// UI Componentes
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarsIcon from '@material-ui/icons/Stars';

// Import Components
import Pagination from '../../components/pagination/pagination.component';
import Avatar from '../../components/avatar/avatar.component';

class Directory extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.pageId = (this.props.pageId) ? this.props.pageId : this.props.match.params.pageId;
        this.state = {
            loading: true,
            results: [],
            pagination: {
                page: 1,
                pages: 1,
                total: 0
            },
            noResults: false
        };
    }


        componentDidMount(){

            const id = this.props.match.params.categoryId;
            if(id){
                this.getPosts(1, id);
            }else{
                window.location = '/404';
            }

        } 
    
        getPosts = (page) => {

        const id = this.props.match.params.categoryId;
        this.setState({loading: true, results: []});
        const urlParameters = getUrlParameters(this.props.location);
        const author = (urlParameters?.author) ? parseInt(urlParameters.author) : 0;
        const searchKey = '';
  
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            app: window.$appToken,
            page: page,
            searchKey: searchKey,
            author: author,
            category: id,
            subcategory: 0
          }),
        };
  
        fetch("/api/get_blog.php", requestOptions)
        .then(async (response) => {
  
          const data = await response.json();
  
          // check for error response
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          
          const pagination = this.state.pagination;
          pagination.total = data.totalItems;
          pagination.page= data.currentPage;
          pagination.pages = data.totalPages;
          const results = data.items;

          const noResults = (results.length === 0);
  
          this.setState({ 
            pagination: pagination,
            results: results,
            loading: false,
            noResults: noResults
          });
  
        })
        .catch((error) => {
          console.dir(error);
        });
  
        }
    
        formatDate = (date, withHour) => {

            let d = date.substr(8, 2);
            let m = date.substr(5, 2);
            let y = date.substr(0, 4);
            let sDate = '';

            let mNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

            if(withHour){

            let h = date.substr(11,2);
            let n = date.substr(14,2);
            sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

            }else{

            sDate = d + ' de ' + mNames[m - 1] + ', ' + y;

            }

            return sDate;

        }

        goto = (url) => {
            window.location = '/page/' + url;
        }

    //Rendering
    render(){

        const { loading, results, pagination } = this.state;
        
        if(loading){

            return(
                <div className='page'>
                <div className="loading"><CircularProgress size={48}/></div>
                </div>
            );

        }


        return(
            <div className='page'>
                <div className='content content-page directory-list module__blog'>
                <div className="blog-items container">

                    {
                        (results.length === 0) ?
                        <div className='no-results'>
                            <HighlightOffIcon fontSize="large" />
                            <p>
                                <span>Ops, nenhum abriccó por aqui...</span>
                                Nenhum conteúdo encontrado, tente refazer sua busca.
                            </p>
                        </div>:
                        <div className="results">
                            
                        {
                            results.map((item, index) => {
    
                                let postKey = 'post-' + this.props.contentId + '-' + index;
                                let thumb = (item.thumb) ? 'url("' + window.$imagesPath + item.thumbSrc + '")' : 'none';
    
                                return(
                                    <div key={postKey} id={postKey} className="post-card" onClick={() => this.goto(item.url)}>
                                        {(item.premium === 1)? (<div className="label"><span><StarsIcon />Premium</span></div>) : null}
                                        <div className='info'>
                                            <div className='title'>{item.title}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                        
                        

                    }
                    
                    <div className={(results.length > 0) ? 'pagination-bar' : 'pagination-bar hidden'}>
                        <Pagination 
                        currentPage={pagination.page} 
                        totalPages={pagination.pages} 
                        totalItems={pagination.total}
                        goToFunction={this.getPosts}
                        />
                    </div>
                </div>
                
                </div>
            </div>
        );
        
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(Directory));