import React, {Component} from 'react';
import { connect } from 'react-redux';
import './welcome.styles.scss';

import ContainerWrapper from '../../containers/container-wrapper/container-wrapper.component';
import {CircularProgress} from '@material-ui/core';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

import {getParameterByName} from '../../utils/utils';

class Welcome extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.pageSuccessId = 'content-39';
        this.pageErrorId = 'content-40';
        this.state = {
            loading: false,
            pageData: [],
            modules: [],
        }
    }

    getPageContent(pageId){

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              app: window.$appToken,
              id: pageId
            }),
          };
    
          fetch("/api/get_content.php", requestOptions)
          .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            if(!data.success){
                window.location = '/404';
            }

            this.setState({ 
              ...this.state, 
              loading: false,
              pageData: data.pageData,
              modules: data.modules
            });
    
          })
          .catch((error) => {
            console.dir(error);
          });
        
    }

    componentDidMount(){

        this.setState({
            ...this.state,
            loading: true
        })

        const id = getParameterByName('id');
        const key = getParameterByName('key');
        
        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              app: window.$appToken,
              id: id,
              key: key
            }),
          };
    
          fetch("/api/user_verify.php", requestOptions)
          .then(async (response) => {
    
            const data = await response.json();

            console.dir(data);

            if(data.success){
                this.getPageContent(this.pageSuccessId);
            }else{
                window.location = '/404';
            }

          });
    }

    //Rendering
    render(){
        
        return(
            <div className='page welcome'>
            <div className={(this.state.loading)? 'loading' : 'loading hidden'}><CircularProgress size={48}/></div>
          
            <div className='content content-page'>
            {
                // eslint-disable-next-line
                this.state.modules.map((item) => {
                        return(
                            <ContainerWrapper 
                            key={item.content}
                            pageData={this.state.pageData}
                            {...item} 
                            />
                        );
                })
            }
            </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default connect(null, mapDispatchToProps)(Welcome);