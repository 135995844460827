import React from 'react';
import './author.styles.scss';

// Import UI component
import Button from "@material-ui/core/Button";

const AuthorEditMode = (props) =>{

        return(
        <div className='author-edit-mode'>
            <div className="module-title-bar">Módulo Author : {props.contentId}</div>
            <p><b>Este módulo não permite edição</b></p>
            <p>O conteúdo deste módulo é preenchido automaticamente com os dados do autor deste conteúdo.</p>
            <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                <Button color="primary" variant="contained" disableElevation onClick={() => props.functions.toggleMode(props.contentId)}>Ok, entendi</Button>
            </div>
        </div>
        )
}


export default AuthorEditMode;
