import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';


import './my-favorites.styles.scss';

// Import Redux Action
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils

// Import Material UI components
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from '@material-ui/icons/Delete';


// Import Components
import ExpandCollapseViewMode from '../../modules/expand-collapse/expand-collapse.view.component';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
 
class MyBookmarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      action: 'list',
      itemToDelete: 0,
      results: []
    };
    }

    getItems(action, itemId){

      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          action: action,
          id: itemId,
          list: 0
        }),
      };

      fetch("/api/my_favorites.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

          if(data.success){
          
          const results = data.items;

          this.setState({ 
            ...this.state, 
            loading: false,
            results: results,
            itemToDelete: 0,
            action: 'list'
          });

        }

      })
      .catch((error) => {
        console.dir(error);
      });

    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.setState({
          ...this.state,
          loading: true,
        });
  
        this.getItems('list', 0);
      
    }

  render(props){
  
  const moreInfo = [
    {
    hasError: false,
    title: 'Como adicionar artigos aos meus bookmarks?',
    text: 'No final do seu artigo preferido encontrará o botão <b>Ler Depois</b><span class="material-icons">bookmark</span>, basta clicar para adicionar o artigo à sua lista. Para utilizar esta funcionalidade você precisa ser um membro da Abriccó.'
    },
    {
    hasError: false,
    title: 'Como remover artigos dos meus bookmarks?',
    text: 'Para remover um artigo dos seus Bookmarks, você pode:<ul><li>Desmarcar o botão <b>Ler depois</b><span class="material-icons">bookmark</span> no próprio artigo; ou</li><li>Clicar no botão <b>Remover</b><span class="material-icons">delete</span> do card do item em <b>Meus Bookmarks</b>.</li></ul>'
    }
  ];

  const deleteItem = (itemId) => {

    console.dir('show');

    this.setState({
      ...this.state,
      itemToDelete: itemId,
      action: 'delete'
    });

    this.getItems('delete', itemId);

  }

  const goTo = (url) => {
    window.location = url;
  }
      

  return (
    <div className="page my-favorites">

      { /* PAGE */ }
      <div className="content">
        
          <div className="container">
                <div className="module-heading margin-bottom--lg">
                    <h1 className="heading-1">Meus Bookmarks</h1>
                </div>

                <div className="module-richtext">
                    <p className="center">Na correria do dia-a-dia nem sempre conseguimos ler tudo o que gostaríamos.<br/>Veja aqui todos os artigos que você adicionou à lista para Ler Depois.</p>
                </div>
          </div>
          <div className="container results">
            <div className={(this.state.loading) ? 'loading' : 'loading hidden'}><CircularProgress size={48} /></div>
            <div className={(this.state.results.length > 0) ? 'has-results' : 'has-results hidden'}>
                {this.state.results.map(item => {

                  let bookmarkKey = 'bookmark-1-' + item.id;
                  let background = (item.thumb > 0) ? 'url("' + window.$thumbsPath + item.thumbSrc + '")' : 'none.jpg';

                return(
                <div key={bookmarkKey} className="bookmark" style={{backgroundImage: background}}>
                  <div className="action btn-wrapper">
                    <div className={(this.state.itemToDelete === item.id) ? 'btn-circle medium ghost deleting' : 'btn-circle medium ghost'} onClick={() => deleteItem(item.id)}>
                      <DeleteIcon />
                    </div>
                  </div>
                  <div className="title" onClick={() => goTo(item.url)}>{item.title}</div>
                </div>
                  )

                })}
                
            </div>
            <div className={(this.state.results.length > 0) ? 'no-results hidden' : 'no-results'}>
                <p><b>Nenhum abriccó por aqui...</b><br/>
                Você ainda não adicionou nenhum artigo aos seus bookmarks.</p>
            </div>
          </div>
          <div className="container">
          <ExpandCollapseViewMode contentId={0} items={moreInfo} />
          </div>
          

      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(MyBookmarks));