import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import './settings.styles.scss';

// Import Redux Action
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    
    this.state = {
      formItems: {
        fullname: {
          id: 'fullname',
          label: 'Nome Completo',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        email: {
          id: 'email',
          label: 'E-mail',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        birthdate: {
          id: 'birthdate',
          label: 'Data de Nascimento',
          placeholder: 'dd/mm/aaaa',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        current: {
          id: 'current',
          label: 'Senha Atual',
          placeholder: 'Digite a senha atual',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        new: {
          id: 'new',
          label: 'Nova Senha',
          placeholder: 'Digite a nova senha',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        confirm: {
          id: 'confirm',
          label: 'Confirmação',
          placeholder: 'Digite a nova senha',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
      }
    };
    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }
      
    }

  render(props){
  
    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event) => {

      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // Get current FormItems object from State
      const formItems = this.state.formItems;

      // Get State of the Input
      const formItem = {
          ...formItems[name]
      };

      // Validate Element if it has been Touched Before
      if(formItem.hasTouched){
          if(formItem.rules){
              const validation = validate(value, formItem.rules, formItems);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }
      }

      // Set the new value to element
      formItem.value = value;

      // Update formItems collection
      formItems[name] = formItem;


      // Update State
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  };

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = (event) => {
              
      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // formItems collection
      const formItems = {
          ...this.state.formItems
      };

      // state of modified element
      const formItem = {
          ...formItems[name]
      };

      formItem.hasTouched = true;
      if(formItem.rules){
          const validation = validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }

      // Update formItems collection
      formItems[name] = formItem;

      // Update state
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  }


  return (
    <div className="page">


      { /* PAGE */ }
      <div className="content dashboard-page">
        
          { /* PAGE TITTLE */ }
          <div className="module-heading margin-bottom--lg">
            <h1 className="heading-1">Configurações</h1>
          </div>
          

          { /* FORM PERSONAL DATA*/ }
          <form className="container compact">
          <div className="module-heading">
            <h4 className="heading-4 left">Meus dados</h4>
          </div>
          <div className="form-panel">
            
          <Grid container spacing={2}>

              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.fullname.id}
                  id={this.state.formItems.fullname.id}
                  value={this.state.formItems.fullname.value}
                  label={this.state.formItems.fullname.label}
                  placeholder={this.state.formItems.fullname.placeholder}
                  error={this.state.formItems.fullname.hasError}
                  helperText={this.state.formItems.fullname.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.email.id}
                  id={this.state.formItems.email.id}
                  value={this.state.formItems.email.value}
                  label={this.state.formItems.email.label}
                  placeholder={this.state.formItems.email.placeholder}
                  error={this.state.formItems.email.hasError}
                  helperText={this.state.formItems.email.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.birthdate.id}
                  id={this.state.formItems.birthdate.id}
                  value={this.state.formItems.birthdate.value}
                  label={this.state.formItems.birthdate.label}
                  placeholder={this.state.formItems.birthdate.placeholder}
                  error={this.state.formItems.birthdate.hasError}
                  helperText={this.state.formItems.birthdate.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12}>
                  <div className="btn-wrapper on-right">
                      <Button 
                      type="submit" 
                      color="primary" 
                      variant="contained" 
                      disableElevation 
                      >Atualizar</Button>
                  </div>
              </Grid>

          </Grid>
          </div>
          </form>


      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(Settings));