import React, {Component} from 'react';
import './posts.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";

// Import components

// Import Redux Actions

// Import Utils
import { getToken } from '../../utils/user-validation/user-validation.utils';
import validate from '../../utils/form-validation/form-validation.utils';


class PostsEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            authors: [],
            formItems: {
                category: {
                    id: 'category',
                    label: 'Categoria',
                    placeholder: '',
                    value: this.props.category,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                subcategory: {
                    id: 'subcategory',
                    label: 'Sub-Categoria',
                    placeholder: '',
                    value: (this.props.subcategory) ? this.props.subcategory : 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                author: {
                    id: 'author',
                    label: 'Autor',
                    placeholder: '',
                    value: (this.props.author) ? this.props.author : 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
            }
        }
    }

    getCategories = () => {

        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            deep: true
          }),
        };
  
        fetch('/api/get_categories.php', requestOptions)
        .then(data => data.json())
        .then(data => {
            this.setState({ 
                ...this.state, 
                categories: data.items,
                authors: data.authors
             });
        })
  
    }


    componentDidMount(){

        this.getCategories();
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event, callbacks) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = {
                ...this.state.formItems
            };

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;

            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            if(callbacks){
            callbacks.map(item => {
                return item(event);
            })
            }
        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const handleSubmit = (event) => {

            event.preventDefault();
            let category = this.state.formItems.category.value;
            let subcategory = this.state.formItems.subcategory.value;
            let author = this.state.formItems.author.value;
            

            const parameters = {
                category: category,
                subcategory: subcategory,
                author: author
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        const category = this.state.formItems.category.value;
        const subcategory = this.state.formItems.subcategory.value;
        const author = this.state.formItems.author.value;

    
        const categoriesList = this.state.categories.filter((item) => (item.categoryParent === 0));
        const subcategories = this.state.categories.filter((item) => (item.categoryParent > 0 && item.categoryParent == category));
        const subcategoriesList = (subcategories.length > 0) ? subcategories : [];

        return(
            <form className='posts-edit-mode'>
                <div className="module-title-bar">Módulo Últimos Posts: {this.props.contentId}</div>
                 <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.category.hasError}  >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.category.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.category.id}
                            id={this.state.formItems.category.id}
                            value={this.state.formItems.category.value}
                            label={this.state.formItems.category.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            <option key={'category-' + this.props.contentId + '-0'} value='0'>Selecione</option>
                            {categoriesList.map((item) => {
                                return (
                                <option
                                    key={"category-" + this.props.contentId + '-' + item.categoryId}
                                    value={item.categoryId}
                                >
                                    {item.categoryTitle}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.category.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.subcategory.hasError}  >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.subcategory.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.subcategory.id}
                            id={this.state.formItems.subcategory.id}
                            value={this.state.formItems.subcategory.value}
                            label={this.state.formItems.subcategory.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            <option key={'category-' + this.props.contentId + '-0'} value='0'>Todas</option>
                            {subcategoriesList.map((item) => {
                                return (
                                <option
                                    key={"category-" + this.props.contentId + '-' + item.categoryId}
                                    value={item.categoryId}
                                >
                                    {item.categoryTitle}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.subcategory.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.author.hasError}  >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.author.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.author.id}
                            id={this.state.formItems.author.id}
                            value={this.state.formItems.author.value}
                            label={this.state.formItems.author.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            <option key={'author-' + this.props.contentId + '-0'} value='0'>Todos</option>
                            {this.state.authors.map((item) => {

                                let names = item.fullname.split(' ');
                                let screenname = (names.length > 0) ? names[0] + ' ' + names[names.length - 1] : item.fullname;

                                return (
                                <option
                                    key={"author-" + this.props.contentId + '-' + item.id}
                                    value={item.id}
                                >
                                    {screenname}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.author.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    
                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={(category === '' || category === 0 || category === null || category === undefined)}
                            >Atualizar</Button>
                        </div>
                    </Grid>
                 </Grid>
            </form>
        )
    }
}

  
export default PostsEditMode;

