//Initial State
const INITIAL_STATE = {
    
    modalIsOpen: false,
    modalIcon: 'priority_high',
    modalType: '',
    modalComponent: '',
    modalTitle: '',
    modalActions: [],
    modalParameters: []
}

//Reducer and Actions
const modalReducer = (state = INITIAL_STATE, action) => {
switch (action.type) {
  
    case 'OPEN_MODAL':
        return {
            ...state,
            ...action.payload,
            modalIsOpen: true,
        }
    
        case 'CLOSE_MODAL':
            return{
                ...state,
                modalIsOpen: false
            }
  
    default:
        return state
}
}

//Export Reducer
export default modalReducer;