import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';


//Import assets and styles
import './assets/styles/global.scss';

// Import UI components
import materialTheme from './assets/styles/materialTheme';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

// Import components
import Modal from './modals/modal/modal.component';
import Header from './pages/header/header.component';
import Routes from './routes/routes';
import Footer from './pages/footer/footer.component';

import './App.scss';

//Actions
import { setMenuActiveItem } from './redux/menus/menus.actions';
import { setActiveUser } from './redux/active-user/active-user.actions';
import { setMenuItems } from './redux/menus/menus.actions';

// Import redux actions
import { addLoading, removeLoading } from './redux/auth/auth.actions';

const theme = createMuiTheme(materialTheme);

class App extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      specialPages: [],
      source: [],
      results: [],
    }
  }

  // Get Menu Items
  getMenuItems(){

    // Get source
  const requestOptions = {
    method: "POST",
    mode: "cors",
    headers: { 
    'Content-Type': 'application/json',
    "Access-Control-Request-Headers": "*",
    "Access-Control-Request-Method": "*"
    },
        body: JSON.stringify({
        app: window.$appToken
        }),
    };

    fetch("/api/menu_list.php", requestOptions)
    .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
        }

        // If page has been published
        if(data.success){

            this.setState({
                source: data.source,
                results: data.items,
                specialPages: data.pages,
                loading: false
            })

            this.props.setMenuItems(data.items)

        }

    })
    .catch((error) => {
        console.dir(error);
    });
  }


  componentDidMount(){

    if(!localStorage.getItem('abriccoAuth')){

      const abriccoAuth = {
        error: false,
        currentPage: window.location,
        title: null,
        feedback: null
      }
      localStorage.setItem('abriccoAuth', JSON.stringify(abriccoAuth));
    }

    this.getMenuItems();

    setTimeout(function(){ 
      
        let cookies = localStorage.getItem('user-accept-cookies');
        let cookiesAlert = document.getElementById('CookiesAlert');
        // eslint-disable-next-line
        if(cookies != 'true'){ 
          cookiesAlert.classList.remove('hidden');
        }

    }, 3000);

  }


  //Rendering
  render(props){

    const lockScrolling = (this.props.modal.modalIsOpen)?'site scroll-locked':'site';

    const acceptCookies = () => {
      localStorage.setItem('user-accept-cookies', true);
      let cookiesAlert = document.getElementById('CookiesAlert');
      cookiesAlert.classList.add('hidden');
    }

    const { specialPages } = this.state;

    return (

      <ThemeProvider theme={theme}>
      <div id="site" className={lockScrolling}>
        <div className={(this.state.loading) ? 'site-loading' : 'site-loading hidden'}></div>

        <Router forceRefresh={true}>
          <Header exceptions={specialPages} />
          <Routes />
          <Footer exceptions={specialPages} />
        </Router>
        <Modal isOpen={this.props.modal.modalIsOpen} />
        
      </div>
      <div className={(this.props.auth.appLoading) ? 'app-loading' : 'app-loading hidden'}>
      <LinearProgress />
      </div>
      <div id="CookiesAlert" className="cookies-alert hidden">
        <div className="wrapper">
          <div className="text">
            <h6>Cookies</h6>
            <p>Este site utiliza cookies principalmente para otimizar a experiência de usuário e medir a audiência. Ao continuar você declara estar ciente e de acordo com nossa <a className="dark" href="\privacidade" target="_blank">Política de Privacidade</a>.</p>
          </div>
          <div className="button">
            <div className="btn-wrapper">
            <Button variant="contained" color="primary" onClick={acceptCookies}>Ok, eu concordo</Button>
            </div>
          </div>
        </div>
      </div>
      </ThemeProvider>
      
    );

  }

}

const mapStateToProps = state => ({
  activeUser: state.activeUser, 
  modal: state.modal,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    setMenuActiveItem: item => dispatch(setMenuActiveItem(item)),
    setActiveUser: user => dispatch(setActiveUser(user)),
    addLoader: () => dispatch(addLoading()),
    removeLoader: () => dispatch(removeLoading()),
    setMenuItems: (items) => dispatch(setMenuItems(items))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
