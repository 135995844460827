import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './cards.styles.scss';

// Import UI components
import TextField from "@material-ui/core/TextField";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DeleteIcon from '@material-ui/icons/Delete';

// Import components
import IptImage from '../../components/ipt-image/ipt-image.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';



class CardForm extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            formItems: {
                src: {
                    id: 'src',
                    label: 'Imagem',
                    placeholder: '',
                    value: this.props.src,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                srcId: {
                    id: 'srcId',
                    label: 'Imagem',
                    placeholder: '',
                    value: this.props.srcId,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: null
                },
                title: {
                    id: 'title',
                    label: 'Título',
                    placeholder: '',
                    value: this.props.title,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                desc: {
                    id: 'desc',
                    label: 'Texto',
                    placeholder: '',
                    value: this.props.desc,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }

                },
                link: {
                    id: 'link',
                    label: 'Link',
                    placeholder: '',
                    value: this.props.link,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                        link: true
                    }
                },
            }
           
        }
    }

    componentDidMount(){

        
    }

    componentDidUpdate(prevProps, prevState){

        const formItems = this.state.formItems;
        let hasChanges = false;

        if (prevState.formItems.src.value !== this.props.src) {
            formItems.src.value = this.props.src;
            hasChanges = true;
        }

        if (prevState.formItems.srcId.value !== this.props.srcId) {
            formItems.srcId.value = this.props.srcId;
            hasChanges = true;
        }

        if (prevState.formItems.title.value !== this.props.title) {
            formItems.title.value = this.props.title;
            hasChanges = true;
        }

        if (prevState.formItems.desc.value !== this.props.desc) {
            formItems.desc.value = this.props.desc;
            hasChanges = true;
        }

        if (prevState.formItems.link.value !== this.props.link) {
            formItems.link.value = this.props.link;
            hasChanges = true;
        }

        if(hasChanges){
            this.setState({
                ...this.state,
                formItems: formItems
            })
        }
        
    }

    render(props){

        const setImage = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.src.value = obj[0].mediaFile;
            formItems.srcId.value = obj[0].mediaId;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();

            this.props.cardChange(this.props.cardId, 'src', obj[0].mediaFile);
            this.props.cardChange(this.props.cardId, 'srcId', obj[0].mediaId);
    
        }

        const unsetImage = () => {
    
            const formItems = this.state.formItems;
            formItems.src.value = '';
            formItems.srcId.value = 0;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            });

            this.props.cardChange(this.props.cardId, 'src', '');
            this.props.cardChange(this.props.cardId, 'srcId', 0);
    
        }

        const setCtaLink = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.link.value = url;
            
    
            formItems.link.hasTouched = true;
            const validation = validate(url, formItems.link.rules, formItems);
            formItems.link.hasError = validation[0];
            formItems.link.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });

            checkErrors();

            this.props.cardChange(this.props.cardId, 'link', url);
    
            this.props.closeDirectory();
    
        }

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            checkErrors();

            this.props.cardChange(this.props.cardId, name, value);

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            checkErrors();

        }

        const checkErrors = () => {

            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;
            let hasError = false;
            
            for(let i=0; i < size; ++i){
                
                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    hasError = true;
                }
            }

            this.props.cardValidation(this.props.cardId, hasError);


        }

        const cardClasses = (this.props.hasError) ? 'card-form card has-error' : 'card-form card';

        const allowDrop = (ev) => {
            ev.preventDefault();
          }
          
        const drag = (e) => {
            let objId = e.target.id;
            let obj = document.getElementById(objId);
            let wrapperId = 'cards-wrapper-' + this.props.contentId;
            let wrapper = document.getElementById(wrapperId);
            
            e.dataTransfer.setData('cardToSort', e.target.getAttribute('data-index'));
            obj.style.opacity = 0;

            
            let clonedObj = obj.cloneNode(true);
            clonedObj.id = 'cloned-card';
            clonedObj.style.position = 'fixed';
            clonedObj.style.top = -5000;
            clonedObj.style.opacity = 1;
            clonedObj.style.width = obj.style.width;
            clonedObj.classList.add('cloned-card');
            e.dataTransfer.setData('movedObj', objId);
            
            wrapper.appendChild(clonedObj);
            e.dataTransfer.setDragImage(clonedObj, 0, 0);

        }

        const dragEnd = (ev) => {
            let movedObj = document.getElementById(ev.target.id);
            movedObj.style.opacity = 1;
            let clonedObj = document.getElementById('cloned-card');
            clonedObj.remove();
        }
          
        const drop = (ev) => {
            ev.preventDefault();
            const el = ev.currentTarget;
            const oldIndex = ev.dataTransfer.getData('cardToSort');
            const newIndex = el.getAttribute("data-index");
            this.props.sortCards(oldIndex, newIndex);
        }

        return(
            <div 
            id={'card-' + this.props.contentId + '-' + this.props.cardId} 
            data-index={this.props.cardId} 
            className={cardClasses} 
            draggable="true" 
            onDragStart={drag} 
            onDrop={drop} 
            onDragOver={allowDrop}
            onDragEnd={dragEnd}

            >
                
                { /* Imagem */ }
                <div className="image-area">
                    <IptImage 
                    className="image" 
                    limit={1} 
                    action={setImage}
                    clearFunction = {unsetImage}
                    selectedFile={this.state.formItems.src.value} 
                    selectedImage={this.state.formItems.srcId.value} 
                    />
                </div>

                { /* Title */ }
                <div className="title">
                    <TextField
                    name={this.state.formItems.title.id}
                    id={this.state.formItems.title.id + this.props.cardId}
                    value={this.state.formItems.title.value}
                    label={this.state.formItems.title.label}
                    placeholder={this.state.formItems.title.placeholder}
                    error={this.state.formItems.title.hasError}
                    helperText={this.state.formItems.title.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                </div>

                { /* Desc */ }
                <div className="desc">
                    <TextField
                    name={this.state.formItems.desc.id}
                    id={this.state.formItems.desc.id + this.props.cardId}
                    value={this.state.formItems.desc.value}
                    label={this.state.formItems.desc.label}
                    placeholder={this.state.formItems.desc.placeholder}
                    error={this.state.formItems.desc.hasError}
                    helperText={this.state.formItems.desc.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    />
                </div>

                { /* Link */ }
                <div className="link">
                    <TextField
                    name={this.state.formItems.link.id}
                    id={this.state.formItems.link.id + this.props.cardId}
                    value={this.state.formItems.link.value}
                    label={this.state.formItems.link.label}
                    placeholder={this.state.formItems.link.placeholder}
                    error={this.state.formItems.link.hasError}
                    helperText={this.state.formItems.link.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                            aria-label="Procurar conteúdo"
                            edge="end"
                            onClick={() => this.props.openDirectory(1, setCtaLink)}
                            >
                            <DescriptionIcon /> 
                            </IconButton>
                        </InputAdornment>,
                    }}
                    />
                </div>

                { /* Buttons */ }
                <div className="buttons">
                    <div className="btn-wrapper centered">
                        <button className="btn-circle attention medium" onClick={() => this.props.removeCard(this.props.cardId)} ><DeleteIcon /></button>
                    </div>
                </div>

            </div>
        );
    }

}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(CardForm));