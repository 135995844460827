//Define selected item on menu
export const listCollections = collections => ({
    type: 'LIST_COLLECTIONS',
    payload: collections
})

export const openGallery = (limit, action) => ({
    type: 'OPEN_GALLERY',
    payload: {
        limit: limit,
        action: action
    }
})

export const closeGallery = () => ({
    type: 'CLOSE_GALLERY'
})