import React, {Component} from 'react';
import './classes.styles.scss';

import Button from "@material-ui/core/Button";

class Classes extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            classes: [],
            weekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            weekdaysFull: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        };
    }

    componentDidMount(){

        const classes = (this.props.classes) ? this.props.classes : [];
        this.setState({classes: classes});
    }

    componentDidUpdate(prevProps){
        if(prevProps.classes !== this.props.classes){
            this.setState({classes: this.props.classes});
        }
    }

    // FORMAT DATE
    formatDate = (date, withHour) => {

        if(date){
            let d = date.substr(8, 2);
            let m = date.substr(5, 2);
            let y = date.substr(0, 4);
            let sDate = '';
    
            if(withHour){
    
            let h = date.substr(11,2);
            let n = date.substr(14,2);
            sDate = d + '/' + m + '/' + y + ' às ' + h + 'h' + n;
    
            }else{
    
            sDate = d + '/' + m + '/' + y;
    
            }
    
            return sDate;

        }
        return;

    }

    // Format Time
    formatTime = (time) => {
        if(time){
            let t = time.split(':');
            let h = t[0];
            let m = t[1];
            return h + ':' + m;
        }
    }

    // Get selected weekdays
    getWeekdays = (str) => {
        
        console.dir(str);
        const { weekdays } = this.state;
        console.dir(weekdays);

        if(str && str.length > 0){
            if(str === '1,2,3,4,5'){
                return 'Seg - Sex';
            }
            const x = str.split(',');
    
            let days = [];
            x.forEach((item) => {
                let idx = parseInt(item);
                days.push(weekdays[idx]);
            });
           
            return days.join(',');
        }
        
    }

    render(){

        const { classes } = this.state;

        return(
            <div className="mdl mdl-classes">
                <div className="module-heading">
                    <h3 className="heading-3 left">Próximas Turmas</h3>
                </div>
                <div className="classes-list">
                    {
                        classes.map((item) => {

                            let cKey = 'class-' + item.CodClass;

                            let iDate = this.formatDate(item.startDate, false);
                            let eDate = this.formatDate(item.endDate, false);
                            let iDt = iDate.split('/');
                            let eDt = eDate.split('/');
                            let id = iDt[0];
                            let im = iDt[1];
                            let iy = iDt[2];
                            let ed = eDt[0];
                            let em = eDt[1];
                            let ey = eDt[2];

                            let cDate = '';
                            if(id === ed && im === em && iy === ey){
                                cDate = id + '/' + this.state.months[parseInt(im)];
                            }else if(im === em && iy === ey){
                                cDate = id + ' - ' + ed + '/' + this.state.months[parseInt(im)];
                            }else{
                                cDate = id  + '/' + this.state.months[parseInt(im)] + ' à ' + ed + '/' + this.state.months[parseInt(em)];
                            }

                            let weeks = this.getWeekdays(item.weekdays);

                            return(
                                <div className="class" key={cKey}>
                                    <div className="info">
                                        <span className="date">{cDate}</span>
                                        <span className="time">{weeks} das {this.formatTime(item.startTime)} às {this.formatTime(item.endTime)}</span>
                                        <span className="capacity">{parseInt(item.capacity) - parseInt(item.subscribers)} vaga(s)</span>
                                    </div>
                                    <div className="buttons">
                                        <div className="btn-wrapper on-right">
                                            <Button
                                                color="primary"
                                                size="small"
                                                variant="contained"
                                                disableElevation
                                            >
                                                Inscreva-se agora
                                            </Button>
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}
export default Classes;