import React, {useEffect} from 'react';
import './calendar-week.styles.scss';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


const CalendarWeek = (props) =>{

    useEffect(() => {
       
        scrollToTimeline();
       
    }, [props.now]);
   

    const scrollToTimeline = () => {
        
        const currentHour = new Date().getHours();
        const timelineId = 'week-time-' + currentHour;
        const objToGo = document.getElementById(timelineId);
        if(objToGo){
            const objParent = document.getElementById('week-body');

            // Where is the parent on page
            var parentRect = objParent.getBoundingClientRect();
            // What can you see?
            var parentViewableArea = {
                height: objParent.clientHeight,
                width: objParent.clientWidth
            };

            // Where is the child
            var childRect = objToGo.getBoundingClientRect();
            // Is the child viewable?
            var isViewable = (childRect.top >= parentRect.top) && (childRect.top <= parentRect.top + parentViewableArea.height);

            // if you can't see the child try to scroll parent
            if (!isViewable) {
                // scroll by offset relative to parent
                objParent.scrollTop = (childRect.top + objParent.scrollTop) - parentRect.top
            }
        }
        
    }

    const theWeek = props.days.filter((item) => item.page === props.page);
    const nowPos = (props.now) ? (props.now.getHours() * 60) + props.now.getMinutes() + 'px' : 0;

    const nextWeek = () => {
        let page = props.page;
        let pages = props.pages;
        let nPage;
        if(page < pages){
            nPage = page + 1;
            props.gotoPage(nPage);
        }else{
            props.nextMonth();
        }
    }

    const prevWeek = () => {
        let page = props.page;
        let nPage;
        if(page > 1){
            nPage = page - 1;
            props.gotoPage(nPage);
        }else{
            props.prevMonth('last');
        }
    }

    
        return(
            <div className="calendar-week">
                <div className="previous-area btn-wrapper">
                    <button className="btn-circle primary medium" onClick={prevWeek}><ArrowBackIcon /></button>
                </div>
                <div className="week">
                    <div className="week-header">
                        <div className="weekday-header-time"></div>
                        <div className="weekday-header-days">
                        {
                            theWeek.map((item) => {

                                let whKey = 'week-header-' + item.day + item.month;
                                let isToday = false;
                                if(item.day === props.today.d && item.month === props.today.m && item.year === props.today.y){
                                    isToday = true;
                                }

                                return(
                                    <div key={whKey} className={(isToday) ? 'weekday-header today' : 'weekday-header'}>
                                        <span className="label">{props.weekdays[item.weekday]}</span>
                                        <span className="day">{item.day}</span>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                    <div id="week-body" className="week-body">
                        <div className="now" style={{top: nowPos}}><span></span></div>
                        <div className="weekday-body-time">
                            {
                                props.timeline.map((item) => {
                                    let whKey = 'week-time-' + item.hour;
                                    return(
                                        <div id={whKey} key={whKey} className="time">{item.time}</div>
                                    );
                                })
                            }
                        </div>
                        {
                            
                            theWeek.map((item) => {

                                let whKey = 'week-body-' + item.day + item.month;
                                let eventsOfTheDay = props.slots.filter((slot) => (slot.day === item.day && slot.month === item.month + 1 && slot.year === item.year));
                                
                                return(
                                    <div key={whKey} className={(item.weekday === 0 || item.weekday === 6) ? 'weekday weekend' : 'weekday'}>
                                        <div className="events">
                                            {
                                            eventsOfTheDay.map((event) => {

                                                let eventKey = 'event-' + event.id;
                                                let cardHeight = event.duration + 'px';
                                                let n = (event.hour * 60) + event.minutes;
                                                let cardTop = n + 'px';

                                                let eventClasses = 'event';
                                                eventClasses += (event.past) ? ' past' : '';
                                                eventClasses += (event.status === 0) ? ' open' : '';
                                                eventClasses += (event.status !== 0) ? ' busy' : '';
                                                
                                                let title = (event.user && event.user.FullName) ? event.user.FullName : event.title; 

                                                return(
                                                    <div 
                                                        id={eventKey} 
                                                        key={eventKey} 
                                                        className={eventClasses} 
                                                        style={{ height: cardHeight, top: cardTop }} 
                                                        onClick={() => props.openEvent(event.id)}
                                                    >
                                                       <span className="event-time">{event.startTime}</span>
                                                       <span className="event-title">{title}</span>
                                                    </div>
                                                );

                                            })
                                            }
                                        </div>
                                        {
                                        props.timeline.map((line) => {
                                            let whKey = 'day-grade-' + item.day + item.month + line.hour;
                                            return(
                                                <div key={whKey} className="line"><div className="subline"></div></div>
                                            );
                                        })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="next-area btn-wrapper">
                    <button className="btn-circle primary medium" onClick={nextWeek}><ArrowForwardIcon /></button>
                </div>

            </div>
        );
}
export default CalendarWeek;