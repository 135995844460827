import React, {Component} from 'react';
import './partners.styles.scss';

// Import UI component
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// Import Utils
import { getToken } from '../../utils/user-validation/user-validation.utils';
import validate from '../../utils/form-validation/form-validation.utils';

class PartnersEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            formItems: {
                service: {
                    id: 'service',
                    label: 'Serviço',
                    placeholder: '',
                    value: (this.props.service) ? this.props.service : 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                items: {
                    id: 'items',
                    label: 'Registros por página',
                    placeholder: '',
                    value: (this.props.items) ? this.props.items : 4,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      digits: true,
                      biggerThan: 3,
                      lessThan: 13
                    }
                },
            }
        }
    }

    // Get Categories
    getOptions = () => {

        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken
          }),
        };
  
        fetch('/api/get_services.php', requestOptions)
        .then(data => data.json())
        .then(data => {
            this.setState({ 
                ...this.state, 
                options: data.items
             });
        })
  
    }

    // Component Did Mount
    componentDidMount(){

        this.getOptions();
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event, callbacks) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = {
                ...this.state.formItems
            };

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;

            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            if(callbacks){
            callbacks.map(item => {
                return item(event);
            })
            }
        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMS: FORM SUBMIT FUNCTION
        const handleSubmit = (event) => {

            event.preventDefault();
            let service = this.state.formItems.service.value;
            let items = this.state.formItems.items.value;
            

            const parameters = {
                service: service,
                items: items
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

        return(
            <form className='partners-edit-mode'>
                <div className="module-title-bar">Módulo Parceiros : {this.props.contentId}</div>
                 <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.service.hasError}  >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.service.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.service.id}
                            id={this.state.formItems.service.id}
                            value={this.state.formItems.service.value}
                            label={this.state.formItems.service.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            <option key={'service-' + this.props.contentId + '-0'} value='0'>Todas</option>
                            {this.state.options.map((item) => {
                                return (
                                <option
                                    key={"service-" + this.props.contentId + '-' + item.id}
                                    value={item.id}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.service.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                variant="outlined"
                                name= {this.state.formItems.items.id} 
                                label={this.state.formItems.items.label} 
                                value={this.state.formItems.items.value} 
                                error={this.state.formItems.items.hasError}
                                helperText={this.state.formItems.items.errorMsg} 
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            >Atualizar</Button>
                        </div>
                    </Grid>
                 </Grid>
            </form>
        );
    }
}

export default PartnersEditMode;