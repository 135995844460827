// Import libraries
import React,  { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// Import assets and styles
import './comments.styles.scss';

// Import Material-UI
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';

// Import Utils
import {getUser, getToken, authAction} from '../../utils/user-validation/user-validation.utils';
import validate from '../../utils/form-validation/form-validation.utils';

// Import Redux
import { openModal } from '../../redux/modal/modal.actions';
 

class Comments extends Component{

    constructor(props) {
        super(props);
        this.state = {
            loadings: {
                page: false,
                pagination: false,
                comment: false,
                favorite: false,
                bookmark: false
            },
            userObj: {
                id: 0,
                name: '',
                token: '',
                avatar: ''
            },
            formItems: {
                comment: {
                    id: 'comment',
                    label: 'Comentário',
                    placeholder: 'Digite aqui seu comentário',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
            },
            items:[],
            bookmarks: {
                favorite: false,
                bookmark: false
            },
            page: 1,
            pages: 1,
            total: 0
        }
    }

    // GET PAGE OF COMMENTS
    getComments(page){
        
        const contentId = this.props.content;
        const loadings = this.state.loadings;

        const currentUser = getUser();
        const userObj = this.state.userObj;

        if(currentUser){

           userObj.id = currentUser.id;
           userObj.name = currentUser.name;
           userObj.avatar = currentUser.avatar;
           userObj.token = currentUser.token;

        }

        loadings.pagination = true;
        this.setState({
            ...this.state,
            userObj: userObj,
            loadings: loadings
        });
        
        // Get comments
        const userToken = getToken();
        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
            },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            page: page,
            content: contentId
            }),
        };

        fetch('/api/get_comments.php', requestOptions)
        .then(data => data.json())
        .then(data => {

            let items = this.state.items;

            if(page === 1){
                items = data.items;
            }else{
                
                data.items.forEach((item) => {
                    items.push(item);
                });

            }

            const bookmarks = this.state.bookmarks;
            bookmarks.favorite = data.favorite;
            bookmarks.bookmark = data.bookmark;
            loadings.pagination = false;

            this.setState({ 
            ...this.state, 
            items: items,
            loadings: loadings,
            bookmarks: bookmarks,
            page: data.page,
            pages: data.pages,
            total: data.total
            });

        })

    }

    componentDidMount(){

        const userObj = getUser();

        if(userObj){

            this.setState({
                ...this.state,
                userObj: userObj
            });

        }

        this.getComments(1);

    }

    
    //Rendering
    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMAT DATE
        const formatDate = (date, withHour) => {

            if(date){
                let d = date.substr(8, 2);
                let m = date.substr(5, 2);
                let y = date.substr(0, 4);
                let sDate = '';
        
                if(withHour){
        
                let h = date.substr(11,2);
                let n = date.substr(14,2);
                sDate = d + '/' + m + '/' + y + ' às ' + h + 'h' + n;
        
                }else{
        
                sDate = d + '/' + m + '/' + y;
        
                }
        
                return sDate;

            }
            return;

        }

        // GET AVATAR URL
        const userAvatar = (userObj) => {

            let Img = '0.jpg';

            if(userObj.id > 0){
                const avatar = userObj.avatar;
                if(avatar){
                    Img = avatar;
                }
            }
            
            return 'url("' + window.$usersPath + Img + '")';
    
        }

         // COMMENT
         const toComment = (event) => {

            event.preventDefault();
            const loadings = this.state.loadings;

            loadings.comment = true;

            this.setState({
                ...this.state,
                loadings: loadings
            });

            const contentId = this.props.content;

            // Get comments
            const userToken = getToken();
            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Request-Headers": "*",
                "Access-Control-Request-Method": "*"
                },
                body: JSON.stringify({
                userToken: userToken,
                app: window.$appToken,
                comment: this.state.formItems.comment.value,
                content: contentId
                }),
            };

            fetch('/api/comment_create.php', requestOptions)
            .then(data => data.json())
            .then(data => {

                authAction(data.authenticated);

                const formItems = this.state.formItems;
                formItems.comment.value = '';

                loadings.comment = false;

                this.setState({
                    ...this.state,
                    loadings: loadings,
                    formItems: formItems
                });

                if(data.success){
                    this.getComments(1);
                }else{
                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Hmmm... acho que não.',
                        modalParameters: {
                            text: data.feedback
                        },
                        modalActions: null
                    }
                    this.props.openModal(modalObj);
                }
            
            })

        }

        // SET AS FAVORITE
        const setBookmark = (event, action) => {

            event.preventDefault();

            const contentId = this.props.content;
            const loadings = this.state.loadings;

            if(action === 1){
                loadings.favorite = true;
            }else{
                loadings.bookmark = true;
            }

            this.setState({
                ...this.state,
                loadings: loadings
            });

            // Get comments
            const userToken = getToken();
            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Request-Headers": "*",
                "Access-Control-Request-Method": "*"
                },
                body: JSON.stringify({
                userToken: userToken,
                app: window.$appToken,
                action: action,
                content: contentId
                }),
            };

            fetch('/api/set_bookmark.php', requestOptions)
            .then(data => data.json())
            .then(data => {

                authAction(data.authenticated);

                const bookmarks = this.state.bookmarks;
                
                if(parseInt(data.action) === 1){
                    bookmarks.favorite = data.value;
                    loadings.favorite = false;
                }else{
                    bookmarks.bookmark = data.value;
                    loadings.bookmark = false;
                }

                this.setState({
                    ...this.state,
                    bookmarks: bookmarks,
                    loadings: loadings
                });
            
            })

        }

        // RENDER FORM TO COMMENT
        const renderForm = () => {

            const user = this.state.userObj;
        
            if(user.id > 0){
                return(
                    <div className="with-user">
                        <form className="form">
                            <div className="row margin-bottom--xs">

                                <div className="ipt">
                                <TextField
                                name={this.state.formItems.comment.id}
                                id={this.state.formItems.comment.id}
                                value={this.state.formItems.comment.value}
                                label={this.state.formItems.comment.label}
                                placeholder={this.state.formItems.comment.placeholder}
                                error={this.state.formItems.comment.hasError}
                                helperText={this.state.formItems.comment.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                </div>

                            </div>
                            <div className="row">
                                <div className="btn-wrapper on-right">
                                    <Button 
                                    className="btn-comment"
                                    type="submit" 
                                    color="primary" 
                                    variant="contained" 
                                    disableElevation 
                                    onClick={(e) => toComment(e)} 
                                    disabled={(this.state.formItems.comment.value === '' || this.state.loadings.comment)}
                                    >{(this.state.loadings.comment) ? <CircularProgress color="primary" size={24} /> : 'Comentar'}</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                );
            }
            return(
                <div className="no-user">
                    <a href="/login">Registre-se ou acesse sua conta</a> para poder deixar seu comentário e interagir com esse conteúdo.
                </div>
            )


        }

        // SET PATHNAME FOR FACEBOOK SHARING BUTTON
        const pathname = window.location.href;

        // BOOKMARK ICONS
        const favoriteIcon = (this.state.bookmarks.favorite) ? <FavoriteIcon /> : <FavoriteBorderIcon />; 
        const bookmarkIcon = (this.state.bookmarks.bookmark) ? <BookmarkIcon /> : <BookmarkBorderIcon />; 
        const totalComments = this.state.items.filter((item) => item.approved).length;

        const urlDecoded = (this.props.pageData?.url) ? 'https://www.abricco.com/page/' + this.props.pageData.url : 'https://www.abricco.com/';
        const urlEncoded = (this.props.pageData?.url) ? 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.abricco.com%2Fpage%2F' + this.props.pageData.url + '&amp;src=sdkpreparse' : 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.abricco.com%2F&amp;src=sdkpreparse';

        return(
            <div className="mdl mdl-comments">
            <div className="comments">

                { /* SHARING BAR */ }
                <div className="sharing-bar">
                    <div className='title'>
                        <h4 className='heading-4'>Comentários{(totalComments > 0) ? <span className="flag">{totalComments}</span>: ''}</h4>
                    </div>

                    <div className="options">
                        <div className='facebook'>
                            <div className="fb-share-button fb-xfbml-parse-ignore" data-href={urlDecoded} data-layout="button" data-size="large"><a target="_blank" href={urlEncoded}>Compartilhar</a></div>
                        </div>
                        <div className="favorite">
                            <IconButton
                            aria-label="Guardar em Favoritos"
                            edge="end"
                            onClick = {(e) => setBookmark(e, 1)}
                            disabled = {(this.state.userObj.id === 0 || this.state.loadings.favorite)}
                            >
                            {(this.state.loadings.favorite) ? <CircularProgress color="primary" size={24} /> : favoriteIcon}
                            </IconButton>
                            
                            <IconButton
                            aria-label="Guardar em Ler Depois"
                            edge="end"
                            onClick = {(e) => setBookmark(e, 0)}
                            disabled = {(this.state.userObj.id === 0 || this.state.loadings.bookmark)}
                            >
                            {(this.state.loadings.bookmark) ? <CircularProgress color="primary" size={24} /> : bookmarkIcon}
                            </IconButton>
                        </div>
                    </div>
                   
                </div>
                
                { /* FORM */ }
                <div className="insert-comment">{renderForm()}</div>

                { /* COMMENTS */ }
                <div className={(this.state.items.length) ? 'items' : 'items hidden'}>
                {
                    this.state.items.map((item, index) => {

                        let key= 'comment-' + index;
                        let signature = item.author.name + ' em ' + formatDate(item.date, true);
                        let userIsTheAuthor = item.author.id === parseInt(this.state.userObj.id);
                        let approved = (item.approved) ? 'approved' : 'checking';
                        let shouldRender = (item.approved || userIsTheAuthor);
                        shouldRender = true;
                        
                        const renderReply = (obj) => {
                            
                            if(obj){
                                return(
                                <div className="answer">
                                        <div className="avatar" style={{backgroundImage: userAvatar(obj.author)}}></div>
                                        <div className="comment">
                                            <div className="signature">Respondido por {obj.author.name} em {formatDate(obj.date, true)}</div>
                                            <div className="text">{obj.comment}</div>
                                        </div>
                                </div>
                                );
                            }
                            return;

                        }

                        const renderComment = () => {
                
                                return(
                                <div key={key} className={'item margin-bottom--sm ' + approved}>
                                    <div className="row">
                                        <div className="avatar" style={{backgroundImage: userAvatar(item.author)}}></div>
                                        <div className="comment">
                                            {(!item.approved) ? <div className="checking-info"><span>Em verificação</span></div>:null}
                                            <div className="signature">{signature}</div>
                                            <div className="text">
                                                {item.comment}
                                            </div>
                                        </div>
                                    </div>
                                    {(item.reply.comment) ? renderReply(item.reply) : null}
                                </div>
                                   
                                )

                        }

                        return (shouldRender) ? renderComment() : null;

                    })
                }
                </div>
                <div className={(this.state.total === this.state.items.length) ? 'more-items hidden' : 'more-items'}>
                        <IconButton
                        aria-label="Carregar mais comentários"
                        edge="end"
                        onClick = {() => this.getComments(this.state.page + 1)}
                        disabled = {(this.state.loadings.pagination)}
                        >
                        {(this.state.loadings.pagination) ? <CircularProgress color="primary" size={24} /> : <AddIcon />}
                        </IconButton>
                </div>
                
            </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(Comments));