import React from 'react';

// Import UI Components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Import Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TodayIcon from '@material-ui/icons/Today';

const Toolbar = (props) => {

    return(
        <Grid container spacing={2}>
                <Grid className="calendar-header" item xs={6}>
                    <Tooltip title="Ir para o mês anterior">
                      <IconButton color="default" onClick={() => props.actions.previousMonth(false)}><ArrowLeftIcon /></IconButton>
                    </Tooltip>
                    <span className="month">{props.monthsList[props.month - 1]}</span>
                    <span className="year">{props.year}</span>
                    <Tooltip title="Ir para o mês seguinte">
                      <IconButton color="default" onClick={() => props.actions.nextMonth(false)}><ArrowRightIcon /></IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6} className="right">

                    <Button disabled={props.dayIsToday} onClick={props.actions.goToToday}>Hoje</Button>

                    <Tooltip title="Lista">
                      <IconButton color={(props.view === 'list') ? 'primary' : 'default'} onClick={() => props.actions.toggleView('list')}>
                        <ListAltIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Dia">
                      <IconButton color={(props.view === 'day') ? 'primary' : 'default'} onClick={() => props.actions.toggleView('day')}>
                        <TodayIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Semana">
                      <IconButton color={(props.view === 'week') ? 'primary' : 'default'} onClick={() => props.actions.toggleView('week')}>
                        <DateRangeIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Mês">
                      <IconButton color={(props.view === 'month') ? 'primary' : 'default'} onClick={() => props.actions.toggleView('month')}>
                        <EventNoteIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Abrir slots de agenda">
                      <IconButton color={(props.createSlotsFormIsVisible) ? 'primary' : 'default'} onClick={() => props.actions.createSlots(true, false)}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>

                </Grid>
            </Grid>
    )

}

export default Toolbar;