// Import libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// Import assets and styles
import './content-directory.styles.scss';

// Import Material-UI components
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Import components
import Pagination from '../pagination/pagination.component';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';
import { closeDirectory, getContents } from '../../redux/content-directory/content-directory.actions';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
import {lockScrollbar} from '../../utils/utils';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

class ContentDirectory extends Component {
  constructor(props) {
    super(props);

    this.windowObj = React.createRef();

    this.state = {
      loading: true,
      loadingCategories: false,
      selected: [],
      categories: [],
      results: [],
      formItems: {
        searchKey: {
          id: 'searchKey',
          label: 'Busca',
          placeholder: 'Procurar por',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        },
        category: {
          id: 'category',
          label: 'Categoria',
          placeholder: 'Seleciona uma categoria',
          value: 0,
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        },
        ordering: {
          id: 'ordering',
          label: 'Ordenação',
          placeholder: 'Ordernar por',
          value: 'b.Title ASC',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        },
      },
      pagination: {
        page: 1,
        pages: 1,
        total: 0
      },
      orderOptions: [
        {
          label: 'Mais recentes',
          value: 'b.Published DESC'
        },
        {
          label: 'Mais antigos',
          value: 'b.Published ASC'
        },
        {
          label: 'Título (A-Z)',
          value: 'b.Title ASC'
        },
        {
          label: 'Título (Z-A)',
          value: 'b.Title DESC'
        },
        {
          label: 'Categoria (A-Z)',
          value: 'CategoryName ASC'
        },
        {
          label: 'Categoria (Z-A)',
          value: 'CategoryName DESC'
        },
      ]
    };

  }

  // Get contents
  getContents = (page) => {

    this.setState({
      ...this.state,
      loading: true
    });

    const searchKey = this.state.formItems.searchKey.value;
    const category = this.state.formItems.category.value;
    const ordering = this.state.formItems.ordering.value;
    const userToken = getToken();

    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { 
        'Content-Type': 'application/json',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
    },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken,
        page: page,
        searchKey: searchKey,
        category: category,
        ordering: ordering
      }),
    };

    fetch("/api/get_contents.php", requestOptions)
    .then(async (response) => {

      const data = await response.json();

      // check for error response
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }

      authAction(data.authenticated);
      
      const pagination = this.state.pagination;
      pagination.total = data.totalItems;
      pagination.page= data.currentPage;
      pagination.pages = data.totalPages;
      const results = data.items;

      this.setState({ 
        ...this.state, 
        pagination: pagination,
        results: results,
        loading: false
      });

    })
    .catch((error) => {
      console.dir(error);
    });

  }

  // Get categories
  getCategories = () => {

    const userToken = getToken();
    
    this.setState({
      ...this.state,
      loadingCategories: true
    });
      
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { 
        'Content-Type': 'application/json',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
    },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken
      }),
    };

    fetch("/api/get_categories.php",requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

        this.setState({ 
          ...this.state, 
          categories: data.items,
          loadingCategories: false
        });

      })
      .catch((error) => {
        console.dir(error);
      });

  }
  
  componentDidMount(){
    
    // Get initial page
    this.getContents(1);
    this.getCategories();

  }

  //Rendering
  render(props) {

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {

      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // Get current FormItems object from State
      const formItems = {
          ...this.state.formItems
      };

      // Get State of the Input
      const formItem = {
          ...formItems[name]
      };

      // Validate Element if it has been Touched Before
      if(formItem.hasTouched){
          if(formItem.rules){
              const validation = validate(value, formItem.rules);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }
      }

      // Set the new value to element
      formItem.value = value;

      // Update formItems collection
      formItems[name] = formItem;

      // Update State
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

      if(callbacks){
        callbacks.map(item => {
          return item(event);
        })
      }

    }

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    const isChecked = (id) => {

      const selected = this.state.selected;
      let isChecked = false;

      for(let i = 0; i < selected.length; ++i){
        if(selected[i].id === id){
          isChecked = true;
        }
      }

      return isChecked;

    }
    
    const toggleCheckItem = (id) => {

        let selectedItem = [];
        let contents = this.state.results;
        let selected = this.state.selected;
        let selectedItems = selected.length;
        let itemPos = null;
        let limit = this.props.contentDirectory.limit;

        // Get the item selected
        contents.map((item)=>{
          if(item.id === id){
            selectedItem = item;
          }
          return null;
        });

        // Check if item has been already selected
        for(let i=0; i < selectedItems; ++i){
          if(selected[i].id === id){
            itemPos = i;
          }
        }

        if(itemPos === null){

          if(limit === 0){
            selected.push(selectedItem);
          }else if(limit === 1){
            selected.splice(0, 1, selectedItem);
          }else if(selectedItems < limit){
            selected.push(selectedItem);
          }else{
            const modalObj = {
              modalIcon: 'clear',
              modalType: 'error',
              modalComponent: 'GENERAL_ALERT',
              modalTitle: 'Ops...',
              modalParameters: {
                text: 'Você pode selecionar no máximo ' + limit + ' conteúdos.'
              },
              modalActions: []
            }
            this.props.openModal(modalObj);
          }

        }else{
          
          selected.splice(itemPos, 1);

        }

        this.setState({
          ...this.state,
          selected: selected
        })

    }

    const search = (page) => {

        this.getContents(page);       

    }

    const checkItemKeyPressHandle = (e, id) =>{
      if(e.keyCode === 13 || e.keyCode === 32){
        toggleCheckItem(id);
      }
    }

    // Format Data
    const formatDate = (date, withHour) => {

      let d = date.substr(8, 2);
      let m = date.substr(5, 2);
      let y = date.substr(0, 4);
      let sDate = '';

      if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

      }else{

        sDate = d + '/' + m + '/' + y;

      }

      return sDate;

    }

    const lockSite = (isOpen) => {
      if(isOpen){
        lockScrollbar('add');
        return true;
      }else{
        lockScrollbar('remove');
        return false;
      }
    }

    return (
      <div className={(lockSite(this.props.contentDirectory.visible)) ? 'content-selector' : 'content-selector hidden'}>
     
      
        <div className="window">
          
          { /* HEADER */ }
          <div className="header">
            <div className="title-bar">
              <h2 className="title">Diretório de Conteúdo</h2>
              <button className="close-button" onClick={() => this.props.closeDirectory()}><CloseIcon /> </button>
            </div>
          </div>

          { /* BODY */ }
          <div className="body">
              
              { /* FILTERS */ }
              <div className="filters">

                  <div className="ipt-box wide-fill">
                  <TextField
                      name="searchKey"
                      id={this.state.formItems.searchKey.id}
                      value={this.state.formItems.searchKey.value}
                      label={this.state.formItems.searchKey.label}
                      placeholder={this.state.formItems.searchKey.placeholder}
                      error={this.state.formItems.searchKey.hasError}
                      helperText={this.state.formItems.searchKey.errorMsg}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                  />
                  </div>

                  <div className="ipt-box wide-sm">
                    <FormControl variant="outlined" size="small" fullWidth error={this.state.formItems.category.hasError}>
                      <InputLabel htmlFor="category">{this.state.formItems.category.label}</InputLabel>
                      <Select
                        native
                        fullWidth
                        name={this.state.formItems.category.id}
                        id={this.state.formItems.category.id}
                        value={this.state.formItems.category.value}
                        label={this.state.formItems.category.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option key="category-0" value={0}>Todas</option>
                        {this.state.categories.map((item) => {
                          let strKey = 'category-' + item.categoryId;
                          return(
                          <option key={strKey} value={item.categoryId}>{item.categoryTitle}</option>
                          )
                        })}
                      </Select>
                      <FormHelperText>
                        {this.state.formItems.category.errorMsg}
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="ipt-box wide-sm">
                    <FormControl variant="outlined" size="small" fullWidth error={this.state.formItems.ordering.hasError}>
                      <InputLabel htmlFor="ordering">{this.state.formItems.ordering.label}</InputLabel>
                      <Select
                        native
                        fullWidth
                        name={this.state.formItems.category.id}
                        id={this.state.formItems.ordering.id}
                        value={this.state.formItems.ordering.value}
                        label={this.state.formItems.ordering.label}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {
                          this.state.orderOptions.map((item, index) => {
                            let keyOrder = 'order-' + index;
                            return(<option key={keyOrder} value={item.value}>{item.label}</option>);
                          })
                        }
                      </Select>
                      <FormHelperText>
                        {this.state.formItems.category.errorMsg}
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="ipt-box">
                  <Button type="button"  color="primary" variant="contained" disableElevation onClick={() => search(1)}>Procurar</Button>
                  </div>
              </div>

              { /* RESULTS */ }
              <div className="results">
                  <div className={(this.state.loading) ? 'loading' : 'loading hidden'}><CircularProgress /></div>
                  <div className="results-table">
                    <table>
                    <thead>
                      <tr>
                        <th className="cell-xs center"></th>
                        <th className="left">Título</th>
                        <th className="cell-md left hide-in-mobile">Categoria</th>
                        <th className="cell-md left hide-in-mobile">Publicação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.results.map((item,index) => {

                        let rowIndex = 'content-' + index;

                        return(
                          <tr key={rowIndex} onClick={() => toggleCheckItem(item.id)} onKeyDown={(e) => checkItemKeyPressHandle(e, item.id)} tabIndex="0">
                            <td className="cell-xs center">
                            {(isChecked(item.id)) ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon color="disabled" />}
                            </td>
                            <td className="left">{item.title}</td>
                            <td className="cell-md left hide-in-mobile">{(item.category === 0) ? 'Nenhuma' : item.categoryName}</td>
                            <td className="cell-md left hide-in-mobile">{(item.hasPublishedVersion)? formatDate(item.publishDate, true) : 'Não publicado'}</td>
                          </tr>
                        );

                      })}
                      
                    </tbody>
                  </table>
                  </div>
                  <div className="results-pagination">
                    <Pagination 
                      totalItems={this.state.pagination.total} 
                      currentPage={this.state.pagination.page} 
                      totalPages={this.state.pagination.pages}
                      goToFunction={search}  
                    />
                  </div>
              </div>

              <div className="actions">
                <div className="btn-wrapper side-by-side on-right">
                  <Button type="button"  color="default" variant="outlined" onClick={() => this.props.closeDirectory()} disableElevation>Cancelar</Button>
                  <Button type="button"  color="primary" variant="contained" disableElevation disabled={(this.state.selected.length === 0)} onClick={() => this.props.contentDirectory.onSave(this.state.selected)}>Continuar</Button>
                </div>
              </div>

          </div>

        </div>
      
     
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contentDirectory: state.contentDirectory,
});

const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData)),
  closeDirectory: () => dispatch(closeDirectory()),
  getContents: contents => dispatch(getContents(contents))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentDirectory));