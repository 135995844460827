import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import './form-coaching.styles.scss';

// Import Redux Action
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui/core/Slider';
import CircularProgress from "@material-ui/core/CircularProgress";
 
class FormCoaching extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    
    this.state = {
      sending: false,
      goalsOptions: [
            {id: 1, checked: false, label: 'Planejamento e desenvolvimento de sua carreira'},
            {id: 2, checked: false, label: 'Desenvolvimento pessoal para melhorar relacionamentos'},
            {id: 3, checked: false, label: 'Equilíbrio entre vida pessoal e profissional'},
            {id: 4, checked: false, label: 'Planejamento financeiro'},
            {id: 5, checked: false, label: 'Melhorar a sua auto-estima e cuidar mais de você mesmo'},
            {id: 6, checked: false, label: 'Administrar melhor o seu tempo'},
            {id: 7, checked: false, label: 'Transição de carreira'},
            {id: 8, checked: false, label: 'Planejamento do seu negócio'},
            {id: 9, checked: false, label: 'Melhorar a sua performance'},
            {id: 10, checked: false, label: 'Definir metas e objetivos claros para a sua vida'},
            {id: 11, checked: false, label: 'Desenvolver uma nova rotina/hábito para melhorar sua qualidade de vida'},
            {id: 12, checked: false, label: 'Melhorar sua saúde e disposição'},
            {id: 13, checked: false, label: 'Reduzir sua ansiedade e identificar o que está te travando'},
            {id: 14, checked: false, label: 'Sentir-se mais motivado para ir atrás dos seus sonhos'},
            {id: 15, checked: false, label: 'Escolher uma carreira que realmente te traga motivação profissional'},
            {id: 16, checked: false, label: 'Ter mais clareza do que você quer fazer'},
      ],
      showOther: false,
      formItems: {
        fullname: {
          id: 'fullname',
          label: 'Nome Completo',
          placeholder: 'Informe seu nome completo',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            isFullName: true,
            maxLength: 255
          }
        },
        city: {
            id: 'city',
            label: 'Cidade',
            placeholder: 'Informe sua cidade',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
          phone: {
            id: 'phone',
            label: 'Telefone',
            placeholder: 'Apenas números, sem separadores ou espaços',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              phone: true,
              maxLength: 10
            }
          },
        email: {
          id: 'email',
          label: 'E-mail',
          placeholder: 'Informe seu e-mail',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            email: true,
            maxLength: 255
          }
        },
        birthdate: {
          id: 'birthdate',
          label: 'Data de Nascimento',
          placeholder: 'dd/mm/aaaa',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            isDate: true,
            isAdult: true
          }
        },
        challenge: {
          id: 'challenge',
          label: '',
          placeholder: 'Digite sua resposta',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255
          }
        },
        block: {
          id: 'block',
          label: '',
          placeholder: 'Digite sua resposta',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255
          }
        },
        goals: {
            id: 'goals',
            label: 'Objetivos',
            placeholder: '',
            value: [],
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true
            }
          },
          other: {
            id: 'other',
            label: 'Qual seria?',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
          wishes: {
            id: 'wishes',
            label: 'Resposta',
            placeholder: 'Digite sua resposta',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
          available: {
            id: 'available',
            label: '',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
          financialHealth: {
            id: 'financialHealth',
            label: '',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
          budget: {
            id: 'budget',
            label: '',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: null
          },
          contactMethod: {
            id: 'contactMethod',
            label: '',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: null
          },
          contactTime: {
            id: 'contactTime',
            label: '',
            placeholder: 'Informe o melhor horário para entrarmos em contato',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
          focusLevel: {
            id: 'focusLevel',
            label: '',
            placeholder: '',
            value: 0,
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: null
          },
          whyMe: {
            id: 'whyMe',
            label: '',
            placeholder: 'Digite sua resposta',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
              required: true,
              maxLength: 255
            }
          },
      }
    };
    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }
      
    }

  render(props){
  
    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event) => {

      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // Get current FormItems object from State
      const formItems = this.state.formItems;

      // Get State of the Input
      const formItem = {
          ...formItems[name]
      };

      // Validate Element if it has been Touched Before
      if(formItem.hasTouched){
          if(formItem.rules){
              const validation = validate(value, formItem.rules, formItems);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }
      }

      // Set the new value to element
      formItem.value = value;

      // Update formItems collection
      formItems[name] = formItem;


      // Update State
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  };

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = (event) => {
              
      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // formItems collection
      const formItems = {
          ...this.state.formItems
      };

      // state of modified element
      const formItem = {
          ...formItems[name]
      };

      formItem.hasTouched = true;
      if(formItem.rules){
          const validation = validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }

      // Update formItems collection
      formItems[name] = formItem;

      // Update state
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  }

  const handleSliderChange = (event, newValue) => {

    const formItems = this.state.formItems;
    formItems.focusLevel.value = newValue;

    this.setState({
        ...this.state,
        formItems: formItems
    })
  };

  const submitDisabled = () => {
      
    let disabled = false;

    const formItems = this.state.formItems;
    const options = this.state.goalsOptions;

    let optCounter = 0
    for(let i = 0; i < options.length; i++){

        if(options[i].checked){
            optCounter++;
        }
    }
    if(optCounter === 0 && (formItems.other.value === '' || formItems.other.hasError)){
        disabled = true;
    }

    if(formItems.fullname.value === '' || formItems.fullname.hasError){
        disabled = true;
    }

    if(formItems.email.value === '' || formItems.email.hasError){
        disabled = true;
    }

    if(formItems.phone.value === '' || formItems.phone.hasError){
        disabled = true;
    }

    if(formItems.city.value === '' || formItems.city.hasError){
        disabled = true;
    }

    if(formItems.birthdate.value === '' || formItems.birthdate.hasError){
        disabled = true;
    }

    if(formItems.challenge.value === '' || formItems.challenge.hasError){
        disabled = true;
    }

    if(formItems.block.value === '' || formItems.block.hasError){
        disabled = true;
    }

    if(formItems.wishes.value === '' || formItems.wishes.hasError){
        disabled = true;
    }

    if(formItems.available.value === '' || formItems.available.hasError){
        disabled = true;
    }

    if(formItems.contactMethod.value === '' || formItems.contactMethod.hasError){
        disabled = true;
    }

    if(formItems.contactTime.value === '' || formItems.contactTime.hasError){
        disabled = true;
    }

    if(formItems.whyMe.value === '' || formItems.whyMe.hasError){
        disabled = true;
    }

    if(formItems.financialHealth.value === '' || formItems.financialHealth.hasError){
        disabled = true;
    }

    if(formItems.budget.value === '' || formItems.budget.hasError){
        disabled = true;
    }

    return disabled;

  }

  const toogleCheckbox = (id) => {

    const options = this.state.goalsOptions;

    for(let i = 0; i<options.length; i++){
        if(options[i].id === id){
            options[i].checked = !options[i].checked;
        }
    }

    this.setState({
        ...this.state,
        goalsOptions: options
    });

  }

  const setOther = () => {

    this.setState({
        ...this.state,
        showOther: !this.state.showOther
    })

  }

  const handleSubmit = (e) => {

    e.preventDefault();

    this.setState({
        ...this.state,
        sending: true
    })

    //const formIsValid = submitDisabled();
    const formItems = this.state.formItems;
    const goals = this.state.goalsOptions.filter((item) => {
        return item.checked;
    }).map((item) => {
        return item.label
    });


    const formData = {
        app: window.$appToken,
        fullname: formItems.fullname.value,
        email: formItems.email.value,
        phone: formItems.phone.value,
        city: formItems.city.value,
        birthdate: formItems.birthdate.value,
        challenge: formItems.challenge.value,
        block: formItems.block.value,
        goals: goals,
        other: formItems.other.value,
        wishes: formItems.wishes.value,
        financialHealth: formItems.financialHealth.value,
        budget: formItems.budget.value,
        available: formItems.available.value,
        contactMethod: formItems.contactMethod.value,
        contactTime: formItems.contactTime.value,
        focusLevel: formItems.focusLevel.value,
        whyMe: formItems.whyMe.value
    }

    // Prepare Request
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
        body: JSON.stringify(formData)
    };

    // Request API
    fetch("/api/form_coaching.php", requestOptions)
    .then(async response => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }

        // fail case
        if(!data.success){   

            this.setState({
                ...this.state, 
                sending: false,
            });

            // Show error modal
            const modalObj = {
                modalIcon: 'clear',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ops, algo correu mal :(',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: []
              }
              this.props.openModal(modalObj);


        // success case
        }else{

            // Show success modal
            const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Pronto! ;)',
                modalParameters: {
                    text: 'Obrigado por responder ao formulário. Em breve entraremos em contato.'
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

              // Clean inputs
            const formItems = this.state.formItems;
            formItems.fullname.value = '';
            formItems.email.value = '';
            formItems.phone.value = '';
            formItems.city.value= '';
            formItems.birthdate.value = '';
            formItems.challenge.value = '';
            formItems.block.value = '';
            formItems.other.value = '';
            formItems.wishes.value = '';
            formItems.financialHealth.value = '';
            formItems.budget.value = '';
            formItems.available.value = '';
            formItems.contactMethod.value = '';
            formItems.contactTime.value = '';
            formItems.focusLevel.value = 0;
            formItems.whyMe.value = '';

            let resetedGoals = this.state.goalsOptions;
            for(let i = 0; i < resetedGoals.length; i++){
                resetedGoals[i].checked = false;
            }

              // Remove loader
              this.setState({
                ...this.state, 
                formItems: formItems,
                showOther: false,
                goalsOptions: resetedGoals,
                sending: false,
            });

        }

    })
    .catch(error => {

        // case server error
        this.setState({
            ...this.state, 
            sending: false,
        });

        // show error modal
        const modalObj = {
            modalIcon: 'clear',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Ops, algo correu mal :(',
            modalParameters: {
                Text: 'Não foi possível aceder aos serviços de login no momento. Tente novamente mais tarde ou contate o suporte.'
            },
            modalActions: []
        }
        this.props.openModal(modalObj);

    });


  }

  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content form-coaching">
        
          { /* PAGE TITTLE */ }
          <div className="module-heading margin-bottom--lg">
            <h1 className="heading-1">Coaching Abriccó</h1>
          </div>

          <div className="mdl mdl-richtext">
              <div className="module-richtext">
                  <div className="center">Parabéns pelo interesse de investir em si mesmo e fazer o coaching da Abriccó. Peço que, por gentileza, responsa essas breves perguntas para eu te conhecer melhor e conseguir te proporcionar uma melhor experiência no mundo do coaching. Vamos lá?<br/></div>
              </div>
          </div>
          

          { /* FORM PERSONAL DATA*/ }
          <form className="container">

              { /* PERSONAL */ }
              <div className="form-group">
              <div className="title">Você</div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="question">Para começar, que tal nos contar um pouco sobre você e de onde você é?</div>
                    <TextField
                    name={this.state.formItems.fullname.id}
                    id={this.state.formItems.fullname.id}
                    value={this.state.formItems.fullname.value}
                    label={this.state.formItems.fullname.label}
                    placeholder={this.state.formItems.fullname.placeholder}
                    error={this.state.formItems.fullname.hasError}
                    helperText={this.state.formItems.fullname.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                    name={this.state.formItems.email.id}
                    id={this.state.formItems.email.id}
                    value={this.state.formItems.email.value}
                    label={this.state.formItems.email.label}
                    placeholder={this.state.formItems.email.placeholder}
                    error={this.state.formItems.email.hasError}
                    helperText={this.state.formItems.email.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                    name={this.state.formItems.phone.id}
                    id={this.state.formItems.phone.id}
                    value={this.state.formItems.phone.value}
                    label={this.state.formItems.phone.label}
                    placeholder={this.state.formItems.phone.placeholder}
                    error={this.state.formItems.phone.hasError}
                    helperText={this.state.formItems.phone.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                    name={this.state.formItems.city.id}
                    id={this.state.formItems.city.id}
                    value={this.state.formItems.city.value}
                    label={this.state.formItems.city.label}
                    placeholder={this.state.formItems.city.placeholder}
                    error={this.state.formItems.city.hasError}
                    helperText={this.state.formItems.city.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.birthdate.id}
                  id={this.state.formItems.birthdate.id}
                  value={this.state.formItems.birthdate.value}
                  label={this.state.formItems.birthdate.label}
                  placeholder={this.state.formItems.birthdate.placeholder}
                  error={this.state.formItems.birthdate.hasError}
                  helperText={this.state.formItems.birthdate.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
              </Grid>
              </Grid>
              </div>
              
              { /* CHALLENGES */ }
              <div className="form-group">
              <div className="title">Desafios</div>
              <Grid container spacing={2}>
                
                <Grid item xs={12}>
                    <div className="question">Atualmente, qual é o maior desafio que você está enfrentando na sua vida?</div>
                    <TextField
                    name={this.state.formItems.challenge.id}
                    id={this.state.formItems.challenge.id}
                    value={this.state.formItems.challenge.value}
                    label={this.state.formItems.challenge.label}
                    placeholder={this.state.formItems.challenge.placeholder}
                    error={this.state.formItems.challenge.hasError}
                    helperText={this.state.formItems.challenge.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                </Grid>

                <Grid item xs={12}>
                  <div className="question">Qual é o maior bloqueio (interno e/ou externo) que você acredita que está sendo um grande obstáculo para você alcançar o próximo nível da sua vida?</div>
                  <TextField
                  name={this.state.formItems.block.id}
                  id={this.state.formItems.block.id}
                  value={this.state.formItems.block.value}
                  label={this.state.formItems.block.label}
                  placeholder={this.state.formItems.block.placeholder}
                  error={this.state.formItems.block.hasError}
                  helperText={this.state.formItems.block.errorMsg}
                  fullWidth
                  multiline
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                </Grid>

              </Grid>
              </div>

              { /* GOALS */ }
              <div className="form-group">
              <div className="title">Objetivos</div>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <div className="question">Após você refletir sobre os seus principais desafios, quais seriam as suas principais prioridades no seu processo de coaching?</div>
                  <FormControl component="fieldset">
                    <FormGroup>
                    {this.state.goalsOptions.map((item, index) => {

                        let goalKey = 'goal-' + index;
                        return(
                            <FormControlLabel
                            key={goalKey}
                            
                            control={<Checkbox checked={item.checked} name={goalKey} onClick={() => toogleCheckbox(item.id)} />}
                            label={item.label}
                            />
                        )
                    })}
                    
                    <FormControlLabel
                        control={<Checkbox checked={this.state.formItems.showOther} onChange={setOther} name="otherGoal" />}
                        label="Outra"
                    />
                    </FormGroup>
                    <FormHelperText>{this.state.formItems.goals.errorMsg}</FormHelperText>
                </FormControl>
                <div className={(this.state.showOther) ? 'other' : 'other hidden'}>
                    <TextField
                    name={this.state.formItems.other.id}
                    id={this.state.formItems.other.id}
                    value={this.state.formItems.other.value}
                    label={this.state.formItems.other.label}
                    placeholder={this.state.formItems.other.placeholder}
                    error={this.state.formItems.other.hasError}
                    helperText={this.state.formItems.other.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="question">Se você encontrasse uma lâmpada mágica e um gênio te concedesse 3 desejos a serem realizados (bem específicos e pontuais). O que você mudaria na sua vida?</div>
                  <TextField
                  name={this.state.formItems.wishes.id}
                  id={this.state.formItems.wishes.id}
                  value={this.state.formItems.wishes.value}
                  label={this.state.formItems.wishes.label}
                  placeholder={this.state.formItems.wishes.placeholder}
                  error={this.state.formItems.wishes.hasError}
                  helperText={this.state.formItems.wishes.errorMsg}
                  fullWidth
                  multiline
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  />
                </Grid>

              </Grid>
              </div>

              { /* BUDGET */ }
              <div className="form-group">
              <div className="title">Investimento</div>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div className="question">Para que você seja meu coachee (cliente), eu preciso ter certeza de que o investimento no nosso processo de coaching não vai prejudicar a sua família ou a sua vida financeira. Você tem capacidade de investir em você mesmo nesse momento da sua vida?</div>
                        <FormControl component="fieldset">
                            <RadioGroup name="financialHealth" value={this.state.formItems.financialHealth.value} onChange={handleChange}>
                                <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                <FormControlLabel value="Não" control={<Radio />} label="Não" />
                            </RadioGroup>
                        </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <div className="question">Para alcançar este objetivo até quanto você está disposto a investir?</div>
                    <FormControl component="fieldset">
                        <RadioGroup name="budget" value={this.state.formItems.budget.value} onChange={handleChange}>
                            <FormControlLabel value="Até R$1500" control={<Radio />} label="Até R$1.500" />
                            <FormControlLabel value="Até R$3000" control={<Radio />} label="Até R$3.000" />
                            <FormControlLabel value="Até R$5000" control={<Radio />} label="Até R$ 5.000" />
                            <FormControlLabel value="Mais de R$5000" control={<Radio />} label="Acima de R$ 5.000" />
                            <FormControlLabel value="Não pode investir no momento" control={<Radio />} label="Quero muito mas não posso investir no momento" />
                        </RadioGroup>
                    </FormControl>
                    
                </Grid>

              </Grid>
              </div>

              { /* AVAILABILITY */ }
              <div className="form-group">
              <div className="title">Disponibilidade & Comprometimento</div>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div className="question">Você tem disponibilidade para se dedicar aos seus sonhos/objetivos, pelo menos uma vez por semana, por cerca de 1h30, durante um período de 90 dias?</div>
                        <FormControl component="fieldset">
                            <RadioGroup name="available" value={this.state.formItems.available.value} onChange={handleChange}>
                                <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                <FormControlLabel value="Não" control={<Radio />} label="Não" />
                            </RadioGroup>
                        </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <div className="question">Qual a melhor forma de entrar em contato com você para conversamos melhor?</div>
                    <FormControl component="fieldset">
                        <RadioGroup name="contactMethod" value={this.state.formItems.contactMethod.value} onChange={handleChange}>
                            <FormControlLabel value="Telefone" control={<Radio />} label="Telefone" />
                            <FormControlLabel value="E-mail" control={<Radio />} label="E-mail" />
                            <FormControlLabel value="WhatsApp" control={<Radio />} label="WhatsApp" />
                        </RadioGroup>
                    </FormControl>
                    
              </Grid>

              <Grid item xs={12}>
                  <div className="question">Qual seria o melhor horário para entrarmos em contato?</div>
                  <TextField
                    name={this.state.formItems.contactTime.id}
                    id={this.state.formItems.contactTime.id}
                    value={this.state.formItems.contactTime.value}
                    label={this.state.formItems.contactTime.label}
                    placeholder={this.state.formItems.contactTime.placeholder}
                    error={this.state.formItems.contactTime.hasError}
                    helperText={this.state.formItems.contactTime.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
              </Grid>

              <Grid item xs={12}>
                  <div className="question">Sabendo que eu só trabalho com pessoas altamente comprometidas com as suas vidas, que nota você daria para o seu comprometimento para mudar a sua vida neste exato momento?</div>
                    <Slider
                    name={this.state.formItems.focusLevel.id}
                    value={this.state.formItems.focusLevel.value}
                    valueLabelDisplay="auto"
                    step={10}
                    marks={[
                        {label: 0, value: 0},
                        {label: 10, value: 10},
                        {label: 20, value: 20},
                        {label: 30, value: 30},
                        {label: 40, value: 40},
                        {label: 50, value: 50},
                        {label: 60, value: 60},
                        {label: 70, value: 70},
                        {label: 80, value: 80},
                        {label: 90, value: 90},
                        {label: 100, value: 100},
                    ]}
                    min={0}
                    max={100}
                    onChange={handleSliderChange}
                    />
              </Grid>

              <Grid item xs={12}>
                  <div className="question">Por que eu, Adriana Ferreira, sou a pessoa mais indicada para ser sua coach e te ajudar a atingir seus objetivos?</div>
                  <TextField
                    name={this.state.formItems.whyMe.id}
                    id={this.state.formItems.whyMe.id}
                    value={this.state.formItems.whyMe.value}
                    label={this.state.formItems.whyMe.label}
                    placeholder={this.state.formItems.whyMe.placeholder}
                    error={this.state.formItems.whyMe.hasError}
                    helperText={this.state.formItems.whyMe.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    />
              </Grid>


              </Grid>
              </div>

            { /* BUTTONS */ }
            <Grid container spacing={2}>
          
              <Grid item xs={12}>
                  <div className="btn-wrapper side-by-side on-right">
                      <Button 
                      type="button" 
                      color="default" 
                      variant="contained" 
                      disableElevation 
                      >Voltar
                      </Button>

                      <Button 
                      type="button" 
                      color="primary" 
                      variant="contained" 
                      disableElevation 
                      disabled={(submitDisabled() || this.state.sending)}
                      onClick={(e) => handleSubmit(e)}
                      >{(this.state.sending) ? <CircularProgress size={24} /> : 'Enviar'}
                      </Button>
                  </div>
              </Grid>

          </Grid>
          
          </form>


      </div>

      { /* FOOTER */ }

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(FormCoaching));