// Import libraries
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// Import assets and styles
import "./media-gallery.styles.scss";

// Import Material-UI components
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import PublishIcon from "@material-ui/icons/Publish";
import SearchIcon from '@material-ui/icons/Search';
import ErrorIcon from '@material-ui/icons/Error';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

//Import Redux Actions
import { listCollections, closeGallery } from '../../redux/media-gallery/media-gallery.actions';
import { openModal } from '../../redux/modal/modal.actions';
import { authAction, getToken } from '../../utils/user-validation/user-validation.utils';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

class MediaGallery extends Component {
  constructor(props) {
    super(props);
    this.collectionField = React.createRef();
    this.fileInput = React.createRef();
    this.state = {
      selectedCollection: {
        cod: "all",
        label: "Todas as Imagens",
      },
      selectedImages: [],
      selectedFiles: [],
      selectedImagesTotal: 0,
      selectedImagesLabel: 'Usar imagem selecionada',
      editingImage: 0,
      editingImageWidth: 0,
      editingImageHeight: 0,
      editingImageSize: 0,
      loadings: {
        creatingCollection: false,
        deletingCollection: 0,
        listingCollection: false,
        gettingImages: false,
        uploadingImage: false,
        gettingMoreImages: false,
        updatingImage: false,
        deletingImage: false
      },
      mode: "none",
      fileCounter: 0,
      imagePreview: 'none',
      uploadButtonDisabled: true,
      formItems: {
        selectedFile: {
          id: 'fileImage',
          label: 'Imagem',
          placeholder: 'Selecione uma imagem',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
          }
        },
        selectedTitle: {
          id: 'fileTitle',
          label: 'Título',
          placeholder: 'Defina um título para a imagem',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
          }
        },
        selectedCollection: {
          id: "fileCollection",
          label: 'Coleção',
          placeholder: 'Seleciona uma coleção',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
          }
        },
        newCollection: {
          id: 'newCollection',
          label: 'Nova coleção',
          placeholder: 'Criar nova coleção',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        },
        updatedFile: {
          id: 'updatedFile',
          label: '',
          placeholder: '',
          value: 'none',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        },
        updatedCollection: {
          id: 'updatedCollection',
          label: 'Atribuído a',
          placeholder: 'Atualizar coleção',
          value: '0',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        },
        updatedTitle: {
          id: 'updatedTitle',
          label: 'Título',
          placeholder: 'Atualizar título',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        searchKey: {
          id: 'searchKeyMediaGallery',
          label: 'Procurar por',
          placeholder: 'Procurar por',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: null
        }
      },
      pagination:{
        totalRg: 0,
        totalRgLabel: 'Recuperando imagens...',
        totalPg: 1,
        currentPage: 1,
        results: []
      },
      environment: 'desktop'
    };

  }

  // Get images from server
  getImages = (collection, page, keyword) => {

    const loadings = this.state.loadings;

    const userToken = getToken();
    
    if(page === 1){
      loadings.gettingImages = true;
      this.setState({ 
        ...this.state, 
        loadings: loadings
      });
    }else{
      loadings.gettingMoreImages = true;
      this.setState({ 
        ...this.state, 
        loadings: loadings
      });

    }
      
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { 
        'Content-Type': 'application/json',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
    },
      body: JSON.stringify({
        page: page,
        collection: collection,
        searchKey: keyword,
        userToken: userToken,
        app: window.$appToken
      }),
    };

    fetch("/api/get_gallery.php",requestOptions)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
        
        const pagination = this.state.pagination;
        pagination.totalRg = data.totalItems;
        pagination.currentPage= data.currentPage;
        pagination.totalPg = data.totalPages;
        if(page===1){
          pagination.results = data.items;
        }else{
          pagination.results = pagination.results.concat(data.items);
        }

        if(data.totalItems === 0){
          pagination.totalRgLabel = 'Nenhum registro encontrado';
        }else if(data.totalItems === 1){
          pagination.totalRgLabel = 'Apenas 1 registro encontrado';
        }else{
          pagination.totalRgLabel = data.totalItems + ' registros encontrados';
        }
        
        if(page === 1){
          loadings.gettingImages = false;
        }else{
          loadings.gettingMoreImages = false;
        }

        this.setState({ 
          ...this.state, 
          pagination: pagination,
          loadings: loadings
        });

      })
      .catch((error) => {
        console.dir(error);
      });
  }
  
  componentDidMount(){
    
    const userToken = getToken();
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: { 
        'Content-Type': 'application/json',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
    },
      body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken
      }),
    };
    fetch('/api/collection_list.php', requestOptions)
    .then(data => data.json())
    .then(data => {
        authAction(data.authenticated);
        this.props.listCollections(data.collections);
    })

    this.getImages('all', 1);

    const windowSize = window.innerWidth;
    let mode = 'none';
    if(windowSize > 767){
      mode = 'browse';
    }
    this.setState({
      ...this.state,
      mode: mode
    });

  }

  //Rendering
  render(props) {

    // #region FUNCTIONS

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
            ...this.state.formItems
        };

        // Get State of the Input
        const formItem = {
            ...formItems[name]
        };

        // Validate Element if it has been Touched Before
        if(formItem.hasTouched){
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
        }

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

        if(callbacks){
          callbacks.map(item => {
            return item(event);
          })
        }

    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    // Allowed File Size
    const allowedFileSize = {
      handler: 2000000,
      label: '2Mb'
    };

    // Get display site
    const displayFileSize = (size) => {

      let fileSize;

      if (size>=1073741824){
        fileSize = Math.round(size/1073741824) + 'Gb';
      }else if(size>=1048576){
        fileSize = Math.round(size/1048576) + 'Mb';
      }else if(size>=1024){
        fileSize = Math.round(size/1024) + 'Kb';
      }else{
        fileSize = size + ' bytes';
      }

      return fileSize;
      
    }

    // Allowed File Types Array
    const allowedFileTypes = {
      types: [
        'png',
        'jpg',
        'jpeg',
        'gif'
      ],
      label: 'JPG, PNG ou GIF'
    };

    // Create a colllection
    const createCollection = () => {

        // Save the content of the input
        let collectionToSave = this.collectionField.current.value;

        if (collectionToSave.length > 0) {
          
          // Start loading and disable the input field
          const loadings = this.state.loadings;
          loadings.creatingCollection = true;

          this.setState({
            ...this.state,
            loadings: loadings
          });

          // Collect needed info
          const userToken = getToken();
          const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              userToken: userToken,
              app: window.$appToken,
              newCollection: collectionToSave,
            }),
          };

          fetch("/api/collection_create.php",requestOptions)
          .then(async (response) => {
              
            const data = await response.json();

              // check for error response
              if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }

              authAction(data.authenticated);
              
              this.props.listCollections(data.collections);

              loadings.creatingCollection = false;
              const formItems = this.state.formItems;
                const newCollection = formItems.newCollection;
                newCollection.hasError = false;
                newCollection.errorMsg = '';
                newCollection.value = '';
                formItems.newCollection = newCollection;
              this.setState({
                ...this.state,
                loadings: loadings,
                formItems: formItems
              });

              this.collectionField.current.value = "";

              if(!data.success){
                const formItems = this.state.formItems;
                const newCollection = formItems.newCollection;
                newCollection.hasError = true;
                newCollection.errorMsg = data.feedback;
                formItems.newCollection = newCollection;

                this.setState({
                  ...this.state,
                  formItems: formItems
                });

              }


            })
            .catch((error) => {

              console.dir(error);

            });

        }else{

                const formItems = this.state.formItems;
                const newCollection = formItems.newCollection;
                newCollection.hasError = true;
                newCollection.errorMsg = 'Informe o nome da nova coleção';
                formItems.newCollection = newCollection;

                this.setState({
                  ...this.state,
                  formItems: formItems
                });

        }
    };

    // Create Collection by Enter Key
    const createCollectionKey = (e) => {
      if(e.keyCode === 13){
        createCollection();
      }
    }

    // Ask if user wants to delete the selected collection
    const requestConfirmationForDeleteCollection = (id) => {

      // Collect collection info
      let collectionName;
      this.props.mediaGallery.collections.map((item) => {
        if(item.CodCollection === id){
          collectionName = item.Collection;
        }
        return null;
      });

      const modalObj = {
        modalIcon: 'clear',
        modalType: 'error',
        modalComponent: 'CONFIRMATION',
        modalTitle: 'Tem certeza?',
        modalParameters: {
          text: 'A coleção <b>' + collectionName + '</b> será apagada. Essa operação é irreversível.<br>Tem certeza que quer continuar?'
        },
        modalActions: {
          action: deleteCollection,
          parameters: id
        }
      }

      this.props.openModal(modalObj);

    }  
  
    // Delete a collection after user confirmation
    const deleteCollection = (id) => {

        // Get user token
        const userToken = getToken();

        // Start loading
        const loadings = this.state.loadings;
        loadings.deletingCollection = id;
        this.setState({
          ...this.state,
          loadings: loadings
        });
        
        // Collect needed info
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            collectionId: id,
            userToken: userToken,
            app: window.$appToken
          }),
        };

        fetch("/api/collection_delete.php",requestOptions)
          .then(async (response) => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            authAction(data.authenticated);
            
            this.props.listCollections(data.collections);

            // Start loading
            loadings.deletingCollection = 0;
            this.setState({
              ...this.state,
              loadings: loadings
            });

          })
          .catch((error) => {
            console.dir(error);
          });

    }
  
    // Select a collection
    const selectCollection = (id) => {

      let cod = id;
      let label = '';

      if(cod === 'all'){
        label = 'Todas as imagens';
      }else if(cod === 'recents'){
        label = 'Mais recentes';
      }else{
        this.props.mediaGallery.collections.map((item)=>{
          if(item.CodCollection === id){
            label = item.Collection;
          }
          return '';
        })
      }
      
      const pagination = this.state.pagination;
      pagination.totalRgLabel = 'Recuperando imagens...';

      const formItems = this.state.formItems;
      formItems.searchKey.value = '';

      this.getImages(id, 1);

        const windowSize = window.innerWidth;
        let sMode;
        if(windowSize > 767){
          sMode = 'browse';
        }else{
          sMode = 'none';
        }

      this.setState({
        ...this.state,
        mode: sMode,
        selectedCollection: {
          cod: id,
          label: label
        },
        pagination: pagination,
        formItems: formItems
      });

    }

    // Get Image Extension
    const getExtension = (filename) => {

      let tam = filename.length;
      let pos1 = filename.lastIndexOf('.') + 1;
      let dif = tam - pos1;
      let ext = filename.substr(pos1, dif);
      return ext.toLowerCase();
    }

    // File Input Handler
    const selectFileHandler = e => {
      
      let selectedFile = e.target.files[0];
      let selectedFileExt = getExtension(selectedFile.name);
      const formItems = this.state.formItems;
      const formItem = formItems.selectedFile;

      if(allowedFileTypes.types.indexOf(selectedFileExt) !== -1){
        
        if(selectedFile.size > allowedFileSize.handler){

          formItem.value = '';
          formItem.hasError = true;
          formItem.hasTouched = true;
          formItem.errorMsg = 'O tamanho da imagem (' + displayFileSize(selectedFile.size) + ') ultrapassa o limite máximo de ' + allowedFileSize.label;

          formItems.selectedFile = formItem;

          // File is too big
          this.setState({
            ...this.state,
            imagePreview: 'none',
            formItems: formItems,
            fileCounter: 0
          });

          this.fileInput.current.value = null;

        }else{
          
          formItem.value = e.target.files[0];
          formItem.hasError = false;
          formItem.hasTouched = true;
          formItem.errorMsg = '';

          formItems.selectedFile = formItem;

          // File is valid
          this.setState({
            ...this.state,
            imagePreview: 'url(' + URL.createObjectURL(e.target.files[0]) + ')',
            formItems: formItems,
            fileCounter: 1
          });

        }

      }else{

        formItem.value = '';
        formItem.hasError = true;
        formItem.hasTouched = true;
        formItem.errorMsg = 'Arquivos ' + selectedFileExt.toUpperCase() + ' não são permitidos. Utilize apenas imagens do tipo ' + allowedFileTypes.label;

        formItems.selectedFile = formItem;

          // File type is invalid
          this.setState({
            ...this.state,
            imagePreview: 'none',
            fileCounter: 0,
            formItems: formItems
          });

          this.fileInput.current.value = null;

      }
      //uploadButtonDisabled();
    }

    // File Input Accessibility Enter Key
    const handleFileInputEnter = (e) => {
      if(e.keyCode === 13){
        document.getElementById('uploadField').click();
      }
    }

    // Get User Data
    const getUserData = () => {
      let userData = [];
        if(localStorage.getItem('user') && localStorage.getItem("userToken")){
          userData = JSON.parse(localStorage.getItem('user'));
          userData.token = localStorage.getItem("userToken");
        }else{
          userData = {
            id: 0,
            token: ''
          };
        }

        return userData;
    }

    // Upload File
    const uploadFile = () => {

      const loadings = this.state.loadings;
      loadings.uploadingImage = true;

      const formItems = this.state.formItems;

      // Check if a file has been selected
      if(this.state.selectedFile === ''){

        const modalObj = {
          modalIcon: 'clear',
          modalType: 'error',
          modalComponent: 'GENERAL_ALERT',
          modalTitle: 'Hmmm... não esqueceu nada?',
          modalParameters: {
            text: 'Nenhum arquivo foi selecionado.'
          },
          modalActions: []
          }
          this.props.openModal(modalObj);

      }else{
      
        let collection = this.state.formItems.selectedCollection.value;
      
        // Check if a collection has been selected
        if(collection === '' || collection === 0){

          const modalObj = {
            modalIcon: 'clear',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Hmmm... não esqueceu nada?',
            modalParameters: {
              text: 'As coleções ajudam a manter sua galeria mais organizada e a encontrar a imagem desejada mais facilmente. Escolha uma coleção para inserir a nova imagem.'
            },
            modalActions: []
            }
            this.props.openModal(modalObj);

        }else{

          this.setState({
            ...this.state,
            loadings: loadings
          })
        
        // User Validation Info
        const userObj = getUserData();
        
        const formData  = new FormData();
        formData.append('imagem', this.state.formItems.selectedFile.value);
        formData.append('collection', this.state.formItems.selectedCollection.value);
        formData.append('title', this.state.formItems.selectedTitle.value);
        formData.append('userToken', getToken());
        formData.append('userId', userObj.id);
        formData.append('app', window.$appToken);
        
        const selectedCollection = this.state.formItems.selectedCollection.value;
        
          fetch('/api/media_upload.php', {
            method: 'POST',
            body: formData
          })
          .then(async (response) => {
            
            const data = await response.json();

            loadings.uploadingImage = false;
            formItems.selectedFile.value = '';
            formItems.selectedCollection.value = 0;
            formItems.selectedTitle.value = '';
            

            this.setState({
              ...this.state,
              loadings: loadings,
              formItems: formItems,
              imagePreview: 'none',
              fileCounter: 0
            })

            this.fileInput.current.value= '';

            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            authAction(data.authenticated);

            if(!data.success){
              const modalObj = {
                modalIcon: 'clear',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ops, algo correu mal :(',
                modalParameters: {
                  text: data.feedback
                },
                modalActions: []
              }

              this.props.openModal(modalObj);
            }else{

              const modalObj = {
                modalIcon: 'check',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Pronto :D',
                modalParameters: {
                  text: 'Upload completo!'
                },
                modalActions: []
              }

              this.props.openModal(modalObj);
              selectCollection(selectedCollection);
              

            }

          })
          .catch((error) => {
            console.dir(error);
          });
        }
      
      }

      
    }
    
    // Change view mode
    const changeMode = mode => {

      const windowSize = window.innerWidth;
      let sMode = 'none';

      if(mode === 'back' && windowSize < 768){
        sMode = 'none';
      }else if(mode === 'back' && windowSize >= 768){
        sMode = 'browse';
      }else{
        sMode = mode;
      }

      this.setState({
        ...this.state,
        mode: sMode
      })
    }

    // Select an Image on Gallery
    const selectImage = (id) => {

        const limit = this.props.mediaGallery.limit;
       
        let selectedImage = [];
        let results = this.state.pagination.results;
        let selected = this.state.selectedImages;
        let selectedImages = selected.length;
        let itemPos = null;

        // Get the image selected
        results.map((item)=>{
          if(item.mediaId === id){
            selectedImage = item;
          }
          return null;
        });

        // Check if image has been already selected
        selected.map((item, index) => {
            if(item.mediaId === id){
              itemPos = index;
            }
            return null;
        });

        if(itemPos === null){

          // Image has been not found and user can add infinite images
          if(limit === 0){
            selected.push(selectedImage);
          }else if(limit === 1){
            selected.splice(0, 1, selectedImage);
          }else if(selectedImages < limit){
            selected.push(selectedImage);
          }else{
            const modalObj = {
              modalIcon: 'clear',
              modalType: 'error',
              modalComponent: 'GENERAL_ALERT',
              modalTitle: 'Ops...',
              modalParameters: {
                text: 'Você só pode selecionar no máximo ' + limit + ' imagens.'
              },
              modalActions: []
            }
            this.props.openModal(modalObj);
          }

        }else{

          selected.splice(itemPos, 1);

        }

        let total = selected.length;
        let label = '';
        if(total <= 1){
          label = "Usar imagem selecionada";
        }else{
          label = "Usar " + total + " imagens selecionadas";
        }

        const windowSize = window.innerWidth;
        let sMode;
        if(windowSize > 767){
          sMode = 'browse';
        }else{
          sMode = 'none';
        }

        this.setState({
          ...this.state,
          mode: sMode,
          selectedImages: selected,
          selectedImagesTotal: total,
          selectedImagesLabel: label
        })
      
    }

    // Select an Image on Gallery By Keyboard
    const selectImageKeyPressHandle = (e, id, limit) =>{
      if(e.keyCode === 13 || e.keyCode === 32){
        selectImage(id);
      }
    }

    // Edit an Image Info
    const editImage = (id) => {

      const formItems = this.state.formItems;
      const title = formItems.updatedTitle;
      const collection = formItems.updatedCollection;
      const file = formItems.updatedFile;

      let imgWidth = 0;
      let imgHeight = 0;
      let imgSize = 0;

      // Get selected image info
      this.state.pagination.results.map((item) => {

          if(item.mediaId === id){
            file.value = 'url("' + window.$thumbsPath + item.mediaFile + '")';
            title.value = item.mediaTitle;
            collection.value = item.mediaCollection;

            imgWidth = item.mediaWidth;
            imgHeight = item.mediaHeight;
            imgSize = displayFileSize(item.mediaSize);
          }

          return null;

      })

      formItems.updatedFile = file;
      formItems.updatedTitle = title;
      formItems.updatedCollection = collection;

      this.setState({
        ...this.state,
        formItems: formItems,
        editingImage: id,
        editingImageWidth: imgWidth,
        editingImageHeight: imgHeight,
        editingImageSize: imgSize,
        mode: 'editing'
      })

    }

    // Update Image Info on Server
    const updateImageInfo = () => {

      const collectionId = this.state.formItems.updatedCollection.value;
      const userToken = getToken();

        // Start loading
        const loadings = this.state.loadings;
        loadings.updatingImage = true;
        this.setState({
          ...this.state,
          loadings: loadings
        });
        
        // Collect needed info
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            mediaId: this.state.editingImage,
            mediaCollection: this.state.formItems.updatedCollection.value,
            mediaTitle: this.state.formItems.updatedTitle.value,
            userToken: userToken,
            app: window.$appToken
          }),
        };

        fetch("/api/media_update.php",requestOptions)
          .then(async (response) => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            authAction(data.authenticated);

            // Start loading
            loadings.updatingImage = false;
            this.setState({
              ...this.state,
              loadings: loadings
            });

            selectCollection(collectionId);

          })
          .catch((error) => {
            console.dir(error);
          });

    }

    // Search for a image by keyword
    const searchImage = () => {

      const keyword = this.state.formItems.searchKey.value;

      if(keyword !== ''){

        const selectedCollection = {
          cod: 'search',
          label: 'Resultado da Pesquisa'
        }

        this.getImages('search', 1, keyword);

        this.setState({
          ...this.state,
          selectedCollection: selectedCollection
        });

      }

    }

    const searchImageKey = (e) => {
      if(e.keyCode === 13){
        searchImage();
      }
    }

    // Ask if user wants to delete the selected image
    const requestConfirmationForDeleteImage = () =>{

      const modalObj = {
        modalIcon: 'clear',
        modalType: 'error',
        modalComponent: 'CONFIRMATION',
        modalTitle: 'Tem certeza?',
        modalParameters: {
          text: 'A imagem <b>' + this.state.formItems.updatedTitle.value + '</b> será apagada. Essa operação é irreversível.<br>Tem certeza que quer continuar?'
        },
        modalActions: {
          action: deleteImage
        }
      }

      this.props.openModal(modalObj);
    }

    // Delete an image
    const deleteImage = () => {
      
        // Get user token
        const userToken = getToken();

        // Start loading
        const loadings = this.state.loadings;
        loadings.deletingImage = true;
        this.setState({
          ...this.state,
          loadings: loadings
        });

        // Collect needed info
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            imageId: this.state.editingImage,
            userToken: userToken,
            app: window.$appToken
          }),
        };

        fetch("/api/media_delete.php",requestOptions)
          .then(async (response) => {
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            authAction(data.authenticated);

            if(data.success){
                
                const formItems = this.state.formItems;
                formItems.updatedFile.value = '';
                formItems.updatedTitle.value = '';
                formItems.updatedCollection.value = 0;
              
                loadings.deletingImage = false;
                this.setState({
                  ...this.state,
                  mode: 'browse',
                  editingImage: 0,
                  editingImageWidth: 0,
                  editingImageHeight: 0,
                  editingImageSize: 0,
                  loadings: loadings,
                  formItems: formItems
                });
    
                selectCollection('all');

                const modalObj = {
                  modalIcon: 'check',
                  modalType: 'success',
                  modalComponent: 'GENERAL_ALERT',
                  modalTitle: 'Tchau, thau imagem :)',
                  modalParameters: {
                    text: 'A imagem foi excluída com sucesso.'
                  },
                  modalActions: null
                }
                this.props.openModal(modalObj);

            }else{

              const modalObj = {
                modalIcon: 'clear',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ops :(',
                modalParameters: {
                  text: data.feedback
                },
                modalActions: null
              }
              this.props.openModal(modalObj);

            }

          })
          .catch((error) => {
            console.dir(error);
          });

    }

    // Use the selected image(s)
    const saveSelection = () => {

      if(this.props.mediaGallery.onSave){
        this.props.mediaGallery.onSave(this.state.selectedImages);
      }
      this.props.closeGallery();

    }

    // Condition for Upload File Button to be disabled
    const disabledUploadButton = ((this.state.formItems.selectedFile.hasError) || (this.state.formItems.selectedTitle.value === '') || (this.state.formItems.selectedCollection.value === 0) || (this.state.loadings.uploadingImage));

    // Condition for Update Image Button to be disabled
    const disabledUpdateButton = ((this.state.formItems.updatedCollection.value === '0' || this.state.formItems.updatedCollection.value === '') || (this.state.formItems.updatedTitle.hasError) || (this.state.loadings.updatingImage));

    // #endregion
    
    return (
      <div className={this.props.mediaGallery.visible?"media-gallery":"media-gallery hidden"}>
        <div className="holder">
          
          { /* TITLE BAR */ }
          <div className="title-bar">
            
            <h2 className="title">Selecione uma imagem</h2>
            <button className="close-button" onClick={() => this.props.closeGallery()}><CloseIcon /> </button>

          </div>

          { /* CONTENTS */ }
          <div className="body">

            {/*  SIDE BAR  ********************************************************************************/ }
           
              
              {/* UPLOAD AREA */}
              <div className={this.state.mode === "upload" ? "upload-area" : "upload-area hidden"}>
                <div className={this.state.formItems.selectedFile.hasError?"upload-drop error":"upload-drop"}>
                  <div
                    className="upload-field"
                    tabIndex="0"
                    aria-label="Selecione uma imagem"
                    onKeyDown={handleFileInputEnter}
                  >
                    <input
                      id={this.state.formItems.selectedFile.id}
                      name="selectedFile"
                      className="ipt-file"
                      ref={this.fileInput}
                      type="file"
                      onChange={(e) => handleChange(e, [selectFileHandler])}
                      accept={allowedFileTypes}
                      tabIndex="-1"
                    />
                    <div
                      className={
                        this.state.fileCounter === 0
                          ? "img-preview"
                          : "img-preview filled"
                      }
                      style={{ backgroundImage: this.state.imagePreview }}
                    ></div>
                    <div className={(this.state.fileCounter=== 0)?'info':'info hidden'}>
                      <PublishIcon />
                      <Typography variant="body2" gutterBottom>
                        Arraste e solte uma imagem aqui ou
                        <br />
                        clique para selecionar
                      </Typography>
                    </div>
                  </div>
                  <FormHelperText>
                    {this.state.formItems.selectedFile.errorMsg}
                  </FormHelperText>
                </div>

                <div className="ipt-box">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={this.state.formItems.selectedCollection.hasError}
                  >
                    <InputLabel htmlFor="fileCollection">Atribuir à</InputLabel>
                    <Select
                      native
                      fullWidth
                      name="selectedCollection"
                      id={this.state.formItems.selectedCollection.id}
                      value={this.state.formItems.selectedCollection.value}
                      label={this.state.formItems.selectedCollection.label}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option aria-label="None" value="" />
                      {this.props.mediaGallery.collections.map((item) => {
                        return (
                          <option
                            key={"collection" + item.CodCollection}
                            value={item.CodCollection}
                          >
                            {item.Collection}
                          </option>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {this.state.formItems.selectedCollection.errorMsg}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="ipt-box">
                  <TextField
                    name="selectedTitle"
                    id={this.state.formItems.selectedTitle.id}
                    value={this.state.formItems.selectedTitle.value}
                    label={this.state.formItems.selectedTitle.label}
                    placeholder={this.state.formItems.selectedTitle.placeholder}
                    error={this.state.formItems.selectedTitle.hasError}
                    helperText={this.state.formItems.selectedTitle.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                
                <div className="btn-wrapper at-bottom margin-bottom--sm">
                  <button className="btn-circle other" onClick={() => changeMode('back')} ><ArrowBackIcon /></button>
                  <button className="btn-circle primary" onClick={uploadFile} disabled={disabledUploadButton} >{(this.state.loadings.uploadingImage)?<CircularProgress size={24} />:<DoneIcon />}</button>
                </div>
              </div>

              {/* COLLECTIONS AREA */}
              <div className={this.state.mode === "browse" ? "collections-area" : "collections-area hidden"}>
                
                <h4>Coleções</h4>
                <TextField
                  fullWidth
                  id={this.state.formItems.newCollection.id}
                  name="newCollection"
                  value={this.state.formItems.newCollection.value}
                  inputRef={this.collectionField}
                  helperText={this.state.formItems.newCollection.errorMsg}
                  className="ipt-add-collection"
                  variant="outlined"
                  placeholder={this.state.formItems.newCollection.placeholder}
                  disabled={this.state.loadings.creatingCollection}
                  error={this.state.formItems.newCollection.hasError}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            this.state.formItems.newCollection.placeholder
                          }
                          onClick={createCollection}
                          edge="end"
                        >
                          {this.state.loadings.creatingCollection ? (
                            <CircularProgress size={20} />
                          ) : (
                            <AddCircleIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    onKeyDown: (
                      event: React.KeyboardEvent<HTMLInputElement>
                    ) => {
                      createCollectionKey(event);
                    },
                  }}
                />
                <div className="collections">
                  <div
                    className={
                      this.state.selectedCollection.cod === "all"
                        ? "collection active"
                        : "collection"
                    }
                  >
                    <span
                      tabIndex="0"
                      onClick={(id) => selectCollection("all")}
                    >
                      Todas
                    </span>
                  </div>
                  
                  {this.props.mediaGallery.collections.map((item) => {
                    return (
                      <div
                        key={`collection${item.CodCollection}`}
                        className={
                          this.state.selectedCollection.cod ===
                          item.CodCollection
                            ? "collection active"
                            : "collection"
                        }
                      >
                        <span
                          onClick={(id) => selectCollection(item.CodCollection)}
                          tabIndex="0"
                        >
                          {item.Collection}
                        </span>
                        {this.state.loadings.deletingCollection ===
                        item.CodCollection ? (
                          <CircularProgress size={20} />
                        ) : (
                          <RemoveCircleIcon
                            onClick={(id) =>
                              requestConfirmationForDeleteCollection(item.CodCollection)
                            }
                            tabIndex="0"
                            aria-label="Apagar coleção"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className="btn-wrapper at-bottom mobile-only margin-bottom--sm">
                  <button className="btn-circle other" onClick={() => changeMode('back')} ><ArrowBackIcon /></button>
                </div>
                <div className="btn-wrapper at-bottom desktop-only margin-bottom--sm">
                  <button className="btn-circle primary" onClick={() => changeMode('upload')} ><AddAPhotoIcon /></button>
                </div>
              </div>

              {/* IMAGE EDITION AREA */}
              <div className={(this.state.mode === "editing")?"editing-area":"editing-area hidden"}>
                <h4>Editar Imagem</h4>
                <div className="image-sample" style={{backgroundImage:this.state.formItems.updatedFile.value}}>
                </div>
                <div className="image-info">
                  <Typography variant="overline" gutterBottom>
                  {this.state.editingImageWidth + ' x ' + this.state.editingImageHeight + ' pixels / ' + this.state.editingImageSize} 
                  </Typography>
                </div>
                <div className="ipt-box">
                  <FormControl variant="outlined" fullWidth error={this.state.formItems.updatedCollection.hasError}>
                    <InputLabel htmlFor="updatedCollection">Atribuído à</InputLabel>
                    <Select
                      native
                      fullWidth
                      name="updatedCollection"
                      id={this.state.formItems.updatedCollection.id}
                      value={this.state.formItems.updatedCollection.value}
                      label={this.state.formItems.updatedCollection.label}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option key="photo-collection-0" value={0}>Nenhuma</option>
                      {this.props.mediaGallery.collections.map((item) => {
                        return (
                          <option
                            key={"photo-collection" + item.CodCollection}
                            value={item.CodCollection}
                          >
                            {item.Collection}
                          </option>
                        );
                      })}
                    </Select>
                    <FormHelperText>
                      {this.state.formItems.updatedCollection.errorMsg}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="ipt-box">
                  <TextField
                    name="updatedTitle"
                    id={this.state.formItems.updatedTitle.id}
                    value={this.state.formItems.updatedTitle.value}
                    label={this.state.formItems.updatedTitle.label}
                    placeholder={this.state.formItems.updatedTitle.placeholder}
                    error={this.state.formItems.updatedTitle.hasError}
                    helperText={this.state.formItems.updatedTitle.errorMsg}
                    fullWidth
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="btn-wrapper">
                <Button color="secondary" fullWidth onClick={() => requestConfirmationForDeleteImage()}>Apagar Imagem <DeleteIcon /></Button>
                </div>
                <div className="btn-wrapper at-bottom margin-bottom--sm">
                  <button className="btn-circle other" onClick={() => changeMode('back')} ><ArrowBackIcon /></button>
                  <button className="btn-circle primary" disabled={disabledUpdateButton} onClick={() => updateImageInfo()}>{(this.state.loadings.updatingImage)?<CircularProgress size={24} />:<DoneIcon />}</button>
                </div>
              </div>

            

            {/* MAIN / GALLERY AREA  ********************************************************************/ }
            <div className="browse">
              
                {/* HEADER */}
                <div className="gallery-header">
                  <div className="collection-info">
                    <h2 className="title">
                      {this.state.selectedCollection.label}
                    </h2>
                    <div className="results">
                      <Typography variant="caption" gutterBottom>
                      {this.state.pagination.totalRgLabel}
                      </Typography>
                    </div>
                  </div>
                  <div className="search-area">
                    <TextField
                      name='searchKey'
                      id={this.state.formItems.searchKey.id}
                      value= {this.state.formItems.searchKey.value}
                      error = {this.state.formItems.searchKey.hasError}
                      helperText={this.state.formItems.searchKey.errorMsg}
                      fullWidth
                      inputRef={this.searchField}
                      className="ipt-search"
                      variant="outlined"
                      onChange = {handleChange}
                      onBlur = {handleBlur}
                      onKeyDown = {(e) => searchImageKey(e)}
                      size="small"
                      placeholder= {this.state.formItems.searchKey.placeholder}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Procurar"
                              onClick={() => searchImage()}
                              edge="end"
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                       
                      }}
                    />
                  </div>
                </div>
                
                {/* BODY */}
                <div className="gallery-body">
                  
                    { /* LOADING RESULTS */ }
                    <div className={(this.state.loadings.gettingImages)?'results-loading':'results-loading hidden'}>
                        <CircularProgress size={48} />
                    </div>
                    
                    { /* NO RESULTS */ }
                    <div className={(this.state.pagination.totalRg === 0 && !this.state.loadings.gettingImages)?'no-results':'no-results hidden'}>
                      <ErrorIcon />
                      Nenhum resultado encontrado
                    </div>
                    
                    { /* RESULTS HAS BEEN FOUND */ }
                    <div className={(this.state.pagination.totalRg > 0 && !this.state.loadings.gettingImages)?'results':'results hidden'}>

                      { /* LIST IMAGES */ }
                      <div className="list">
                        {
                          this.state.pagination.results.map((item) => {
                            let imageKey = 'imagem' + item.mediaId;
                            let imageUrl = "url('" + window.$thumbsPath + item.mediaFile + "')";
                            let found = false;

                            this.state.selectedImages.map((img)=>{
                              if(img.mediaId === item.mediaId){
                                found = true;
                              }
                              return null;
                            });


                            return (<div key={imageKey} className={(found)?'item active':'item'}>
                                      <div className="selected-icon" tabIndex="0" onClick={() => selectImage(item.mediaId)} onKeyDown={(e) => selectImageKeyPressHandle(e, item.mediaId)}><DoneIcon /></div>
                                      <div className="tools">
                                        <div className="btn-wrapper on-right">
                                          <button className="btn-circle small ghost" onClick={() => editImage(item.mediaId)} ><EditIcon /></button>
                                        </div>
                                      </div>
                                      <div id={'photo-' + item.mediaId} className="photo" style={{ backgroundImage: imageUrl }}></div>
                                    </div>)
                          })
                        }
                      </div>

                      { /* LOAD MORE BUTTON */ }
                      <div className={(this.state.pagination.totalPg > this.state.pagination.currentPage)?'see-more':'see-more hidden'}>
                          <div className="btn-wrapper centered">
                          <button 
                          onClick={() => this.getImages(this.state.selectedCollection.cod, this.state.pagination.currentPage + 1)} 
                          disabled={this.state.loadings.gettingMoreImages}
                          className="btn-circle medium other" >{(this.state.loadings.gettingMoreImages)?<CircularProgress size={24} />:<AddIcon />}</button>
                          </div>
                      </div>
                    
                    </div>

                </div>

                {/* FOOTER */}
                <div className="gallery-footer">
                    
                    <div className="btn-wrapper side-by-side on-right desktop-only">
                      <Button type="button"  color="default" variant="outlined" disableElevation onClick={() => this.props.closeGallery()}>Cancelar</Button>
                      <Button type="button"  color="primary" variant="contained" disableElevation onClick={() => saveSelection()} disabled={(this.state.selectedImagesTotal === 0)}>{this.state.selectedImagesLabel}</Button>
                    </div>
                    <div className="btn-wrapper at-bottom mobile-only">
                      <button className="btn-circle primary" onClick={() => changeMode('upload')} ><AddAPhotoIcon /></button>
                      <button className="btn-circle primary" onClick={() => changeMode('browse')} ><ListIcon /></button>
                      <button className="btn-circle primary" disabled={(this.state.selectedImagesTotal === 0)} onClick={() => saveSelection()}><DoneIcon /></button>
                    </div>
                    
                </div>

            </div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mediaGallery: state.mediaGallery,
});

const mapDispatchToProps = dispatch => ({
  listCollections: collections => dispatch(listCollections(collections)),
  closeGallery: () => dispatch(closeGallery()),
  openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MediaGallery));