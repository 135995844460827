import React from 'react';
import './banner-title.styles.scss';

import Headings from '../headings/heading.view.component';

const BannerTitleViewMode = (props) =>{

     
    const background = (props.img !== '')? 'url("' + window.$imagesPath + props.img + '")' : 'none';
           

    return(
    <div className='module-banner-title' style={{backgroundImage: background}}>
        <div className="banner" >
            <div className="banner-heading">
                <span className={(props.pageData.categoryId < 3) ? 'category-name hidden' : 'category-name'}>{props.pageData.category}</span>
                <Headings type={1} title={props.pageData.title} darkmode />
            </div>
        </div>
    </div>
    );

}

export default BannerTitleViewMode;