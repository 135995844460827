import React from 'react';
import './calendar-list.styles.scss';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const CalendarList = (props) => {

        const { slots } = props;
        let filteredSlots = slots;

        if(!props.showPast && !props.showEmpty){
            filteredSlots = slots.filter((item) => (item.status !== 0 && !item.past));
        }
        if(!props.showPast && props.showEmpty){
            filteredSlots = slots.filter((item) => (!item.past));
        }
        if(props.showPast && !props.showEmpty){
            filteredSlots = slots.filter((item) => (item.status !== 0));
        }

        const getUniqueDays = (obj) => {

            let uniqueDays = [];
            obj.forEach((item) => {
                if(uniqueDays.indexOf(item.day) === -1){
                    uniqueDays.push(item.day);
                }
            })

            return uniqueDays;

        }

        const daysWithEvents = getUniqueDays(filteredSlots);
   
        return (
          <div className="calendar-list">
            <div className="toolbar">
              <FormControlLabel
                control={
                  <Switch
                    checked={props.showEmpty}
                    onChange={props.changeOption}
                    name="showEmpty"
                    color="primary"
                  />
                }
                label="Mostrar slots vazios"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={props.showPast}
                    onChange={props.changeOption}
                    name="showPast"
                    color="primary"
                  />
                }
                label="Mostrar eventos passados"
              />
            </div>
            
            {daysWithEvents.map((day) => {
              let dayKey = "day-" + day;
              let dt = new Date(props.year, props.month - 1, day);
              let wkd = props.weekdays[dt.getDay()];
              let eventsOfDay = filteredSlots.filter((item) => item.day === day);


              return (
                <div key={dayKey} className="day">
                  <div className="day-header">
                    <span className="label-day">{day}</span>
                    <span className="label-weekday">{wkd}</span>
                  </div>
                  <div className="day-body">
                    {eventsOfDay.map((event) => {
                      let eKey = "event-" + event.id;

                      let eventClasses = "event-card";
                      let title = '';

                      if (event.past) {
                        eventClasses += " past";
                        
                      }

                      if (event.status === 0 && !event.past) {
                        eventClasses += " free";
                        title = 'Livre';
                        
                      }

                      if (event.status !== 0 && !event.past) {
                        eventClasses += " busy";
                        title = (event.title) ? event.title : event.user.FullName;
                      }
                      
                      if(!props.showEmpty && event.status === 0){
                        eventClasses += " hidden";
                      }

                      if(!props.showPast && event.past){
                        eventClasses += " hidden";
                      }


                      return (
                        <div key={eKey} className={eventClasses} onClick={() => props.openEvent(event.id)}>
                          <div className="schedule">{event.startTime}</div>
                          <div className="title">{title}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            {
                (filteredSlots.length === 0)?
                <div className="no-results">
                    <ErrorOutlineIcon />
                    <p className="title">Nenhum evento encontrado</p>
                    <p className="text">Slots livres e/ou eventos passados podem estar ocultos de acordo com os filtros selecionados.</p>
                </div>:
                null
            }

            <div className={(filteredSlots.length === 0) ? 'legend hidden' : 'legend'}>
                <div className="free"><span></span>Livre</div>
                <div className="busy"><span></span>Ocupado</div>
                <div className="past"><span></span>Passado</div>
            </div>
          </div>
        );

}
export default CalendarList;