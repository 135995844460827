import React, { Component } from 'react';
import { connect } from "react-redux";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {getToken, authAction} from "../../utils/user-validation/user-validation.utils";

// Import UI components
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

// Import Icons
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import MailIcon from '@material-ui/icons/Mail';
import { CircularProgress } from '@material-ui/core';

import { openModal } from '../../redux/modal/modal.actions';

class View extends Component{

    constructor(props){
      super(props);
      this.state = {
        sending: false,
        sendingSuccess: false,
        sendingError: false,
        sendingErrorMsg: '',
        deleting: false,
      };
    }

    sendLink = () => {

        const userToken = getToken();
        const formData = {
          userToken: userToken,
          app: window.$appToken,
          slot: this.props.slot.id,
        }

        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify(formData)
        };

        fetch("/api/slots_send_link.php", requestOptions)
        .then(async (response) => {
          const data = await response.json();

          if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
          }
          authAction(data.authenticated);

          if(data.success){
            this.setState({sending: false, sendingSuccess: true, sendingError: false, sendingErrorMsg: ''});
          }else{
            this.setState({sending: false, sendingSuccess: false, sendingError: true, sendingErrorMsg: data.feedback});
          }

        });
    }

    deleteSlot = () => {

      const userToken = getToken();
      const formData = {
        userToken: userToken,
        app: window.$appToken,
        slot: this.props.slot.id,
      }

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*",
        },
        body: JSON.stringify(formData)
      };

      fetch("/api/slots_delete.php", requestOptions)
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
        }
        authAction(data.authenticated);

        if(data.success){

            const modalObj = {
            modalIcon: 'done',
            modalType: 'success',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Evento Excluído',
            modalParameters: {
                text: 'O evento foi cancelado com sucesso.'
            },
            modalActions: {
              action: this.props.closeCard,
              parameters: true
            }
            };

            const callback = () => {this.props.openModal(modalObj)}
            this.setState({deleting: false}, callback);

        }else{

            const modalObj = {
            modalIcon: 'clear',
            modalType: 'error',
            modalComponent: 'GENERAL_ALERT',
            modalTitle: 'Ops!',
            modalParameters: {
                text: data.feedback
            },
            modalActions: []
            };

            const callback = () => {this.props.openModal(modalObj)}
            this.setState({deleting: false}, callback);

        }

      });
  }

    render(){

      const {slot} = this.props;

      // Theme for red button
      const theme = createMuiTheme({
          palette: {
            primary: red,
          },
      });
      
      // Format Dates
      const formatDate = (date, withHour) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        let mNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

        sDate = d + ' de ' + mNames[m - 1] + ', ' + y;

        }

        return sDate;

      }

      const sendLinkToClient = () => {
        this.setState({sending: true}, this.sendLink);
      }

      const deleteCard = () => {

        let msg = '.';
        if(slot.status > 0){
          msg = ' e o cliente notificado por e-mail.'
        }

        const callback = () => {
          this.setState({deleting: true}, this.deleteSlot);
        }
        
        const modalObj = {
          modalIcon: 'clear',
          modalType: 'error',
          modalComponent: 'CONFIRMATION',
          modalTitle: 'Excluir Evento',
          modalParameters: {
              text: 'Se continuar, o evento será excluído' + msg + ' Essa operação é irreversível.<br/><br/><b>Tem certeza que quer prosseguir?</b>'
          },
          modalActions: {
              action: callback
          }
          }
          this.props.openModal(modalObj);

      }

      return(
          <div className="view">
                {
                  (slot.status > 0)?
                  <div className="notes-box">
                    <div className="title">
                      <span className="text">Notas</span>
                      <span className="action" onClick={() => this.props.toggleView('note')}>adicionar</span>
                    </div>
                    <div className="notes-wrapper">
                    <div className="notes">
                      {
                        (slot.notes.length === 0)?
                        <div className="note">
                          <span className="text">Nenhuma nota registrada</span>
                        </div>:
                        slot.notes.map((item, index) => {

                          let sKey = 'note-' + slot.id + '-' + index;

                          return(
                            <div key={sKey} className="note">
                              <span className="date">{formatDate(item.date, true)}</span>
                              <span className="text">{item.note}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                    </div>
                  </div>:
                  null
                }
                
                {
                  (slot.status > 0)?
                  <div className="call-box">
                    <div className="title">
                      <span className="text">Vídeo Conferência</span>
                      <span className="action" onClick={() => this.props.toggleView('conference')}>definir link</span>
                    </div>
                    <div className="call">
                      {
                        (slot.url)?
                        <>
                        <a href={slot.url} target="_blank" rel="noopener noreferrer">{slot.url}</a>
                      <Tooltip title={
                        (this.state.sendingSuccess) ?
                        'Link enviado com sucesso' :
                        (this.state.sendingError) ?
                        this.state.sendingErrorMsg :
                        'Re-enviar link por e-mail ao cliente'}
                        >
                            <IconButton color={(this.state.sendingSuccess) ? 'primary' : (this.state.sendingError) ? 'secondary' : 'default'} onClick={sendLinkToClient} disabled={(this.state.sending || this.state.deleting)}>
                              {
                              (this.state.sending) ? 
                              <CircularProgress size={18} />:
                              (this.state.sendingError)?
                              <ClearIcon />:
                              (this.state.sendingSuccess)?
                              <DoneIcon />:
                              <MailIcon />
                              }
                            </IconButton>
                      </Tooltip>
                      </>:
                      'Sem link definido'
                      }
                    </div>
                  </div>:
                  null
                }
                

                <div className="btn-wrapper side-by-side on-right">
                  <ThemeProvider theme={theme}>
                    <Button color="primary" variant="contained" disableElevation endIcon={<DeleteIcon />} onClick={deleteCard} disabled={this.state.deleting}>
                      {(this.state.deleting) ? 'Apagando...' : 'Apagar'}
                    </Button>
                  </ThemeProvider>
                  {
                    (slot.status > 0)?
                    <Button color="primary" variant="contained" disableElevation endIcon={<InsertInvitationIcon />} onClick={() => this.props.toggleView('reschedule')} disabled={this.state.deleting}>Reagendar</Button>:
                    null
                  }
                </div>
              </div>
      )

    }
}

const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData))
});

export default connect(null, mapDispatchToProps)(View);