import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

// Import routes types
import PrivateRoute from '../utils/routes/private-route';
import PublicRoute from '../utils/routes/public-route';

// Import Routes Destinations
import Search from '../pages/search/search.component';
import Page from '../pages/page/page.component';
import PageEditor from '../pages/page-editor/page-editor.component';
import Login from '../pages/login/login.component';
import Dashboard from '../pages/dashboard/dashboard.component';
import PagesDirectory from '../pages/pages-directory/pages-directory.component';
import UnlockContent from '../pages/unlock-content/unlock-content.component';
import MenusAdmin from '../pages/menus-admin/menus-admin.component';
import Users from '../pages/users/users.component';
import Leads from '../pages/leads/leads.component';
import Settings from '../pages/settings/settings.component';
import MyFavorites from '../pages/my-favorites/my-favorites.component';
import MyBookmarks from '../pages/my-favorites/my-bookmarks.component';
import CommentsManager from '../pages/comments/comments.component';
import FormCoaching from '../pages/form-coaching/form-coaching.component';
import Welcome from '../pages/welcome/welcome.component';
import ForgotPassword from '../pages/forgot-password/forgot-password.component';
import ApproveContents from '../pages/approve-contents/approve-contents.component';
import PagePreview from '../pages/page-preview/page-preview.component';
import PartnersSchedule from '../pages/partners-schedule/partners-schedule.component';
import CustomerSchedule from '../pages/customer-schedule/customer-schedule.component';
import PartnerPage from '../pages/partner-page/partner-page.component';
import Classes from '../pages/classes/classes.component';
import Directory from '../pages/directory/directory.component';


export class Routes extends Component{
    render(){
        return(
        
            <Switch>

                { /* PUBLIC AREA */ }
                <Route exact path='/' render={(props) => <Page pageId='home' {...props} />}/>
                <Route exact path='/contato' render={(props) => <Page pageId='contato' {...props} />}/>
                <Route exact path='/blog' render={(props) => <Page pageId='blog' {...props} />}/>
                <Route exact path='/sobre' render={(props) => <Page pageId='sobre' {...props} />}/>
                <Route exact path='/parceiros' render={(props) => <Page pageId='content-44' {...props} />}/>
                <Route exact path='/treinamento' render={(props) => <Page pageId='content-47' {...props} />}/>
                <Route exact path='/parceiros/:partnerId' render={(props) => <PartnerPage {...props} />}/>
                <Route exact path='/conteudo/:categoryId' render={(props) => <Directory {...props} />}/>
                <Route exact path='/termo-de-uso' render={(props) => <Page pageId='termo-de-uso' {...props} />}/>
                <Route exact path='/coaching' render={(props) => <Page pageId='coaching' {...props} />}/>
                <Route exact path='/coaching/form' component={FormCoaching}/>
                <Route exact path='/privacidade' render={(props) => <Page pageId='privacidade' {...props} />}/>
                <Route exact path='/busca' component={Search} />
                <Route exact path='/esqueci-a-senha' component={ForgotPassword} />
                <Route exact path='/sobre/:pageId' render={(props) => <Page {...props} />} />
                <Route exact path='/page/:pageId' render={(props) => <Page {...props} />} />
                <Route exact path='/welcome' render={(props) => <Welcome {...props} />} />

                <PublicRoute exact path='/login' component={Login} />

                { /* ADMIN AREA */ }
                <PrivateRoute exact path="/pages" component={PagesDirectory} />
                <PrivateRoute exact path="/leads" component={Leads} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/navigation" component={MenusAdmin} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/comments" component={CommentsManager} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute exact path='/edit/:pageId' component={PageEditor} />
                <PrivateRoute exact path='/approve-contents' component={ApproveContents} />
                <PrivateRoute exact path='/preview/:pageId/:backTo' component={PagePreview} />
                <PrivateRoute exact path='/agenda' component={CustomerSchedule} />
                <PrivateRoute exact path='/schedule' component={PartnersSchedule} />
                <PrivateRoute exact path='/classes' component={Classes} />

                { /* MEMBERS AREA */ }
                <PrivateRoute exact path='/unlock-content/:token' component={UnlockContent} />
                <PrivateRoute exact path='/meus-favoritos' component={MyFavorites} />
                <PrivateRoute exact path='/bookmarks' component={MyBookmarks} />

                { /* FALLBACK */ }
                <Route exact path='/404' render={(props) => <Page pageId='404' {...props} />} />
                <Route path='*' render={(props) => <Page pageId='404' {...props} />} />

            </Switch>
        
        )
    }
};

export default Routes;