import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import FooterMenu from '../../components/footer-menu/footer-menu.component';
import SignatureLogo from '../../assets/images/logo_fulviopinto_signature.svg';
import AbriccoFooter from '../../assets/images/abricco_footer.svg';
import AbriccoText from '../../assets/images/abricco_text.svg';
import './footer.styles.scss';

class Footer extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {  
            exceptions: [],
            footerIsVisible: true,
            footerIsHidden: false
        }
    }

    componentDidMount(){

        const checkWhatsAppButton = () => {

            const footer = document.querySelector('.footer-site');
            const button = document.querySelector('.whatsapp-always-visible-button');

            const rect = footer.getBoundingClientRect();
            const isVisible = (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            if(isVisible){
                button.classList.add('hidden')
            }else{
                button.classList.remove('hidden')
            }

        }

        checkWhatsAppButton()
        document.addEventListener('scroll', checkWhatsAppButton)
    }

    componentDidUpdate(prevProps){
        if(prevProps.exceptions !== this.props.exceptions){
            
            const updatedExceptions = this.props.exceptions;
            const noHeaderPages = updatedExceptions.map(item => item.page);
            const currentIdx = noHeaderPages.indexOf(this.props.location.pathname);
            let updatedFooter = false;

            if(currentIdx > -1){
                updatedFooter = (updatedExceptions[currentIdx].footer === 0);
            }
            
            this.setState({exceptions: updatedExceptions, footerIsHidden: updatedFooter}, this.toggleHeaderAndFooterClasses);

        }
    }


    toggleHeaderAndFooterClasses = () => {

        const { footerIsHidden } = this.state;
        const body = document.getElementById('root');

        if(footerIsHidden){
            body.classList.add('no-footer');
        }else{
            body.classList.remove('no-footer');
        }
        

    }

    isInViewport = (el) => {
        
    }

    //Rendering
    render(){

        const { footerIsHidden } = this.state;

        if(footerIsHidden){
            return null;
        }

        return(
            <footer className="footer-site">

                <div className='whatsapp-always-visible-button hidden'>
                <a className="footer-social whatsapp" href="https://api.whatsapp.com/send?phone=5522999858362" target="_blank" rel="noopener noreferrer">Whatsapp</a>
                </div>
                
                <div className="footer-diagonal">
                    <div className="footer-logo-text">
                        <a href="/" target="_self">
                        <img src={AbriccoText} alt="Abriccó" />
                        </a>
                    </div>
                </div>
                <div className="footer-row">
                    <div className="footer-abricco">
                        <img src={AbriccoFooter} alt="Abriccó" />
                    </div>
                    <div className="footer-left">
                        <FooterMenu {...this.props} />
                    </div>
                    <div className="footer-right">
                        <div className="footer-social-icons">
                            <a className="footer-social facebook" href="https://www.facebook.com/abricco.treinamento" target="_blank" rel="noopener noreferrer">Facebook</a>
                            <a className="footer-social whatsapp" href="https://api.whatsapp.com/send?phone=5522999858362" target="_blank" rel="noopener noreferrer">Whatsapp</a>
                            <a className="footer-social instagram" href="https://www.instagram.com/abricco.treinamento/" target="_blank" rel="noopener noreferrer">Instagram</a>
                            <a className="footer-social youtube hidden" href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">YouTube</a>
                            <a className="footer-social linkedin hidden" href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">Linkedin</a>
                        </div>
                    </div>
                </div>
                <div className="footer-credits">
                    <div className="copyright">
                        Abriccó &copy; Copyright2020 - Todos os direitos reservados
                    </div>
                    <div className="signature">
                        <a href="http://www.fulviopinto.com" target="_blank" rel="noopener noreferrer">
                        <span className="signature-text">Designed and developed by Fúlvio Pinto</span>
                        <img className="signature-logo" src={SignatureLogo} alt="Designed and developed by Fúlvio Pinto" />
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => ({
    menus: state.menus 
});


export default withRouter(connect(
    mapStateToProps,
    null
  )(Footer));