import React, {Component} from 'react';
import './partners.styles.scss';

// Import components
import Container from '@material-ui/core/Container';
import Partner from './partner.component';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button";
import Pagination from '../../components/pagination/pagination.component';
import validate from '../../utils/form-validation/form-validation.utils';

class PartnersViewMode extends Component{

    constructor(props) {
        super(props);
        
        this.state = {
            loading: true,
            refreshing: false,
            results: [],
            pagination: {
                page: 1,
                pages: 1,
                total: 0
            },
            noResults: false,
            formItems: {
                searchKey: {
                    id: 'searchKey',
                    label: 'Procurar por',
                    placeholder: 'Procurar por parceiro, serviço etc',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255
                    }
                },
                ordering:{
                    id: 'ordering',
                    label: 'Ordenar por',
                    placeholder: '',
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                }
            },
            ordering: [
                {id: 0, label: 'Nome A-Z'},
                {id: 1, label: 'Nome Z-A'},
                {id: 2, label: 'Mais Recentes'},
                {id: 3, label: 'Mais Antigos'},
            ]
              
        }
    }

    componentDidMount(){

        const formItems = this.state.formItems;
        formItems.searchKey.value = (this.props.keyword) ? this.props.keyword : '';

        this.setState({
            ...this.state,
            formItems: formItems
        }, this.getItems(1));

    }

    getItems = (page) => {
  
        const searchKey = this.state.formItems.searchKey.value;
        const ordering = this.state.formItems.ordering.value;
        const perpage = (this.props.items) ? parseInt(this.props.items) : 0;
        const perservice = (this.props.service) ? parseInt(this.props.service) : 0;

        if(!this.state.loading){
            this.setState({refreshing: true});
        }
  
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            app: window.$appToken,
            page: page,
            searchKey: searchKey,
            ordering: ordering,
            perpage: perpage,
            service: perservice
          }),
        };
  
        fetch("/api/get_partners.php", requestOptions)
        .then(async (response) => {
  
                const data = await response.json();
        
                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }
                
                // Feed pagination
                const pagination = this.state.pagination;
                pagination.total = data.totalItems;
                pagination.page= data.currentPage;
                pagination.pages = data.totalPages;
                const results = data.items;

                const noResults = (results.length === 0);
        
                this.setState({ 
                    ...this.state, 
                    pagination: pagination,
                    results: results,
                    loading: false,
                    refreshing: false,
                    noResults: noResults
                });
  
        })
        .catch((error) => {
          console.dir(error);
        });
  
    }

    render(){

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
            ...this.state.formItems
        };

        // Get State of the Input
        const formItem = {
            ...formItems[name]
        };

        // Validate Element if it has been Touched Before
        if(formItem.hasTouched){
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
        }

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

        if(callbacks){
          callbacks.map(item => {
            return item(event);
          })
        }

    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    // FORMS: SUBMIT
    const handleSubmit = () => {
        this.getItems(1);
    }

    const missingRow = (this.state.results.length < 4);


    if(this.state.loading){
        return(
        <div className='module-partners'>
            <div className={(this.state.loading) ? 'loading' : 'loading hidden'}>
                <CircularProgress size={48} />
            </div>
        </div>)
    }

    return(
        
            <div className='module-partners'>
                <div className="partners-filter">
                    <Container className="filters" maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="searchKey"
                                id={this.state.formItems.searchKey.id}
                                value={this.state.formItems.searchKey.value}
                                label={this.state.formItems.searchKey.label}
                                placeholder={this.state.formItems.searchKey.placeholder}
                                error={this.state.formItems.searchKey.hasError}
                                helperText={this.state.formItems.searchKey.errorMsg}
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={this.state.formItems.ordering.hasError}
                            >
                                <InputLabel htmlFor={this.state.formItems.ordering.id}>{this.state.formItems.ordering.label}</InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    name={this.state.formItems.ordering.id}
                                    id={this.state.formItems.ordering.id}
                                    value={this.state.formItems.ordering.value}
                                    label={this.state.formItems.ordering.label}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    {this.state.ordering.map((item) => {
                                    let catKey = 'partner-ordering-' + item.id;
                                    return(<option key={catKey} value={item.id}>{item.label}</option>);
                                    })}
                                </Select>
                                <FormHelperText>
                                    {this.state.formItems.ordering.errorMsg}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button color="primary" variant="contained" onClick={handleSubmit} fullWidth disabled={this.state.refreshing}>Filtrar</Button>
                        </Grid>
                    </Grid>
                    </Container>
                </div>

                <div className={(missingRow)? 'partners-list missing-items' : 'partners-list'}>
                    <div className={(this.state.refreshing) ? 'loading' : 'loading hidden'}>
                        <CircularProgress size={48} />
                    </div>
                    <Grid container spacing={2}>
                    {
                        this.state.results.map((item) => {
                            return(<Grid item xs={12} sm={6} md={3} key={'partner-' + this.props.contentId + '-' + item.id }><Partner {...item}/></Grid>);
                        })
                    }
                    
                    </Grid>
                </div>
                {
                    (this.state.pagination.pages > 1) ?
                    <div className="partners-pagination">
                        <Pagination 
                            currentPage={this.state.pagination.page} 
                            totalPages={this.state.pagination.pages} 
                            totalItems={this.state.pagination.total}
                            goToFunction={this.getItems}
                        />
                    </div>:null
                }
                
            </div>
        )
    }

}

export default PartnersViewMode;
