import React from 'react';
import { withRouter} from "react-router-dom";
import './footer-menu.styles.scss';

const FooterMenu = (props) => {

    const MenuId = 7;
    
    const FooterMenuItems = props.menus.menuItems.filter(
        menuObj => parseInt(menuObj.placeholder) === parseInt(MenuId)
    );
   

    return(
        <div className="footer-menu">
            <ul className="menu">
                {
                    FooterMenuItems.map((item) => {

                        let menuKey = 'footer-menu-' + item.id;

                        return (
                            <li key={menuKey} className='menu-item'>
                                <a href={item.url}>
                                    <span>{item.label}</span>
                                    <span className="menu-item__line"></span>
                                </a>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default withRouter(FooterMenu);