//Define selected item on menu
export const openDirectory = (limit, saveFunction) => ({
    type: 'OPEN_DIRECTORY',
    payload: {
        limit: limit,
        saveFunction: saveFunction
    }
})

export const closeDirectory = () => ({
    type: 'CLOSE_DIRECTORY'
})

export const getContents = (contents) => ({
    type: 'GET_CONTENTS',
    payload: contents
})