import React from 'react';
import './image-with-text.styles.scss';

const ImageTextViewMode = (props) =>{

    const moduleClasses = (props.size === 'avatar-small') ? 'module-imagetext compact ' + props.alignment : 'module-imagetext ' + props.alignment;
    const Image = (props.src !== '') ? window.$imagesPath + props.src : window.$imagesPath +  'none.png';

    const orientation = (props.width > props.height) ? 'horizontal' : 'vertical';

    const ImgClasses = () => {

        let classes = 'image ';

        classes += orientation + ' ';
        classes += (props.size) ? props.size : 'large';

        if(props.size === 'avatar-small' || props.size === 'avatar-large'){
            classes = ' hidden';
        }

        return classes;
    }

    const avatarClasses = (props.size === 'avatar-small' || props.size === 'avatar-large') ? 'avatar ' + props.size : 'hidden';

    return(
    <div className={moduleClasses}>

       

            { /* Normal Image */ }
            <img className={ImgClasses()} src={Image} alt={props.alt} />

            { /* Avatar Image */}
            <div className={avatarClasses} style={{backgroundImage: 'url("' + Image + '")'}}></div>

       

        { /* TEXT AREA */ }
        <div className="text-wrapper">
            <div className="richtext" dangerouslySetInnerHTML={{__html: props.text}}></div>
        </div>

    </div>
    );

}

export default ImageTextViewMode;