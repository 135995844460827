import React from 'react';
import './leadtext.styles.scss';

const LeadtextViewMode = (props) =>{

    const text = (props.text) ? props.text.replace(/\r?\n|\r/g, "<br>") : '';

    return(
    <div className='module-leadtext' dangerouslySetInnerHTML={{__html: text}}></div>
    );

}

export default LeadtextViewMode;