//Initial State
const INITIAL_STATE = {
    
    userIsLogged: false,
    userId: 0,
    userName: '',
}

//Reducer and Actions
const activeUserReducer = (state = INITIAL_STATE, action) => {
switch (action.type) {
  
    case 'SET_ACTIVE_USER':
        return {
            ...state,
            userIsLogged: true,
            userId: action.payload.id,
            userName: action.payload.name,
        }
    
    case 'LOGOUT_USER':
            return {
                ...state,
                userIsLogged: false,
                userId: 0,
                userName: '',
            }
  
    default:
        return state
}
}

//Export Reducer
export default activeUserReducer;