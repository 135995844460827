//Define selected item on menu
export const setMenuActiveItem = item => ({
    type: 'SET_MENU_ACTIVE_ITEM',
    payload: item
})

export const toggleUserMenu = () => ({
    type: 'TOGGLE_USER_MENU'
})

export const setMenuItems = items => ({
    type: 'SET_MENU_ITEMS',
    payload: items
})