import React from 'react';
import ReactPlayer from 'react-player/youtube'

import './youtube.styles.scss';

const YoutubeViewMode = (props) =>{


    return(
    <div className='module__youtube'>
        <ReactPlayer url={props.src} />
    </div>
    );

}

export default YoutubeViewMode;