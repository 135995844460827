//Import Libraries
import React from 'react';


//Import Styles and Assets
import './autocomplete.styles.scss';

//Import UI components
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from "@material-ui/core/CircularProgress";

// Import components


//Import Redux Actions


// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class Autocomplete extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {
            isOpen: false,
            search: '',
            value: '',
            label: '',
            new: false,
            filtered: [],
            source: [],
            creating: false,
            deleting: false,
            rules: [],
            error: false,
            errorMsg: '',
            hasBeenTouched: false
        }
    }

    
    componentDidMount(){

        this.setState({
            ...this.state,
            source: this.props.source,
            filtered: this.props.source,
            label: this.props.currentvalue.label,
            value: this.props.currentvalue.value,
            search: this.props.currentvalue.label,
            rules: this.props.rules,
            error: this.props.error,
            errorMsg: this.props.errorMsg
        })

    }
    
    componentDidUpdate(prevProps, prevState){

        if (prevProps.source !== this.props.source) {
            this.setState({
                ...this.state,
                source: this.props.source,
                filtered: this.props.source,
                label: this.props.currentvalue.label,
                value: this.props.currentvalue.value,
                search: this.props.currentvalue.label,
                creating: false,
                deleting: false,
                new: false,
                error: this.props.error,
                errorMsg: this.props.errorMsg
            })
        }

    }
    //Rendering
    render(props){

        const validateIpt = (value) => {

            if(this.state.hasBeenTouched){
                if(this.state.rules){
                    const validation = validate(value, this.state.rules);
                    this.props.functions.validate(validation[0], validation[1]);
                }
            }
        }

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const value = event.target.value;

            const suggestions = this.state.source;

            const filteredSuggestions = suggestions.filter(
                suggestion =>
                  suggestion.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            );

            const itemExists = this.state.source.map(function(e) { return e.label; }).indexOf(value);
            const showAdd = (itemExists === -1 && value.length > 0) ? true : false;

            // Update State
            this.setState({ 
                ...this.state, 
                search: value,
                filtered: filteredSuggestions,
                new: showAdd
            }, validateIpt(value));

        };

        const openCombolist = () => {
            this.setState({
                ...this.state,
                isOpen: true,
                hasBeenTouched: true
            })
        }

        const closeCombolist = () => {

            const search = this.state.search;
            const source = this.state.source;
            let item = this.props.currentvalue;

            for(let i = 0; i < source.length; i++){

                let x = source[i].label;
                if(x === search){
                    item = source[i]
                }

            }

            validateIpt(item.label);

            this.setState({
              ...this.state,
              isOpen: false,
              label: item.label,
              value: item.value,
              search: item.label
            })
          
      }

      const setValue = (item) => {
        
        this.setState({
              ...this.state,
              label: item.label,
              value: item.value,
              search: item.label,
              new: false,
              isOpen: false
          }, this.props.functions.select(item));

      }

      const createItem = () => {

        const newItem = this.state.search;

        this.setState({
            ...this.state,
            creating: true
        }, this.props.functions.create(newItem));

      }

      const deleteItem = (id) => {

        this.setState({
            ...this.state,
            deleting: true
        }, this.props.functions.delete(id));

      }

      const classes = () => {

        let classes = 'autocomplete';

        if(this.state.isOpen){
            classes += ' opened'; 
        }

        if(this.props.error){
            classes += ' error'; 
        }

        return classes;

      }

      return (
        <div className={classes()} onMouseLeave={closeCombolist}>
          <div className='combobox'>
              <input 
              id="autocomplete-search" 
              name="autocomplete-search" 
              className="combobox-ipt" 
              type="text" 
              value={this.state.search} 
              autoComplete="off"
              placeholder={this.props.placeholder} 
              onClick={openCombolist} 
              onChange={handleChange} 
              />
              <div className={(this.state.isOpen && (this.state.source.length >0 || this.state.new)) ? 'combolist' : 'combolist hidden'}>
              <ul>
                  {
                    this.state.filtered.map((item, index) =>{

                        let itemKey = "autocomplete-option-" + index;

                        return(
                            <li key={itemKey} onClick={() => setValue(item)}>
                                <div className="item-title">{item.label}</div>
                                <div className="btn-delete-item">
                                    <IconButton
                                    aria-label="Apagar"
                                    onClick={() => deleteItem(item.value)}
                                    edge="end"
                                    disabled={this.state.deleting}
                                    >
                                   {(this.state.deleting) ? <CircularProgress size={24} /> : <DeleteIcon />}
                                    </IconButton>
                                </div>
                            </li>
                        )
                    })
                  }
                  <li key="autocomplete-option-add" className={(this.state.new) ? '' : 'hidden'}>
                    <div className="item-title"><b>Adicionar </b>{this.state.search}</div>
                    <div className="btn-add-item">
                        <IconButton
                        aria-label="Criar item"
                        onClick={createItem}
                        edge="end"
                        disabled={this.state.creating}
                        >
                        {(this.state.creating) ? <CircularProgress size={24} /> :<AddIcon />}
                        </IconButton>
                    </div>
                  </li>
              </ul>
            </div>
          </div>
          <div className="error-message">{this.props.errorMsg}</div>
        </div>
      );
    }
}

export default Autocomplete;