import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './gift.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import {CircularProgress} from '@material-ui/core';


//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class GiftViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            formItems: {
                name: {
                    id: 'name',
                    label: 'Nome',
                    placeholder: 'Digite seu nome',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
                email: {
                    id: 'email',
                    label: 'E-mail',
                    placeholder: 'Digite seu e-mail',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255,
                        email: true
                    }
                },
                terms: {
                    id: 'terms',
                    label: '',
                    placeholder: '',
                    value: false,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        terms: true
                    }
                },

            }
        }
    }

    componentDidMount(){
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS:  HANDLE CHECKBOX 
        const handleSwitch = (event) => {

            const name = 'terms';
            const value = !this.state.formItems.terms.value;

            console.dir(value);

            // Get current FormItems object from State
            const formItems = this.state.formItems;
            const formItem = formItems[name];

           // Validate
           if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMS: HANDLE SUBMIT
        const handleSubmit = (event) => {

            // Prevent Default
            event.preventDefault();

            // Add Loader
            this.setState({
                ...this.state,
                sending: true
            });

            // Get form data
            let name = this.state.formItems.name.value;
            let email = this.state.formItems.email.value;
            
            
            // Prepare Request
            const requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: { 
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*"
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    source: this.props.id,
                    tag: this.props.tag,
                    file: this.props.fileUrl,
                    author: this.props.authorId,
                    text: this.props.text,
                    app: window.$appToken
                })
            };

            // Request API
            fetch("/api/gift_send.php", requestOptions)
            .then(async response => {
                const data = await response.json();
    
                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                // success case
                if(data.success){   

                    // Clean inputs
                    const { formItems } = this.state;
                    const updatedForm = {...formItems};
                    updatedForm.name.value = '';
                    updatedForm.email.value = '';
                    updatedForm.terms.value = false;

                    if(this.props.successPage){

                        const successPage = window.$domainWithoutSlash + this.props.successPage;
                        window.location = successPage;

                    }else{

                        const callback = () => {
                            const modalObj = {
                                modalIcon: 'done',
                                modalType: 'success',
                                modalComponent: 'GENERAL_ALERT',
                                modalTitle: 'Plim! Já foi!',
                                modalParameters: {
                                    text: 'Enviamos nosso presente para você por e-mail. Caso não receba em alguns momentos, verifique sua caixa de spam. ;)'
                                },
                                modalActions: []
                            }
                            this.props.openModal(modalObj);
                        }

                        this.setState({
                            sending: false,
                            formItems: updatedForm
                        }, callback);

                    }
                    
                // success case
                }else{

                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Ops, algo correu mal :(',
                        modalParameters: {
                            text: data.feedback
                        },
                        modalActions: []
                    }
                      
                      // Remove loader
                      this.setState({
                        sending: false,
                      }, () => this.props.openModal(modalObj));

                }

            })
            .catch(error => {

                // show error modal
                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        Text: 'Não foi possível aceder aos serviços no momento. Tente novamente mais tarde ou contate o suporte.'
                    },
                    modalActions: []
                }

                this.setState({
                    sending: false,
                }, () => this.props.openModal(modalObj));

            });

        }

        // ENABLE / DISABLE SUBMIT BUTTON 
        const submitIsEnabled = () => {
            
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            // If any field in component form has an error
            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            // If form is not valid
            if(this.state.formItems.name.value === '' || this.state.formItems.email.value === '' || !this.state.formItems.terms.value){
                disabled = true;
            }

            return disabled;

        }

        const formId = 'form-' + this.props.contentId;

        return(
            <div className='module-gift'>
            <div className={(this.state.sending) ? 'loading' : 'loading hidden'}><CircularProgress /></div>
            <form className='form' id={formId} name={formId}>

                 <Grid container spacing={2}>

                    <Grid item xs={12} sm={10}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                            <TextField
                            name={this.state.formItems.name.id}
                            id={this.state.formItems.name.id}
                            value={this.state.formItems.name.value}
                            label={this.state.formItems.name.label}
                            placeholder={this.state.formItems.name.placeholder}
                            error={this.state.formItems.name.hasError}
                            helperText={this.state.formItems.name.errorMsg}
                            fullWidth
                            multiline
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                name={this.state.formItems.email.id}
                                id={this.state.formItems.email.id}
                                value={this.state.formItems.email.value}
                                label={this.state.formItems.email.label}
                                placeholder={this.state.formItems.email.placeholder}
                                error={this.state.formItems.email.hasError}
                                helperText={this.state.formItems.email.errorMsg}
                                fullWidth
                                multiline
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                    <FormGroup row className='vertical-middle' error={this.state.formItems.terms.hasError.toString()}>
                                    <Checkbox checked={this.state.formItems.terms.value} onChange={handleSwitch} name={this.state.formItems.terms.id} error={this.state.formItems.terms.hasError.toString()}/>
                                    <label onClick={handleSwitch} htmlFor={this.state.formItems.terms.id}>
                                        Estou ciente e de acordo com a <a href="/page/content-4" target="_self">Política de Privacidade</a> e os <a href="/page/content-3" target="_self">Termos de Uso</a>
                                        <FormHelperText error={this.state.formItems.terms.hasError}>{this.state.formItems.terms.errorMsg}</FormHelperText>
                                    </label>
                                    </FormGroup>
                            </Grid>

                        </Grid>
                    </Grid>
                    

                    <Grid item xs={12} sm={2}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={submitIsEnabled()}
                            fullWidth
                            >{(this.props.label) ? this.props.label : 'Enviar'}</Button>
                        </div>
                    </Grid>
                    
                 </Grid>
            </form>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(GiftViewMode));