import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

import './dashboard.styles.scss';

// Import Redux Action
import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {CircularProgress} from '@material-ui/core';

import ContentDirectory from '../../components/content-directory/content-directory.component';

import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
import { formatDate } from '../../utils/utils';

 
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.token = getToken();
    this.state = {
      loading: false,
      saving: false,
      password: false,
      type: 1,
      formItems: {
        fullname: {
          id: 'fullname',
          label: 'Nome Completo',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            isFullName: true,
            maxLength: 255
          }
        },
        company: {
          id: 'company',
          label: 'Empresa',
          placeholder: 'Nome Fantasia',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
          }
        },
        email: {
          id: 'email',
          label: 'E-mail',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            email: true,
            emailAlreadyExists: this.token
          }
        },
        site: {
          id: 'site',
          label: 'Site',
          placeholder: 'http://',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            website: true
          }
        },
        birthdate: {
          id: 'birthdate',
          label: 'Data de Nascimento',
          placeholder: 'dd/mm/aaaa',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            isDate: true,
            isAdult: true
          }
        },
        phone: {
          id: 'phone',
          label: 'Telefone',
          placeholder: 'Apenas números com o DDD',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            phone: true,
          }
        },
        mobile: {
          id: 'mobile',
          label: 'Celular',
          placeholder: 'Apenas números com o DDD',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            phone: true,
          }
        },
        about: {
          id: 'about',
          label: 'Apresentação',
          placeholder: 'Digite um pequeno parágrafo de apresentação',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            maxLength: 400,
          }
        },
        address: {
          id: 'address',
          label: 'Endereço',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            maxLength: 255,
          }
        },
        neighborhood: {
          id: 'neighborhood',
          label: 'Bairro',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            maxLength: 255,
          }
        },
        city: {
          id: 'city',
          label: 'Cidade',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            maxLength: 255,
          }
        },
        state: {
          id: 'state',
          label: 'Estado',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            maxLength: 2,
          }
        },
        postal: {
          id: 'postal',
          label: 'CEP',
          placeholder: 'Apenas números',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            digits: true,
            maxLength: 10,
          }
        },
        facebook: {
          id: 'facebook',
          label: 'Facebook',
          placeholder: 'https://facebook.com/...',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            website: true
          }
        },
        instagram: {
          id: 'instagram',
          label: 'Instagram',
          placeholder: 'https://instagram.com/...',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            website: true
          }
        },
        youtube: {
          id: 'youtube',
          label: 'YouTube',
          placeholder: 'https://youtube.com/...',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            website: true
          }
        },
        linkedin: {
          id: 'linkedin',
          label: 'LinkedIn',
          placeholder: 'https://linkedin.com/...',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            maxLength: 255,
            website: true
          }
        },
        whatsapp: {
          id: 'whatsapp',
          label: 'WhatsApp',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            phone: true
          }
        },
        current: {
          id: 'current',
          label: 'Senha Atual',
          placeholder: 'Digite a senha atual',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        new: {
          id: 'new',
          label: 'Nova Senha',
          placeholder: 'Digite a nova senha',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true
          }
        },
        confirm: {
          id: 'confirm',
          label: 'Confirmação',
          placeholder: 'Digite a nova senha',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {
            required: true,
            isEqual: {
              id: 'new',
              label: 'Nova Senha'
            }
          }
        },
      }
    };
    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        // Get User Data
        this.setState({
          ...this.state,
          loading: true
        })


        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken
          }),
        };
  
        fetch('/api/my_data_get.php', requestOptions)
        .then(data => data.json())
        .then(data => {

            authAction(data.authenticated);

            const formItems = this.state.formItems;
            formItems.fullname.value = data.userData.fullName;
            formItems.email.value = data.userData.email;
            formItems.site.value = (data.userData.site) ? data.userData.site : '';
            formItems.birthdate.value = (data.userData.birthdate) ? formatDate(data.userData.birthdate, false) : '';
            formItems.company.value = (data.userData.company) ? data.userData.company : '';
            formItems.phone.value = (data.userData.phone) ? data.userData.phone : '';
            formItems.mobile.value = (data.userData.mobile) ? data.userData.mobile : '';
            formItems.about.value = (data.userData.about) ? data.userData.about : '';
            formItems.facebook.value = (data.userData.facebook) ? data.userData.facebook : '';
            formItems.instagram.value = (data.userData.instagram) ? data.userData.instagram : '';
            formItems.youtube.value = (data.userData.youtube) ? data.userData.youtube : '';
            formItems.linkedin.value = (data.userData.linkedin) ? data.userData.linkedin : '';
            formItems.whatsapp.value = (data.userData.whatsapp) ? data.userData.whatsapp : '';
            formItems.address.value = (data.userData.address) ? data.userData.address : '';
            formItems.neighborhood.value = (data.userData.neighborhood) ? data.userData.neighborhood : '';
            formItems.city.value = (data.userData.city) ? data.userData.city : '';
            formItems.state.value = (data.userData.state) ? data.userData.state : '';
            formItems.postal.value = (data.userData.postal) ? data.userData.postal : '';

            this.setState({ 
                ...this.state,
                loading: false, 
                type: data.userData.type,
                formItems: formItems
             });
        })
      
    }

  render(props){
  
    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event) => {

      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // Get current FormItems object from State
      const formItems = this.state.formItems;

      // Get State of the Input
      const formItem = {
          ...formItems[name]
      };

      // Validate Element if it has been Touched Before
      if(formItem.hasTouched){
          if(formItem.rules){
              const validation = validate(value, formItem.rules, formItems);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }
      }

      // Set the new value to element
      formItem.value = value;

      // Update formItems collection
      formItems[name] = formItem;


      // Update State
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  };

  // FORMS: INPUT BLUR HANDLE FUNCTION
  const handleBlur = async(event) => {
              
      // Get Name and Value from element
      const name = event.target.name;
      const value = event.target.value;

      // formItems collection
      const formItems = {
          ...this.state.formItems
      };

      // state of modified element
      const formItem = {
          ...formItems[name]
      };

      formItem.hasTouched = true;
      if(formItem.rules){
          const validation = await validate(value, formItem.rules, formItems);
          formItem.hasError = validation[0];
          formItem.errorMsg = validation[1];
      }else{
          formItem.hasError = false;
          formItem.errorMsg = '';
      }

      // Update formItems collection
      formItems[name] = formItem;

      // Update state
      this.setState({ 
          ...this.state, 
          formItems: formItems
      });

  }

  const dataDisabled = () => {

    const name = this.state.formItems.fullname;
    const email = this.state.formItems.email;
    const phone= this.state.formItems.phone;
    const mobile = this.state.formItems.mobile;
    const birthdate = this.state.formItems.birthdate;
    const company = this.state.formItems.company;
    const about = this.state.formItems.about;
    let disabled = false;

    if(name.value === '' || email.value ===''){
      disabled = true;
    }

    if(name.hasError || email.hasError || birthdate.hasError || company.hasError || phone.hasError || mobile.hasError || about.hasError){
      disabled = true;
    }

    return disabled;

  }

  const passDisabled = () => {

    const current = this.state.formItems.current;
    const newPass = this.state.formItems.new;
    const confirmPass = this.state.formItems.confirm;
    let disabled = false;

    if(current.value === '' || newPass.value ==='' || confirmPass.value === ''){
      disabled = true;
    }

    if(newPass.value !== confirmPass.value){
      disabled = true;
    }

    if(current.hasError || newPass.hasError || confirmPass.hasError){
      disabled = true;
    }

    return disabled;

  }

  const dataSubmit = (e) => {

    e.preventDefault();

    this.setState({
      ...this.state,
      saving: true
    });

    const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            fullname: this.state.formItems.fullname.value,
            email: this.state.formItems.email.value,
            birthdate: this.state.formItems.birthdate.value,
            company: this.state.formItems.company.value,
            mobile: this.state.formItems.mobile.value,
            phone: this.state.formItems.phone.value,
            about: this.state.formItems.about.value,
            site: this.state.formItems.site.value,
            facebook: this.state.formItems.facebook.value,
            instagram: this.state.formItems.instagram.value,
            linkedin: this.state.formItems.linkedin.value,
            whatsapp: this.state.formItems.whatsapp.value,
            youtube: this.state.formItems.youtube.value,
            address: this.state.formItems.address.value,
            neighborhood: this.state.formItems.neighborhood.value,
            city: this.state.formItems.city.value,
            state: this.state.formItems.state.value,
            postal: this.state.formItems.postal.value,

          }),
        };
  
        fetch('/api/my_data_update.php', requestOptions)
        .then(data => data.json())
        .then(data => {

            authAction(data.authenticated);

            if(data.success){
              
              const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Pronto! :)',
                modalParameters: {
                    text: 'Seus dados foram atualizados com sucesso.'
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

            }else{

              const modalObj = {
                modalIcon: 'priority_high',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ooops!',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

            }
            

            this.setState({ 
                ...this.state,
                saving: false
             });
        })

  }

  const passSubmit = (e) => {

    e.preventDefault();

    this.setState({
      ...this.state,
      password: true
    });

        const userToken = getToken();
        const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
          body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            current: this.state.formItems.current.value,
            new: this.state.formItems.new.value
          }),
        };
  
        fetch('/api/my_password.php', requestOptions)
        .then(data => data.json())
        .then(data => {

            authAction(data.authenticated);

            if(data.success){
              
              const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Pronto! :)',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

              const formItems = this.state.formItems;
              formItems.current.value = '';
              formItems.new.value= '';
              formItems.confirm.value= '';

              this.setState({ 
                ...this.state,
                password: false,
                formItems: formItems
             });



            }else{

              const modalObj = {
                modalIcon: 'priority_high',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ooops!',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: []
              }
              this.props.openModal(modalObj);

              this.setState({ 
                ...this.state,
                password: false
             });

            }
            

            
        })

  }

  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content dashboard-page">
      <div className={(this.state.loading) ? 'loading' : 'loading hidden'}><CircularProgress size={72} /></div>
        
          { /* PAGE TITTLE */ }
          <div className="module-heading margin-bottom--lg">
            <h1 className="heading-1">Minha conta</h1>
          </div>
          

          { /* FORM PERSONAL DATA*/ }
          <form className="container compact">
          <div className="module-heading">
            <h4 className="heading-4 left">Meus dados</h4>
          </div>
          <div className="form-panel">
            
          <Grid container spacing={2}>

              <Grid item xs={12} sm={8}>
                  <TextField
                  name={this.state.formItems.fullname.id}
                  id={this.state.formItems.fullname.id}
                  value={this.state.formItems.fullname.value}
                  label={this.state.formItems.fullname.label}
                  placeholder={this.state.formItems.fullname.placeholder}
                  error={this.state.formItems.fullname.hasError}
                  helperText={this.state.formItems.fullname.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={4}>
                  <TextField
                  name={this.state.formItems.birthdate.id}
                  id={this.state.formItems.birthdate.id}
                  value={this.state.formItems.birthdate.value}
                  label={this.state.formItems.birthdate.label}
                  placeholder={this.state.formItems.birthdate.placeholder}
                  error={this.state.formItems.birthdate.hasError}
                  helperText={this.state.formItems.birthdate.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              {
                (this.state.type > 3) ?
                <>
                <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.company.id}
                  id={this.state.formItems.company.id}
                  value={this.state.formItems.company.value}
                  label={this.state.formItems.company.label}
                  placeholder={this.state.formItems.company.placeholder}
                  error={this.state.formItems.company.hasError}
                  helperText={this.state.formItems.company.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.about.id}
                  id={this.state.formItems.about.id}
                  value={this.state.formItems.about.value}
                  label={this.state.formItems.about.label}
                  placeholder={this.state.formItems.about.placeholder}
                  error={this.state.formItems.about.hasError}
                  helperText={this.state.formItems.about.errorMsg}
                  fullWidth
                  multiline
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>
              </>: null
              }
              

              <Grid item xs={12}><h4>Contatos</h4></Grid>

              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.email.id}
                  id={this.state.formItems.email.id}
                  value={this.state.formItems.email.value}
                  label={this.state.formItems.email.label}
                  placeholder={this.state.formItems.email.placeholder}
                  error={this.state.formItems.email.hasError}
                  helperText={this.state.formItems.email.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              {
                (this.state.type > 3) ?
              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.site.id}
                  id={this.state.formItems.site.id}
                  value={this.state.formItems.site.value}
                  label={this.state.formItems.site.label}
                  placeholder={this.state.formItems.site.placeholder}
                  error={this.state.formItems.site.hasError}
                  helperText={this.state.formItems.site.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>: null
              }

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.phone.id}
                  id={this.state.formItems.phone.id}
                  value={this.state.formItems.phone.value}
                  label={this.state.formItems.phone.label}
                  placeholder={this.state.formItems.phone.placeholder}
                  error={this.state.formItems.phone.hasError}
                  helperText={this.state.formItems.phone.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.mobile.id}
                  id={this.state.formItems.mobile.id}
                  value={this.state.formItems.mobile.value}
                  label={this.state.formItems.mobile.label}
                  placeholder={this.state.formItems.mobile.placeholder}
                  error={this.state.formItems.mobile.hasError}
                  helperText={this.state.formItems.mobile.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              {
                (this.state.type > 3) ?
                <>
              <Grid item xs={12}>
                  <TextField
                  name={this.state.formItems.address.id}
                  id={this.state.formItems.address.id}
                  value={this.state.formItems.address.value}
                  label={this.state.formItems.address.label}
                  placeholder={this.state.formItems.address.placeholder}
                  error={this.state.formItems.address.hasError}
                  helperText={this.state.formItems.address.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.neighborhood.id}
                  id={this.state.formItems.neighborhood.id}
                  value={this.state.formItems.neighborhood.value}
                  label={this.state.formItems.neighborhood.label}
                  placeholder={this.state.formItems.neighborhood.placeholder}
                  error={this.state.formItems.neighborhood.hasError}
                  helperText={this.state.formItems.neighborhood.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.city.id}
                  id={this.state.formItems.city.id}
                  value={this.state.formItems.city.value}
                  label={this.state.formItems.city.label}
                  placeholder={this.state.formItems.city.placeholder}
                  error={this.state.formItems.city.hasError}
                  helperText={this.state.formItems.city.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.state.id}
                  id={this.state.formItems.state.id}
                  value={this.state.formItems.state.value}
                  label={this.state.formItems.state.label}
                  placeholder={this.state.formItems.state.placeholder}
                  error={this.state.formItems.state.hasError}
                  helperText={this.state.formItems.state.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.postal.id}
                  id={this.state.formItems.postal.id}
                  value={this.state.formItems.postal.value}
                  label={this.state.formItems.postal.label}
                  placeholder={this.state.formItems.postal.placeholder}
                  error={this.state.formItems.postal.hasError}
                  helperText={this.state.formItems.postal.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12}><h4>Redes Sociais</h4></Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.facebook.id}
                  id={this.state.formItems.facebook.id}
                  value={this.state.formItems.facebook.value}
                  label={this.state.formItems.facebook.label}
                  placeholder={this.state.formItems.facebook.placeholder}
                  error={this.state.formItems.facebook.hasError}
                  helperText={this.state.formItems.facebook.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.instagram.id}
                  id={this.state.formItems.instagram.id}
                  value={this.state.formItems.instagram.value}
                  label={this.state.formItems.instagram.label}
                  placeholder={this.state.formItems.instagram.placeholder}
                  error={this.state.formItems.instagram.hasError}
                  helperText={this.state.formItems.instagram.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.youtube.id}
                  id={this.state.formItems.youtube.id}
                  value={this.state.formItems.youtube.value}
                  label={this.state.formItems.youtube.label}
                  placeholder={this.state.formItems.youtube.placeholder}
                  error={this.state.formItems.youtube.hasError}
                  helperText={this.state.formItems.youtube.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.linkedin.id}
                  id={this.state.formItems.linkedin.id}
                  value={this.state.formItems.linkedin.value}
                  label={this.state.formItems.linkedin.label}
                  placeholder={this.state.formItems.linkedin.placeholder}
                  error={this.state.formItems.linkedin.hasError}
                  helperText={this.state.formItems.linkedin.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>

              <Grid item xs={12} sm={6}>
                  <TextField
                  name={this.state.formItems.whatsapp.id}
                  id={this.state.formItems.whatsapp.id}
                  value={this.state.formItems.whatsapp.value}
                  label={this.state.formItems.whatsapp.label}
                  placeholder={this.state.formItems.whatsapp.placeholder}
                  error={this.state.formItems.whatsapp.hasError}
                  helperText={this.state.formItems.whatsapp.errorMsg}
                  fullWidth
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </Grid>
              </>: null
            }
              

              <Grid item xs={12}>
                  <div className="btn-wrapper on-right">
                      <Button 
                      type="submit" 
                      color="primary" 
                      variant="contained" 
                      disableElevation 
                      disabled={dataDisabled() || this.state.saving}
                      onClick={dataSubmit}
                      endIcon={(this.state.saving) ? <CircularProgress size={16} /> : null}
                      >
                      {(this.state.saving) ? 'Atualizando...' : 'Atualizar'}
                      </Button>
                  </div>
              </Grid>

          </Grid>
          </div>
          </form>
      
          { /* FORM CHANGE PASSWORD */ }
          <form className="container compact">
          <div className="module-heading">
            <h4 className="heading-4 left">Alterar Senha</h4>
          </div>
          <div className="form-panel">
          <Grid container spacing={2}>

            <Grid item xs={12} sm={4}>
                <TextField
                name={this.state.formItems.current.id}
                id={this.state.formItems.current.id}
                value={this.state.formItems.current.value}
                label={this.state.formItems.current.label}
                placeholder={this.state.formItems.current.placeholder}
                error={this.state.formItems.current.hasError}
                helperText={this.state.formItems.current.errorMsg}
                type='password'
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                name={this.state.formItems.new.id}
                id={this.state.formItems.new.id}
                value={this.state.formItems.new.value}
                label={this.state.formItems.new.label}
                placeholder={this.state.formItems.new.placeholder}
                error={this.state.formItems.new.hasError}
                helperText={this.state.formItems.new.errorMsg}
                type='password'
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                />
            </Grid>

            <Grid item xs={12} sm={4}>
                <TextField
                name={this.state.formItems.confirm.id}
                id={this.state.formItems.confirm.id}
                value={this.state.formItems.confirm.value}
                label={this.state.formItems.confirm.label}
                placeholder={this.state.formItems.confirm.placeholder}
                error={this.state.formItems.confirm.hasError}
                helperText={this.state.formItems.confirm.errorMsg}
                type='password'
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                />
            </Grid>

            <Grid item xs={12}>
                  <div className="btn-wrapper on-right">
                      <Button 
                      type="submit" 
                      color="primary" 
                      variant="contained" 
                      disableElevation 
                      onClick={passSubmit}
                      disabled={passDisabled() || this.state.password}
                      endIcon={(this.state.password) ? <CircularProgress size={16} /> : null}
                      >
                      {(this.state.password) ? 'Atualizando...' : 'Atualizar'}  
                      </Button>
                  </div>
              </Grid>


          </Grid>
          </div>
          </form>

          { /* FORM DELETE ACCOUNT */ }
          <form className="container compact">
          <div className="module-heading">
            <h4 className="heading-4 left">Excluir conta</h4>
          </div>
          <div className="form-panel alert-box">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <span>Ao excluir sua conta todos os seus dados serão excluídos, incluindo seus favoritos, artigos salvos e comentários. Essa operação é irreversível.</span>
            </Grid>
            <Grid item xs={12} sm={2}>
                  <div className="btn-wrapper on-right">
                      <Button 
                      type="submit" 
                      color="default" 
                      variant="outlined" 
                      disableElevation 
                      >Excluir</Button>
                  </div>
              </Grid>
          </Grid>
          </div>
          </form>



      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(Dashboard));