import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './richtext-editor.styles.scss';

// Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import {openGallery, closeGallery} from '../../redux/media-gallery/media-gallery.actions';

// Import UI Icons
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import LinkIcon from '@material-ui/icons/Link';
import FormatClearIcon from '@material-ui/icons/FormatClear';
import TextField from "@material-ui/core/TextField";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import TitleIcon from '@material-ui/icons/Title';
import ImageIcon from '@material-ui/icons/Image';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';


// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class RichtextEditor extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            commands: false,
            range: null,
            linkDialog: false,
            alreadyHasLink: false,
            imageDialog: false,
            imgSizes: [
                {label: 'ícone' , value: 'icon'},
                {label: 'pequeno' , value: 'small'},
                {label: 'médio' , value: 'medium'},
                {label: 'grande' , value: 'large'},
                {label: 'full' , value: 'module'},

            ],
            imgAligns: [
                {label: 'esquerdo' , value: 'left'},
                {label: 'direito' , value: 'right'},
                {label: 'alinhado' , value: 'inline'},
                {label: 'bloco' , value: 'box'},
            ],
            formItems: {
                link: {
                    id: 'link',
                    label: 'Link',
                    placeholder: 'http://',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                        link: true
                    }
                },
                image: {
                    id: 'image',
                    label: 'Imagem',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                    }
                },
                imgSize: {
                    id: 'imgSize',
                    label: 'Tamanho',
                    placeholder: '',
                    value: 'small',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                imgAlign: {
                    id: 'imgAlign',
                    label: 'Alinhamento',
                    placeholder: '',
                    value: 'left',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                },
                imgAlt: {
                    id: 'imgAlt',
                    label: 'Texto Alternativo',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                    }
                },
                imgWidth: {
                    id: 'imgWidth',
                    label: 'Largura',
                    placeholder: '',
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                    }
                },
                imgHeight: {
                    id: 'imgHeight',
                    label: 'Altura',
                    placeholder: '',
                    value: 0,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        maxLength: 255,
                    }
                }
            }
            
        }
    }

    componentDidMount(){

        const editor = 'editor-' + this.props.editor;
        const changeHandler = this.props.functions.handleChange;
        const blurHandler = this.props.functions.handleBlur;

        document.getElementById(editor).addEventListener("input", function(e) {
            e.preventDefault();
            changeHandler(document.getElementById(editor).innerHTML);
        }, false);

        document.getElementById(editor).addEventListener("blur", function(e) {
            e.preventDefault();
            if(blurHandler){
                blurHandler(document.getElementById(editor).innerHTML);
            }
        }, false);

        document.getElementById(editor).addEventListener("paste", function(e) {
            // cancel paste
            e.preventDefault();
        
            // get text representation of clipboard
            var text = (e.originalEvent || e).clipboardData.getData('text/plain');
        
            // insert text manually
            document.execCommand("insertHTML", false, text);
        });

    }

     //Rendering
     render(props){

        // Execute general commands of editor
        const execCommand = (command) =>{

            document.execCommand(command, false, null);

        }

        // Set editor initial value
        const setInitialValue = () => {  
            
                let initialValue = (this.props.content) ? this.props.content : '';
                return {__html: initialValue};
        }

        // Open de Link Dialog
        const createLink = () => {

            let s = window.getSelection();
            if (s.rangeCount) {

                const formItems = this.state.formItems;

                let range = s.getRangeAt(0);
                let hasLink = false;
                
                // Check if it is already a link
                let node = range.startContainer.parentNode;
                if(node){
                    if(node.nodeName === 'A'){
                        hasLink = true;
                        formItems.link.value = node.attributes.href.value;
                    }
                }

                this.setState({
                    ...this.state,
                    linkDialog: true,
                    alreadyHasLink: hasLink,
                    range: range,
                    formItems: formItems
                });

            }

        }

        // Set or Unset a link to selected text
        const setLink = (event) => {

            event.preventDefault();
            let url = this.state.formItems.link.value;

            let range = this.state.range;
            let s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);
            let oldContent = s.getRangeAt(0);

            let isLink = false;
            let node = oldContent.startContainer.parentNode;
            if(node){
                if(node.nodeName === 'A'){
                    isLink = true;
                }
            }

            if(url){

                let target = '_blank';
                if(url.substr(0, 1) === '/'){
                    target= '_self';
                }
               
                if(isLink){
                    
                    // Update link
                    node.href = url;
                    node.setAttribute('target', target);

                }else{
                    
                    // Create link
                    let newNode = document.createElement("a");
                    newNode.href = url;
                    newNode.setAttribute('target', target);
                    let original = range.cloneContents();
                    newNode.appendChild(original);
                    range.deleteContents();
                    range.insertNode(newNode);
                }

            }else{

                // Remove link
                if(isLink){

                    let nodeParent = node.parentNode;
                    let text = document.createTextNode(node.innerHTML);
                    nodeParent.replaceChild(text, node);
                    
                }

            }

            s.removeAllRanges();
            const formItems = this.state.formItems;
            formItems.link.value = '';

            this.props.functions.handleChange(document.getElementById('editor-' + this.props.editor).innerHTML);

            this.setState({
                ...this.state,
                range: null,
                linkDialog: false,
                formItems: formItems
            });

        }

        const setTitle = () => {

            document.execCommand('formatblock', false, 'h4');
            var obj = window.getSelection().focusNode.parentNode;
            obj.setAttribute('style', '');
            obj.classList.add('heading-4');
            obj.classList.add('richtext-title');
            this.props.functions.handleChange(document.getElementById('editor-' + this.props.editor).innerHTML);

        }

        // Confirm clear
        const clear = () => {

                const s = window.getSelection();
                
                if (s.rangeCount) {

                    document.execCommand('removeFormat', false, null);
                    document.execCommand('formatblock', false, 'p');

                }else{

                    const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'CONFIRMATION',
                    modalTitle: 'Tem certeza?',
                    modalParameters: {
                        text: 'Este comando limpará todos os estilos aplicados a todo o texto. Negrito, listas e outros estilos serão redefinidos ao estilo normal, como texto corrido.<br /><b>Tem certeza que quer continuar?</b>'
                    },
                    modalActions: {
                        action: unformat
                    }
                    }
            
                    this.props.openModal(modalObj);

                }

                

        }

        // Clear all editor formatting 
        const unformat = () => {

                let editor = document.getElementById('editor-' + this.props.editor);
                let content = editor.innerText;
                editor.innerHTML = content;
                this.props.functions.handleChange(document.getElementById('editor-' + this.props.editor).innerHTML);
        }

        // Set a link from directory
        const setCtaLink = (itemObj) => {

            const url = '/' + itemObj[0].url;
    
            const formItems = this.state.formItems;
            formItems.link.value = url;
            
    
            formItems.link.hasTouched = true;
            const validation = validate(url, formItems.link.rules, formItems);
            formItems.link.hasError = validation[0];
            formItems.link.errorMsg = validation[1];
    
            this.setState({
                ...this.state,
                formItems: formItems
            });

            this.props.closeDirectory();
    
        }

        // Clear link ulr input
        const clearLink = () => {

            const formItems = this.state.formItems;

            formItems.link.value = '';

            this.setState({
                ...this.state,
                formItems: formItems
            });

        }

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });


        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = this.state.formItems;

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const insertImage = () => {

            let s = window.getSelection();
            if (s.rangeCount) {

                let range = s.getRangeAt(0);
                
                this.setState({
                    ...this.state,
                    imageDialog: true,
                    range: range
                });

            }

        }

        const setImage = (event) => {

            event.preventDefault();
            let src = this.state.formItems.image.value;
            let alt = this.state.formItems.imgAlt.value;
            let w = this.state.formItems.imgWidth.value;
            let h = this.state.formItems.imgHeight.value;
            let sz = this.state.formItems.imgSize.value;
            let p = this.state.formItems.imgAlign.value;

            let range = this.state.range;
            let s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);

            if(src && alt){

                let o = (h > w) ? 'vertical' : 'horizontal';
                let c = "img " + o + " " + sz + " " + p;

                // Insert Image 
                let newNode = document.createElement("img");
                newNode.src = window.$imagesPath + src;
                //newNode.setAttribute('src', );
                newNode.setAttribute('alt', alt);
                newNode.setAttribute('class', c);
                range.deleteContents();
                range.insertNode(newNode);

            }

            s.removeAllRanges();
            const formItems = this.state.formItems;
            formItems.image.value = '';
            formItems.imgSize.value = 'small';
            formItems.imgAlign.value = 'left';
            formItems.imgWidth.value = '';
            formItems.imgHeight.value = '';
            formItems.imgAlt.value = '';

            this.props.functions.handleChange(document.getElementById('editor-' + this.props.editor).innerHTML);

            this.setState({
                ...this.state,
                range: null,
                imageDialog: false,
                formItems: formItems
            });

        }

        const chooseImage = (obj) => {

            const formItems = this.state.formItems;
            formItems.image.value = obj[0].mediaFile;
            formItems.imgAlt.value = obj[0].mediaTitle;
            formItems.imgWidth.value = obj[0].mediaWidth;
            formItems.imgHeight.value = obj[0].mediaHeight;
    
            this.setState({
                ...this.state,
                formItems: formItems
            })
    
            this.props.closeGallery();

        }

      
        return(

            <div className="richtext-editor">
                <div className={(this.state.linkDialog || this.state.imageDialog) ? 'mask' : 'mask hidden'}>

                    { /* Link Dialog Box */ }
                    <div className={(this.state.linkDialog) ? 'link-box' : 'link-box hidden'}>

                        <div className="ipt">
                            <TextField
                            name={this.state.formItems.link.id}
                            id={this.state.formItems.link.id + '-' + this.props.contentId}
                            value={this.state.formItems.link.value}
                            label={this.state.formItems.link.label}
                            placeholder={this.state.formItems.link.placeholder}
                            error={this.state.formItems.link.hasError}
                            helperText={this.state.formItems.link.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                            endAdornment: <InputAdornment position="end">
                            <IconButton
                            aria-label="Limpar"
                            edge="end"
                            onClick={clearLink}
                            className={(this.state.formItems.link.value === '')? 'hidden' : ''}
                            >
                            <ClearIcon /> 
                            </IconButton>
                            <IconButton
                            aria-label="Procurar conteúdo"
                            edge="end"
                            onClick={() => this.props.openDirectory(1, setCtaLink)}
                            >
                            <DescriptionIcon /> 
                            </IconButton>
                            </InputAdornment>,
                            }}
                            />
                        </div>
                        <div className="btns">
                            <div className='btn-wrapper'>
                                <button className="btn-circle primary medium" onClick={setLink} disabled={this.state.formItems.link.hasError}><DoneIcon /></button>
                            </div>
                        </div>
                        
                    </div>
                    
                    { /* Image Dialog Box */ }
                    <div className={(this.state.imageDialog) ? 'image-box' : 'image-box hidden'}>

                        <div className="ipt">
                            <TextField
                            name={this.state.formItems.image.id}
                            id={this.state.formItems.image.id + '-' + this.props.contentId}
                            value={this.state.formItems.image.value}
                            label={this.state.formItems.image.label}
                            placeholder={this.state.formItems.image.placeholder}
                            error={this.state.formItems.image.hasError}
                            helperText={this.state.formItems.image.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                            endAdornment: <InputAdornment position="end">
                            <IconButton
                            aria-label="Buscar imagem"
                            edge="end"
                            onClick={() => this.props.openGallery(1, chooseImage)}
                            >
                            <DescriptionIcon /> 
                            </IconButton>
                            </InputAdornment>,
                            }}
                            />
                        </div>

                        <div className="ipt">
                            <FormControl variant="outlined" fullWidth error={this.state.formItems.imgSize.hasError} >
                                <InputLabel htmlFor="updatedCollection">{this.state.formItems.imgSize.label}</InputLabel>
                                <Select
                                autoFocus
                                native
                                fullWidth
                                name={this.state.formItems.imgSize.id}
                                id={this.state.formItems.imgSize.id + '-' + this.props.contentId}
                                value={this.state.formItems.imgSize.value}
                                label={this.state.formItems.imgSize.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                >
                                {this.state.imgSizes.map((item) => {
                                    return (
                                    <option
                                        key={"img-size-type-" + this.props.editor + '-' + item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </option>
                                    );
                                })}
                                </Select>
                                <FormHelperText>
                                {this.state.formItems.imgSize.errorMsg}
                                </FormHelperText>
                            </FormControl>
                        </div>

                        <div className="ipt">
                            <FormControl variant="outlined" fullWidth error={this.state.formItems.imgAlign.hasError} >
                                <InputLabel htmlFor="updatedCollection">{this.state.formItems.imgAlign.label}</InputLabel>
                                <Select
                                autoFocus
                                native
                                fullWidth
                                name={this.state.formItems.imgAlign.id}
                                id={this.state.formItems.imgAlign.id + '-' + this.props.contentId}
                                value={this.state.formItems.imgAlign.value}
                                label={this.state.formItems.imgAlign.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                >
                                {this.state.imgAligns.map((item) => {
                                    return (
                                    <option
                                        key={"img-align-type-" + this.props.editor + '-' + item.value}
                                        value={item.value}
                                    >
                                        {item.label}
                                    </option>
                                    );
                                })}
                                </Select>
                                <FormHelperText>
                                {this.state.formItems.imgAlign.errorMsg}
                                </FormHelperText>
                            </FormControl>
                        </div>

                        <div className="ipt">
                            <TextField
                            name={this.state.formItems.imgAlt.id}
                            id={this.state.formItems.imgAlt.id + '-' + this.props.contentId}
                            value={this.state.formItems.imgAlt.value}
                            label={this.state.formItems.imgAlt.label}
                            placeholder={this.state.formItems.imgAlt.placeholder}
                            error={this.state.formItems.imgAlt.hasError}
                            helperText={this.state.formItems.imgAlt.errorMsg}
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                        </div>


                        <div className="btns">
                            <div className='btn-wrapper centered'>
                                <button className="btn-circle primary medium" onClick={setImage} ><DoneIcon /></button>
                            </div>
                        </div>

                    </div>
                </div>
                        <ul className="richtext-tools">
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('bold')}><FormatBoldIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('italic')}><FormatItalicIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('underline')}><FormatUnderlinedIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={setTitle}><TitleIcon /></button>
                            </li>
                            <li className="separator"></li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('justifyLeft')}><FormatAlignLeftIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('justifyCenter')}><FormatAlignCenterIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('justifyRight')}><FormatAlignRightIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('justifyFull')}><FormatAlignJustifyIcon /></button>
                            </li>
                            <li className="separator"></li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('insertUnorderedList')}><FormatListBulletedIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('insertOrderedList')}><FormatListNumberedIcon /></button>
                            </li>
                            <li className="separator"></li>
                            <li className="tool">
                                <button type="button" onClick={createLink}><LinkIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={insertImage}><ImageIcon /></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('subscript')}><span className="material-icons">subscript</span></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={() => execCommand('superscript')}><span className="material-icons">superscript</span></button>
                            </li>
                            <li className="tool">
                                <button type="button" onClick={clear}><FormatClearIcon /></button>
                            </li>
                            
                            
                        </ul>
                        <div 
                        key={this.props.editor} 
                        id={'editor-' + this.props.editor} 
                        className={(this.props.hasError)? 'richtext richtext-ipt error' : 'richtext richtext-ipt'} 
                        contentEditable="true" 
                        suppressContentEditableWarning={true} 
                        dangerouslySetInnerHTML={setInitialValue()} />
                        <div className={(this.props.hasError)? 'richtext-error' : 'richtext-error hidden'}>{this.props.errorMsg}</div>
                        
                </div>

        );

     }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});

export default withRouter(connect(null, mapDispatchToProps)(RichtextEditor));