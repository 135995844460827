import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './image-with-text.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from "@material-ui/core/Button";

// Import components
import IptImage from '../../components/ipt-image/ipt-image.component';
import RichtextEditor from '../../components/richtext-editor/richtext-editor.component';

// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class ImageTextEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            align: [
                {
                    label: 'À esquerda',
                    value: 'at-left'
                },
                {
                    label: 'À direita',
                    value: 'at-right'
                },
            ],
            sizes: [
                {
                    label: 'Pequeno',
                    value: 'small'
                },
                {
                    label: 'Médio',
                    value: 'medium'
                },
                {
                    label: 'Grande',
                    value: 'large'
                },
                {
                    label: 'Avatar Pequeno',
                    value: 'avatar-small'
                },
                {
                    label: 'Avatar Grande',
                    value: 'avatar-large'
                },
                

            ],
            formItems: {
                src: {
                    id: 'src',
                    label: 'Imagem',
                    placeholder: 'Selecione uma imagem',
                    value: this.props.src,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                srcId: {
                    id: 'srcId',
                    label: 'Imagem',
                    placeholder: 'Selecione uma imagem',
                    value: this.props.srcId,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                width: {
                    id: 'width',
                    label: 'Largura da Imagem',
                    placeholder: '',
                    value: this.props.width,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                height: {
                    id: 'height',
                    label: 'Altura da Imagem',
                    placeholder: '',
                    value: this.props.height,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                alt: {
                    id: 'alt',
                    label: 'Texto Alternativo da Imagem',
                    placeholder: '',
                    value: this.props.alt,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                alignment: {
                    id: 'alignment',
                    label: 'Alinhamento da Imagem',
                    placeholder: 'Alinhamento da Imagem',
                    value: this.props.alignment,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                text: {
                    id: 'text',
                    label: 'Texto',
                    placeholder: 'Digite seu texto',
                    value: this.props.text,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                },
                size: {
                    id: 'size',
                    label: 'Tamanho da Imagem',
                    placeholder: '',
                    value: (this.props.size) ? this.props.size : 'module',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                }
            }
        }
    }

    componentDidMount(){
       
    }

    render(props){

        const setImage = (obj) => {

            const formItems = this.state.formItems;
    
            formItems.src.value = obj[0].mediaFile;
            formItems.srcId.value = obj[0].mediaId;
            formItems.width.value = obj[0].mediaWidth;
            formItems.height.value = obj[0].mediaHeight;
            formItems.alt.value = obj[0].mediaTitle;
    
            this.setState({
                ...this.state,
                formItems: formItems,
            })
    
            this.props.closeGallery();
        }
    
        const unsetImage = () => {
    
            const formItems = this.state.formItems;
            formItems.src.value = '';
            formItems.srcId.value = 0;
            formItems.width.value = 0;
            formItems.height.value = 0;
            formItems.alt.value = '';
    
            this.setState({
                ...this.state,
                formItems: formItems,
            });
    
        }

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event, callbacks) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = {
                ...this.state.formItems
            };

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;

            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

            if(callbacks){
            callbacks.map(item => {
                return item(event);
            })
            }
        };

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        const handleEditor = (htmlValue) => {

            const formItems = this.state.formItems;
            formItems.text.value = htmlValue;

            this.setState({
                ...this.state,
                formItems: formItems
            });
        }

        const handleSubmit = (event) => {

            event.preventDefault();
            let src = (this.state.formItems.src.value.length > 0) ? this.state.formItems.src.value : 'none.png';
            let srcId = this.state.formItems.srcId.value;
            let width = this.state.formItems.width.value;
            let height = this.state.formItems.height.value;
            let alt = this.state.formItems.alt.value;
            let alignment = this.state.formItems.alignment.value;
            let size = this.state.formItems.size.value;
            let text = this.state.formItems.text.value;
            

            const parameters = {
                src: src,
                srcId: srcId,
                width: width,
                height: height,
                alt: alt,
                alignment: alignment,
                size: size,
                text: text
            }

            this.props.functions.updateModule(this.props.contentId, parameters);

        }

       const editorFunctions = {
           handleChange: handleEditor,
       }

        return(
            <form className='image-text-edit-mode'>
                <Grid container spacing={2}>
                    
                    { /* IMAGE FIELD */ }
                    <Grid item xs={12} sm={6}>
                        <IptImage 
                        className="image" 
                        limit={1} 
                        action={setImage}
                        clearFunction = {unsetImage}
                        selectedFile={this.state.formItems.src.value} 
                        selectedImage={this.state.formItems.srcId.value} 
                        />
                    </Grid>

                    
                    <Grid item xs={12} sm={6}>

                        { /* Image Alignment */ }
                        <div className="margin-bottom--md">
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.alignment.hasError} size="small">
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.alignment.label}</InputLabel>
                            <Select
                            native
                            fullWidth
                            name={this.state.formItems.alignment.id}
                            id={this.state.formItems.alignment.id}
                            value={this.state.formItems.alignment.value}
                            label={this.state.formItems.alignment.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            {this.state.align.map((item) => {
                                return (
                                <option
                                    key={"align-type-" + item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.alignment.errorMsg}
                            </FormHelperText>
                        </FormControl>
                        </div>
                        
                        { /* Image Size */ }
                        <div className="margin-bottom--md">
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.size.hasError}  size="small">
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.size.label}</InputLabel>
                            <Select
                            
                            native
                            fullWidth
                            name={this.state.formItems.size.id}
                            id={this.state.formItems.size.id}
                            value={this.state.formItems.size.value}
                            label={this.state.formItems.size.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            {this.state.sizes.map((item) => {
                                return (
                                <option
                                    key={"align-type-" + item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.size.errorMsg}
                            </FormHelperText>
                        </FormControl>
                        </div>
                        
                        { /* Text */ }
                        <RichtextEditor editor={this.props.contentId} content={this.props.text} functions={editorFunctions} />
                        

                    </Grid>
  
                </Grid> 
                

                { /* Buttons */ }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={(this.state.formItems.src.value === '')}
                            >Atualizar</Button>
                        </div>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
});
  
export default withRouter(connect(null, mapDispatchToProps)(ImageTextEditMode));

