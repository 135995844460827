import React from 'react';
import './image.styles.scss';

const ImageViewMode = (props) =>{

    const Image = (props.src !== '') ? window.$imagesPath + props.src : 'none';

    const ImgClasses = () => {

        let w = props.width;
        let h = props.height;

        // Define if photo is vertical or horizontal
        let orientation = ' vertical';
        if(w > h){
            orientation = ' horizontal';
        }

        let classes = 'image ' + props.size + orientation;

        if(props.size === 'avatar-small' || props.size === 'avatar-large'){
            classes = 'hidden';
        }

        return classes;
    }
    
    const hasLink = (props.url) ? ' has-link' : '';
    const avatarClasses = (props.size === 'avatar-small' || props.size === 'avatar-large') ? 'avatar ' + props.size + hasLink : 'hidden';

    const gotoLink = () => {

        if(props.url){
        window.location = props.url;
        }
        return;

    }

    const renderImg = () => {

        if(props.url){
            return(
                <a href={props.url} target="_blank" rel="noopener noreferrer">
                <img src={Image} className={ImgClasses()} alt={(props.imgAltText) ? props.imgAltText : 'Imagem'} />
                </a>
            );
        }else{
            return(
                <img src={Image} className={ImgClasses()} alt={(props.imgAltText) ? props.imgAltText : 'Imagem'} />
            );
        }


    }


    return(
    <div className='module-image'>
        <div className={`image-wrapper ${props.alignment}`}>
            { /* Normal Image */ }
            {renderImg()}

            { /* Avatar Image */}
            <div className={avatarClasses} style={{backgroundImage: 'url("' + Image + '")'}} onClick={gotoLink}></div>   
        </div>

        <div className={(props.legend !== '' || props.source !== '') ? 'info' : 'info hidden'}>
            <div className={(props.legend !== '') ? 'legend' : 'legend hidden'}>{props.legend}</div>
            <div className={(props.legend !== '') ? 'source' : 'source hidden'}>
                {(props.sourceUrl !== '') ? <a href={props.sourceUrl} target="_blank" rel="noopener noreferrer">{props.source}</a> : props.source}
            </div>
        </div>
    </div>
    );

}

export default ImageViewMode;