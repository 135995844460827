import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './customer-schedule.styles.scss';

// Import Material UI components
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import {CircularProgress} from '@material-ui/core';
import HeadingViewMode from '../../modules/headings/heading.view.component';

// Import Components
import EventCard from './event-card.component';


import { addLoading, removeLoading } from '../../redux/auth/auth.actions';
import { openModal } from '../../redux/modal/modal.actions';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
import {lockScrollbar} from '../../utils/utils';

 
class CustomerSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      eventLoading: 0,
      weekdays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      weekdaysFull: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      now: new Date(),
      slots: []
    }
  };

    componentDidMount(){
      this.fetchSchedule();
    }
    
    // Get schedule of user
    fetchSchedule = () => {

      const userToken = getToken();
      const formData = {
          userToken: userToken,
          app: window.$appToken,
      }

      // Build request header parameters
      const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify(formData)
      };

      // Call the API
      fetch("/api/get_customer_schedule.php", requestOptions)
      .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          
          // Check if user is still authenticated
          authAction(data.authenticated);

          if(data.success){

            this.setState({eventLoading: 0, slots: data.slots, loading: false});

          }
      })

    }

    // Cancel Appointment
    cancelEvent = (id) => {

          // Get the event
          const { slots } = this.state;
          const obj = slots;
          const selected = obj.filter((item) => (item.id === id));
          
          if(selected && selected.length > 0){
            
            const obj = selected[0];

            // Check if event date is after 48 hours
            let currentDate = new Date();
            let eventDate = new Date(obj.year, obj.month, obj.day, obj.hour, obj.minutes);
            let hours = Math.abs(eventDate - currentDate) / 36e5;

            console.dir(hours);

            if(hours < 48){

                const modalObj = {
                  modalIcon: 'clear',
                  modalType: 'error',
                  modalComponent: 'GENERAL_ALERT',
                  modalTitle: 'Ups!',
                  modalParameters: {
                      text: 'Não foi possível cancelar o agendamento ' + obj.id + '.<br/><br/>Você só pode cancelar eventos com no mínimo 48 horas de antecedência. Por favor, <a href="/parceiros/' + obj.owner.CodUser + '">entre em contato com o parceiro</a>.'
                  },
                  modalActions: null
                }

                this.props.openModal(modalObj);

            }else{

                const modalObj = {
                  modalIcon: 'priority_high',
                  modalType: 'error',
                  modalComponent: 'CONFIRMATION',
                  modalTitle: 'Cancelar Agendamento',
                  modalParameters: {
                      text: 'Tem certeza que deseja cancelar o agendamento ' + obj.id + '? Essa operação é irreversível.'
                  },
                  modalActions: {
                    action: this.confirmCancelEvent,
                    parameters: obj.id
                  }
                }
                this.props.openModal(modalObj);

            }
          }
    
    }

    confirmCancelEvent = (id) => {

      this.setState({eventLoading: id}, () => this.deleteEvent(id));

    }

    deleteEvent = (id) => {

      // Get the event
      const { slots } = this.state;
      const obj = slots;
      const selected = obj.filter((item) => (item.id === id));
      const evt = selected[0];

      const userToken = getToken();
      const formData = {
          userToken: userToken,
          app: window.$appToken,
          id: evt.id,
          owner: evt.owner.CodUser 
      }

      // Build request header parameters
      const requestOptions = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
          },
          body: JSON.stringify(formData)
      };

      // Call the API
      fetch("/api/scheduler_cancel_event.php", requestOptions)
      .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
          
          // Check if user is still authenticated
          authAction(data.authenticated);

          if(data.success){

            this.fetchSchedule();

          }else{

            const modalObj = {
              modalIcon: 'clear',
              modalType: 'error',
              modalComponent: 'GENERAL_ALERT',
              modalTitle: 'Ups!',
              modalParameters: {
                  text: data.feedback
              },
              modalActions: null
            }

            this.setState({eventLoading: 0}, () => this.props.openModal(modalObj));

          }
      })

    }

    

  render(props){

    const { slots } = this.state;
    
    if(this.state.loading){
      return(
        <div className="page">
          <div className='loading'>
            <CircularProgress size={72} />
          </div>
        </div>
        )
    }

  return (
    <div className="page">

      { /* PAGE */ }
      <div className="content customer-schedule">
      
        
          { /* PAGE TITTLE */ }
          <HeadingViewMode type={1} title="Agenda" />

          {
            (slots.length > 0) ?
            <div className="container results-list">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <p className="center margin-bottom--md"><b>Estes são seus agendamentos com nossos parceiros.</b><br />Você pode cancelar um agendamento até 48 horas antes da data de atendimento.</p>
                </Grid>
              </Grid>

              {
                slots.map((item, index) => {
                  return(
                    <EventCard key={`slot-${index}`} slot={item} weekdays={this.state.weekdays} months={this.state.months} cancel={this.cancelEvent} loading={(this.state.eventLoading === item.id)}/>
                  );
                })

              }

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p className="center notes">1. Ainda não há um link disponível para este atendimento. Assim que o parceiro definir um, você será notificado por e-mail.</p>
            </Grid>
          </Grid>

            </div>:
            <div className="container">
              <div className="no-results">
                <span className="material-icons">event_busy</span>
                <h4>Nenhum evento</h4>
                <p>Você não possui nenhum atendimento ou treinamento agendado no momento.</p>
              </div>
            </div>

          }

        
          

         
          
    
      </div>
      
    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  addLoader: () => dispatch(addLoading()),
  removeLoader: () => dispatch(removeLoading()),
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(CustomerSchedule));