//Import Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Styles and Assets
import './unlock-content.styles.scss';

//Import UI components
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';

class UnlockContent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          loadingPage: true,
          loadingSubmit: false,
          showForm: false,
          token: this.props.match.params.token,
          ticket: {
            ticketId: 0,
            NameFrom: '',
            NameTo: '',
            UserFrom: 0,
            UserTo: 0,
            Type: 0,
            Source: '',
            SourceId: 0,
            Object: '',
            ObjectId: 0,
            Status: 0,
            Created: '',
            Conclusion: '',
            Justification: '',
            Deadline: ''
          },
          objectInfo: {
              Id: 0,
              Title: '',
              Url: '',
              LockDate: ''
          },
          formItems: {
              option: {
                  id: 'option',
                  label: '',
                  value: 'none',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: []                 
              },
              
          },
        }
    }
    
    getInfo = (token) => {

      this.setState({
        ...this.state,
        loadingPage: true
      });

      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          ticket: token
        }),
      };

      fetch("/api/ticket_info.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

        if(!data.success){

            const modalObj = {
                modalIcon: 'clear',
                modalType: 'error',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Ops...',
                modalParameters: {
                    text: data.feedback
                },
                modalActions: {}
            }
            localStorage.setItem('openModal', '1');
            localStorage.setItem('modalObj', JSON.stringify(modalObj));
            this.props.history.push('/');

        }else{
            
            this.setState({ 
                ...this.state, 
                loadingPage: false,
                showError: false,
                showFeedback:  false,
                showForm: true,
                ticket: data.ticket,
                objectInfo:  data.ObjectInfo
              });

        }


      })
      .catch((error) => {
        console.dir(error);
      });

    }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.getInfo(this.props.match.params.token);

    }

    //Rendering
    render(props){

      // FORMS: INPUT CHANGE HANDLE FUNCTION
      const handleChange = (event, callbacks) => {

          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // Get current FormItems object from State
          const formItems = {
              ...this.state.formItems
          };

          // Get State of the Input
          const formItem = {
              ...formItems[name]
          };

          // Validate Element if it has been Touched Before
          if(formItem.hasTouched){
              if(formItem.rules){
                  const validation = validate(value, formItem.rules);
                  formItem.hasError = validation[0];
                  formItem.errorMsg = validation[1];
              }else{
                  formItem.hasError = false;
                  formItem.errorMsg = '';
              }
          }

          // Set the new value to element
          formItem.value = value;

          // Update formItems collection
          formItems[name] = formItem;

          // Update State
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });


      };

      // FORMS: SUBMIT
      const handleSubmit = (event) => {
        event.preventDefault();

        const ticket = this.state.token;
        const action = this.state.formItems.option.value;

        this.setState({
            ...this.state,
            loadingSubmit: true
        });
    
          const userToken = getToken();
    
          const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              userToken: userToken,
              app: window.$appToken,
              ticket: ticket,
              option: action
            }),
          };
    
          fetch("/api/content_unlock.php", requestOptions)
          .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
    
            authAction(data.authenticated);
    
            if(data.success){
    
                const modalObj = {
                    modalIcon: 'priority_high',
                    modalType: 'success',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Pronto :)',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: {}
                }
                localStorage.setItem('openModal', '1');
                localStorage.setItem('modalObj', JSON.stringify(modalObj));
                this.props.history.push('/');
    
            }else{
                
                this.setState({ 
                    ...this.state, 
                    loadingSubmit: false
                  });
    
            }
    
    
          })
          .catch((error) => {
            console.dir(error);
          });
        
      }

      // CANCEL
      const handleCancel = () => {
        this.props.history.push('/');
      }
      
      // Format Data
      const formatDate = (date, withHour) => {

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

          let h = date.substr(11,2);
          let n = date.substr(14,2);
          sDate = d + '/' + m + '/' + y + ' ' + h + 'h' + n;

        }else{

          sDate = d + '/' + m + '/' + y;

        }

        return sDate;

      }
      
      return (
        <div className="page">
          <div className="content unlock-content">
            
            <div className={(this.state.loadingPage) ? 'loading' : 'loading hidden'}>
                <CircularProgress size={48}/>
            </div>
            
            <div className={(this.state.showForm) ? 'form' : 'form hidden'}>
              <div className="container compact">
                  <HeadingViewMode type={1} title="Desbloquear Conteúdo" />
                  <div className="margin-bottom--md">
                      <p>Olá {this.state.ticket.NameTo},</p>
                      <p>{this.state.ticket.NameFrom} solicita o desbloqueio do conteúdo <b><a href={this.state.objectInfo.Url} target="_blank" rel="noopener noreferrer">{this.state.objectInfo.Title}</a></b>, bloqueado por você desde {(this.state.objectInfo.LockDate) ? formatDate(this.state.objectInfo.LockDate, true) : null}.</p>
                      <p>Quando você bloqueia um conteúdo passa a ser o seu proprietário e outros utilizadores não podem editá-lo.</p>
                      <p><u>Se não responder a esta solicitação até {(this.state.ticket.Deadline) ? formatDate(this.state.ticket.Deadline, true) : null}, o conteúdo será automaticamente desbloqueado.</u></p>
                  </div>
                  <div className="margin-bottom--md">
                      <p><b>Por favor, selecione uma das opções abaixo:</b></p>
                      <FormControl component="fieldset">
                          <RadioGroup aria-label="gender" name="option" value={this.state.formItems.option.value} onChange={handleChange}>
                              <FormControlLabel value="unlock" control={<Radio color="primary" />} label="Liberar o conteúdo para edição por outros usuários" />
                              <FormControlLabel value="lock" control={<Radio color="primary" />} label="Manter o conteúdo bloqueado" />
                          </RadioGroup>
                      </FormControl>
                  </div>

                  <div className="btn-wrapper side-by-side centered">
                      <Button color="default" variant="outlined" onClick={handleCancel}>Cancelar</Button>
                      <Button color="primary" variant="contained" onClick={handleSubmit} disabled={(this.state.formItems.option.value === 'none')}>{(this.state.loadingSubmit)? <CircularProgress size={24} /> : 'Continuar'}</Button>
                  </div>

              </div>
            </div>
            
          </div>
        </div>
      );
    }
}


const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(UnlockContent));