import React, { createRef, useEffect } from 'react';
import { connect } from 'react-redux';

// Import UI Components
import Button from '@material-ui/core/Button';

// Import Redux Actions
import { closeModal } from '../../redux/modal/modal.actions';
import {lockScrollbar} from '../../utils/utils';


const Confirmation = (props) => {

        const mainButton = createRef();
        const secondaryButton = createRef();

        useEffect(()=>{
            function keyListener(e) {
                const listener = keyListenersMap.get(e.keyCode);
                return listener && listener(e);
              }
              mainButton.current.focus();
    
              document.addEventListener("keydown", keyListener);
          
              return () => document.removeEventListener("keydown", keyListener);
        });
    
        const keyListenersMap = new Map([[27, props.closeModal]]);

        const executeAction = () => {
            props.closeModal();
            props.modal.modalActions.action(props.modal.modalActions.parameters);
        }

        const closeOpenedModal = () => {
            props.closeModal();
            lockScrollbar('remove');
        }

        //Rendering
        return(
            <div className="general-alerts">
                <div className={(props.modal.modalType==='success')?'modal-adornment success':'modal-adornment'}>
                <span className="material-icons">{props.modal.modalIcon}</span>
                </div>
                <div className="heading"><h2>{props.modal.modalTitle}</h2></div>
                <div className="content" dangerouslySetInnerHTML={{ __html: props.modal.modalParameters.text }}></div>
                <div className="btn-wrapper side-by-side centered inverted-in-mobile">
                <Button ref={mainButton} type="button" onClick={closeOpenedModal} color="default" variant="contained" disableElevation>Não</Button>
                <Button ref={secondaryButton} type="button" onClick={executeAction} color="default" variant="outlined" disableElevation>Sim</Button>
                </div>
            </div>
        );
}

const mapStateToProps = state => ({
    modal: state.modal
  });
  
  
  const mapDispatchToProps = dispatch => ({
      closeModal: () => dispatch(closeModal()),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);