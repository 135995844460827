import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';


import './comments.styles.scss';

// Import Redux Action
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils

// Import Material UI components
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


// Import Components
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';
import Pagination  from '../../components/pagination/pagination.component';
import Avatar from '../../components/avatar/avatar.component';
import HeadingViewMode from '../../modules/headings/heading.view.component';

import {lockScrollbar} from '../../utils/utils';
 
class CommentsManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      replying: false,
      deleting: false,
      results: [],
      pagination: {
        page: 1,
        pages: 1,
        total: 0
      },
      formMode: 'list',
      toReply: [],
      statusOptions: [
        {label: 'Todos', value: -1},
        {label: 'Novos', value: 0},
        {label: 'Aprovados', value: 1},
        {label: 'Cancelados', value: 6},
      ],
      orderingOptions: [
        {label: 'Mais recentes', value: 0},
        {label: 'Mais Antigos', value: 1},
      ],
      formItems: {
        searchKey: {
          id: 'searchKey',
          label: 'Procurar por',
          placeholder: '',
          value: '',
          hasTouched: false,
          hasError: false,
          errorMsg: '',
          rules: {}
        },
        situation: {
            id: 'situation',
            label: 'Situação',
            placeholder: '',
            value: -1,
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {}
        },
        ordering: {
            id: 'ordering',
            label: 'Ordenar por',
            placeholder: '',
            value: 0,
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {}
        },
        replyMessage: {
            id: 'replyMessage',
            label: 'Resposta',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
                required: true,
                maxLength: 255
            }
        },
        cancelReason: {
            id: 'cancelReason',
            label: 'Motivo',
            placeholder: '',
            value: '',
            hasTouched: false,
            hasError: false,
            errorMsg: '',
            rules: {
                maxLength: 255
            }
        },
      }
    };
  }

    

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.getItems(1);
      
    }

    getItems = (page) => {

      this.setState({loading: true});
     
      const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          page: page,
          keySearch: this.state.formItems.searchKey.value,
          situation: this.state.formItems.situation.value,
          order: this.state.formItems.ordering.value
        }),
      };

      fetch("/api/comments_list.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);

          if(data.success){
          
          const pagination = this.state.pagination;
          pagination.total = data.totalItems;
          pagination.page= data.currentPage;
          pagination.pages = data.totalPages;
          const results = data.items; 

          this.setState({ 
            ...this.state, 
            loading: false,
            results: results,
            pagination: pagination
          });

        }

      })
      .catch((error) => {
        console.dir(error);
      });

    }

  render(props){
  
   // FORMS: INPUT CHANGE HANDLE FUNCTION
   const handleChange = (event) => {

    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;
  
    // Get current FormItems object from State
    const formItems = this.state.formItems;
  
    // Get State of the Input
    const formItem = {
        ...formItems[name]
    };
  
    // Validate Element if it has been Touched Before
    if(formItem.hasTouched){
        if(formItem.rules){
            const validation = validate(value, formItem.rules, formItems);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }
    }
  
    // Set the new value to element
    formItem.value = value;
  
    // Update formItems collection
    formItems[name] = formItem;
  
  
    // Update State
    this.setState({ 
        ...this.state, 
        formItems: formItems
    });
  
    }
  
    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
            
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;
  
    // formItems collection
    const formItems = {
        ...this.state.formItems
    };
  
    // state of modified element
    const formItem = {
        ...formItems[name]
    };
  
    formItem.hasTouched = true;
    if(formItem.rules){
        const validation = validate(value, formItem.rules, formItems);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
    }else{
        formItem.hasError = false;
        formItem.errorMsg = '';
    }
  
    // Update formItems collection
    formItems[name] = formItem;
  
    // Update state
    this.setState({ 
        ...this.state, 
        formItems: formItems
    });
  
    }
  
    // FORMS: HANDLE SUBMIT
    const handleSubmit = () => {
  
      this.getItems(1);
  
    }

  // Format Data
  const formatDate = (date, withHour) => {

    if(date){

        let d = date.substr(8, 2);
        let m = date.substr(5, 2);
        let y = date.substr(0, 4);
        let sDate = '';

        if(withHour){

        let h = date.substr(11,2);
        let n = date.substr(14,2);
        sDate = d + '/' + m + '/' + y + ' às ' + h + 'h' + n;

        }else{

        sDate = d + '/' + m + '/' + y;

        }

        return sDate;

    }
    return '-';

  }

  // Reply Comment
  const setReplyMode = (item) => {

        if(item.replyMessage){
            const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Aviso',
                modalParameters: {
                    text: 'Este comentário já foi respondido.'
                },
                modalActions: null
              }
              this.props.openModal(modalObj);
              return;
        }

        const formItems = this.state.formItems;
        formItems.replyMessage.value = '';
        formItems.replyMessage.hasError = false;
        formItems.replyMessage.hasTouched = false;
        formItems.replyMessage.errorMsg = '';

        lockScrollbar('add');

        this.setState({
            ...this.state,
            formMode: 'reply',
            toReply: item,
            formItems: formItems
        })
  }

  // Reply Comment
  const setDeleteMode = (item) => {

    lockScrollbar('add');

    this.setState({
        ...this.state,
        formMode: 'delete',
        toReply: item
    })
  }

  // Close Form
  const setListMode = () => {

      const formItems = this.state.formItems;

      formItems.replyMessage.value = '';

      lockScrollbar('remove');

      this.setState({
          ...this.state,
          formMode: 'list',
          formItems: formItems
      })
  }

  // Disabled Reply Form Button
  const disabledReply = () => {
      let disabled = false;

      if(this.state.formItems.replyMessage.value === ''){
          disabled = true;
      }

      if(this.state.formItems.replyMessage.hasError){
        disabled = true;
      }

      return disabled;

  }

  // Reply Message
  const reply = (id) => {

        this.setState({
            ...this.state,
            formMode: 'list',
            loading: true
        });

        const userToken = getToken();

      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
          'Content-Type': 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*"
      },
        body: JSON.stringify({
          userToken: userToken,
          app: window.$appToken,
          id: this.state.toReply.id,
          message: this.state.formItems.replyMessage.value,
        }),
      };

      fetch("/api/comments_reply.php", requestOptions)
      .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        authAction(data.authenticated);
        lockScrollbar('remove');

        if(data.success){
            this.getItems(this.state.pagination.page);
        }else{
            const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Aviso',
                modalParameters: {
                text: data.feedback
            },
            modalActions: null
            }
            this.props.openModal(modalObj);
        }

      })
      .catch((error) => {
        console.dir(error);
      });


  }

  // Delete Message
  const deleteReply = (id) => {
    
    this.setState({
        ...this.state,
        formMode: 'list',
        loading: true
    });

    const userToken = getToken();

    const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
        'Content-Type': 'application/json',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
    },
        body: JSON.stringify({
        userToken: userToken,
        app: window.$appToken,
        id: this.state.toReply.id,
        message: this.state.formItems.cancelReason.value,
        }),
    };

    fetch("/api/comments_delete.php", requestOptions)
    .then(async (response) => {

        const data = await response.json();

        // check for error response
        if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
        }

        authAction(data.authenticated);
        lockScrollbar('remove');

        if(data.success){
            this.getItems(this.state.pagination.page);
        }else{
            const modalObj = {
                modalIcon: 'done',
                modalType: 'success',
                modalComponent: 'GENERAL_ALERT',
                modalTitle: 'Aviso',
                modalParameters: {
                text: data.feedback
            },
            modalActions: null
            }
            this.props.openModal(modalObj);
        }

    })
    .catch((error) => {
        console.dir(error);
    });

  }

  return (
    <div className="page comments-manager">

      { /* PAGE */ }
      <div className="content">
        
          { /* TITLE */ }
          <div className="container">
                <div className="module-heading margin-bottom--lg">
                    <h1 className="heading-1">Comentários</h1>
                </div>
          </div>

          { /* SEARCH BAR */ }
          <div className="container">
            <form className="form-content-search" onSubmit={handleSubmit}>
              <Grid container spacing={1}>

                <Grid item xs>
                  <TextField
                      name={this.state.formItems.searchKey.id}
                      id={this.state.formItems.searchKey.id}
                      value={this.state.formItems.searchKey.value}
                      label={this.state.formItems.searchKey.label}
                      placeholder={this.state.formItems.searchKey.placeholder}
                      error={this.state.formItems.searchKey.hasError}
                      helperText={this.state.formItems.searchKey.errorMsg}
                      fullWidth
                      variant="outlined"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.situation.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.situation.id}>{this.state.formItems.situation.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.situation.id}
                  id={this.state.formItems.situation.id}
                  value={this.state.formItems.situation.value}
                  label={this.state.formItems.situation.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >
                  {this.state.statusOptions.map((item) => {
                    let itemKey = 'sit-' + item.value;
                    return(<option key={itemKey} value={item.value}>{item.label}</option>);
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.situation.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={2}>
                  <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={this.state.formItems.ordering.hasError}
                  >
                  <InputLabel htmlFor={this.state.formItems.ordering.id} >{this.state.formItems.ordering.label}</InputLabel>
                  <Select
                  native
                  fullWidth
                  name={this.state.formItems.ordering.id}
                  id={this.state.formItems.ordering.id}
                  value={this.state.formItems.ordering.value}
                  label={this.state.formItems.ordering.label}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  >

                  {this.state.orderingOptions.map((item, index) => {
                  return (
                  <option
                  key={"ordering-" + index}
                  value={item.value}
                  >
                  {item.label}
                  </option>
                  );
                  })}
                  </Select>
                  <FormHelperText>
                  {this.state.formItems.ordering.errorMsg}
                  </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={1}>
                <Button color="primary" variant="outlined" onClick={handleSubmit} fullWidth >Filtrar</Button>
              </Grid>

            </Grid>
            </form>
          </div>

          { /* RESULTS */ }
          <div className="container results">
            <div className={(this.state.loading) ? 'loading' : 'loading hidden'}><CircularProgress size={48} /></div>
            <div className={(this.state.results.length > 0) ? 'has-results' : 'has-results hidden'}>
                {this.state.results.map(item => {

                  let itemKey = 'comments-1-' + item.id;
                  let classes = 'comment-card';
                  let msg = '';
                  let msgInfo = ''

                  if(item.status === 0){
                      classes += ' new';
                  }else if(item.status === 1 && item.replyMessage){
                      classes += ' replied';
                      msg = item.replyMessage;
                      msgInfo = 'Respondido em ' + formatDate(item.replyDate, true);
                  }else if(item.status === 1){
                    classes += ' approved';
                  }else if(item.status === 6){
                    classes += ' cancelled';
                    msgInfo = 'Cancelado em ' + formatDate(item.replyDate, true);
                    msg= item.cancelReason;
                  }

                return(
                <div key={itemKey} className={classes}>

                    { /* Body */ }
                    <div className="comment-body" onClick={() => setReplyMode(item)}>
                        
                        { /* Message */ }
                        <div className="owner">
                            
                            <div className="image">
                                <Avatar source={item.avatar} size="small" />
                            </div>
                            
                            <div className="info">
                                <div className="msg-date">Em {formatDate(item.created, true)}, <b>{item.authorName}</b> comentou em <a href={item.url}>{item.title}</a></div>
                                <div className="message">{item.message}</div>
                                
                                <div className={(item.replyMessage) ? 'reply' : 'reply hidden'}>
                                    <div className="msg-date">{msgInfo}</div>
                                    <div className="message">{msg}</div>
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    <div className="select">
                    <IconButton onClick={() => setDeleteMode(item)} disabled={(parseInt(item.status) === 6)}>
                        <DeleteIcon />
                    </IconButton>
                    </div>

                </div>
                  )

                })}


                { /* LEGEND */ }
                <div className="container">
                    <div className="legend">

                        <div className="item">
                            <div className="new"></div>Novo
                        </div>

                        <div className="item">
                            <div className="approved"></div>Aprovado
                        </div>

                        <div className="item">
                            <div className="replied"></div>Respondido
                        </div>

                        <div className="item">
                            <div className="cancelled"></div>Cancelado
                        </div>

                    </div>
                </div>
                
                { /* PAGINATION */ }
                <div className="container">
                    <Pagination 
                        currentPage={this.state.pagination.page} 
                        totalPages={this.state.pagination.pages} 
                        totalItems={this.state.pagination.total}
                        goToFunction={this.getItems}
                    />
                </div>
          

                
            </div>
            <div className={(this.state.results.length > 0) ? 'no-results hidden' : 'no-results'}>
                <p><b>Nenhum abriccó por aqui...</b><br/>
                Nenhum comentário encontrado.</p>
            </div>
          </div>

      </div>

      { /* REPLY FORM */ }
      <div className={(this.state.formMode !== 'list') ? 'reply-area' : 'reply-area hidden'} >
          <div className={(this.state.formMode === 'reply') ? 'reply-form' : 'reply-form hidden'} >
          <Grid container spacing={1}>

                { /* FORM TITLE */ }
                <Grid item xs={12} className="margin-bottom--sm">
                <HeadingViewMode type={2} title="Responder Comentário" />
                </Grid>

                { /* COMMENT */ }
                <Grid item xs={12} className="margin-bottom--sm">
                <div className="original-message">
                    <div className="user">
                        <Avatar source={this.state.toReply.avatar} size="small" />
                    </div>
                    <div className="message">
                        <div className="date">Em {formatDate(this.state.toReply.created, true)}, <b>{this.state.toReply.authorName}</b> comentou em <a href={this.state.toReply.url}>{this.state.toReply.title}</a></div>
                        <div className="text">{this.state.toReply.message}</div>
                    </div>
                </div>
                
                </Grid>

                { /* REPLY MESSAGE */ }
                <Grid item xs={12} className="margin-bottom--sm">
                    <TextField
                    name={this.state.formItems.replyMessage.id}
                    id={this.state.formItems.replyMessage.id}
                    value={this.state.formItems.replyMessage.value}
                    label={this.state.formItems.replyMessage.label}
                    placeholder={this.state.formItems.replyMessage.placeholder}
                    error={this.state.formItems.replyMessage.hasError}
                    helperText={this.state.formItems.replyMessage.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    />
                </Grid>

                { /* BUTTONS */ }
                <Grid item xs={12}>
                    <div className="btn-wrapper side-by-side inverted-in-mobile on-right">

                    <Button color="default" variant="outlined" disableElevation onClick={setListMode}>Cancelar</Button>
                    <Button 
                    color="primary" 
                    variant="contained" 
                    disableElevation
                    disabled={disabledReply()}
                    onClick={reply}
                    >
                    Responder
                    </Button>
                    
                    </div>
                </Grid>

          </Grid>
          </div>
          <div className={(this.state.formMode === 'delete') ? 'delete-form' : 'delete-form hidden'}>
          <Grid container spacing={1}>

                { /* FORM TITLE */ }
                <Grid item xs={12} className="margin-bottom--sm">
                <HeadingViewMode type={2} title="Apagar Comentário" />
                </Grid>

                { /* COMMENT */ }
                <Grid item xs={12} className="margin-bottom--sm">
                <div className="original-message">
                    <div className="user">
                        <Avatar source={this.state.toReply.avatar} size="small" />
                    </div>
                    <div className="message">
                        <div className="date">Em {formatDate(this.state.toReply.created, true)}, <b>{this.state.toReply.authorName}</b> comentou em <a href={this.state.toReply.url}>{this.state.toReply.title}</a></div>
                        <div className="text">{this.state.toReply.message}</div>
                    </div>
                </div>
                
                </Grid>

                { /* CANCEL REASON */ }
                <Grid item xs={12} className="margin-bottom--sm">
                    <TextField
                    name={this.state.formItems.cancelReason.id}
                    id={this.state.formItems.cancelReason.id}
                    value={this.state.formItems.cancelReason.value}
                    label={this.state.formItems.cancelReason.label}
                    placeholder={this.state.formItems.cancelReason.placeholder}
                    error={this.state.formItems.cancelReason.hasError}
                    helperText={this.state.formItems.cancelReason.errorMsg}
                    fullWidth
                    multiline
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    />
                </Grid>

                { /* BUTTONS */ }
                <Grid item xs={12}>
                    <div className="btn-wrapper side-by-side inverted-in-mobile on-right">

                    <Button color="default" variant="outlined" disableElevation onClick={setListMode}>Cancelar</Button>
                    <Button 
                    color="secondary" 
                    variant="contained" 
                    disableElevation
                    onClick = {() => deleteReply(this.state.toReply.id)}
                    >
                    Apagar
                    </Button>
                    
                    </div>
                </Grid>

          </Grid>
          </div>
      </div>

    </div>
  );
  }
}


const mapDispatchToProps = dispatch => ({
  openModal: modalData => dispatch(openModal(modalData))
});

export default withRouter(connect(null, mapDispatchToProps)(CommentsManager));