//Initial State
const INITIAL_STATE = {
    
    loadingResults: false,
    isOpen: false,
    type: 0,
    position: 0,
    modules: []
}

//Reducer and Actions
const ModuleSelectorReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
    
        case 'OPEN_SELECTOR' :
            return{
                ...state,
                isOpen: true,
                type: action.payload.type,
                position: action.payload.position
            }
        
        case 'CLOSE_SELECTOR' :
            return{
                ...state,
                isOpen: false
            }
        
        case 'GET_MODULES' :
            return{
                ...state,
                loadingResults: false,
                modules: action.payload
            }
        
        case 'TOGGLE_LOADER' :
            return{
                ...state,
                loadingResults: !state.loadingResults
            }
    
        default:
            return state
    }
}

//Export Reducer
export default ModuleSelectorReducer;