import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './cta.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';


// Import Redux Actions
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';


// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class CtaEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            formItems: {
                primaryLabel: {
                    id: 'primaryLabel',
                    label: 'Label',
                    placeholder: 'Label Primária',
                    value: (this.props.primaryLabel) ? this.props.primaryLabel : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      maxLength: 255
                    }
                  },
                  primaryLink: {
                    id: 'primaryLink',
                    label: 'Link',
                    placeholder: 'Link Primário',
                    value: (this.props.primaryLink) ? this.props.primaryLink : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                      maxLength: 255,
                      link: true
                    }
                  },
                  secondaryLabel: {
                    id: 'secondaryLabel',
                    label: 'Label',
                    placeholder: 'Label Secundária',
                    value: (this.props.secondaryLabel) ? this.props.secondaryLabel : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      requiredIfFilled: {id: 'secondaryLink', label: 'Link Secundário'},
                      maxLength: 255
                    }
                  },
                  secondaryLink: {
                    id: 'secondaryLink',
                    label: 'Link',
                    placeholder: 'Link Secundário',
                    value: (this.props.secondaryLink) ? this.props.secondaryLink : '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      requiredIfFilled: {id: 'secondaryLabel', label: 'Label Secundária'},
                      maxLength: 255,
                      link: true
                    }
                  },
            }
        }
    }

    componentDidMount(){
       
    }

    render(props){

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
            ...this.state.formItems
        };

        // Get State of the Input
        const formItem = {
            ...formItems[name]
        };

        // Validate Element if it has been Touched Before
        if(formItem.hasTouched){
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
        }

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

        if(callbacks){
          callbacks.map(item => {
            return item(event);
          })
        }

    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules, formItems);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let primaryLabel = this.state.formItems.primaryLabel.value;
        let primaryLink = this.state.formItems.primaryLink.value;
        let secondaryLink = this.state.formItems.secondaryLink.value;
        let secondaryLabel = this.state.formItems.secondaryLabel.value;

        const parameters = {
            primaryLabel: primaryLabel,
            primaryLink: primaryLink,
            secondaryLabel: secondaryLabel,
            secondaryLink: secondaryLink
        }

        this.props.functions.updateModule(this.props.contentId, parameters);
    }

    const setPrimaryUrl = (itemObj) => {

        const url = '/' + itemObj[0].url;

        const formItems = this.state.formItems;
        formItems.primaryLink.value = url;
        

        formItems.primaryLink.hasTouched = true;
        const validation = validate(url, formItems.primaryLink.rules, formItems);
        formItems.primaryLink.hasError = validation[0];
        formItems.primaryLink.errorMsg = validation[1];

        this.setState({
            ...this.state,
            formItems: formItems
        });

        this.props.closeDirectory();

    }

    const setSecondaryUrl = (itemObj) => {

        const url = '/' + itemObj[0].url;

        const formItems = this.state.formItems;
        formItems.secondaryLink.value = url;
        

        formItems.secondaryLink.hasTouched = true;
        const validation = validate(url, formItems.secondaryLink.rules, formItems);
        formItems.secondaryLink.hasError = validation[0];
        formItems.secondaryLink.errorMsg = validation[1];

        this.setState({
            ...this.state,
            formItems: formItems
        });

        this.props.closeDirectory();

    }

    const formHasError = () => {
        
        let hasError = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    hasError = true;
                }

            }

            if(formItems.primaryLabel.value === '' || formItems.primaryLink.value === ''){
                hasError = true;
            }

            return hasError;
    }


        return(
            <form className='cta-edit-mode'>
                <div className="module-title-bar">Módulo Botão de Ação : {this.props.contentId}</div>
                
                <Grid container spacing={2}>

                    {/* Primary CTA */}
                    <Grid item xs={12} sm={6}>   
                            <fieldset className='btn-group'>
                                <legend className='btn-group-label'>Botão Primário</legend>
                                <Grid container spacing={2}>

                                { /* Label */ }
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                    name={this.state.formItems.primaryLabel.id}
                                    id={this.state.formItems.primaryLabel.id}
                                    value={this.state.formItems.primaryLabel.value}
                                    label={this.state.formItems.primaryLabel.label}
                                    placeholder={this.state.formItems.primaryLabel.placeholder}
                                    error={this.state.formItems.primaryLabel.hasError}
                                    helperText={this.state.formItems.primaryLabel.errorMsg}
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                </Grid>

                                { /* Link */ }
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                    name={this.state.formItems.primaryLink.id}
                                    id={this.state.formItems.primaryLink.id}
                                    value={this.state.formItems.primaryLink.value}
                                    label={this.state.formItems.primaryLink.label}
                                    placeholder={this.state.formItems.primaryLink.placeholder}
                                    error={this.state.formItems.primaryLink.hasError}
                                    helperText={this.state.formItems.primaryLink.errorMsg}
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                            aria-label="Procurar conteúdo"
                                            edge="end"
                                            onClick={() => this.props.openDirectory(1, setPrimaryUrl)}
                                            >
                                            <DescriptionIcon /> 
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                    />
                                </Grid>
                            
                            </Grid>
                            </fieldset>
                        
                    </Grid>

                    {/* Secondary CTA */}
                    <Grid item xs={12} sm={6}>

                        <fieldset className='btn-group'>
                            <legend className='btn-group-label'>Botão Secundário (opcional)</legend>
                            
                                <Grid container spacing={2}>
                                
                                    { /* Label */ }
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                        name={this.state.formItems.secondaryLabel.id}
                                        id={this.state.formItems.secondaryLabel.id}
                                        value={this.state.formItems.secondaryLabel.value}
                                        label={this.state.formItems.secondaryLabel.label}
                                        placeholder={this.state.formItems.secondaryLabel.placeholder}
                                        error={this.state.formItems.secondaryLabel.hasError}
                                        helperText={this.state.formItems.secondaryLabel.errorMsg}
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        />
                                    </Grid>

                                    { /* Link */ }
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                        name={this.state.formItems.secondaryLink.id}
                                        id={this.state.formItems.secondaryLink.id}
                                        value={this.state.formItems.secondaryLink.value}
                                        label={this.state.formItems.secondaryLink.label}
                                        placeholder={this.state.formItems.secondaryLink.placeholder}
                                        error={this.state.formItems.secondaryLink.hasError}
                                        helperText={this.state.formItems.secondaryLink.errorMsg}
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                aria-label="Procurar conteúdo"
                                                edge="end"
                                                onClick={() => this.props.openDirectory(1, setSecondaryUrl)}
                                                >
                                                <DescriptionIcon /> 
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                        />
                                    </Grid>

                                </Grid>
                        </fieldset>

                    </Grid>

                </Grid>
                 
                 
                 { /* Buttons */ }
                 <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={formHasError()}
                            >Atualizar</Button>
                        </div>
                    </Grid>

                </Grid>
                
                    
                
                
                
            </form>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
});
  
export default withRouter(connect(null, mapDispatchToProps)(CtaEditMode));