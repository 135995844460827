import React, {Component} from 'react';
import './heading.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";


// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';


class HeadingEditMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.headingType = React.createRef();
        this.state = {
            alignment: [
                {
                    label: 'Centralizado',
                    value: 'center'
                },
                {
                    label: 'A Esquerda',
                    value: 'left'
                }
            ],
            types: [
                {
                    label: 'Nível 1 (H1)',
                    value: 1
                },
                {
                    label: 'Nível 2 (H2)',
                    value: 2
                },
                {
                    label: 'Nível 3 (H3)',
                    value: 3
                },
                {
                    label: 'Nível 4 (H4)',
                    value: 4
                },
                {
                    label: 'Nível 5 (H5)',
                    value: 5
                },
                {
                    label: 'Nível 6 (H6)',
                    value: 6
                },
            ],
            formItems: {
                headingType: {
                    id: 'headingType',
                    label: 'Nível',
                    placeholder: 'Selecione',
                    value: this.props.type,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                      required: true,
                    }
                  },
                  headingTitle: {
                    id: 'headingTitle',
                    label: 'Título',
                    placeholder: 'Digite o título',
                    value: this.props.title,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                  },
                  alignment: {
                    id: 'alignment',
                    label: 'Alinhamento',
                    placeholder: '',
                    value: (this.props.alignment) ? this.props.alignment : 'center',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: []
                  },
            }
        }
    }

    componentDidMount(){
       
    }

    render(props){

    // FORMS: INPUT CHANGE HANDLE FUNCTION
    const handleChange = (event, callbacks) => {

        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // Get current FormItems object from State
        const formItems = {
            ...this.state.formItems
        };

        // Get State of the Input
        const formItem = {
            ...formItems[name]
        };

        // Validate Element if it has been Touched Before
        if(formItem.hasTouched){
            if(formItem.rules){
                const validation = validate(value, formItem.rules);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }
        }

        // Set the new value to element
        formItem.value = value;

        // Update formItems collection
        formItems[name] = formItem;

        // Update State
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

        if(callbacks){
          callbacks.map(item => {
            return item(event);
          })
        }

    };

    // FORMS: INPUT BLUR HANDLE FUNCTION
    const handleBlur = (event) => {
                
        // Get Name and Value from element
        const name = event.target.name;
        const value = event.target.value;

        // formItems collection
        const formItems = {
            ...this.state.formItems
        };

        // state of modified element
        const formItem = {
            ...formItems[name]
        };

        formItem.hasTouched = true;
        if(formItem.rules){
            const validation = validate(value, formItem.rules);
            formItem.hasError = validation[0];
            formItem.errorMsg = validation[1];
        }else{
            formItem.hasError = false;
            formItem.errorMsg = '';
        }

        // Update formItems collection
        formItems[name] = formItem;

        // Update state
        this.setState({ 
            ...this.state, 
            formItems: formItems
        });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let type = this.state.formItems.headingType.value;
        let title = this.state.formItems.headingTitle.value;
        let alignment = this.state.formItems.alignment.value;

        const parameters = {
            type: parseInt(type),
            title: title,
            alignment: alignment
        }

        this.props.functions.updateModule(this.props.contentId, parameters);
    }


        return(
            <form className='heading-edit-mode'>
                 <Grid container spacing={2}>
                    
                    <Grid item xs={12} sm={2}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.headingType.hasError} ref={this.headingType} >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.headingType.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.headingType.id}
                            id={this.state.formItems.headingType.id}
                            value={this.state.formItems.headingType.value}
                            label={this.state.formItems.headingType.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            {this.state.types.map((item) => {
                                return (
                                <option
                                    key={"heading-type-" + item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.headingType.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl variant="outlined" fullWidth error={this.state.formItems.alignment.hasError} >
                            <InputLabel htmlFor="updatedCollection">{this.state.formItems.alignment.label}</InputLabel>
                            <Select
                            autoFocus
                            native
                            fullWidth
                            name={this.state.formItems.alignment.id}
                            id={this.state.formItems.alignment.id}
                            value={this.state.formItems.alignment.value}
                            label={this.state.formItems.alignment.label}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            >
                            {this.state.alignment.map((item) => {
                                return (
                                <option
                                    key={"heading-alignment-" + item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                                );
                            })}
                            </Select>
                            <FormHelperText>
                            {this.state.formItems.alignment.errorMsg}
                            </FormHelperText>
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={8}>
                        <TextField
                        name={this.state.formItems.headingTitle.id}
                        id={this.state.formItems.headingTitle.id}
                        value={this.state.formItems.headingTitle.value}
                        label={this.state.formItems.headingTitle.label}
                        placeholder={this.state.formItems.headingTitle.placeholder}
                        error={this.state.formItems.headingTitle.hasError}
                        helperText={this.state.formItems.headingTitle.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <div className="btn-wrapper on-right side-by-side inverted-in-mobile">
                            <Button color="default" variant="contained" disableElevation onClick={() => this.props.functions.toggleMode(this.props.contentId)}>Cancelar</Button>
                            <Button 
                            type="submit" 
                            color="primary" 
                            variant="contained" 
                            disableElevation 
                            onClick={(e) => handleSubmit(e)} 
                            disabled={(this.state.formItems.headingType.hasError || this.state.formItems.headingTitle.hasError)}
                            >Atualizar</Button>
                        </div>
                    </Grid>

                </Grid>
                
                    
                
                
                
            </form>
        )
    }
}

export default HeadingEditMode;
