import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import validate from "../../utils/form-validation/form-validation.utils";
import {getToken, authAction} from "../../utils/user-validation/user-validation.utils";


class AddNote extends Component{

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            saving: false,
            hasError: false,
            errorMessage: '',
            formItems: {
                note: {
                    id: "note",
                    label: "Nota",
                    placeholder: "Digite uma nota até 255 caracteres",
                    value: '',
                    hasTouched: true,
                    hasError: false,
                    errorMsg: "",
                    rules: {
                      required: true,
                      maxLength: 255
                    },
                },
            }
        }
    }

    // MOUNT
    componentDidMount(){
       
    }

  // Handle Inputs Change
  handleChange = (event, callbacks) => {
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // Get current FormItems object from State
    const formItems = {
      ...this.state.formItems,
    };

    // Get State of the Input
    const formItem = {
      ...formItems[name],
    };

    // Validate Element if it has been Touched Before
    if (formItem.hasTouched) {
      if (formItem.rules) {
        const validation = validate(value, formItem.rules, formItems);
        formItem.hasError = validation[0];
        formItem.errorMsg = validation[1];
      } else {
        formItem.hasError = false;
        formItem.errorMsg = "";
      }
    }

    // Set the new value to element
    formItem.value = value;

    // Update formItems collection
    formItems[name] = formItem;

    // Update State
    this.setState({
      ...this.state,
      formItems: formItems
    });

    if (callbacks) {
      callbacks.map((item) => {
        return item(event);
      });
    }
  };

  // Handle Inputs Blur
  handleBlur = (event) => {
    // Get Name and Value from element
    const name = event.target.name;
    const value = event.target.value;

    // formItems collection
    const formItems = {
      ...this.state.formItems,
    };

    // state of modified element
    const formItem = {
      ...formItems[name],
    };

    formItem.hasTouched = true;
    if (formItem.rules) {
      const validation = validate(value, formItem.rules, formItems);
      formItem.hasError = validation[0];
      formItem.errorMsg = validation[1];
    } else {
      formItem.hasError = false;
      formItem.errorMsg = "";
    }

    // Update formItems collection
    formItems[name] = formItem;

    // Update state
    this.setState({
      ...this.state,
      formItems: formItems,
    });
  };

  handleSubmit = () => {
    
    // Validate formItems
    const {formItems} = this.state;
    let hasError = false;
    let updatedFormItems = formItems;

    for (let key in updatedFormItems) {

        let obj = updatedFormItems[key];
        
        if (obj.rules) {
          let validation = validate(obj.value, obj.rules, formItems);
          if (validation[0]) {
            hasError = true;
          }
          obj.hasError = validation[0];
          obj.errorMsg = validation[1];
        } else {
          obj.hasError = false;
          obj.errorMsg = "";
        } 
        
    }

    if(hasError){
        this.setState({
            formItems: updatedFormItems,
            hasError: true,
            errorMessage: 'O formulário possui um ou mais erros'
        });
    }else{
        this.setState({
            saving: true, 
            hasError: false, 
            errorMessage: ''
        }, this.saveNote);
    }
  
}

saveNote = () => {

    const { formItems } = this.state;
    const userToken = getToken();
    const formData = {
        userToken: userToken,
        app: window.$appToken,
        slot: this.props.slot.id,
        note: formItems.note.value,
    }

    const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
        },
        body: JSON.stringify(formData)
    };

    fetch("/api/slots_add_note.php", requestOptions)
    .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        authAction(data.authenticated);

        if(data.success){

            this.setState({
                saving: false,
                hasError: false,
                errorMessage: ''
            },this.props.update);

        }else{
            
            this.setState({
                saving: false,
                hasError: true, 
                errorMessage: data.feedback
            });
        }

    });

}


    render(){
        return(
            <div className="add-note">
                <div className="title">
                  <span className="text">Adicionar Anotação</span>
                </div>

                <div className={(this.state.hasError) ? 'form-error-alert' : 'form-error-alert hidden'}>
                    {this.state.errorMessage}
                </div>

                <Grid container spacing={2}>    
                    <Grid item xs={12}>
                        <TextField
                            id={this.state.formItems.note.id}
                            name={this.state.formItems.note.id}
                            label={this.state.formItems.note.label}
                            variant="outlined"
                            value={this.state.formItems.note.value}
                            placeholder={this.state.formItems.note.placeholder}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                            multiline
                            error={this.state.formItems.note.hasError}
                            helperText={this.state.formItems.note.errorMsg}
                            autoFocus
                        />
                    </Grid>
                </Grid>
                
                <div className="btn-wrapper side-by-side on-right">
                    <Button 
                        color="default" 
                        variant="outlined" 
                        disableElevation 
                        disabled={(this.state.loading || this.state.saving)}
                        onClick={() => this.props.toggleView('view')}
                    >
                        Voltar
                    </Button>
                    <Button 
                        color="primary" 
                        variant="contained" 
                        disableElevation 
                        disabled={(this.state.loading || this.state.saving)}
                        onClick={this.handleSubmit}
                    >
                        {(this.state.saving) ? 'Salvando...' : 'Salvar'}
                    </Button>
                </div>
            </div>
        )
    }
}
export default AddNote;