// Import libraries
import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

// Import styles
import './image-selector.styles.scss';

// Import Material UI components
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

// Import redux actions
import { openGallery, closeGallery } from '../../redux/media-gallery/media-gallery.actions';

const ImageSelector = (props) => {

       const [selectedImage, selectImage] = useState({id: 0, src: ''});

       useEffect(() => {
        const Obj = { id: props.selectedImage, src: props.selectedFile};
        selectImage(Obj);
      }, [props]);

       const setImage = (obj) => {

            const imgObj = selectedImage;
            imgObj.id = parseInt(obj[0].mediaId);
            imgObj.src = obj[0].mediaFile;

            selectImage({id: parseInt(obj[0].mediaId), src: obj[0].mediaFile});        

            props.closeGallery();

            props.action(obj);

       }

       const unsetImage = () => {
            selectImage({id: 0, src: ''});  
            props.clearFunction();
       }

       const bgImg = (selectedImage.id === 0) ? 'url("' + window.$imagesPath + 'none.png")' :'url("' + window.$thumbsPath + selectedImage.src + '")';

        return(
            <div className='image-selector'>
                <div className="input">
                    <div className="image-preview" aria-label={props.label} style={{backgroundImage: bgImg}} onClick={() => props.openGallery(1, setImage)}>
                        
                    </div>
                    <div className="info">
                            <div className='btn-wrapper centered'>
                                <button className="btn-circle primary medium" onClick={() => props.openGallery(1, setImage)} ><SearchIcon /></button>
                                <button className="btn-circle other medium" onClick={() => unsetImage()} ><ClearIcon /></button>
                            </div>
                    </div>
                </div>
            </div>
        )
    
}


const mapDispatchToProps = dispatch => ({
    openGallery: (limit, action) => dispatch(openGallery(limit, action)),
    closeGallery: () => dispatch(closeGallery())
  });
  
  export default withRouter(connect(null, mapDispatchToProps)(ImageSelector));
  