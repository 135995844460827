import React from 'react';
import './cta.styles.scss';

// Import UI components
import Button from "@material-ui/core/Button";

const CtaViewMode = (props) =>{

    const gotoLink = (link) => {
        window.location = link;
    }

    const NoButton = (props.primaryLink === '' && props.primaryLabel === '' && props.secondaryLabel === '' && props.secondaryLink === '')?(<p>ERROR: Nenhum botão foi configurado</p>): null;

    const PrimaryButton = (props.primaryLink && props.primaryLabel)
            
            ?(
                <Button 
                type="submit" 
                color="primary" 
                variant="contained" 
                disableElevation 
                onClick={() => gotoLink(props.primaryLink)} 
                >{props.primaryLabel}</Button> 
            )
            :null;
       

    const SecondaryButton = (props.secondaryLink && props.secondaryLabel)
            ?(
                <Button 
                type="submit" 
                color="primary" 
                variant="outlined" 
                disableElevation 
                onClick={() => gotoLink(props.secondaryLink)} 
                >{props.secondaryLabel}</Button> 
            )
            :null;


    return(
            <div className='module-cta'>
                <div className="btn-wrapper centered inverted-in-mobile">
                    {NoButton}
                    {SecondaryButton}
                    {PrimaryButton}
                </div>
            </div>
        )

}

export default CtaViewMode;
