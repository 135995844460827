import React, {Component} from 'react';
import './expand-collapse.styles.scss';

// Import Material UI components
import Icon from '@material-ui/core/Icon';



class ExpandCollapseViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            opened: [],
            items: (this.props.items) ? this.props.items : []
        }
    }

    componentDidMount(){

    }

    render(props){

        const toggleItem = item => {

            let itemObj = parseInt(item);
            const currentOpened = this.state.opened;
            const itemOnArray = currentOpened.indexOf(item);

            if(itemOnArray === -1){
                currentOpened.push(itemObj);
            }else{
                currentOpened.splice(itemOnArray, 1);
            }

            this.setState({
                ...this.state,
                opened: currentOpened
            })

        }

        return(
            <div className='module__expand-collapse'>
                <div className={(this.state.items.length > 0 )? 'items' : 'items hidden'}>
                    { 
                      
                        this.state.items.map((item, index) => {

                            let itemId = 'expand-collapse-' + this.props.contentId + '-' + index;

                            return(
                                <div key={itemId} className={(this.state.opened.includes(index))? 'expand-collapse opened' : 'expand-collapse'}>
                                    <div className="title" onClick={() => toggleItem(index)}>
                                        <div className="text">{item.title}</div>
                                        <div className="icon-open"><Icon>add</Icon></div>
                                    </div>
                                    <div className="panel">
                                        <div className="text" dangerouslySetInnerHTML={{__html: item.text}}></div>
                                    </div> 
                                </div>
                            );
                        })
                        
                       
                    }
                </div>
                <div className={(this.state.items.length === 0 )? 'no-items' : 'no-items hidden'}><p><b>ERRO: Módulo sem datasource</b><br/>Clique em editar e adicione items a este módulo.</p></div>
            </div>
        );
    }

}

export default ExpandCollapseViewMode;