//Initial State
const INITIAL_STATE = {  
    visible: false,
    collections: [],
    limit: 1,
    onSave: null
}

//Reducer and Actions
const mediaGalleryReducer = (state = INITIAL_STATE, action) => {
switch (action.type) {

    case 'OPEN_GALLERY':
        return{
            ...state,
            visible: true,
            limit: action.payload.limit,
            onSave: action.payload.action
        }
    
    case 'CLOSE_GALLERY':
        return{
            ...state,
            visible: false
        }
    
    case 'LIST_COLLECTIONS':
        return {
            ...state,
            collections: action.payload
        }

    default:
        return state
}
}

//Export Reducer
export default mediaGalleryReducer;