//Import Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Styles and Assets
import './menus-admin.styles.scss';

//Import UI components
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// Import components
import HeadingViewMode from '../../modules/headings/heading.view.component';
import Autocomplete from '../../components/autocomplete/autocomplete.component';
import ContentDirectory from '../../components/content-directory/content-directory.component';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';
import { openDirectory, closeDirectory } from '../../redux/content-directory/content-directory.actions';
import { setMenuItems } from '../../redux/menus/menus.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';
import { getToken, authAction } from '../../utils/user-validation/user-validation.utils';

class MenusAdmin extends React.Component{

    constructor(props){
        super(props);
        this.state = {
          loadings: {
              page: false,
              results: false,
              savingMenu: false,
              getMenuItems: false,
              creatingMenu: false,
              addingItem: false,
              deletingItem: false,
              reordering: 0
          },
          formItems: {
              menu: {
                  id: 'menu',
                  label: 'Procurar por',
                  value: {
                      label: '',
                      value: 0
                  },
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: {
                      required: true,
                      maxLength: 255
                  }                 
              },
              menuName: {
                  id: 'menuName',
                  label: 'Label',
                  value: '',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: {
                      required: true,
                      maxLength: 255
                  }                 
              },
              menuLink: {
                  id: 'menuLink',
                  label: 'Destino',
                  value: '',
                  hasTouched: false,
                  hasError: false,
                  errorMsg: '',
                  rules: {
                    required: true,
                    maxLength: 255,
                    link: true
                  }                 
              }
          },
          results: [],
          menus: [],
          source: []
        }
    }

    getItems(){

        // Get source
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: { 
        'Content-Type': 'application/json',
        "Access-Control-Request-Headers": "*",
        "Access-Control-Request-Method": "*"
        },
            body: JSON.stringify({
            app: window.$appToken
            }),
        };

        fetch("/api/menu_list.php", requestOptions)
        .then(async (response) => {

            const data = await response.json();

            // check for error response
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }

            // If page has been published
            if(data.success){

                this.setState({
                    ...this.state,
                    source: data.source,
                    results: data.items
                })

                this.props.setMenuItems(data.items)

            }

        })
        .catch((error) => {
            console.dir(error);
        });

    }
    
    componentDidMount(){

      // Verify for Session Modals
      const hasModalToOpen = localStorage.getItem('openModal');
      if(hasModalToOpen && hasModalToOpen === '1'){
          const modalObj = JSON.parse(localStorage.getItem('modalObj'));
          this.props.openModal(modalObj);
      }
    this.getItems();  

    }

    //Rendering
    render(props){

      // FORMS: INPUT CHANGE HANDLE FUNCTION
      const handleChange = (event) => {

          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // Get current FormItems object from State
          const formItems = {
              ...this.state.formItems
          };

          // Get State of the Input
          const formItem = {
              ...formItems[name]
          };

          // Validate Element if it has been Touched Before
          if(formItem.hasTouched){
              if(formItem.rules){
                  const validation = validate(value, formItem.rules);
                  formItem.hasError = validation[0];
                  formItem.errorMsg = validation[1];
              }else{
                  formItem.hasError = false;
                  formItem.errorMsg = '';
              }
          }

          // Set the new value to element
          formItem.value = value;

          // Update formItems collection
          formItems[name] = formItem;

          // Update State
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });

      };

      // FORMS: INPUT BLUR HANDLE FUNCTION
      const handleBlur = (event) => {
                  
          // Get Name and Value from element
          const name = event.target.name;
          const value = event.target.value;

          // formItems collection
          const formItems = {
              ...this.state.formItems
          };

          // state of modified element
          const formItem = {
              ...formItems[name]
          };

          formItem.hasTouched = true;
          if(formItem.rules){
              const validation = validate(value, formItem.rules);
              formItem.hasError = validation[0];
              formItem.errorMsg = validation[1];
          }else{
              formItem.hasError = false;
              formItem.errorMsg = '';
          }

          // Update formItems collection
          formItems[name] = formItem;

          // Update state
          this.setState({ 
              ...this.state, 
              formItems: formItems
          });

      }

      const getLastPosition = (placeholder) => {

            const items = this.state.results.filter(
                item =>
                  item.placeholder === placeholder
            );

            if(items.length > 0){

                const itemsOrders = items.map((item) => {
                    return item.order
                });
    
                var max = itemsOrders.reduce(function(a, b) {
                    return Math.max(a, b);
                });
    
                return max;
            }
            return 0;

      }

      // FORMS: SUBMIT
      const handleSubmit = (event) => {
        
        event.preventDefault();
        const userToken = getToken();

        const loadings = this.state.loadings;
        loadings.addingItem = true;
        loadings.reordering = this.state.formItems.menu.value.value;

        const newItemOrderPosition = getLastPosition(this.state.formItems.menu.value.value) + 1;
        
        this.setState({
            ...this.state,
            loadings: loadings
        })

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            menu: this.state.formItems.menu.value.value,
            label: this.state.formItems.menuName.value,
            link: this.state.formItems.menuLink.value,
            order: newItemOrderPosition
            }),
        };
    
        fetch("/api/menu_add_item.php", requestOptions)
        .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }
    
            authAction(data.authenticated);
            loadings.addingItem = false;
            loadings.reordering = 0;

            // If page has been published
            if(data.success){

                const formItems = this.state.formItems;
                formItems.menu.value = {label: '', value: 0};
                formItems.menuName.value = '';
                formItems.menuLink.value = '';

                

                this.setState({
                    ...this.state,
                    formItems: formItems,
                    loadings: loadings,
                    source: data.source,
                    results: data.items
                });

            }else{

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);

                  this.setState({
                    ...this.state,
                    loadings: loadings
                });

            }
    
        })
        .catch((error) => {
            console.dir(error);
        });


      }

      //AUTO COMPLETE FUNCTIONS
      // Create Menu
      const createMenu = (menuTitle) => {
        
        const userToken = getToken();

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            menu: menuTitle
            }),
        };
    
        fetch("/api/menu_create.php", requestOptions)
        .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }
    
            authAction(data.authenticated);

            // If page has been published
            if(data.success){

                const formItems = this.state.formItems;
                formItems.menu.value = data.created;

                this.setState({
                    ...this.state,
                    formItems: formItems,
                    source: data.source
                })

            }else{

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);

            }
    
        })
        .catch((error) => {
            console.dir(error);
        });
      }

      // Delete Menu
      const deleteMenu = (id) => {
        
        const userToken = getToken();

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            menu: id
            }),
        };
    
        fetch("/api/menu_delete.php", requestOptions)
        .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }
    
            authAction(data.authenticated);

            // If page has been published
            if(data.success){

                const formItems = this.state.formItems;
                if(formItems.menu.value.value === id){
                    formItems.menu.value = {
                        label: '',
                        value: 0
                    }
                };

                this.setState({
                    ...this.state,
                    formItems: formItems,
                    source: data.source
                })

            }else{

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);

            }
    
        })
        .catch((error) => {
            console.dir(error);
        });
      }

      // Select Menu
      const selectMenu = (obj) => {
          const formItems = this.state.formItems;

          formItems.menu.value = obj;
          this.setState({
              ...this.state,
              formItems: formItems
          })
      }

      // Validate
      const menuHasError = (error, msg) => {

        const formItems = this.state.formItems;
        formItems.menu.hasError = error;
        formItems.menu.errorMsg = msg;
        
        this.setState({
            ...this.state,
            formItems: formItems
        });

      }

      // Functions
      const autocompleteFunctions = {
          create: createMenu,
          delete: deleteMenu,
          select: selectMenu,
          validate: menuHasError
      }

      // Choose link
      const setPrimaryUrl = (itemObj) => {

        const url = '/' + itemObj[0].url;

        const formItems = this.state.formItems;
        formItems.menuLink.value = url;
        

        formItems.menuLink.hasTouched = true;
        const validation = validate(url, formItems.menuLink.rules, formItems);
        formItems.menuLink.hasError = validation[0];
        formItems.menuLink.errorMsg = validation[1];

        this.setState({
            ...this.state,
            formItems: formItems
        });

        this.props.closeDirectory();

    }

    // DELETE MENU ITEM
    const deleteMenuItem = (menu, id) => {

        const userToken = getToken();

        const loadings = this.state.loadings;
        loadings.reordering = menu;
        this.setState({
            ...this.state,
            loadings: loadings
        });

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: id,
            menu: menu
            }),
        };
    
        fetch("/api/menu_delete_item.php", requestOptions)
        .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }
    
            authAction(data.authenticated);
    
            // If page has been published
            if(data.success){

                loadings.reordering = 0;
                this.setState({
                    ...this.state,
                    loadings: loadings,
                    source: data.source,
                    results: data.items
                });

            }else{

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);

            }
    
        })
        .catch((error) => {
            console.dir(error);
        });

    }

    // SORT MENU ITEMS
    const orderMenuItem = (menu, id, direction) => {

        const userToken = getToken();

        const loadings = this.state.loadings;
        loadings.reordering = menu;
        this.setState({
            ...this.state,
            loadings: loadings
        });

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
            'Content-Type': 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*"
        },
            body: JSON.stringify({
            userToken: userToken,
            app: window.$appToken,
            id: id,
            direction: direction
            }),
        };
    
        fetch("/api/menu_order_item.php", requestOptions)
        .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }
    
            authAction(data.authenticated);
    
            // If page has been published
            if(data.success){

                loadings.reordering = 0;
                this.setState({
                    ...this.state,
                    loadings: loadings,
                    source: data.source,
                    results: data.items
                });

            }else{

                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        text: data.feedback
                    },
                    modalActions: []
                  }

                  this.props.openModal(modalObj);

            }
    
        })
        .catch((error) => {
            console.dir(error);
        });

    }
      

      return (
        <div className="page menu-manager">
          

          <div className="content">
            
            {/* HEADING */}
            <HeadingViewMode type={1} title="Navegação" />

            { /* FORM ADD NEW MENU ITEM */ }
            <div className="container menu-add">
                <Grid container spacing={1}>
                    
                    { /* Menu Placeholder */ }
                    <Grid item xs={12} sm={3}>
                        <Autocomplete 
                        currentvalue={this.state.formItems.menu.value} 
                        placeholder="Selecione ou crie um placeholder" 
                        label="Área" 
                        source={this.state.source} 
                        functions={autocompleteFunctions} 
                        rules={this.state.formItems.menu.rules}
                        error={this.state.formItems.menu.hasError}
                        errorMsg={this.state.formItems.menu.errorMsg}
                        />
                    </Grid>

                    { /* Label */ }
                    <Grid item xs={12} sm={3}>
                        <TextField
                        name={this.state.formItems.menuName.id}
                        id={this.state.formItems.menuName.id}
                        value={this.state.formItems.menuName.value}
                        label={this.state.formItems.menuName.label}
                        placeholder={this.state.formItems.menuName.placeholder}
                        error={this.state.formItems.menuName.hasError}
                        helperText={this.state.formItems.menuName.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        />
                    </Grid>

                    { /* Link */ }
                    <Grid item xs>
                        <TextField
                        name={this.state.formItems.menuLink.id}
                        id={this.state.formItems.menuLink.id}
                        value={this.state.formItems.menuLink.value}
                        label={this.state.formItems.menuLink.label}
                        placeholder={this.state.formItems.menuLink.placeholder}
                        error={this.state.formItems.menuLink.hasError}
                        helperText={this.state.formItems.menuLink.errorMsg}
                        fullWidth
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        size="small"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                aria-label="Procurar conteúdo"
                                edge="end"
                                onClick={() => this.props.openDirectory(1, setPrimaryUrl)}
                                >
                                <DescriptionIcon /> 
                                </IconButton>
                            </InputAdornment>,
                        }}
                        />
                    </Grid>

                    { /* Button */ }
                    <Grid item xs={12} sm={2}>
                        <Button 
                        fullWidth 
                        color="primary" 
                        variant="contained"  
                        onClick={handleSubmit}
                        endIcon={(this.state.loadings.addingItem)? <CircularProgress size={24}/> : <AddIcon />} 
                        disabled={this.state.loadings.addingItem}
                        >Adicionar</Button>
                    </Grid>

                </Grid>
            </div>
            
            { /* REGISTERED MENU LISTS */ }
            <div className="container menu-list">
                {
                this.state.source.map((item) => {
                    let placeholderKey = 'menu-placeholder-' + item.value;

                    let placeholderItems = this.state.results.filter(
                        menuObj => parseInt(menuObj.placeholder) === parseInt(item.value)
                    );
                    
                    const lastItem = placeholderItems.length - 1;

                    return(
                        <div key={placeholderKey} className="placeholder">
                            <div className="title">
                                <span className="text">{item.label}</span>
                            </div>
                            <div className="items">
                            <div className={(this.state.loadings.reordering === item.value) ? 'loading' : 'loading hidden'}><CircularProgress size={36} /> </div>
                            {
                            placeholderItems.map((menu, index) => {
                                let itemKey = 'placeholder-item-' + menu.id;
                                return(
                                        <div key={itemKey} className="item">
                                            <div className="label">{menu.label}</div>
                                            <div className="link">{menu.url}</div>
                                            <div className="options">
                                                <IconButton
                                                aria-label="Subir"
                                                onClick={() => orderMenuItem(item.value, menu.id, 'up')}
                                                edge="end"
                                                disabled={(menu.order === 1)}
                                                >
                                                {(this.state.loadings.deletingItem) ? <CircularProgress size={24} /> : <ArrowUpwardIcon />}
                                                </IconButton>
                                                <IconButton
                                                aria-label="Descer"
                                                onClick={() => orderMenuItem(item.value, menu.id, 'down')}
                                                edge="end"
                                                disabled={(index === lastItem)}
                                                >
                                                {(this.state.loadings.deletingItem) ? <CircularProgress size={24} /> : <ArrowDownwardIcon />}
                                                </IconButton>
                                                <IconButton
                                                aria-label="Apagar"
                                                onClick={() => deleteMenuItem(item.value, menu.id)}
                                                edge="end"
                                                disabled={this.state.loadings.deletingItem}
                                                >
                                                {(this.state.loadings.deletingItem) ? <CircularProgress size={24} /> : <DeleteIcon />}
                                                </IconButton>
                                            </div>
                                        </div>
                                   
                                )
                            })
                            }
                             </div>
                        </div>
                    );
                })
                }
               
            </div>

            { /* CONTENT DIRECTORY */ }
            <ContentDirectory />
          
          </div>
        </div>
      );
    }
}


const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
    openDirectory: (limit, saveFunction) => dispatch(openDirectory(limit, saveFunction)),
    closeDirectory: () => dispatch(closeDirectory()),
    setMenuItems: items => dispatch(setMenuItems(items))
});

export default withRouter(connect(null, mapDispatchToProps)(MenusAdmin));