import React, {Component} from 'react';
import './cards.styles.scss';

import Headings from '../headings/heading.view.component';
import Button from "@material-ui/core/Button";

class CardsViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        
        this.state = {
            active: 0,
            isPaused: false,
            cards: (this.props.cards) ? this.props.cards : []
        }
    }

    componentDidMount(){

        
    }

    render(props){

        const gotoLink = (link) => {
            window.location = link;
        }
        
        const logo = window.$imagesPath + this.props.src;

        const hasLogo = (this.props.src !== '') ? ' has-logo' : '';

        const cardsContainer = (this.state.cards.length < 4) ? 'cards centered' : 'cards';

        const hasGrayBackground = (this.props.bg === 1) ? ' gray-mode' : '';

        const moduleClasses = 'module-cards' + hasGrayBackground + hasLogo;

        return(
            <div className={moduleClasses}>

                { /* Section Logo */ }
                <div className={(this.props.src) ? 'cards-logo' : 'cards-logo hidden'}>
                    <img src={logo} className="image" alt="logo" />
                </div>

                { /* Section Title */ }
                <div className={(this.props.title) ? 'cards-title' : 'cards-title hidden'}>
                    <Headings type={3} title={this.props.title}  />
                </div>

                { /* Section Desc */ }
                <div className={(this.props.desc) ? 'cards-text' : 'cards-text hidden'}>
                    <p className="center">{this.props.desc}</p>
                </div>

                { /* Cards */ }
                <div className={cardsContainer}>
                    {
                        this.state.cards.map((item, index) => {

                            let cardId = "card-" + index;
                            let cardBg = 'url("' + window.$imagesPath + item.src + '")';
                            let cardLink = (item.link !== '') ? <a className="link" href={item.link}>{item.title}</a> : '' ;

                            return(
                                <div key={cardId} className={(this.props.type === 1 || this.props.type === '1') ? 'card plain' : 'card'}>
                                    {cardLink}
                                    <div className={(item.src !== '') ? 'image' : 'image hidden'} style={{backgroundImage: cardBg}}></div>
                                    <div className="card-body">
                                        <div className="title">{item.title}</div>
                                        <div className="text">{item.desc}</div>
                                    </div>
                                </div>
                            );

                        })
                        
                    }
                </div>

                { /* Section CTA */ }
                <div className={(this.props.label  && this.props.link) ? 'cards-cta' : 'cards-cta hidden'}>
                    <div className="btn-wrapper centered">
                        <Button 
                        color="primary" 
                        variant="outlined" 
                        disableElevation 
                        onClick={() => gotoLink(this.props.link)} 
                        >{(this.props.label) ? this.props.label : 'Saiba mais'}</Button>
                    </div>
                </div>

                    
            </div>
        );
    }

}

export default CardsViewMode;