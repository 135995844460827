import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './contact.styles.scss';

// Import UI component
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from "@material-ui/core/CircularProgress";


//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

// Import Utils
import validate from '../../utils/form-validation/form-validation.utils';

class ContactViewMode extends Component{
    
    //Constructor
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            formItems: {
                name: {
                    id: 'name',
                    label: 'Nome',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255
                    }
                },
                email: {
                    id: 'email',
                    label: 'E-mail',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true,
                        maxLength: 255,
                        email: true
                    }
                },
                phone: {
                    id: 'phone',
                    label: 'Telefone',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        phone: true,
                    }
                },
                message: {
                    id: 'message',
                    label: 'Mensagem',
                    placeholder: '',
                    value: '',
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        required: true
                    }
                },
                terms: {
                    id: 'terms',
                    label: '',
                    placeholder: '',
                    value: false,
                    hasTouched: false,
                    hasError: false,
                    errorMsg: '',
                    rules: {
                        terms: true
                    }
                },

            }
        }
    }

    componentDidMount(){

        
       
    }

    render(props){

        // FORMS: INPUT CHANGE HANDLE FUNCTION
        const handleChange = (event) => {

            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // Get current FormItems object from State
            const formItems = this.state.formItems;

            // Get State of the Input
            const formItem = {
                ...formItems[name]
            };

            // Validate Element if it has been Touched Before
            if(formItem.hasTouched){
                if(formItem.rules){
                    const validation = validate(value, formItem.rules, formItems);
                    formItem.hasError = validation[0];
                    formItem.errorMsg = validation[1];
                }else{
                    formItem.hasError = false;
                    formItem.errorMsg = '';
                }
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        };

        // FORMS:  HANDLE CHECKBOX 
        const handleSwitch = (event) => {

            const name = 'terms';
            const value = !this.state.formItems.terms.value;

            console.dir(value);

            // Get current FormItems object from State
            const formItems = this.state.formItems;
            const formItem = formItems[name];

           // Validate
           if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Set the new value to element
            formItem.value = value;

            // Update formItems collection
            formItems[name] = formItem;


            // Update State
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMS: INPUT BLUR HANDLE FUNCTION
        const handleBlur = (event) => {
                    
            // Get Name and Value from element
            const name = event.target.name;
            const value = event.target.value;

            // formItems collection
            const formItems = {
                ...this.state.formItems
            };

            // state of modified element
            const formItem = {
                ...formItems[name]
            };

            formItem.hasTouched = true;
            if(formItem.rules){
                const validation = validate(value, formItem.rules, formItems);
                formItem.hasError = validation[0];
                formItem.errorMsg = validation[1];
            }else{
                formItem.hasError = false;
                formItem.errorMsg = '';
            }

            // Update formItems collection
            formItems[name] = formItem;

            // Update state
            this.setState({ 
                ...this.state, 
                formItems: formItems
            });

        }

        // FORMS: HANDLE SUBMIT
        const handleSubmit = (event) => {

            // Prevent Default
            event.preventDefault();

            // Add Loader
            this.setState({
                ...this.state,
                sending: true
            });

            // Get form data
            let tag = this.props.tag;
            let name = this.state.formItems.name.value;
            let email = this.state.formItems.email.value;
            let phone = this.state.formItems.phone.value;
            let text = this.state.formItems.message.value;
            let message = this.props.message;
            let sendTo = this.props.sendTo;
            
            // Prepare Request
            const requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: { 
                    'Content-Type': 'application/json',
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Request-Method": "*"
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone: phone,
                    text: text,
                    source: tag,
                    message: message,
                    app: window.$appToken,
                    sendTo: sendTo
                })
            };

            // Request API
            fetch("/api/contact.php", requestOptions)
            .then(async response => {
                const data = await response.json();
    
                // check for error response
                if (!response.ok) {
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                }

                // fail case
                if(!data.success){   

                    this.setState({
                        ...this.state, 
                        sending: false,
                    });
    
                    // Show error modal
                    const modalObj = {
                        modalIcon: 'clear',
                        modalType: 'error',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Ops, algo correu mal :(',
                        modalParameters: {
                            text: data.feedback
                        },
                        modalActions: []
                      }
                      this.props.openModal(modalObj);


                // success case
                }else{

                    // Show success modal
                    const modalObj = {
                        modalIcon: 'done',
                        modalType: 'success',
                        modalComponent: 'GENERAL_ALERT',
                        modalTitle: 'Recado recebido ;)',
                        modalParameters: {
                            text: 'Obrigado por sua mensagem. Em breve entraremos em contato.'
                        },
                        modalActions: []
                      }
                      this.props.openModal(modalObj);

                      // Clean inputs
                      const formItems = this.state.formItems;
                      formItems.name.value = '';
                      formItems.email.value = '';
                      formItems.phone.value = '';
                      formItems.message.value= '';
                      formItems.terms.value = false;

                      // Remove loader
                      this.setState({
                        ...this.state, 
                        formItems: formItems,
                        sending: false,
                    });

                }

            })
            .catch(error => {

                // case server error
                this.setState({
                    ...this.state, 
                    sending: false,
                });

                // show error modal
                const modalObj = {
                    modalIcon: 'clear',
                    modalType: 'error',
                    modalComponent: 'GENERAL_ALERT',
                    modalTitle: 'Ops, algo correu mal :(',
                    modalParameters: {
                        Text: 'Não foi possível aceder aos serviços de login no momento. Tente novamente mais tarde ou contate o suporte.'
                    },
                    modalActions: []
                }
                this.props.openModal(modalObj);

            });

        }

        // ENABLE / DISABLE SUBMIT BUTTON 
        const submitIsEnabled = () => {
            
            let disabled = false;
            const formItems = this.state.formItems;
            const keys = Object.keys(formItems);
            const size = keys.length;

            // If any field in component form has an error
            for(let i = 0; i < size; ++i){

                let keyItem = keys[i];
                let item = formItems[keyItem];
                if(item.hasError){
                    disabled = true;
                }

            }

            // If there is no card registered
            if(this.state.formItems.name.value === '' || this.state.formItems.email.value === '' || !this.state.formItems.terms.value){
                disabled = true;
            }

            return disabled;

        }

        const formId = 'form-' + this.props.contentId;

        return(
            <div className='module-contact'>
            

                <div className='module-body'>
                    
                    {/* FORM AREA */}
                    <div className='form-area'>
                        <form className='form' id={formId} name={formId}>
                        <div className={(this.state.sending) ? 'loading' : 'loading hidden'}><CircularProgress /></div>
                    
                        { /* TITLE */ }
                        <div className={(this.props.title) ? 'title' : 'title hidden'}>
                                <h4 className="heading-4 left">{this.props.title}</h4>
                        </div>

                        { /* FORM */}
                        <Grid container spacing={2}>

                                {/* NAME */}
                                <Grid item xs={12}>
                                <TextField
                                name={this.state.formItems.name.id}
                                id={this.state.formItems.name.id + '-' + this.props.contentId}
                                value={this.state.formItems.name.value}
                                label={this.state.formItems.name.label}
                                placeholder={this.state.formItems.name.placeholder}
                                error={this.state.formItems.name.hasError}
                                helperText={this.state.formItems.name.errorMsg}
                                fullWidth
                                multiline
                                variant="outlined"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                />
                                </Grid>

                                {/* EMAIL */}
                                <Grid item xs={12}>
                                    <TextField
                                    name={this.state.formItems.email.id}
                                    id={this.state.formItems.email.id + '-' + this.props.contentId}
                                    value={this.state.formItems.email.value}
                                    label={this.state.formItems.email.label}
                                    placeholder={this.state.formItems.email.placeholder}
                                    error={this.state.formItems.email.hasError}
                                    helperText={this.state.formItems.email.errorMsg}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                </Grid>

                                {/* PHONE */}
                                <Grid item xs={12}>
                                    <TextField
                                    name={this.state.formItems.phone.id}
                                    id={this.state.formItems.phone.id + '-' + this.props.contentId}
                                    value={this.state.formItems.phone.value}
                                    label={this.state.formItems.phone.label}
                                    placeholder={this.state.formItems.phone.placeholder}
                                    error={this.state.formItems.phone.hasError}
                                    helperText={this.state.formItems.phone.errorMsg}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                </Grid>

                                {/* MESSAGE */}
                                <Grid item xs={12}>
                                    <TextField
                                    name={this.state.formItems.message.id}
                                    id={this.state.formItems.message.id + '-' + this.props.contentId}
                                    value={this.state.formItems.message.value}
                                    label={this.state.formItems.message.label}
                                    placeholder={this.state.formItems.message.placeholder}
                                    error={this.state.formItems.message.hasError}
                                    helperText={this.state.formItems.message.errorMsg}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />
                                </Grid>

                                { /* TERMS */ }
                                <Grid item xs={12}>
                                    <div className="terms">
                                    <Checkbox checked={this.state.formItems.terms.value} onChange={handleSwitch} name={this.state.formItems.terms.id} error={this.state.formItems.terms.hasError.toString()}/>
                                    <label onClick={handleSwitch} htmlFor={this.state.formItems.terms.id}>
                                        Estou ciente e de acordo com a <a href="/privacidade" target="_blank">Política de Privacidade</a> e os <a href="/termo-de-uso" target="_blank">Termos de Uso</a> do site.
                                        <FormHelperText error={this.state.formItems.terms.hasError}>{this.state.formItems.terms.errorMsg}</FormHelperText>
                                    </label>
                                    </div>
                                    
                                </Grid>

                                { /* BUTTONS */ }
                                <Grid item xs={12}>
                                    <div className="btn-wrapper on-right">
                                        <Button 
                                        type="submit" 
                                        color="primary" 
                                        variant="contained" 
                                        disableElevation 
                                        onClick={(e) => handleSubmit(e)} 
                                        disabled={submitIsEnabled()}
                                        >{(this.props.label) ? this.props.label : 'Enviar'}</Button>
                                    </div>
                                </Grid>

                        </Grid>

                </form>
                    </div>

                    { /* TEXT AREA */}
                    <div className={(this.props.text) ? 'text-area' : 'text-area hidden'}>
                        <div className='richtext' dangerouslySetInnerHTML={{__html: this.props.text}}></div>
                    </div>

                </div>
            
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default withRouter(connect(null, mapDispatchToProps)(ContactViewMode));