import React, {Component} from 'react';
import { connect } from 'react-redux';
import '../page/page.styles.scss';
import './page-preview.styles.scss';

import ContainerWrapper from '../../containers/container-wrapper/container-wrapper.component';
import Comments from '../../components/comments/comments.component';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from "@material-ui/core/Button";

import {CircularProgress} from '@material-ui/core';

//Import Redux Actions
import { openModal } from '../../redux/modal/modal.actions';

import {getToken} from "../../utils/user-validation/user-validation.utils";

class PagePreview extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.versionId = (this.props.pageId) ? this.props.pageId : this.props.match.params.pageId;
        this.backTo = (this.props.match.params.backTo) ? this.props.match.params.backTo : 'edit';
        this.state = {
            page: this.pageId,
            loading: false,
            pageData: [],
            modules: [],
            bgOptions: [
              {
                  id: 0,
                  label: 'Branco sólido',
                  class: 'bg-white'
              },
              {
                  id: 1,
                  label: 'Cinza sólido',
                  class: 'bg-gray',
              }
          ]
        }
    }

    setBackground = (option) => {

      let newArray = this.state.bgOptions.filter(function (el) {
          return el.id === option;
      });

      if(newArray.length > 0){
          return newArray[0].class;
      }
      return;

  }

    componentDidMount(){

        // Verify for Session Modals
        const hasModalToOpen = localStorage.getItem('openModal');
        if(hasModalToOpen && hasModalToOpen === '1'){
            const modalObj = JSON.parse(localStorage.getItem('modalObj'));
            this.props.openModal(modalObj);
        }

        this.setState({
            ...this.state,
            loading: true
        })

        const userToken = getToken();

        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: { 
              'Content-Type': 'application/json',
              "Access-Control-Request-Headers": "*",
              "Access-Control-Request-Method": "*"
          },
            body: JSON.stringify({
              userToken: userToken,
              app: window.$appToken,
              id: this.versionId
            }),
          };
    
          fetch("/api/get_preview.php", requestOptions)
          .then(async (response) => {
    
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }

            if(!data.success){
                window.location = '/404';
            }

            document.getElementById('site').classList.add(this.setBackground(data.pageData.background));

            this.setState({ 
              ...this.state,
              loading: false,
              pageData: data.pageData,
              modules: data.modules
            });
    
          })
          .catch((error) => {
            console.dir(error);
          });

    }

    //Rendering
    render(){
        
        const renderComments = (showComments) => {
          if(parseInt(showComments) === 1){
            return(
              <Comments content={this.state.pageData.id} />
            )
          }
          return;
        }

        const backToEditor = (id) => {
          if(this.backTo !== 'edit'){
            window.location = '/approve-contents/';
          }else{
            window.location = '/edit/' + id;
          }
        }

        

        return(
            <div className='page page-preview'>
            <div className={(this.state.loading)? 'loading' : 'loading hidden'}><CircularProgress size={48}/></div>
            <div className="preview-advice">
            
              <Button 
              className="back-button" 
              color="inherit"
              variant="outlined" 
              onClick = {() => backToEditor(this.state.pageData.id)}
              startIcon={<ArrowBackIcon />}
              >
              Sair do modo preview
              </Button>
              
            </div>
          
            <div className={'content content-page ' + this.setBackground(this.state.pageData.background)}>
            {
                // eslint-disable-next-line
                this.state.modules.map((item) => {
                        return(
                            <ContainerWrapper 
                            key={item.content}
                            pageData={this.state.pageData}
                            {...item} 
                            />
                        );
                })
            }
            { renderComments(this.state.pageData.comments) }
            </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: modalData => dispatch(openModal(modalData)),
});

export default connect(null, mapDispatchToProps)(PagePreview);