import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import './module-selector.styles.scss';

// Import Redux Actions
import { closeSelector, getModules, toggleLoader } from '../../redux/modules-selector/modules-selector.actions';

// Import Material-UI components
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from '@material-ui/core/Tooltip';

// Import Utils
import { getToken } from '../../utils/user-validation/user-validation.utils';

class ModuleSelector extends Component{
    
    //Constructor
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount(){

            this.props.toggleLoader();

            const userToken = getToken();
            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: { 
                'Content-Type': 'application/json',
                "Access-Control-Request-Headers": "*",
                "Access-Control-Request-Method": "*"
            },
                body: JSON.stringify({
                userToken: userToken,
                app: window.$appToken
                }),
            };
        
            // Load Modules
            fetch("/api/get_modules.php", requestOptions)
            .then(async (response) => {
              
              const data = await response.json();
      
              // check for error response
              if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
      
              if(!data.authenticated){

              }else{
                  this.props.getModules(data.items);
              }
      
            })
            .catch((error) => {
              
            });

    }

    render(props){

        const getIcon = (icon) => {
            return require(`../../assets/images/${icon}`)
        }

        return(
            <div className={(this.props.moduleSelector.isOpen)?'module-selector':'module-selector hidden'}>
                <div className="window">
                    
                    <div className="header">
                        <h2 className="title">Selecione um módulo</h2>
                        <button className="close-button" onClick={() => this.props.closeSelector()}><CloseIcon /> </button>
                    </div>

                    <div className="body">
                        <div className={(this.props.moduleSelector.loadingResults) ? 'loading' : 'loading hidden'}><CircularProgress size={24} /></div>
                        <div className="modules">
                            {
                                this.props.moduleSelector.modules.map((item) => {

                                    let indexKey = 'module-' + item.id;

                                    return(
                                    <div className="module" key={indexKey} onClick={() => this.props.add(item, this.props.moduleSelector.position)}>
                                        <Tooltip title={item.label} arrow>
                                        <div className="icon"><img src={getIcon(item.icon)} alt={item.label} /></div>
                                        </Tooltip>
                                    </div>
                                    )

                                })
                            }
                            
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => ({
    moduleSelector: state.moduleSelector,
  });
  
  const mapDispatchToProps = dispatch => ({
    getModules: modules => dispatch(getModules(modules)),
    closeSelector: () => dispatch(closeSelector()),
    toggleLoader: () => dispatch(toggleLoader())
  });
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleSelector));
